/**
 * copy from mobile
 * 溢出检测，一般用于实现文本溢出检测
 */
import React, {
    CSSProperties,
    HTMLAttributes,
    ReactNode,
    DependencyList,
    useLayoutEffect,
    useRef,
    useState,
    useMemo,
} from 'react';

export interface OverflowDetectProps extends Omit<HTMLAttributes<HTMLDivElement>, 'style'> {
    ellipsis: ReactNode;
    normal: ReactNode;
    deps?: DependencyList;
    hiddenStyle?: CSSProperties;
}

export default function OverflowDetect({ ellipsis, normal, deps = [], hiddenStyle, ...props }: OverflowDetectProps) {
    const eRef = useRef<HTMLDivElement>(null);
    const nRef = useRef<HTMLDivElement>(null);
    const [showEllipsis, setShowEllipsis] = useState(false);
    useLayoutEffect(() => {
        const eHeight = eRef.current?.offsetHeight || 0;
        const nHeight = nRef.current?.offsetHeight || 0;
        setShowEllipsis(Math.round(nHeight) > Math.round(eHeight));
    }, deps);
    const mergedHiddenStyle: CSSProperties = useMemo(
        () => ({
            visibility: 'hidden',
            position: 'absolute',
            ...hiddenStyle,
        }),
        [hiddenStyle],
    );
    return (
        <div style={{ position: 'relative' }} {...props}>
            <div style={showEllipsis ? undefined : mergedHiddenStyle} ref={eRef}>
                {ellipsis}
            </div>
            <div style={showEllipsis ? mergedHiddenStyle : undefined} ref={nRef}>
                {normal}
            </div>
        </div>
    );
}
