import { IHomeState } from '../reducers/home';

export function initHomeData(data: IHomeState, isMobile?: boolean) {
    if (isMobile) {
        data.top_author?.sort((a, b) => b.is_top - a.is_top);
    }

    return {
        type: 'INIT_HOME_DATA',
        data,
    };
}
