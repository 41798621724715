import { isBrowser } from '@constants/const';

export let accountSDK;

const WebInterfaceSdk = isBrowser && require('@byted-sdk/account-api').WebInterfaceSdk;

export function createAccountInstance() {
    if (!accountSDK) {
        accountSDK = new WebInterfaceSdk({
            aid: 4793,
            isOversea: true,
            scene: 'normal',
            region: 'sg',
            language: 'en',
        });
    }
}
