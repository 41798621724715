import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { MessageText } from '@constants/index';
import { isEmailVaild, ProtocolPath, openProtocol, Tea } from '@utils/index';

import { useTranslation, getHtml } from '@ies/intl-react-plugin';

import { Input, Message } from '@byted/serial-components-i18n';

import { keepEmail } from '@service/api';
import { LangCode } from '@common/lang';

import { EmailCode, EmailText, EmailTipColor, FooterLearnType, getIcon, ProgramUrl } from './setting';
import cs from 'classnames';

import './index.less';
import './index.h5.less';

export enum FooterTheme {
    White,
    Black,
}
interface IProps {
    theme?: FooterTheme;
}

const Footer: React.FC<IProps> = ({ theme = FooterTheme.Black }) => {
    const [email, setEmail] = useState('');
    const [emailCode, setEmailCode] = useState(EmailCode.Null);

    const { i18n } = useTranslation();

    const langCode = i18n.language as LangCode;

    const handleSendEmail = async () => {
        try {
            if (emailCode === EmailCode.EmailInvaild) {
                return;
            }
            const res = await keepEmail({ email, language: i18n.language });
            const { code } = res as any;
            if (code === EmailCode.Success) {
                setEmailCode(code);
                return;
            }
            Message.error(MessageText.netError);
        } catch (e) {
            Message.error(MessageText.netError);
        }
    };

    const handleEmailChange = (v: string) => {
        setEmail(v);
        setEmailCode(isEmailVaild(v) ? EmailCode.Null : EmailCode.EmailInvaild);
    };

    const postLearnTea = (type: number) => {
        Tea('website_footer_learn', { content_info: type });
    };

    const postContactTea = (type: number) => {
        Tea('website_footer_contact', { content_info: type });
    };

    const openLangProtocol = openProtocol(langCode);

    return (
        <footer className={cs('footer', { white: theme === FooterTheme.White })}>
            <div className="footer-group">
                <h3>
                    <p>{i18n.t('web_info_email')}</p>
                    <p>{getHtml(['web_info_email1'], {})}</p>
                </h3>
                <div className="footer-input-container">
                    <Input placeholder="Enter your email" onChange={handleEmailChange} />
                    <i className="footer-arrow" onClick={handleSendEmail} />
                </div>
                {emailCode !== EmailCode.Null && (
                    <div className="footer-email-tip" style={{ color: EmailTipColor[emailCode] }}>
                        {i18n.t(EmailText[emailCode])}
                    </div>
                )}
            </div>
            <div className="footer-group">
                <h3>{i18n.t('web_footer_learn')}</h3>
                <div className="footer-link">
                    <Link
                        className="footer-link-item"
                        to="/event/benefit"
                        onClick={() => postLearnTea(FooterLearnType.Benefits)}
                    >
                        {i18n.t('PC_writer_benefits')}
                    </Link>
                    <div
                        className="footer-link-item"
                        onClick={() => {
                            postLearnTea(FooterLearnType.Community);
                            openLangProtocol(ProtocolPath.Community)();
                        }}
                    >
                        {i18n.t('pc_footer_guidelines')}
                    </div>
                    <a
                        target="_blank"
                        className="footer-link-item"
                        href="/faq"
                        rel="noreferrer"
                        onClick={() => postLearnTea(FooterLearnType.FAQ)}
                    >
                        {i18n.t('web_footer_faq')}
                    </a>
                </div>
            </div>
            <div className="footer-group">
                <h3>{i18n.t('web_footer_legal')}</h3>
                <div className="footer-link">
                    <div className="footer-link-item" onClick={openLangProtocol(ProtocolPath.Service)}>
                        {i18n.t('pc_footer_termsofservice')}
                    </div>
                    <div className="footer-link-item" onClick={openLangProtocol(ProtocolPath.Privacy)}>
                        {i18n.t('sso_signup_legal4')}
                    </div>
                    <div className="footer-link-item" onClick={openLangProtocol(ProtocolPath.Cookie)}>
                        {i18n.t('pc_footer_cookiepolicy')}
                    </div>
                </div>
            </div>
            <div className="footer-group footer-group-connect">
                <h3>{i18n.t('web_footer_connected')}</h3>
                <div className="footer-icon">
                    {getIcon(langCode).map((item, index) => (
                        <a
                            href={item.link}
                            key={index}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => postContactTea(item.type)}
                        >
                            <i className={`footer-icon-${item.class}`} />
                        </a>
                    ))}
                </div>
                <span>{i18n.t('web_footer_copyright')}</span>
            </div>
            <div className="footer-bottom">
                <i className="footer-logo" />
                {/* starling-disable-next-line */}
                <span className="footer-bottom-text">© 2021 Poligon Pte. Ltd. </span>
            </div>
        </footer>
    );
};

export default Footer;
