import { LangCode } from '@common/lang';
import I18n from '@ies/starling_intl';
import qs from 'qs';
import { createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { isInApp, isInFizzo } from './env';

export const AdminPathPC = {
    UserInfo: '/user-info',
    Announcement: '/notice',
};

export type AcademyLangCode = LangCode.ID | LangCode.EN;

export const AdminPathH5 = {
    Announcement: '/message/announcement',
    Activity: '/message/activity',
    Academy: {
        [LangCode.EN]: '/message/academy',
        [LangCode.ID]: '/community?page=academy',
    },
};

export const UserPathH5 = '/community/user';

// 跳转后台
export enum Entry {
    PC,
    H5,
}

export interface IAppInfo {
    entry: Entry;
}

export const AppInfoContext = createContext<IAppInfo>({ entry: Entry.PC });

export function useAppInfo() {
    return useContext(AppInfoContext);
}

const connector = (url: string) => `${url.includes('?') ? '&' : '?'}`;

const fizzo = {
    schema: 'worldance4171',
};
const myTopia = {
    schema: 'worldance8481',
};

const getSchema = () => (isInFizzo() ? fizzo.schema : myTopia.schema);

// 纯端外跳转
export const useNav = () => {
    const history = useHistory();
    return (url: string, params?: { [key: string]: string }) => {
        history.push(`${url}?${qs.stringify(params)}`);
    };
};

export type PathMap = { [k in Entry]: string };
const Base: PathMap = {
    [Entry.PC]: '/main/writer',
    [Entry.H5]: '/mobile/writer',
};

// 作家后台: 跳转添加公参
export function getAminUrl(entry: Entry, page?: PathMap, params?: Record<string, any>) {
    const base = Base[entry];
    let url = page ? base + page[entry] : base;

    // 用于slardar上报
    url = `${url}${connector(url)}referer=official`;

    // 添加 query
    url = `${url}${params ? `&${qs.stringify(params)}` : ''}`;

    const { parent_activity } = qs.parse(location.search.slice(1));
    if (parent_activity) {
        url = `${url}&parent_activity=${parent_activity}`;
    }

    return url;
}

// 作家后台: 纯端外跳转
export function useGoAdmin() {
    const { entry } = useAppInfo();

    return (page: string | PathMap = '', params?: Record<string, any>) => {
        const map = typeof page === 'string' ? { [Entry.PC]: page, [Entry.H5]: page } : page;
        location.href = getAminUrl(entry, map, params);
    };
}

export enum ParamValue {
    Off,
    On,
}

export interface SchemaParams {
    // 隐藏导航条
    hideNavigationBar?: ParamValue;
    // 隐藏状态栏
    hideStatusBar?: ParamValue;
    // 禁止bounce （ios only）
    bounceDisable?: ParamValue;
    // 隐藏打开页面时的加载loading
    hideLoading?: ParamValue;
    // 禁用页面body滚动（ios）
    isForbidWebviewScroll?: ParamValue;
    // 由前端手动关闭loading效果
    loadingButHideByFront?: ParamValue;
    // 解决ios键盘遮挡问题
    assistKeyBoard?: ParamValue;
    [key: string]: any;
}

export const BASE_PATH = '/mobile/writer';

// 作家后台h5: 兼容端内外跳转
export const goH5Admin = (
    path: string,
    search?: { [k: string]: any },
    newTab = false,
    schemaParams: SchemaParams = {
        hideNavigationBar: ParamValue.On,
        hideStatusBar: ParamValue.On,
        loadingButHideByFront: ParamValue.On,
    },
) => {
    if (!isInApp()) {
        const url = getAminUrl(Entry.H5, { [Entry.PC]: path, [Entry.H5]: path }, search);
        if (newTab) {
            window.open(url);
        } else {
            location.href = url;
        }
        return;
    }

    const query = qs.stringify(search);
    let url = query ? `${path}${connector(path)}${query}` : path;

    // 添加APP语言设置
    const langQuery = qs.stringify({ user_language: I18n.language });
    url = `${url}${connector(url)}${langQuery}`;

    // 添加schema参数
    const schemaQuery = qs.stringify(schemaParams);

    location.href = `${getSchema()}://webview?url=${location.origin}${BASE_PATH}${encodeURIComponent(
        url,
    )}&${schemaQuery}`;
};

// 官网: 兼容端内外跳转
export const goOfficial = (
    url: string,
    params?: { [key: string]: string },
    schemaParams: { [key: string]: number } = { hideStatusBar: ParamValue.On },
) => {
    if (isInApp()) {
        // 添加 query
        const paramStr = qs.stringify({
            ...params,
            hideNav: '1',
            language: I18n.language,
        });
        const query = paramStr ? `${connector(url)}${paramStr}` : '';
        let uri = encodeURIComponent(`${url}${query}`);

        // 添加 schema 参数
        uri = `${uri}&assistKeyBoard=1&${qs.stringify(schemaParams)}`;

        // 跳转链接
        location.href = `${getSchema()}://webview?url=${uri}`;

        return;
    }

    const uri = params ? `${url}${connector(url)}${qs.stringify(params)}` : url;
    location.href = uri;
};
