import { LangCode } from '@common/lang';

export enum FooterLearnType {
    Benefits,
    Referral,
    Community,
    FAQ,
}

export enum FooterContact {
    FB,
    INS,
    Discord,
    Twitter,
}

export enum EmailCode {
    Null = -1,
    Success = 0,
    EmailInvaild = -1006,
}

export const EmailText = {
    [EmailCode.Null]: '',
    [EmailCode.Success]: 'web_info_email_contact',
    [EmailCode.EmailInvaild]: 'web_info_email_correct',
};

export const EmailTipColor = {
    [EmailCode.Success]: '#0E814A',
    [EmailCode.EmailInvaild]: '#AE2020',
};

const getFacebook = (lang: LangCode) => {
    let link = 'https://www.facebook.com/Fizzoforauthors';
    if (lang === LangCode.ID) {
        link = 'https://www.facebook.com/FizzoIndonesia';
    }
    if (lang === LangCode.PT) {
        link = 'https://www.facebook.com/Fizzo-Escritor-pt-113328564704250';
    }
    if (lang === LangCode.ES) {
        link = 'https://www.facebook.com/Fizzo-Escritor-es-100905449295348';
    }
    return {
        link,
        class: 'facebook',
        type: FooterContact.FB,
    };
};

const getIns = (lang: LangCode) => {
    let link = 'https://www.instagram.com/fizzoforauthors/';
    if (lang === LangCode.ID) {
        link = 'https://www.instagram.com/fizzo_official_id/';
    }
    if (lang === LangCode.PT) {
        link = 'https://www.instagram.com/fizzo_escritor_pt/';
    }
    if (lang === LangCode.ES) {
        link = 'https://www.instagram.com/fizzo_escritor_es/';
    }
    return {
        link,
        class: 'ins',
        type: FooterContact.INS,
    };
};

const getDiscord = (lang: LangCode) => {
    let link = '';
    if (lang === LangCode.EN) {
        link = 'https://discord.gg/FYNpuSnfqU';
    }
    return {
        link,
        class: 'discord',
        type: FooterContact.INS,
    };
};

const getTwitter = (lang: LangCode) => {
    let link = '';
    if (lang === LangCode.ID) {
        link = 'https://twitter.com/Fizzo_ID';
    }
    return {
        link,
        class: 'twitter',
        type: FooterContact.Twitter,
    };
};

export const getIcon = (lang: LangCode) => {
    if (lang === LangCode.ID) {
        return [getFacebook(lang), getIns(lang), getTwitter(lang)];
    }

    if ([LangCode.ES, LangCode.PT].includes(lang)) {
        return [getFacebook(lang), getIns(lang)];
    }

    return [getFacebook(lang), getIns(lang), getDiscord(lang)];
};

export const ProgramUrl = {
    [LangCode.EN]: 'https://docs.google.com/document/d/1hN8aFpClkradflFqUv6cw6bDEt-nSQUCg2N8YIhOU6c/edit',
    [LangCode.ID]: 'https://bit.ly/3DMYOYH',
    [LangCode.ES]: 'https://docs.google.com/document/d/1hN8aFpClkradflFqUv6cw6bDEt-nSQUCg2N8YIhOU6c/edit',
    [LangCode.PT]: 'https://docs.google.com/document/d/1hN8aFpClkradflFqUv6cw6bDEt-nSQUCg2N8YIhOU6c/edit',
};
