import { Button, Empty, Message } from '@byted/serial-components-i18n';
import React, { useEffect, useRef, useState } from 'react';
import ArticleContent from '@components/article-content';
import { Tea } from '@utils/tea';
import { getFAQArticle } from '@service/api';
import { MessageText } from '@constants/value';
import cs from 'classnames';
import Loading from '@components/loading';
import { useLocationParams } from '@utils/hooks';
import { getFromSearchPage } from '../common';
import { useTranslation } from '@ies/intl-react-plugin';

import './index.less';

enum ClikedType {
    NotClick = -1,
    Solve = 0,
    NotSolve = 1,
}

// 当前选中文章的category_id
interface IProps {
    categoryId: string;
}
const Article: React.FC<IProps> = ({ categoryId }) => {
    // 点击了某个按钮
    const [clickedBtn, setClickedBtn] = useState<ClikedType>(ClikedType.NotClick);
    // 文章详情
    const [article, setArticle] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const reqArrRef = useRef<string[]>([]);

    const { params } = useLocationParams();
    const { i18n } = useTranslation();

    const articlePath = (params.article || '').split('_');

    // 获取文章
    const getArticle = async () => {
        try {
            setLoading(true);
            setClickedBtn(ClikedType.NotClick);
            const reqArr = reqArrRef.current;
            reqArr.push(categoryId);

            const resp = await getFAQArticle({
                category_id: categoryId,
            });

            if (resp.code) {
                setArticle({});
                return;
            }

            // 请求文章接口延时有点大，用户如果在一个请求还没完成时点击另外一个菜单，请求另外一篇文章
            // 这个时候第一篇文章请求完成，将会取消第二篇文章的loading
            // 而在第二篇文章请求返回的时候，会突兀地替换掉第一篇的内容
            // 这里用数组把每个请求按顺序丢进去，当最后一个请求的返回时才取消loading
            if (reqArr[reqArr.length - 1] === resp.data.category_id) {
                setArticle(resp.data);
                setLoading(false);
            }

            const isFromSearch = getFromSearchPage(params.article);

            Tea('show_faq_page', {
                category1: articlePath[0] || '',
                category2: articlePath[1] || '',
                category3: articlePath[3] || '',
                page_item_id: resp.data.item_id,
                enter_from: isFromSearch ? 0 : 1,
            });
        } catch (e) {
            Message.error(MessageText.netError);
        }
    };

    const onClickBtn = (type: ClikedType) => {
        setClickedBtn(type);
        Tea('click_help_button', {
            category1: articlePath[0] || '',
            category2: articlePath[1] || '',
            category3: articlePath[2] || '',
            page_item_id: article?.item_id,
            button_type: type,
        });
    };

    // 监听menu选中项变化，重新获取文章
    useEffect(() => {
        if (categoryId) {
            getArticle();
        }
    }, [categoryId]);

    if (loading) {
        return <Loading />;
    }

    return article.content ? (
        <>
            <ArticleContent
                data={{
                    title: article.title,
                    time: article.modify_time,
                    content: article.content,
                }}
                needDir={false}
            />
            <div className="faq-btn-group">
                <Button
                    type="default"
                    className={cs('faq-solve-btn', { active: ClikedType.Solve === clickedBtn })}
                    onClick={() => onClickBtn(ClikedType.Solve)}
                >
                    {i18n.t('helpdesk_page_solved')}
                </Button>
                <Button
                    type="default"
                    className={cs('faq-not-solve-btn', { active: ClikedType.NotSolve === clickedBtn })}
                    onClick={() => onClickBtn(ClikedType.NotSolve)}
                >
                    {i18n.t('helpdesk_page_unsolved')}
                </Button>
            </div>
            {clickedBtn === ClikedType.NotSolve && (
                <div className="faq-right-tip">
                    {i18n.t('helpdesk_page_unsolved_helpdesk')}
                    <b>{i18n.t('helpdesk_page_unsolved_helpdesk1')}</b>
                    {i18n.t('helpdesk_page_unsolved_helpdesk2')}
                </div>
            )}
        </>
    ) : (
        <Empty description={MessageText.netError} />
    );
};

export default Article;
