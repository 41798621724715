import Facebook from '@static/images/facebook.png';
import Google from '@static/images/google.png';
import Twitter from '@static/images/twitter.png';

export enum platformAppId {
    FaceBook = 1247,
    Google = 1245,
    Twitter = 1246,
}

export const LoginChannelList = [
    {
        name: 'sso_facebook',
        logo: Facebook,
        platformAppId: platformAppId.FaceBook,
        teaNeed: 'facebook',
    },
    {
        name: 'sso_google',
        logo: Google,
        platformAppId: platformAppId.Google,
        teaNeed: 'google',
    },
    {
        name: 'sso_twitter',
        logo: Twitter,
        platformAppId: platformAppId.Twitter,
        teaNeed: 'twitter',
    },
];

export const LoginChannelClientId = {
    facebook: '773377663248035',
    google: '841145012288-v37u83pkp5ndbjjnutshkpksjkvvid87.apps.googleusercontent.com',
    twitter: 'HWEFzgKOmPTd7lStyjkOWRJal',
};

export enum AccountPage {
    Login = 'login',
    Register = 'register',
    RegisterEmail = 'registerEmail',
    RegisterOauth = 'registerOauth',
    Forget = 'forget',
    AddName = 'addName',
}

export const getDaysOptions = (days = 31) =>
    Array(days)
        .fill(0)
        .map((_, i) => ({
            label: i + 1,
            value: i + 1,
        }));

const minDate = new Date(1900, 0, 1, 0, 0, 0);
const maxDate = new Date(new Date().getFullYear() - 1, 11, 31, 0, 0, 0);
export const YearOptions = Array(maxDate.getFullYear() - minDate.getFullYear() + 1)
    .fill(maxDate.getFullYear())
    .map((year, index) => ({
        label: year - index,
        value: year - index,
    }));

export enum ErrorCode {
    NotLogin = 101, // 未登录
    UserNameExist = 102, // 用户名已存在
    EmailError = 1003, // 邮箱验证失败
    NotRegistered = 1011, // 未注册
    EmailExist = 1023, // 邮箱已存在
    CodeError = 1202, // 验证码错误
    CodeFail = 1203, // 验证码失效
    AgeGate = 1395, // 年龄无效
    DeviceLocked = 1396, // 触发设备锁
}

export const Events = {
    WriterJoinThirdPart: 'writer_join_thridpart', // 第三方登陆注册情况
    WiterSignupEmail: 'writer_signup_email', // 在注册弹窗点击邮箱
    WriterJoinInformation: 'writer_join_information', // 注册登录的页面信息
    WriterSignupInformation: 'writer_signup_information', // 作者账号注册情况
};

// 登陆 / 注册类型
export enum JoinType {
    UserAcccountFromApp = 1, // 用户账号登录 -- 表示已有C端账号，但是身份非作者
    AuthorAccount = 2, // 作者账号登录 -- 表示已有作者身份账号
    Register = 3, //  注册 -- 表示无账号注册
}

// 注册类型
export enum JoinWay {
    Email = 1,
    Facebook = 2,
    Google = 3,
    Twitter = 4,
}

// 页面信息
export enum InformationType {
    EnterBirth = 1, // 填写生日-仅第三方注册会进入
    EmailRegsiter = 2, // 邮箱注册页面-仅邮箱注册进入
}

export enum AgeNotice {
    AgeGate = 1, // 小于13岁
    DeviceLock = 2, // 触发设备锁
    Success = 3, // 成功
}

export enum RegsiterStatus {
    Success = 1,
    Failed = 2,
}

export const AppJoinWay = {
    [platformAppId.FaceBook]: JoinWay.Facebook,
    [platformAppId.Google]: JoinWay.Google,
    [platformAppId.Twitter]: JoinWay.Twitter,
};

export enum ReportEvent {
    Email = 'email',
    Oauth = 'oauth',
}
