import { Input, Menu, Message } from '@byted/serial-components-i18n';
import React, { useEffect, useState } from 'react';
import { checkArticleIsInMenu, getDefaultActiveMenu } from './common';
import Header from '@components/header';
import { HeaderTheme } from '@constants/enum';
import { bigEmojiReg } from '@constants/const';
import { useHistory } from 'react-router-dom';
import { Tea } from '@utils/tea';
import { getFAQCategory } from '@service/api';
import { MessageText } from '@constants/value';
import { useTranslation } from '@ies/intl-react-plugin';
import Loading from '@components/loading';
import { hasKey } from '@utils/util';
import Search from './search';
import Article from './article';
import { useLocationParams } from '@utils/hooks';

import './index.less';
import RightBar from '@components/right-bar';

const { RankMenu } = Menu;
const FAQ: React.FC = () => {
    // 分类列表
    const [categoryList, setCategoryList] = useState([]);
    // menu展开的项，受控
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    // 当前选中文章的category_id
    const [categoryId, setCategoryId] = useState('');

    const { location, params } = useLocationParams();
    const history = useHistory();

    const searchKey = params.search;
    const articlePath = (params.article || '').split('_');

    const { i18n } = useTranslation();

    const getCategoryList = async () => {
        try {
            const resp = await getFAQCategory({ language: i18n.language });
            const { code, data } = resp;
            if (code) {
                throw Error();
            }
            setCategoryList(data.category_list);
        } catch (e) {
            Message.error(MessageText.netError);
        }
    };

    // 设置高亮和展开的菜单
    const setActiveAndOpenKeys = (init?: boolean) => {
        if (!categoryList.length) {
            return;
        }

        // 搜索文章，收起菜单
        if (hasKey(params, 'search')) {
            setCategoryId('');
            setOpenKeys([]);
            return;
        }

        // 如果当前路由有默认高亮的文章（如article=199_100_111），则展示文章
        if (hasKey(params, 'article') && checkArticleIsInMenu(articlePath, categoryList)) {
            setCategoryId(articlePath[articlePath.length - 1]);

            // 从搜索页后退，路由变化，需要通过activePath重新设置openKeys
            setOpenKeys([...openKeys, ...articlePath]);
            return;
        }

        // 如果没有高亮的菜单，则默认展示第一篇文章
        const defaultMenu = getDefaultActiveMenu(categoryList);
        setCategoryId(defaultMenu.key);
        setOpenKeys(defaultMenu.keyPath);
        if (init) {
            history.push(`/faq?article=${defaultMenu.keyPath.join('_')}`);
        }
    };

    const setArticlePath = (path: string[]) => {
        // path为展开菜单的倒序数组
        let pathStr = '';

        // 转换path为字符串：[1100, 110, 112] -> 112_110_1100
        path.forEach(key => {
            pathStr = `_${key}${pathStr}`;
        });
        history.push(`/faq?article=${pathStr.slice(1)}`);
    };

    // 搜索，设置路由
    const setSearchKey = (v: string) => {
        const value = v.trim();
        history.push(`/faq?search=${value.replace(bigEmojiReg, '')}`);
    };

    // 监听菜单点开收起
    const handleChangeOpenKeys = (e, keyPath: string[]) => {
        setOpenKeys(keyPath);
    };

    // 点击菜单 -> 浏览器路由变更 -> useEffect监听location -> 设置当前选中文章（active变量）
    const handleChangeMenu = (keyPath: string[]) => {
        setArticlePath(keyPath);
        // 判断当前keyPath是否有对应的文章
        Tea('click_help_category', {
            category1: keyPath[2] || '',
            category2: keyPath[1] || '',
            category3: keyPath[0] || '',
        });
    };

    useEffect(() => {
        Tea('enter_faq');
        getCategoryList();
    }, []);

    useEffect(() => {
        setActiveAndOpenKeys(true);
    }, [categoryList]);

    // 监听路由变化，设置菜单的高亮和展开状态
    useEffect(() => {
        if (!categoryList.length) {
            return;
        }
        setActiveAndOpenKeys();
    }, [location]);

    if (!categoryList.length) {
        return <Loading className="faq-loading" />;
    }

    return (
        <div className="faq">
            <Header theme={HeaderTheme.Picture} />
            <div className="faq-body">
                <div className="faq-left">
                    <Input.Search placeholder={i18n.t('helpdesk_search')} value={searchKey} onSearch={setSearchKey} />
                    <RankMenu
                        list={categoryList}
                        selectedKeys={[categoryId]}
                        openKeys={openKeys}
                        onClickMenuItem={(key, e, keyPath) => handleChangeMenu(keyPath)}
                        onClickSubMenu={handleChangeOpenKeys}
                    />
                </div>
                <div className="faq-right">
                    {hasKey(params, 'search') ? <Search /> : <Article categoryId={categoryId} />}
                </div>
                <RightBar showFAQ={false} />
            </div>
        </div>
    );
};

export default FAQ;
