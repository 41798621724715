/**
 * 卡片点击态 copy from mobile
 */

import { useRef, useEffect, RefObject } from 'react';
import { composedPath } from './compose-path';

// 点击态开始延迟（避免滚动时触发press）
const StartDelay = 100;
// 结束延迟，让动画走完
const EndDelay = 200;
// 点击特定区域不触发点击态

interface UsePressProps {
    onPressed: (id: string) => void;
    containerRef: RefObject<HTMLDivElement>;
    excludeClasses?: string[];
    pressKeyCls?: string;
}

export function usePress({ onPressed, containerRef, excludeClasses = [], pressKeyCls }: UsePressProps) {
    const moveRef = useRef(false);
    const isStartRef = useRef(false);
    const pressKeyRef = useRef('');

    useEffect(() => {
        const container = containerRef.current;
        if (!container) {
            return () => {};
        }

        let startTid: number | undefined;

        const setPressed = (e: Event) => {
            const path = composedPath(e);
            // eslint-disable-next-line no-restricted-syntax
            for (const el of path) {
                if (!(el instanceof HTMLElement)) {
                    continue;
                }
                if (excludeClasses.length && excludeClasses.some(cls => el.classList.contains(cls))) {
                    return;
                }
                if (pressKeyCls && el instanceof HTMLElement && el.classList.contains(pressKeyCls)) {
                    onPressed(el.id);
                    pressKeyRef.current = el.id;
                    break;
                }
            }
        };

        const delayEnd = () => {
            setTimeout(() => {
                onPressed('');
                moveRef.current = false;
                isStartRef.current = false;
            }, EndDelay);
        };

        const onTouchStart = (e: TouchEvent) => {
            isStartRef.current = true;
            startTid = window.setTimeout(() => {
                if (moveRef.current || !isStartRef.current) {
                    return;
                }
                setPressed(e);
                startTid = undefined;
            }, StartDelay);
        };

        // 触摸滚动时立即取消press
        const onTouchMove = () => {
            if (pressKeyRef.current) {
                onPressed('');
            }
            moveRef.current = true;
        };

        const onTouchEnd = (e: Event) => {
            if (!moveRef.current && isStartRef.current && startTid) {
                clearTimeout(startTid);
                startTid = undefined;
                setPressed(e);
            }
            delayEnd();
        };

        const onTouchCancel = () => {
            delayEnd();
        };

        container.addEventListener('touchstart', onTouchStart, { passive: true });
        container.addEventListener('touchmove', onTouchMove, { passive: true });
        container.addEventListener('touchend', onTouchEnd, { passive: true });
        container.addEventListener('touchcancel', onTouchCancel, { passive: true });
        return () => {
            container.removeEventListener('touchstart', onTouchStart);
            container.removeEventListener('touchmove', onTouchMove);
            container.removeEventListener('touchend', onTouchEnd);
            container.removeEventListener('touchcancel', onTouchCancel);
        };
    });
}
