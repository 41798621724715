import React, { useEffect, useRef, useState } from 'react';
import { LangDetail, PageEvent, PageMap } from './setting';
import { useTranslation } from '@ies/intl-react-plugin';
import { useParams } from 'react-router-dom';
import cs from 'classnames';
import Loading from '@components/loading';
import { Empty } from '@byted/serial-components-i18n';
import { useRefState } from '@utils/hooks';
import { Tea } from '@utils/tea';
import Footer from '@components/footer';
import { MagicLogger } from '@utils/logger';

import './index.less';

const TIMEOUT = 60 * 1000;
const POLLING_TIMEOUT = 500;

interface IProps {
    isMobile?: boolean;
}

const MagicFrame: React.FC<IProps> = ({ isMobile = false }) => {
    const [frameHeight, frameHeightRef, setFrameHeight] = useRefState<number>(0);
    const [error, setError] = useState(false);
    const [loading, loadingRef, setLoading] = useRefState(true);
    const [frameUrl, setFrameUrl] = useState('');
    const observerRef = useRef<MutationObserver>();
    const timerRef = useRef<NodeJS.Timeout>();

    const { i18n } = useTranslation();
    // 活动名称
    const { name } = useParams<{ name: string }>();

    // iframe相关
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const event = PageMap[name] || {};

    const eventDetail: LangDetail = event.pages?.[i18n.language];

    const eventUrl = (isMobile ? eventDetail?.mobile : eventDetail?.pc) || '';

    const linkEventUrl = (e: PageEvent) => {
        // 当前语言没有该活动
        if (!eventDetail) {
            location.href = e.redirect || '/';
            return;
        }
        // 如果当前活动对应语言没有对应移动端或者pc端的链接，跳转到当前活动对应语言的重定向链接
        if (!eventUrl) {
            location.href = eventDetail.redirect || '/';
        }
    };

    const onLoadFailed = () => {
        if (loadingRef.current || frameHeightRef.current === 0) {
            MagicLogger.info('magic_error', { url: eventUrl });
            setError(true);
        }
    };

    const getPageStatus = () => {
        if (error) {
            return <Empty description={i18n.t('common_error_toast1')} />;
        }
        if (loading) {
            return <Loading />;
        }
        return null;
    };

    const handleChangeHeight = () => {
        const body = iframeRef.current?.contentWindow?.document.body;
        const scrollHeight = body?.scrollHeight || 0;
        const newHeight = Math.max(scrollHeight, frameHeightRef.current);
        const canShow = newHeight > 0;

        if (canShow) {
            // 如果页面高度大于 0，说明目前页面可以展示了，需要设置一个最小高度
            setFrameHeight(Math.max(newHeight, window.innerHeight));
            setLoading(false);
        }
    };

    const onFrameLoaded = () => {
        clearTimeout(timerRef.current);

        const body = iframeRef.current?.contentWindow?.document.body;

        handleChangeHeight();

        if (body) {
            const observer = new MutationObserver(() => {
                console.log('# —— DOM change frame height ——');
                console.log('changeHeight: ', body.scrollHeight);
                handleChangeHeight();
            });
            observer.observe(body, { childList: true, subtree: true });
            observerRef.current = observer;
        }
    };

    const pollingFrameHeight = () => {
        handleChangeHeight();

        console.log('# —— polling frame height ——');
        timerRef.current = setTimeout(pollingFrameHeight, POLLING_TIMEOUT);
    };

    // 监听iframe超时错误或者高度异常
    const listenTimeout = () => {
        const timer = setTimeout(() => {
            console.log('# —— load failed ——');
            onLoadFailed();
            clearTimeout(timerRef.current);
            clearTimeout(timer);
        }, TIMEOUT);
    };

    const sendTea = () => {
        if (event.tea) {
            const postEvent = event.tea;
            Tea(postEvent.event, postEvent.params || {});
        }
    };

    useEffect(() => {
        sendTea();
        pollingFrameHeight();
        listenTimeout();
        return () => {
            observerRef.current?.disconnect();
        };
    }, []);

    useEffect(() => {
        setFrameUrl(eventUrl || '');
    }, [eventUrl]);

    useEffect(() => {
        // 如果获取到的活动链接为空时跳转回首页
        linkEventUrl(event);
    }, [name, i18n.language]);

    return (
        <>
            {getPageStatus()}
            {!error && (
                <div
                    className={cs('magic-page-body', {
                        'magic-page-body--loading': loading,
                        'magic-page-body--fixed': eventDetail.fixed,
                    })}
                >
                    <iframe
                        title="magic"
                        ref={iframeRef}
                        src={frameUrl}
                        width="100%"
                        height={frameHeight}
                        onLoad={() => {
                            console.log('# —— load loaded ——');
                            onFrameLoaded();
                        }}
                        frameBorder="0"
                        onError={() => setError(true)}
                    />
                    <Footer />
                </div>
            )}
        </>
    );
};

export default MagicFrame;
