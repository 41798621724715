const fallbackWithoutUA = (fn: () => boolean) => {
    return () => {
        if (typeof navigator === 'undefined') {
            return false;
        }
        return fn();
    };
};

export const isiOS = fallbackWithoutUA(() => {
    const ua = navigator.userAgent;
    return (
        !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || (/Mac OS/i.test(ua) && location?.href.includes('force_mobile=1'))
    );
});

export const isInFizzo = fallbackWithoutUA(() => {
    const ua = navigator.userAgent;
    return ua.includes('Worldance');
});

export const isInMyTopia = fallbackWithoutUA(() => {
    const ua = navigator.userAgent;
    return ua.includes('MyTopia');
});

export const isInApp = () => isInFizzo() || isInMyTopia();

export const FIZZO_DOMAIN = {
    online: 'https://fizzo.org',
    boe: 'https://fictum-boei18n.byteintl.net',
};
