export interface IUserState {
    id: string;
    media_id: string;
    name: string;
    avatar: string;
    birthday: string;
    isLogin?: boolean;
    isLoading: boolean;
    day: number;
    email: string;
    region: string;
    have_bound_editor: boolean;
}

export const userInit: IUserState = {
    id: '',
    media_id: '',
    name: '',
    avatar: '',
    birthday: '',
    isLoading: true,
    day: -1,
    email: '',
    region: '',
    have_bound_editor: false,
};

// eslint-disable-next-line
export default function (state = userInit, action: ReduxAction): IUserState {
    const data = action.data;
    switch (action.type) {
        case 'FETCH_INFO':
            return {
                ...state,
                ...data,
            };
        case 'FETCH_ABSTRACT':
            return {
                ...state,
                ...data,
            };
        case 'FETCH_EMAIL':
            return {
                ...state,
                ...data,
            };
        case 'FETCH_FINISH':
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}
