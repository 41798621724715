import React, { FC, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import cs from 'classnames';
import { LoadMore, Toast, BottomInput } from '@arco-design/mobile-react-serial-i18n';
import { SerialI18nLoadMoreRef, SerialI18nLoadMoreProps } from '@arco-design/mobile-react-serial-i18n/esm/load-more';
import bridge from '@bridge/worldance';

import ClickLoad from '@components/h5/click-load';
import { usePress } from '@utils/press';
import {
    SerialI18nBottomInputRef,
    SerialI18nBottomInputProps,
    init,
    BottomInputEvent,
} from '@arco-design/mobile-react-serial-i18n/esm/bottom-input';

import Loading from '@components/h5/loading';
import ListLoading from '@components/h5/list-loading';
import CommentCard, {
    CommentCardRef,
    DiggCls,
    PressKeyCls,
    TitleActionCls,
} from '@components/h5/comment-list/comment-card';
import { useRefState, useUserInfo, Tea, px2rem } from '@utils/index';
import Icon from '@components/icon';
import {
    CollectOperate,
    CommentInfo,
    CommentLabelType,
    CommentSortType,
    DiggOperate,
    GetItemCommentListRespData,
    GetItemCommentListResponse,
    GetPostCommentListResponse,
    ReplyCommentInfo,
} from '@typings/api/novel/toutiao_muye_overseas_homeapi';
import { EventType } from '@typings/api/novel/origin_overseas_base';

import CommentTitle from './comment-title';
import Empty from '@components/h5/empty';
import { useTranslation } from '@ies/intl-react-plugin';
import { detectScrollFinished } from '@utils/srcoll';
import {
    createComment,
    createReplyComment,
    differenceByProp,
    formatCommentText,
    genCommentCardUId,
    getContainerScrollTop,
    getCommentCardDom,
    getCurrentCommentFromList,
    isContainerScrollToEnd,
    scrollContainerTo,
    updateItemInList,
} from './utils';
import { getPX } from '@utils/px';
import { isInApp, isiOS } from '@utils/env';
import { useReportComment } from '@components/h5/report-popup/report';
import {
    diggComment,
    getItemCommentList,
    getCommentReplyList,
    publishComment,
    replyComment,
    getPostCommentList,
} from '@service/api';
import { MessageText } from '@constants/value';
import { PickRequired } from '@utils/typings';
import { CommentErrorCode, CommentInfoWithReplyCursor } from './typings';
import { numberFormatter } from '@utils/number';
import { EventMap } from './event';

import './index.less';

const { toast } = Toast;

export enum CommentEvent {
    submit = 0,
    clickFilter,
}

export interface CommentListProps {
    diggCount?: number;
    collectCount?: number;
    itemId?: string;
    /** 区分评论场景 */
    eventType: EventType;
    getContainer: () => HTMLElement | null;
    actions: {
        like: (nextOperate: DiggOperate, userDigged: boolean) => Promise<boolean>;
        collect?: (nextOperate: CollectOperate, userCollected: boolean) => Promise<boolean>;
    };
    initScrollPosition?: number;
    hasCollect?: boolean;
}

const FIRST_LEVEL_COMMENT_KEY = 'firstLevelComment';
const MaxInputLength = 800;
const LIST_PAGE_SIZE = 10;
const REPLY_PAGE_SIZE = 3;
const getCommentTopBrowserOffset = () => (isInApp() ? 0 : 80);

// bottom input env config
init({
    isIOS: isiOS,
    px2rem,
    getPX,
    onKeyboardShow: bridge?.view?.onKeyboardShow,
    onKeyboardHide: bridge?.view?.onKeyboardHide,
});

const CommentList: FC<CommentListProps> = props => {
    const {
        diggCount,
        itemId = '',
        getContainer,
        eventType,
        actions,
        initScrollPosition,
        collectCount,
        hasCollect = false,
    } = props;

    const { i18n } = useTranslation();
    const user = useUserInfo();

    // 闭包老数据
    const [commentList, commentListRef, setCommentList] = useRefState<CommentInfoWithReplyCursor[]>([]);
    const [currentSortType, currentSortTypeRef, setCurrentSortType] = useRefState<CommentSortType>(
        CommentSortType.NEWEST,
    );
    const currentPageIndexRef = useRef(0);
    const listHasMoreRef = useRef(true);
    const [totalLength, setTotalLength] = useState(0); // comment and reply in total
    const [userDigged, setUserDigged] = useState(false);
    const [userCollected, setUserCollected] = useState(false);
    const [commentValue, setCommentValue] = useState('');
    const [currentCommentCtx, setCurrentCommentCtx] = useState<null | {
        comment: CommentInfo;
        reply?: ReplyCommentInfo;
        idxInList: number;
    }>(null); // 当前的目标评论 点击回复评论的时候会 set
    const [pressedCommentId, setPressedCommentId] = useState('');
    const [keyboardShow, setKeyboardShow] = useState(false);
    const [listLoading, setListLoading] = useState(true);
    const containerReadingY = useRef(0);
    const commentReadingY = useRef(0);

    const commentValueCacheRef = useRef<Map<string, string>>(new Map());
    const cardRefMap = useRef<Map<ReturnType<typeof genCommentCardUId>, CommentCardRef | null>>(new Map());
    const inputRef = useRef<SerialI18nBottomInputRef>(null);
    const commentAreaRef = useRef<HTMLDivElement>(null);
    const listContainerRef = useRef<HTMLDivElement>(null);
    const loadMoreRef = useRef<SerialI18nLoadMoreRef>(null);
    const digging = useRef(false);
    const collecting = useRef(false);

    const report = useReportComment({ commentType: eventType });

    /** -------- handles */
    const handleKeyboardShow = useCallback(() => {
        setKeyboardShow(true);
        // 简单防止 textarea 滚动穿透 仅 android
        if (!isiOS()) {
            const container = getContainer();
            if (!container) {
                return;
            }
            container.style.overflow = 'hidden';
        }
    }, [getContainer]);

    const handleKeyboardHide = useCallback(() => {
        setKeyboardShow(false);
        if (!isiOS()) {
            const container = getContainer();
            if (!container) {
                return;
            }
            container.style.overflow = 'scroll';
        }
    }, [getContainer]);

    const handleKeyboard: Exclude<SerialI18nBottomInputProps['onKeyboardReady'], undefined> = useCallback(kb => {
        if (!kb) {
            return;
        }
        kb.on(BottomInputEvent.Show, handleKeyboardShow);
        kb.on(BottomInputEvent.Hide, handleKeyboardHide);
    }, []);

    const handleInputChange = useCallback((val: string) => {
        setCommentValue(val);
    }, []);

    const handleLikeBtnClick = useCallback(async () => {
        if (digging.current) {
            return;
        }
        try {
            digging.current = true;
            const nextDigg = userDigged ? DiggOperate.UNDIGG : DiggOperate.DIGG;
            const success = await actions.like(nextDigg, userDigged);
            if (success) {
                setUserDigged(v => !v);
            }
        } catch (err) {
            toast(MessageText.netError);
        }
        digging.current = false;
    }, [userDigged, actions]);

    const handleCollectBtnClick = useCallback(async () => {
        if (actions.collect) {
            if (collecting.current) {
                return;
            }
            try {
                collecting.current = true;
                const nextCollect = userCollected ? CollectOperate.UNCOLLECT : CollectOperate.COLLECT;
                const success = await actions.collect(nextCollect, userCollected);
                if (success) {
                    setUserCollected(v => !v);
                }
            } catch (err) {
                toast(MessageText.netError);
            }
            collecting.current = false;
        }
    }, [userCollected, actions]);

    const inCommentArea = () => {
        const { top } = commentAreaRef.current?.getBoundingClientRect?.() || {};
        return (top || 0) - getCommentTopBrowserOffset() <= window.screenTop;
    };

    /**
     * 评论区在整个页面的 y
     */
    const getCommentAreaY = () => {
        const { top = 0 } = commentAreaRef.current?.getBoundingClientRect?.() || {};
        const currentY = getContainerScrollTop(getContainer);
        return top + currentY - getCommentTopBrowserOffset();
    };

    /**
     * 评论区在整个页面的 bottom y
     */
    const getCommentAreaEndY = () => {
        const { bottom = 0 } = commentAreaRef.current?.getBoundingClientRect?.() || {};
        const currentY = getContainerScrollTop(getContainer);
        return bottom + currentY - getCommentTopBrowserOffset();
    };

    const bindScrollToView = ({ item }: { item?: HTMLElement | null }) => {
        const keyboard = inputRef.current?.keyboard;
        const content = inputRef.current?.content;

        if (!(keyboard && content && item)) {
            return;
        }
        const onShow = () => {
            setTimeout(() => {
                const itemRect = item.getBoundingClientRect();
                const contentRect = content.getBoundingClientRect();
                const TopInputThreshold = getPX(100);
                let distance: number;
                // 输入框在下半部分
                if (contentRect.top > TopInputThreshold) {
                    // 卡在文本底部
                    distance = itemRect.bottom - contentRect.top - getPX(4);
                } else {
                    distance = itemRect.top - contentRect.bottom;
                }

                getContainer?.()?.scrollBy({ behavior: 'smooth', top: distance });
            }, 150);
        };
        keyboard.once(BottomInputEvent.Show, onShow);
    };

    const highlightCard = (ctx: Parameters<typeof genCommentCardUId>[0]) => {
        if (!ctx) {
            return;
        }
        const cardId = genCommentCardUId(ctx);
        const item = cardRefMap.current.get(cardId);
        item?.highlight();
    };

    /**
     * 拉起 input 键盘
     * @params item 需要滚动 into view 的 dom
     * @params targetCommentId 目标评论的 id genCommentCardUId 获得 用于获取缓存的文案
     *
     */
    const toggleInput = useCallback(
        ({
            item,
            targetCommentId,
        }: {
            item?: HTMLElement | null;
            targetCommentId?: ReturnType<typeof genCommentCardUId>;
        }) => {
            // 先绑定，否则focus后可能无法捕获show
            bindScrollToView({ item });
            const res = inputRef.current?.keyboard?.focus();
            if (!res) {
                // 如果res == false说明输入框已经展现
                return;
            }
            const cacheKey = targetCommentId || FIRST_LEVEL_COMMENT_KEY;
            const cachedValue = commentValueCacheRef.current.get(cacheKey);
            setCommentValue(cachedValue || '');
        },
        [],
    );

    const toggleInputAfterScroll = useCallback(() => {
        if (isiOS()) {
            // IOS 滚动之后拉起键盘 键盘会不见 所以需要先拉再滚动
            toggleInput({});
        } else {
            const clearScroll = detectScrollFinished(getContainer?.(), () => {
                clearScroll();
                // 安卓还是需要滚动结束再拉起键盘
                toggleInput({});
            });
        }
    }, [toggleInput, getContainer]);

    const handleCommentBtnClick = useCallback(() => {
        // 如果在阅读中（评论区的 top >= screenTop）
        //     记录「阅读位置」
        //     滚动页面到「评论区位置」（默认顶部）
        //         如果当前评论数 = 0 需要拉起键盘
        // 如果在评论中（评论区 top < screenTop）
        //     记录「评论区位置」
        //     滚动页面到「阅读位置」
        const commentAreaY = getCommentAreaY();
        const isInCommentArea = inCommentArea();
        const isReachShortCommentAreaEnd = !isInCommentArea && isContainerScrollToEnd(getContainer);
        // 特殊情况 comment 短 scrollTop 没有 < 0 再次点击
        if (isInCommentArea || isReachShortCommentAreaEnd) {
            commentReadingY.current = getContainerScrollTop(getContainer);
            scrollContainerTo(getContainer, containerReadingY.current || 0);
        } else {
            containerReadingY.current = getContainerScrollTop(getContainer);
            if (!totalLength && !listLoading) {
                // 没有评论的时候 需要拉起键盘输入框
                toggleInputAfterScroll();
            }
            // hack 加一些偏移量 IOS screenTop 会不准
            scrollContainerTo(getContainer, (commentReadingY.current || commentAreaY) + 2);
        }
    }, [totalLength, toggleInputAfterScroll, listLoading, getContainer]);

    const clearInput = () => {
        const cacheKey = currentCommentCtx ? genCommentCardUId(currentCommentCtx) : FIRST_LEVEL_COMMENT_KEY;
        commentValueCacheRef.current.set(cacheKey, '');
        setCommentValue('');
        setCurrentCommentCtx(null);
    };

    const handleCancelInput = () => {
        const cacheKey = currentCommentCtx ? genCommentCardUId(currentCommentCtx) : FIRST_LEVEL_COMMENT_KEY;
        commentValueCacheRef.current.set(cacheKey, commentValue);
        if (currentCommentCtx) {
            // 尝试展示一级没有提交的文案
            const defaultValue = commentValueCacheRef.current.get(FIRST_LEVEL_COMMENT_KEY);
            setCommentValue(defaultValue || '');
            setCurrentCommentCtx(null);
        }
        // 直接评论文章没有 context 此时要保留 value
    };

    const handleReplyCard = (
        context: NonNullable<typeof currentCommentCtx>,
        e: React.MouseEvent<HTMLElement, MouseEvent>,
    ) => {
        const item = getCommentCardDom(e.nativeEvent);
        const commentId = genCommentCardUId(context);
        setCurrentCommentCtx(context);
        toggleInput({
            item,
            targetCommentId: commentId,
        });
    };

    const updateCommentInList = (
        newComment: PickRequired<Partial<CommentInfo | CommentInfoWithReplyCursor>, 'comment_id'>,
    ) => {
        const [current] = getCurrentCommentFromList(commentListRef.current, newComment.comment_id);
        if (!current) {
            return;
        }
        const target: CommentInfo = {
            ...current,
            ...newComment,
        };
        const newList = updateItemInList(target, commentListRef.current, 'comment_id');
        setCommentList(newList);
    };

    const updateReplyInList = (newReply: PickRequired<Partial<ReplyCommentInfo>, 'reply_id'>, commentId: string) => {
        const [comment, reply] = getCurrentCommentFromList(commentListRef.current, commentId, newReply.reply_id);
        if (!reply || !comment) {
            return;
        }
        const targetReply = {
            ...reply,
            ...newReply,
        };
        const targetComment: CommentInfo = {
            ...comment,
            reply_list: updateItemInList(targetReply, comment.reply_list || [], 'reply_id'),
        };
        const newList = updateItemInList(targetComment, commentListRef.current, 'comment_id');
        setCommentList(newList);
    };

    const insertComment = (newComment: CommentInfo, index: number) => {
        const curList = commentListRef.current || [];
        const newList = [...curList.slice(0, index), newComment, ...curList.slice(index)];
        setCommentList(newList);
        setTotalLength(l => l + 1);
    };

    const insertReply = (newReply: ReplyCommentInfo, index: number, commentId: CommentInfo['comment_id']) => {
        const [comment] = getCurrentCommentFromList(commentListRef.current, commentId);
        if (!comment) {
            return;
        }
        const curReplyList = comment?.reply_list || [];
        updateCommentInList({
            comment_id: commentId,
            reply_count: (comment?.reply_count || 0) + 1,
            reply_list: [...curReplyList.slice(0, index), newReply, ...curReplyList.slice(index)],
        });
        setTotalLength(l => l + 1);
    };

    const handleToggleDigg = async (context: NonNullable<typeof currentCommentCtx>) => {
        const { comment, reply } = context;
        const [targetCommentId, operate] = reply // 先检查是不是二级评论的点赞
            ? [reply.reply_id, reply.author_digg ? DiggOperate.UNDIGG : DiggOperate.DIGG]
            : [comment.comment_id, comment.author_digg ? DiggOperate.UNDIGG : DiggOperate.DIGG];
        const initCommentList = [...commentList];
        // 乐观更新，先改变UI
        if (reply) {
            const { author_digg, digg_count } = reply;
            updateReplyInList(
                {
                    reply_id: reply.reply_id,
                    digg_count: (digg_count || 0) + (author_digg ? -1 : 1),
                    author_digg: !author_digg,
                },
                comment.comment_id,
            );
        } else {
            const { author_digg, digg_count } = comment;
            updateCommentInList({
                comment_id: comment.comment_id,
                digg_count: (digg_count || 0) + (author_digg ? -1 : 1),
                author_digg: !author_digg,
            });
        }
        try {
            const res = await diggComment({
                comment_id: targetCommentId,
                operate,
            });
            if (res?.code !== 0) {
                throw res;
            }
        } catch (err) {
            // 接口失败，还原状态
            setCommentList(initCommentList);
            toast(MessageText.netError);
        }
    };

    const isListHasMore = () => listHasMoreRef.current;

    const handleClickReport = (ctx: NonNullable<typeof currentCommentCtx>) => {
        const { comment, reply } = ctx;
        if (reply?.reply_id) {
            report.execute({ commentId: reply.reply_id });
        } else if (comment?.comment_id) {
            report.execute({ commentId: comment.comment_id });
        }
    };

    const handleFetchMoreReply = useCallback(async (comment: CommentInfoWithReplyCursor) => {
        try {
            const res = await getCommentReplyList({
                comment_id: comment.comment_id,
                offset: comment.replyPageInfo?.offset || 0,
                count: REPLY_PAGE_SIZE,
            });
            if (res?.code !== 0) {
                throw res;
            }
            const [current] = getCurrentCommentFromList(commentListRef.current, comment.comment_id);
            if (!current) {
                return;
            }
            const { reply_list, next_offset, has_more } = res.data || {};
            const currentReplyList = current.reply_list || [];
            const updatedComment: CommentInfoWithReplyCursor = {
                ...current,
                replyPageInfo: {
                    hasMore: has_more,
                    offset: next_offset || 0,
                },
                reply_list: [...currentReplyList, ...differenceByProp(reply_list || [], currentReplyList, 'reply_id')],
            };
            updateCommentInList(updatedComment);
        } catch (err) {
            toast(MessageText.netError);
        }
    }, []);

    const handleSubmitComment: Exclude<SerialI18nBottomInputProps['onSubmit'], undefined> = async (val: string) => {
        try {
            const reportSuccess = (level: number) =>
                Tea('publish_comment_class', {
                    comment_level: level,
                    event_type: EventMap[eventType],
                });
            const postValue = formatCommentText(val.trim());
            if (currentCommentCtx?.comment) {
                // 发表 已有评论的回复
                const { comment, reply, idxInList } = currentCommentCtx;
                // 如果有 reply 则是 评论的评论
                const resp = await replyComment({
                    reply_text: postValue,
                    comment_id: comment?.comment_id || '',
                    to_reply_id: reply?.reply_id,
                    comment_type: eventType,
                    item_id: itemId,
                });

                // 黑名单
                if (resp?.code === CommentErrorCode.BlackList) {
                    Toast.info(i18n.t('comment_failure'));
                    return;
                }

                if (resp?.code === 0 && resp.data?.reply_id) {
                    const { reply_id } = resp.data;
                    // 将最新的评论 插入到 comment 中
                    const curComment =
                        getCurrentCommentFromList(commentListRef.current, comment?.comment_id)[0] || comment;
                    const newReply = createReplyComment({
                        user,
                        value: postValue,
                        comment: curComment,
                        reply,
                        reply_id,
                    });
                    const newReplyIndex = reply ? idxInList + 1 : 0; // 如果发送的是回复的回复 插在这个回复的后面
                    insertReply(newReply, newReplyIndex, comment.comment_id);
                    clearInput();
                    // 如果是回复的回复 不需要高亮一级卡片
                    // 高亮二级回复
                    setTimeout(() => {
                        highlightCard({
                            reply: newReply,
                            comment,
                        });
                    }, 200);
                    reportSuccess(reply ? 3 : 2);
                }
            } else {
                // 发表 文章的评论
                let idParam: 'post_id' | 'item_id';
                switch (eventType) {
                    case EventType.DetailPageComment: {
                        idParam = 'item_id';
                        break;
                    }
                    case EventType.Post: {
                        idParam = 'post_id';
                        break;
                    }
                    default: {
                        idParam = 'item_id';
                        break;
                    }
                }
                const resp = await publishComment({
                    comment_type: eventType,
                    [idParam]: itemId,
                    text: postValue,
                });

                // 黑名单
                if (resp?.code === CommentErrorCode.BlackList) {
                    Toast.info(i18n.t('comment_failure'));
                    return;
                }

                if (resp?.code === 0 && resp.data?.comment_id) {
                    // 将最新的评论 插入评论列表
                    const { comment_id } = resp.data;
                    const newComment = createComment({
                        comment_id,
                        value: postValue,
                        user,
                    });
                    const curList = commentListRef.current || [];
                    let newIndex = curList.length;
                    // 按照热度 滚动到中间部位 loadmore 不影响
                    let getScrollToPos = () => getCommentAreaEndY() - (window?.innerHeight || 0) / 2;
                    if (currentSortTypeRef.current === CommentSortType.NEWEST) {
                        const topIsPinned = !!curList?.[0]?.label_list?.includes?.(CommentLabelType.PINNED);
                        newIndex = topIsPinned ? 1 : 0;
                        getScrollToPos = getCommentAreaY;
                    }
                    insertComment(newComment, newIndex);
                    clearInput();
                    if (!inCommentArea()) {
                        containerReadingY.current = getContainerScrollTop(getContainer);
                    }
                    setTimeout(() => {
                        scrollContainerTo(getContainer, getScrollToPos());
                        // 高亮卡片
                        highlightCard({
                            comment: newComment,
                        });
                    }, 400); // 先等键盘收起来
                    reportSuccess(1);
                }
            }
        } catch (error) {
            console.log('handleSubmitComment', val);
        }
    };

    /** basic data fetcher */
    const getListData = async (pageInfo?: {
        pageIndex?: number;
        sortType?: CommentSortType;
    }): Promise<
        | void
        | (Omit<NonNullable<GetItemCommentListResponse['data']>, 'comment_list'> & {
              comment_list?: CommentInfoWithReplyCursor[];
          } & { my_collect?: boolean })
    > => {
        try {
            loadMoreRef.current?.changeStatus('loading');
            let d: GetItemCommentListRespData;
            const pageIndex = pageInfo?.pageIndex ?? currentPageIndexRef.current;
            const sortType = pageInfo?.sortType ?? currentSortTypeRef.current;
            if (eventType === EventType.DetailPageComment) {
                const resp = await getItemCommentList({
                    page_count: LIST_PAGE_SIZE,
                    page_index: pageIndex,
                    item_id: itemId,
                    sort_type: sortType,
                });
                const { data, code } = resp || {};
                if (code !== 0 || !data) {
                    return;
                }
                d = data;
            } else if (eventType === EventType.Post) {
                const resp = await getPostCommentList({
                    page_count: LIST_PAGE_SIZE,
                    page_index: pageIndex,
                    post_id: itemId,
                    sort_type: sortType,
                });
                const { data, code } = resp || {};
                if (code !== 0 || !data) {
                    return;
                }
                d = data;
            } else {
                return;
            }

            listHasMoreRef.current = !!d.has_more;
            loadMoreRef.current?.changeStatus(listHasMoreRef.current ? 'prepare' : 'nomore');
            return {
                ...d,
                comment_list: d.comment_list?.map<CommentInfoWithReplyCursor>(c => {
                    return {
                        ...c,
                        replyPageInfo: {
                            hasMore: (c.reply_count || 0) > (c.reply_list?.length || 0),
                            offset: c.reply_list?.length || 0,
                        },
                    };
                }),
            };
        } catch (err) {
            toast(MessageText.netError);
        }
    };

    const handleSortTypeChange = async (type: CommentSortType) => {
        if (listLoading || currentSortType === type) {
            return;
        }
        setCurrentSortType(type);
        setListLoading(true);
        loadMoreRef.current?.changeStatus('nomore');
        currentPageIndexRef.current = 0;
        Tea('click_filter_class', {
            is_newest: type === CommentSortType.HOTTEST ? 2 : 1,
            event_type: EventMap[eventType],
        });
        const data = await getListData({
            pageIndex: 0,
            sortType: type,
        });
        if (data) {
            const { comment_list, my_digg, total, my_collect } = data;
            setCommentList(comment_list || []);
            setUserDigged(!!my_digg);
            setUserCollected(!!my_collect);
            setTotalLength(total || 0);
        }
        setListLoading(false);
    };

    const handleFetchMoreComment = useCallback<Exclude<SerialI18nLoadMoreProps['getData'], undefined>>(
        async resolveRes => {
            if (!isListHasMore()) {
                resolveRes('nomore');
                return;
            }
            try {
                const nextPageIndex = currentPageIndexRef.current + 1;
                resolveRes('loading');
                const data = await getListData({
                    pageIndex: nextPageIndex,
                });
                if (data) {
                    const nextList = [
                        ...commentListRef.current,
                        ...differenceByProp(data.comment_list || [], commentListRef.current, 'comment_id'),
                    ];
                    setCommentList(nextList);
                    currentPageIndexRef.current = nextPageIndex;
                    resolveRes('prepare');
                } else {
                    resolveRes('retry');
                }
            } catch (err) {
                resolveRes('retry');
            }
        },
        [],
    );

    const inputPlaceholder = useMemo(() => {
        if (!currentCommentCtx) {
            return i18n.t('comment_chapter_bottom_placeholder', undefined, 'Write here!');
        }
        const { comment, reply } = currentCommentCtx;
        return `${i18n.t('reply', undefined, 'reply')} ${(reply || comment)?.comment_user?.user_name || ''}`;
    }, [currentCommentCtx, i18n]);

    /** -------- effects */
    useEffect(() => {
        setListLoading(true);
        getListData({
            pageIndex: 0,
        })
            .then(data => {
                if (data) {
                    const { comment_list, my_digg, total, my_collect } = data;
                    setCommentList(comment_list || []);
                    setUserDigged(!!my_digg);
                    setUserCollected(!!my_collect);
                    setTotalLength(total || 0);
                    if (typeof initScrollPosition === 'number') {
                        setTimeout(() => {
                            const commentAreaY = getCommentAreaY();
                            containerReadingY.current = getContainerScrollTop(getContainer);
                            // 移动端无法主动拉起键盘 需要通过 jsb 配合 然而效果很糟糕
                            // if (!total) {
                            // toggleInputAfterScroll();
                            // }
                            scrollContainerTo(getContainer, commentAreaY + 2);
                        }, 150);
                    }
                }
            })
            .catch(err => {
                console.log('err', err);
                setCommentList([]);
            })
            .finally(() => {
                setListLoading(false);
            });

        return () => {
            const kb = inputRef.current?.keyboard;
            kb?.off(BottomInputEvent.Show, handleKeyboardShow);
            kb?.off(BottomInputEvent.Hide, handleKeyboardHide);
        };
    }, []);

    /** 检测 listRef 内所有子元素的 press */
    usePress({
        onPressed: (id: string) => {
            if (keyboardShow) {
                return;
            }
            setPressedCommentId(id);
        },
        containerRef: listContainerRef,
        pressKeyCls: PressKeyCls,
        excludeClasses: [TitleActionCls, DiggCls],
    });

    const extraButtons = useMemo(() => {
        const [likeType, likeColor] = userDigged ? ['heart_filled', '#FF5F00'] : ['heart', ''];
        const [collectLikeType, collectLikeColor] = userCollected ? ['collect_filled', '#FF5F00'] : ['collect', ''];
        return (
            <div className={cs('comment-bottom__extra', { 'has-collect': hasCollect })}>
                <div className="comment-bottom__extra__btn-wrapper" onClick={handleLikeBtnClick}>
                    <div className="comment-bottom__extra__btn">
                        <Icon className="comment-bottom__extra__btn__icon" color={likeColor} type={likeType} />
                    </div>
                    {diggCount ? (
                        <div className="comment-bottom__extra__num">{numberFormatter(diggCount, 1)}</div>
                    ) : null}
                </div>
                {hasCollect && (
                    <div className="comment-bottom__extra__btn-wrapper" onClick={handleCollectBtnClick}>
                        <div className="comment-bottom__extra__btn">
                            <Icon
                                className="comment-bottom__extra__btn__icon"
                                color={collectLikeColor}
                                type={collectLikeType}
                            />
                        </div>
                        {collectCount ? (
                            <div className="comment-bottom__extra__num">{numberFormatter(collectCount, 1)}</div>
                        ) : null}
                    </div>
                )}

                <div className="comment-bottom__extra__btn-wrapper" onClick={handleCommentBtnClick}>
                    <div className="comment-bottom__extra__btn">
                        <Icon className="comment-bottom__extra__btn__icon" type="comment3" />
                    </div>
                    {totalLength ? (
                        <div className="comment-bottom__extra__num">{numberFormatter(totalLength, 1)}</div>
                    ) : null}
                </div>
            </div>
        );
    }, [totalLength, diggCount, userDigged, handleLikeBtnClick, handleCommentBtnClick, userCollected, collectCount]);

    const renderCard = (comment: CommentInfoWithReplyCursor, idx: number) => {
        const commentCtx = {
            comment,
            idxInList: idx,
        };
        const commentId = genCommentCardUId(commentCtx);
        return (
            <div key={comment.comment_id} className="comment-list__list-container__card-container">
                <CommentCard
                    ref={r => cardRefMap.current.set(commentId, r)}
                    pressed={pressedCommentId === commentId}
                    id={commentId}
                    comment={comment}
                    topDivider={Boolean(idx)}
                    bottomDivider={Boolean(comment.reply_count)}
                    showReport // 举报没有差异化 如果有 通过 uid 判断是不是自己
                    onClickReport={() => handleClickReport(commentCtx)}
                    onClickReply={e => handleReplyCard(commentCtx, e)}
                    onClickDigg={() => handleToggleDigg(commentCtx)}
                />
                {comment.reply_list?.length ? (
                    <ClickLoad
                        className=""
                        showLoad={comment.replyPageInfo?.hasMore}
                        restCount={comment.reply_count - comment.reply_list.length}
                        onClickLoad={() => handleFetchMoreReply(comment)}
                    >
                        {comment.reply_list?.map?.((reply, replyIdx) => {
                            const replyCtx = {
                                reply,
                                comment,
                                idxInList: replyIdx,
                            };
                            const replyId = genCommentCardUId(replyCtx);

                            return (
                                <CommentCard
                                    ref={r => cardRefMap.current.set(replyId, r)}
                                    pressed={pressedCommentId === replyId}
                                    inset
                                    id={replyId}
                                    key={replyId}
                                    comment={reply}
                                    showReport
                                    onClickReport={() => handleClickReport(replyCtx)}
                                    onClickReply={e => handleReplyCard(replyCtx, e)}
                                    onClickDigg={() => handleToggleDigg(replyCtx)}
                                />
                            );
                        })}
                    </ClickLoad>
                ) : null}
            </div>
        );
    };

    const renderList = () => {
        return (
            <div ref={listContainerRef} className="comment-list__list-container">
                {commentList.map((comment, idx) => {
                    return renderCard(comment, idx);
                })}
                {/* loadmore */}
                <LoadMore
                    ref={loadMoreRef}
                    className="comment-list__list-container__loadmore"
                    loadingArea={<ListLoading />}
                    getScrollContainer={getContainer}
                    retryArea={i18n.t('loadmore_retry_text', undefined, 'loading failed, please try again')}
                    noMoreArea={null}
                    prepareArea={null}
                    threshold={600}
                    getDataAtFirst={false}
                    getData={handleFetchMoreComment}
                />
            </div>
        );
    };

    const renderEmpty = useCallback(() => {
        return (
            <div className="comment-list__list-container">
                <Empty type="empty" text={i18n.t('no_comment_notification', undefined, 'There is no comments')} />
                <div className="comment-list__padding-bottom" />
            </div>
        );
    }, [i18n]);

    return (
        <div ref={commentAreaRef} className="comment-list">
            <div className="comment-list__title">
                <CommentTitle
                    sortType={currentSortType}
                    onSortTypeChange={handleSortTypeChange}
                    number={totalLength}
                    disableChooseSort={listLoading}
                />
            </div>
            <div className="comment-list__body">
                {totalLength > 0 ? renderList() : renderEmpty()}
                {/* absolute loading mask */}
                {listLoading ? (
                    <div className="comment-list__list-loading" onClickCapture={() => {}}>
                        <div className="comment-list__list-loading__wrapper">
                            <Loading />
                        </div>
                    </div>
                ) : null}
            </div>
            <BottomInput
                permanent
                placeholder={inputPlaceholder}
                ref={inputRef}
                value={commentValue}
                inputExtra={extraButtons}
                showInputExtra={!keyboardShow}
                showSubmitBtn={!!(keyboardShow && commentValue.length)}
                onSubmit={handleSubmitComment}
                onKeyboardReady={handleKeyboard}
                onChange={handleInputChange}
                onCancel={handleCancelInput}
                maxLength={MaxInputLength}
            />
            {report.ReportPopupElement}
        </div>
    );
};

export default CommentList;
