import React, { FC, useCallback, useMemo } from 'react';
import './index.less';

import SelectPopover from '@components/h5/select-popover';
import Icon from '@components/icon';
import { useTranslation } from '@ies/intl-react-plugin';
import { CommentSortType } from '@typings/api/novel/toutiao_muye_overseas_homeapi';
import { getPX } from '@utils/px';

export interface CommentTitleProps {
    number?: number;
    sortType?: CommentSortType;
    disableChooseSort?: boolean;
    onSortTypeChange?: (sortType: CommentSortType) => void;
}

const SortInfo = {
    newest: {
        key: 'newest',
        i18nKey: 'newest',
        sortType: CommentSortType.NEWEST,
    },
    hottest: {
        key: 'hottest',
        i18nKey: 'hottest',
        sortType: CommentSortType.HOTTEST,
    },
};

const CommentTitle: FC<CommentTitleProps> = props => {
    const { number, sortType, onSortTypeChange, disableChooseSort } = props;
    const { i18n } = useTranslation();

    const sortOptions = useMemo(() => {
        return [
            {
                text: i18n.t(SortInfo.newest.i18nKey, undefined, 'Newest'),
                value: SortInfo.newest.sortType,
            },
            {
                text: i18n.t(SortInfo.hottest.i18nKey, undefined, 'Hottest'),
                value: SortInfo.hottest.sortType,
            },
        ];
    }, [i18n]);

    const handleChooseSort = useCallback(
        value => {
            onSortTypeChange?.(value as typeof sortOptions[number]['value']);
        },
        [onSortTypeChange],
    );

    return (
        <div className="comment-title">
            <div className="comment-title__content">
                <span className="comment-title__content__text">{i18n.t('class_comment', undefined, 'Comment')}</span>
                {number ? (
                    <>
                        <span className="comment-title__content__text comment-title__content__text--gap">·</span>
                        <span className="comment-title__content__text">{number}</span>
                    </>
                ) : null}
            </div>
            <div className="comment-title__right">
                {number && number > 0 ? (
                    <SelectPopover
                        disabled={disableChooseSort}
                        verticalOffset={getPX(4)}
                        onSelect={handleChooseSort}
                        selections={sortOptions}
                        selected={sortType}
                    >
                        <div className="comment-title__right__filter">
                            <Icon className="" type="filter" />
                        </div>
                    </SelectPopover>
                ) : null}
            </div>
        </div>
    );
};

export default CommentTitle;
