import React from 'react';
import cs from 'classnames';
import Icon from '@components/icon';

import Apple from '@containers/home/images/apple.png';
import Google from '@containers/home/images/google.png';
import './index.less';

interface IProps {
    icon?: 'apple' | 'google';
    text: string;
    className?: string;
    onClick: () => void;
}

const ArrowBtn = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
    const { icon, text, className, onClick } = props;
    return (
        <div ref={ref} className={cs('arrow-btn', className)} onClick={onClick}>
            {icon && <img src={icon === 'apple' ? Apple : Google} />}
            {text}
            <Icon type="point" className="arrow-btn-arrow" />
        </div>
    );
});

export default ArrowBtn;
