import { PostType } from '@redux/reducers/post';

/**
 * @function 内容云post格式转换为富文本格式，
 * @param content 内容云post格式
 * @param postType 区分作者发帖还是官方发帖，作者发帖的图片有单独的缩略图容器展示，官方发帖则是纯富文本
 * @returns string
 * @description "<p><u>abc</u>ds\n456</p>" -> "<p>abcds</p><p>456</p>"
 */
export function serverPostToClientPost(content: string, postType: PostType) {
    const { skeleton, materials } = JSON.parse(content);
    const imgReg = /<cc_material_([0-9]*) data-type="(.*?)">.*?<\/cc_material_([0-9]*)>/g;
    let result = skeleton?.data;
    result = result.replace(/(<u>)|(<\/u>)/g, '').replace(/\n/g, '</p><p>');
    if (postType === PostType.official) {
        result = result.replace(imgReg, (_, index, type) => {
            const match = materials[index];
            if (type === 'img') {
                // eslint-disable-next-line max-len
                return `<img src="${match.data.web_url}" type="image/jpeg" width="${match.data.width}" height="${match.data.height}" uri="${match.data.web_uri}" source="speak" alt=""/>`;
            }
            return '';
        });
        return result;
    }
    result.replace(imgReg, (_, index, type) => {
        const match = materials[index];
        if (type === 'img') {
            return '';
        }
        // 后续可能新增视频标签
        return '';
    });
    return result;
}
