import qs from 'qs';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '@ies/intl-react-plugin';
import { isBrowser } from '@constants/const';
import { getPid, getStorage, isMobileDevice, setStorage } from './util';
import bridge from '@bridge/worldance';
import { useUserInfo } from './hooks';
import { IUserState } from '@redux/reducers/user';

const Tea = isBrowser && require('@dp/byted-tea-sdk-tcpy').default;

export const useTea = (mobile?: boolean) => {
    const location = useLocation();
    const { isLoading, id, region } = useUserInfo();
    const { i18n } = useTranslation();
    useEffect(() => {
        Tea.init({
            app_id: 4793,
            channel: 'sg',
            log: true,
        });
        Tea('enter_fizzo_writer');
    }, []);
    useEffect(() => {
        if (isLoading) {
            return;
        }
        const uid = id ? `${id}` : '';
        Tea.config({
            user_id: uid as any,
            user_unique_id: uid,
            is_login: Boolean(uid),
            is_wap: mobile ? 1 : 0,
            user_language: i18n.language,
            country: region,
        });
        Tea.start();
    }, [isLoading]);
    useEffect(() => {
        const { search } = location;
        const defaultParentEnterFrom = getStorage<string>('parent_enter_from', 'default');
        const {
            enter_from = 'default',
            parent_enter_from = defaultParentEnterFrom,
            parent_activity,
        } = qs.parse(search.slice(1));
        setStorage<string>('parent_enter_from', parent_enter_from);
        Tea.config({
            enter_from,
            parent_enter_from,
            pid: getPid(),
        });
        if (parent_activity) {
            Tea.config({ parent_activity });
        }
    }, [location]);
};

// 在页面右滑退出后，上报埋点时，由于 store 被销毁，useUserInfo 获取不到用户信息，因此用一个常量来存用户信息
let UserState: Partial<IUserState> = {};

export const useReportClient = () => {
    const user = useUserInfo();
    const { i18n } = useTranslation();

    useEffect(() => {
        UserState = user;
    }, [user]);

    return (event: string, extra: Record<string, string | number>) => {
        const { id, region } = UserState;
        bridge.app.reportV3({
            event,
            args: {
                ...extra,
                user_id: id,
                user_unique_id: id,
                is_login: Boolean(id),
                is_wap: isMobileDevice() ? 1 : 0,
                user_language: i18n.language,
                country: region,
            },
        });
    };
};

export { Tea };
