// starling-disable-file
import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import cs from 'classnames';
import Indicator from '@containers/home-id/component/indicator';
import Carousel from '@containers/home-id/component/carousel';
import { getBackground, useTopAuthor } from '@containers/home-id/utils';
import { TopAuthorItem } from '@containers/home-id/type';
import { useTranslation } from '@ies/intl-react-plugin';
import Loading from '@components/loading';
import { isJumping } from '../..';

import './index.less';
import { Tea } from '@utils/index';

interface AuthorRef {
    play: () => void;
}

const Author: ForwardRefRenderFunction<AuthorRef> = (_, ref) => {
    const [showText, setShowText] = useState(true);
    const [active, setActive] = useState(0);
    const [inited, setInited] = useState(false);
    const triggerRef = useRef<ScrollTrigger>();
    const moduleRef = useRef<HTMLDivElement>(null);
    const bgRef = useRef<HTMLDivElement>(null);
    const carouselRef = useRef<any>();
    const bottomRef = useRef<HTMLDivElement>(null);
    const { data, loading } = useTopAuthor();

    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
        play,
    }));

    const handleClickPrev = () => {
        Tea('click_author_words_slide');
        carouselRef.current.prev();
    };

    const handleClickNext = () => {
        Tea('click_author_words_slide');
        carouselRef.current.next();
    };

    const play = () => {
        if (isJumping) {
            return;
        }
        triggerRef.current?.kill();

        const module = moduleRef.current;
        const tl = gsap.timeline();

        document.body.style.height = '100%';
        document.body.style.overflow = 'hidden';

        tl.to(window, {
            duration: 0.5,
            scrollTo: module!,
            offsetY: 0,
            ease: 'myEase',
        });

        tl.to(
            bgRef.current,
            {
                translateY: 0,
                opacity: 1,
                direction: 0.5,
                ease: 'myEase',
            },
            0.5,
        );

        const carousel = carouselRef.current;
        const cards = carousel.getElement();

        cards.forEach(item => {
            const { width } = carouselRef.current.getSize();
            tl.to(
                item,
                {
                    width,
                    duration: 0.5,
                    ease: 'myEase',
                },
                1.3,
            );
        });

        tl.to(
            bottomRef.current,
            {
                translateY: 0,
                opacity: 1,
                duration: 0.4,
                ease: 'myEase',
                onComplete: () => {
                    document.body.style.height = '';
                    document.body.style.overflow = '';
                },
            },
            2.1,
        );
    };

    useEffect(() => {
        if (!inited) {
            return;
        }
        const carousel = carouselRef.current;
        // 1. 设置卡片初始状态
        const cards = carousel.getElement();
        cards.forEach(item => {
            gsap.to(item, {
                width: 0,
                duration: 0,
            });
        });

        // 2. 设置锚点和箭头的状态&设置背景
        gsap.to(bottomRef.current, {
            opacity: 0,
            translateY: 100,
            duration: 0,
        });

        gsap.to(bgRef.current, {
            opacity: 0,
            translateY: 40,
            duration: 0,
        });

        triggerRef.current = ScrollTrigger.create({
            trigger: moduleRef.current,
            start: 'top+=50px bottom',
            onEnterBack: play,
            onEnter: play,
        });
        return () => triggerRef.current?.kill();
    }, [inited]);

    return (
        <section className="home-id-author" ref={moduleRef} id="home_author">
            {!loading ? (
                <div className="home-id-author-content-wrapper">
                    <div className="home-id-author-bg" ref={bgRef}>
                        Fizzo Star Writer
                    </div>
                    <div className="home-id-author-carousel-wrapper">
                        <Carousel
                            name="top"
                            className="home-id-author-carousel"
                            ref={carouselRef}
                            data={data}
                            active={active}
                            onChange={i => {
                                setActive(i);
                                setShowText(false);
                            }}
                            maxWidth={1532}
                            startPos={0}
                            width={248}
                            height={560}
                            padding={50}
                            onAfterChange={() => setShowText(true)}
                            onInited={() => {
                                setInited(true);
                            }}
                            duration={0.4}
                            renderItem={(item: TopAuthorItem, index) => (
                                <div
                                    className={cs('home-id-author-card-wrapper', {
                                        active: active === index,
                                        'show-text': active === index && showText,
                                    })}
                                    style={{ backgroundImage: `url(${item.book_cover})` }}
                                >
                                    <div className="home-id-author-card">
                                        <div
                                            className="home-id-author-card-bg"
                                            style={{ background: getBackground(item.cover_color) }}
                                        />
                                        <div className="home-id-author-card-content">
                                            {Number(item.is_top) ? (
                                                <img className="author-avatar" src={item.avatar} alt="" />
                                            ) : null}
                                            <div className="author-name">{item.author}</div>
                                            <div className="author-desc">
                                                {item.desc.length > 200
                                                    ? `${item.desc.substring(0, 200)}...`
                                                    : item.desc}
                                            </div>
                                        </div>
                                        <div className="home-id-author-card-name">
                                            <span>{t('pc_written_by')}</span>
                                            {item.author}
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                    <div className="home-id-author-indicator" ref={bottomRef}>
                        <div className="home-id-author-arrow">
                            <div className="home-id-author-arrow-prev" onClick={handleClickPrev} />
                            <div className="home-id-author-arrow-next" onClick={handleClickNext} />
                        </div>
                        <Indicator total={data.length} current={active} />
                        <span className="home-id-author-indicator-text">{t('pc_top_author')}</span>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </section>
    );
};
export default forwardRef(Author);
