import React, { useEffect } from 'react';
import cs from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslation } from '@ies/intl-react-plugin';
import { Message } from '@byted/serial-components-i18n';
import Icon from '@components/icon';
import { Tea, useUserInfo, useGoAdmin } from '@utils/index';
import { isBrowser } from '@constants/const';
import { MessageText } from '@constants/index';
import { updateUser } from '@containers/user';
import { LoginChannelList, LoginChannelClientId, ErrorCode, ReportEvent } from '../constants';
import { accountSDK } from '../sdk';
import { AccountLogger } from '@utils/logger';

import './index.less';

export interface AuthRegisterParams {
    profileKey: string;
    platformAppId: number;
}

interface IProps {
    fromLogin?: boolean;
    onUserNotExist: (params: AuthRegisterParams) => void;
    onUserNameEmpty: (type: string, appId?: string) => void;
}

const hello = isBrowser && require('hellojs');

const Oauth: React.FC<IProps> = ({ fromLogin, onUserNotExist, onUserNameEmpty }) => {
    const { i18n } = useTranslation();
    const goAdmin = useGoAdmin();

    const dispatch = useDispatch();
    const { id: uid } = useUserInfo();

    useEffect(() => {
        hello.init(LoginChannelClientId, {
            redirect_uri: `${location.origin}/main/writer/redirect/`,
            oauth_proxy: `${location.origin}/oauth/`,
            response_type: 'code',
        });
    }, []);

    const authLogin = async (params: any) => {
        try {
            await accountSDK.login.authLogin(params);
            const resp = await updateUser(dispatch);
            if (!resp) {
                return;
            }

            if (resp.code) {
                Message.error(MessageText.operateError);
                return;
            }

            if (resp.data.media_id) {
                goAdmin();
                return;
            }

            onUserNameEmpty(params.teaNeed, params.platform_app_id);
        } catch (e: any) {
            const { error_code, profile_key } = e || {};
            switch (error_code) {
                case ErrorCode.NotRegistered:
                    onUserNotExist({
                        profileKey: profile_key,
                        platformAppId: params.platform_app_id,
                    });
                    break;
                default:
                    Message.error(MessageText.operateError);
                    break;
            }

            // 统一指标
            AccountLogger.info('login_error', {
                error: JSON.stringify(e),
                type: ReportEvent.Oauth,
                step: 'authLogin',
            });

            /* starling-disable-next-line */
            AccountLogger.error('sdk.authLogin', e);
        }
    };

    const helloLogin = (name: string, platformAppId: number, teaNeed: string) => async () => {
        try {
            const resp = await hello(name).login({
                force: true,
            });

            const authResponse = resp.authResponse || ({} as any);
            if (fromLogin) {
                Tea('writer_login', {
                    button_click: teaNeed,
                    user_id: uid,
                    is_success: 1,
                });
            } else {
                Tea('writer_signup', {
                    button_click: teaNeed,
                    user_id: uid,
                    is_success: 1,
                });
            }
            authLogin({
                platform_app_id: platformAppId,
                access_token: authResponse.oauth_token || authResponse.access_token,
                access_token_secret: authResponse.oauth_token_secret || '',
                login_only: true,
                teaNeed,
            });
        } catch (helloErr) {
            Message.error(MessageText.operateError);

            if (fromLogin) {
                Tea('writer_login', {
                    button_click: teaNeed,
                    user_id: uid,
                    is_success: 0,
                });
            } else {
                Tea('writer_signup', {
                    button_click: teaNeed,
                    user_id: uid,
                    is_success: 1,
                });
            }

            // 统一指标
            AccountLogger.info('login_error', {
                type: ReportEvent.Oauth,
                error: JSON.stringify(helloErr),
                step: 'helloLogin',
                network: name,
            });

            /* starling-disable-next-line */
            AccountLogger.error('hello.login', helloErr?.error, {
                network: name,
            });
        }
    };

    return (
        <div className={cs('oauth-list', { 'from-login': fromLogin })}>
            {LoginChannelList.map((item, index) => {
                const network = i18n.t(item.name);
                return (
                    <div
                        className="oauth-item"
                        key={index}
                        onClick={helloLogin(network, item.platformAppId, item.teaNeed)}
                    >
                        <img src={item.logo} />
                        <span className={cs({ hide: fromLogin && index })}>
                            {i18n.t(fromLogin ? 'sso_login_third' : 'sso_signup_third')}
                            &nbsp;
                            <b>{network}</b>
                        </span>
                        <Icon type="left" />
                    </div>
                );
            })}
        </div>
    );
};

export default Oauth;
