import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from '@ies/intl-react-plugin';
import { gsap } from 'gsap';
import { useHome } from '@utils/hooks';
import { Font, FontId } from './font';
import { lazyLoadImg } from '@containers/home/setting';
import { isBrowser } from '@constants/const';
import { LangCode } from '@common/lang';

interface IProps {}

const VIEW_HEIGHT = isBrowser ? window.innerHeight : 0;

const Trend: React.FC<IProps> = () => {
    const { i18n } = useTranslation();
    const { book_trends } = useHome();
    const loaded = useRef(false);
    const ref = useRef<HTMLDivElement>(null);
    const bgRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(960);

    useEffect(() => {
        setHeight(VIEW_HEIGHT);
    }, []);

    useEffect(() => {
        if (height !== VIEW_HEIGHT) {
            return;
        }
        const tl = gsap
            .timeline({
                scrollTrigger: {
                    trigger: ref.current,
                    end: '+=2000px',
                    scrub: true,
                    pin: true,
                    anticipatePin: 3,
                    onEnter: () => {
                        if (!loaded.current) {
                            lazyLoadImg(ref.current!);
                            loaded.current = true;
                        }
                    },
                },
            })
            .fromTo('.home-trend-books.up', { xPercent: -100 }, { xPercent: 0 }, 0)
            .fromTo('.home-trend-books.down', { xPercent: 100 }, { xPercent: 0 }, 0);

        const bgTl = gsap
            .timeline({
                scrollTrigger: {
                    trigger: bgRef.current,
                    end: '+=1000px',
                    scrub: true,
                    pin: true,
                },
            })
            .fromTo(bgRef.current, { opacity: 1 }, { opacity: 0 }, 0);
        return () => {
            tl.scrollTrigger?.kill();
            bgTl.scrollTrigger?.kill();
        };
    }, [height]);

    const halfIndex = book_trends!.length / 2;
    return (
        <section className="home-trend" style={{ height }} ref={ref}>
            <div className="home-trend-books up">
                {book_trends?.slice(0, halfIndex).map((item, index) => (
                    <div className="home-trend-books-item" key={index}>
                        <img data-src={item.cover_url} />
                        <p>{item.book_name}</p>
                    </div>
                ))}
            </div>
            <div className="home-trend-books down">
                {book_trends?.slice(halfIndex).map((item, index) => (
                    <div className="home-trend-books-item" key={index}>
                        <img data-src={item.cover_url} />
                        <p>{item.book_name}</p>
                    </div>
                ))}
            </div>
            <div className="home-trend-bg" ref={bgRef} />
            {i18n.language !== LangCode.ID ? <Font /> : <FontId />}
        </section>
    );
};

export default Trend;
