import { DataAction } from '@redux/typings';
import { PostState } from '../reducers/post';

export const INIT_POST_DATA = 'INIT_POST_DATA';

export const SET_DIGG_NUMBER = 'SET_DIGG_NUMBER';

export const SET_COLLECT_NUMBER = 'SET_COLLECT_NUMBER';

export function initPostData(data: PostState) {
    return {
        type: INIT_POST_DATA,
        data,
    };
}

export function setPostDiggNumber(number: number) {
    return {
        type: SET_DIGG_NUMBER,
        data: number,
    };
}

export function setPostCollectNumber(number: number) {
    return {
        type: SET_COLLECT_NUMBER,
        data: number,
    };
}

export type ActionType =
    | DataAction<PostState, typeof INIT_POST_DATA>
    | DataAction<Parameters<typeof setPostDiggNumber>[0], typeof SET_DIGG_NUMBER>
    | DataAction<Parameters<typeof setPostCollectNumber>[0], typeof SET_COLLECT_NUMBER>;
