import { LangCode } from '@common/lang';

export enum ProtocolPath {
    Privacy = 'privacy-policy',
    Cookie = 'cookies-policy',
    Service = 'service-terms',
    Community = 'community-guidelines',
}

export const openProtocol = (lang: string) => (protocol: ProtocolPath) => () => {
    let url = `https://www.fizzo.org/page/${protocol}/`;
    if (lang !== LangCode.EN) {
        url = `${url}?language=${lang}`;
    }
    window.open(url);
};
