import { LangCode } from '@common/lang';
import HomeID from '@containers/home-id/pc';
import Home from '@containers/home/pc';
import I18n from '@ies/starling_intl';
import React from 'react';

const HomeEntry = () => (I18n.language === LangCode.ID ? <HomeID /> : <Home />);

HomeEntry.loadData = (ctx, store) =>
    I18n.language === LangCode.ID ? HomeID.loadData(ctx, store) : Home.loadData(ctx, store);

export default HomeEntry;
