import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from '@ies/intl-react-plugin';
import cs from 'classnames';
import { Input } from '@byted/serial-components-i18n';
import { InputProps } from '@byted/serial-components-i18n/lib/Input';

import './index.less';

interface IProps extends InputProps {
    wrapperCls?: string;
    password?: boolean;
    validation?: string;
    hideValidation?: boolean;
    verify?: boolean;
    disableVerify?: boolean;
    onVerify?: () => Promise<void>;
}

const AccountInput: React.FC<IProps> = props => {
    const {
        password,
        validation,
        hideValidation,
        verify,
        disableVerify,
        onVerify,
        className,
        wrapperCls,
        showWordLimit,
        ...inputProps
    } = props;

    const { i18n } = useTranslation();

    const [verifyCount, setverifyCount] = useState(0);
    const [countdown, setCountdown] = useState(0);
    const [showPwd, setShowPwd] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const timer = useRef<number | null>(null);

    useEffect(() => {
        if (countdown === 60) {
            timer.current = window.setInterval(() => setCountdown(t => t - 1), 1000);
        }
        if (countdown === 0) {
            window.clearInterval(timer.current as number);
            timer.current = null;
        }
    }, [countdown]);

    const handleVerify = () => {
        if (!onVerify || disableVerify || countdown || isVerifying) {
            return;
        }
        setIsVerifying(true);
        onVerify().then(() => {
            setverifyCount(verifyCount + 1);
            setCountdown(60);
            setIsVerifying(false);
        });
    };

    const classes = cs('account-input', className, { err: validation && !hideValidation });

    const renderInput = () => {
        if (password) {
            return (
                <div className="account-input-password">
                    <Input.Password visibilityToggle={false} visibility={showPwd} className={classes} {...inputProps} />
                    <div
                        className={cs('account-input-password-eye', {
                            showed: showPwd,
                        })}
                        onClick={() => setShowPwd(!showPwd)}
                    />
                </div>
            );
        }

        if (verify) {
            let content = '';
            if (countdown) {
                content = `${countdown}s`;
            } else {
                content = verifyCount ? `${i18n.t('sso_code_resend')}` : `${i18n.t('sso_code_verify')}`;
            }

            return (
                <div
                    className={cs('account-input-verify', `account-input-${i18n.language}`, {
                        'account-input-counting': countdown !== 0,
                    })}
                >
                    <Input className={classes} {...inputProps} />
                    <div
                        className={cs('verify', {
                            disabled: disableVerify || countdown,
                        })}
                        onClick={handleVerify}
                    >
                        {content}
                    </div>
                </div>
            );
        }

        return <Input className={classes} {...inputProps} />;
    };

    return (
        <div
            className={cs('account-input-wrapper', wrapperCls, { 'account-input-limit-wrapper': props.showWordLimit })}
        >
            {renderInput()}
            {showWordLimit && (
                <span className="account-input-limit-text">
                    {props.value?.length}/{props.maxLength}
                </span>
            )}
            {Boolean(validation) && !hideValidation && <div className="account-input-valid">{validation}</div>}
        </div>
    );
};

export default AccountInput;
