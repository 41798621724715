import { IChatProps } from '@novel/feedback-sdk/lib/frame/chat';
import { useTranslation } from '@ies/intl-react-plugin';
import React, { useMemo, useRef, RefObject, useEffect } from 'react';
import { defaultText, FeedbackChat, TextType } from '@novel/feedback-sdk';
import { Message, Tooltip } from '@byted/serial-components-i18n';
import cs from 'classnames';
import get from 'lodash/get';
import { useRefState, useUserInfo } from '@utils/hooks';
import { dayjs } from '@utils/day';
import { useScrollIsolator } from './scroll';
import { uploadCover } from '@service/api';
import { getImgInfo } from '@utils/file';
import { getImgUrl } from '@utils/util';
import Loading from '@components/loading';
import officialAvatarSrc from '@static/images/fizzo_default_avatar.svg';

export interface FeedbackContentProps {
    onTopButtonClick?: IChatProps['onTopButtonClick'];
    onGetNewReply?: IChatProps['getNewReply'];
    visible: boolean;
}

export interface IFeedbackImageItem {
    id: string;
    url: string;
    loading: boolean;
}

export interface IChatRef {
    updateProgress: (index: string, progress: number) => void;
    init: (useDefault: boolean) => void;
    bottomImageAreaRef: RefObject<HTMLDivElement>;
    removeCachedImage: (id: string) => void;
}

function dateFormatter(timestamp: string) {
    const pubDate = new Date(timestamp.length <= 10 ? Number(timestamp) * 1000 : Number(timestamp));
    const currentDate = new Date();
    return currentDate.toDateString() === pubDate.toDateString()
        ? dayjs(pubDate).format('LT')
        : dayjs(pubDate).format('lll');
}

const MaxImageCount = 4;

const MaxImageSize = 2048; // 2M

const getTextareaEl = (): HTMLInputElement | null => document.querySelector('.chat-input-textarea');
const getSendBtnEl = (): HTMLDivElement | null => document.querySelector('.chat-bottom-send ');

export function ChatContent({ visible, onTopButtonClick, onGetNewReply }: FeedbackContentProps) {
    const {
        i18n: { language, t },
    } = useTranslation();
    const { avatar, id } = useUserInfo();
    const [imageList, imageListRef, setImageList] = useRefState<IFeedbackImageItem[]>([]);
    const feedbackRef = useRef<IChatRef>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        // 关闭时也触发init滚动到最末尾
        feedbackRef.current?.init(true);
    }, [visible]);

    useEffect(() => {
        const sendBtn = getSendBtnEl();
        const textarea = getTextareaEl();
        sendBtn?.addEventListener('click', () => {
            if (!textarea) {
                return;
            }
            const [s = 0, e = 0] = [textarea.selectionStart, textarea.selectionEnd];
            setTimeout(() => {
                textarea.focus();
                textarea.setSelectionRange(s, e);
            }, 0);
        });
    }, []);
    useScrollIsolator(containerRef.current);

    const textConfig = useMemo<typeof defaultText>(
        () => ({
            [TextType.chatFrameName]: t('helpdesk_enhtry'),
            [TextType.cardName]: t('helpdesk_faq'),
            [TextType.load]: t('helpdesk_history_click'),
            [TextType.loadMore]: t('helpdesk_history_click'),
            [TextType.noMore]: t('helpdesk_nohistory'),
            [TextType.above]: t('helpdesk_history'),
            [TextType.placeHolder]: t('helpdesk_describeindetail'),
            // 暂未使用
            [TextType.autoReply]:
                'Sorry, I did not understand what you meant, please select FAQ or transfer to manual reply',
            [TextType.transTip]: t('helpdesk_describe'),
            [TextType.transSuccess]: 'Manual service transfer successful', // 暂未使用
            [TextType.change]: 'Change', // 暂未使用
            [TextType.trans]: 'Switch to manual service', // 暂未使用
            [TextType.selectImage]: '', // 隐藏
            [TextType.sendText]: t('helpdesk_submit'),
        }),
        [language],
    );

    const feedbackBaseParams = useMemo(
        () => ({
            aid: '4793',
            app_name: 'novel_serial_author_i18n',
            appkey: 'novel_serial_author_i18n-web',
        }),
        [],
    );

    const clientParams = useMemo(
        () => ({
            country_code: language,
            device_id: id, // 此参数会被ttwid覆盖
            language: language.toUpperCase(),
            lang: language.toUpperCase(),
        }),
        [id, language],
    );

    const sendMessageConfig = useMemo<IChatProps['sendMessageConfig']>(
        () => ({
            sendTriggerInterval: 10000,
            verifySceneSelect: true,
            onSendDisable: () => {
                Message.error(t('helpdesk_send_frenquent'));
            },
            onSceneNotSelect: () => {
                Message.error(t('helpdesk_selecttype'));
            },
            sendCallback: isSuccess => {
                if (isSuccess) {
                    setImageList([]);
                } else {
                    Message.error(t('common_error_toast'));
                }
            },
        }),
        [language],
    );

    const sceneSelectorConfig = useMemo<IChatProps['sceneSelectorConfig']>(
        () => ({
            visible: true,
            faqType: 'feature' as const,
            tipText: t('helpdesk_selecttype'),
        }),
        [language],
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const uploadImage: IChatProps['uploadImage'] = async (file, imgId) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const resp: any = await uploadCover(formData);
            const info = await getImgInfo(file);

            const uri = get(resp, 'data.thumb_uri');
            const newList = [...imageListRef.current];
            newList.forEach((item: any) => {
                if (item.id === imgId) {
                    item.loading = false;
                }
            });
            setImageList(newList);
            return {
                image_uri: getImgUrl({ uri }),
                image_width: info.width,
                image_height: info.height,
            };
        } catch (e: any) {
            setImageList(imageListRef.current.filter(item => item.id !== imgId));
            Message.error(t('contrat_sign_photo_error2'));
        }
    };

    const onImageOversize = () => {
        Message.error(t('helpdesk_photo_size'));
    };

    const onImageInput = (url: string, imgId: string) => {
        setImageList([...imageListRef.current, { id: imgId, url, loading: true }]);
    };

    const ImageListElement = useMemo(() => {
        const renderImageItem = (item: IFeedbackImageItem) => (
            <Tooltip
                key={item.id}
                position="top"
                type="primary"
                className="feedback-img-item-tooltip"
                arrowVisible
                content={
                    <div className="feedback-img-item-tooltip-content">
                        <div
                            className="close-btn"
                            onClick={() => {
                                setImageList(imageList.filter(ti => ti.id !== item.id));
                                feedbackRef.current?.removeCachedImage(item.id);
                            }}
                        />
                        <img className="tooltip-item" src={item.url} alt="" />
                    </div>
                }
            >
                <img className="feedback-img-item" src={item.url} alt="" />
            </Tooltip>
        );
        const renderLoading = (item: IFeedbackImageItem) => (
            <div key={item.id} className="loading-item">
                <Loading style={{ marginTop: 0 }} width={32} height={32} />
            </div>
        );
        return (
            <div className="chat-bottom-img-list">
                {imageList.map(item => (item.loading ? renderLoading(item) : renderImageItem(item)))}
            </div>
        );
    }, [imageList]);

    const DisableImageTipElement = useMemo(
        () => (
            <Tooltip
                position="top"
                type="primary"
                className="feedback-img-btn-tooltip"
                arrowVisible
                content={t('helpdesk_photo_number')}
            >
                <div className="feedback-img-tip-box" />
            </Tooltip>
        ),
        [language],
    );

    return (
        <div
            ref={containerRef}
            className={cs('feedback-container', {
                'disable-img': imageList.length >= MaxImageCount,
            })}
        >
            <FeedbackChat
                ref={feedbackRef}
                baseParams={feedbackBaseParams}
                clientParams={clientParams}
                textConfig={textConfig}
                sendMessageConfig={sendMessageConfig}
                sceneSelectorConfig={sceneSelectorConfig}
                keepRealSendMode
                imageCacheMode
                userAvatar={avatar}
                officialAvatar={officialAvatarSrc}
                imageSizeLimit={MaxImageSize}
                dateFormatter={dateFormatter}
                uploadImage={uploadImage}
                onImageOversize={onImageOversize}
                onTopButtonClick={onTopButtonClick}
                getNewReply={onGetNewReply}
                onImageInput={onImageInput}
                slots={{
                    bottomCenter: ImageListElement,
                    bottomLeftSub: DisableImageTipElement,
                }}
            />
        </div>
    );
}
