import React from 'react';
import Header from '@components/header';
import MagicFrame from './iframe';

import './index.less';

const MagicEventPC: React.FC = () => {
    return (
        <div className="magic-page">
            <Header />
            <MagicFrame />
        </div>
    );
};

export default MagicEventPC;
