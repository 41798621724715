import React, { useEffect, useRef } from 'react';
import SylPage from '@syl-page/react-access';
import { isTestEnv } from '@constants/const';
import { Anchor } from '@byted/serial-components-i18n';
import { DirList } from '@byted/serial-components-i18n/lib/Anchor/directory';
import { MessageText } from '@constants/value';
import { dayjs } from '@utils/day';
import { useParams } from 'react-router-dom';
import cs from 'classnames';
import { Tea } from '@utils/tea';
import { FollowBtn } from '@components/h5/follow-btn';
import { useUserInfo } from '@utils/hooks';
import { goH5Admin, useNav, UserPathH5 } from '@utils/nav';
import { useTranslation } from '@ies/intl-react-plugin';
import { LangCode } from '@common/lang';
import { EVENTS } from '@constants/event';
import { GoToUserPageTeaEnum, PostRole } from '@containers/post/typings';

import './index.less';
import './index.h5.less';
import { useDebounceFn } from '@byted/hooks';

const { Directory } = Anchor;

export interface VideoEvent {
    name: string;
    extra?: Record<string, string | number>;
}
export interface ArticleContentProps {
    content: string;
    title: string;
    time: number;
    author?: {
        userId?: string;
        username?: string;
        avatarURL?: string;
        hasFollowed?: boolean;
        isAuthorSelf?: boolean;
    };
}
interface IProps {
    needDir?: boolean;
    data: ArticleContentProps;
    /** 视频完播率埋点 */
    videoEvent?: VideoEvent;
    showFollowBtn?: boolean;
    isMobile?: boolean;
    getScrollContainer?: () => HTMLElement | null;
    onScrollEnd?: () => void;
}

const videoSettings = {
    encryptVideo: true,
    aid: isTestEnv ? 10068027 : 358879,
    domain: isTestEnv ? 'api-resso-boei18n.*************' : 'vas-alisg16.byteoversea.com',
    enableErrorClickRetry: true,
    xiguaConfig: {
        lang: 'en',
    },
};

const FORMAT = {
    detail: 'YYYY-MM-DD HH:mm:ss',
    localeDate: 'll',
};

const formatData = (data: string) => {
    if (!data) {
        return {};
    }
    const dirList: DirList[] = [];
    let dirIndex = -1;
    const _data = data
        // 生成目录
        .replace(/<h((1|2|3).*?)>(.*?)<\/h\d>/g, (match, ...tags) => {
            dirIndex++;
            // 去掉所有标签 生成目录
            const title: string = match.replace(/<.*?>/g, '');
            dirList.push({ title: title.replace('&nbsp;', ''), href: String(dirIndex), type: tags[1] });
            return `<h${tags[1]} id="${dirIndex}">${tags[2]}</h${tags[1]}>`;
        })
        // 去除src属性，因为获取视频资源使用data-poster
        .replace(/<video.*?(src=".*?").*?>/g, (match, $1) => match.replace($1, ''))
        .replace(/<video/g, '<div')
        .replace(/<\/video>/g, '</div>');
    return {
        content: _data,
        dirList,
    };
};

const ArticleContent: React.FC<IProps> = ({
    needDir = true,
    data,
    videoEvent,
    showFollowBtn,
    isMobile = false,
    onScrollEnd,
    getScrollContainer,
}) => {
    const { content, title, time, author } = data;
    const { content: article, dirList } = formatData(content);
    const { id } = useParams<{ id: string }>();
    const { id: uid, isLogin } = useUserInfo();
    const { i18n } = useTranslation();

    const contentRef = useRef<HTMLDivElement>(null);

    const useDir = dirList?.length !== 0 && needDir;

    const navTo = useNav();

    const handleAvatar = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if (i18n.language !== LangCode.ID) {
            return;
        }
        if (!isLogin && isMobile) {
            navTo('/');
            return;
        }
        if (isMobile) {
            Tea(EVENTS.ENTER_FORUM_HOMEPAGE, {
                channel: GoToUserPageTeaEnum.detailPage,
                role: uid === author?.userId ? PostRole.Subject : PostRole.Object,
                uid: author?.userId,
            });
            e.stopPropagation();
            goH5Admin(`${UserPathH5}/${author?.userId}`, {
                isSubject: uid === author?.userId ? 1 : 0,
            });
        }
    };

    const { run: handleScroll } = useDebounceFn(() => {
        const el = contentRef.current;

        if (!el) return;

        const { y, height } = el.getBoundingClientRect();

        if (y + height <= window.innerHeight) {
            onScrollEnd?.();
        }
    }, 50);

    useEffect(() => {
        const container = getScrollContainer?.() || window;

        if (onScrollEnd) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, [onScrollEnd]);

    return content ? (
        <div className={cs('article-content', { 'article-content-no-dir': !useDir })} ref={contentRef}>
            <div className="article-content-title">{title}</div>
            {author ? (
                <div className="article-content-author">
                    {author.avatarURL ? (
                        <img src={author.avatarURL} className="article-content-author__avatar" onClick={handleAvatar} />
                    ) : null}
                    <div className="article-content-author__main">
                        <div className="article-content-author__main__username">{author.username}</div>
                        <div className="article-content-author__main__time">
                            {dayjs(Number(time) * 1000).format(FORMAT.localeDate)}
                        </div>
                    </div>
                    {showFollowBtn && !author.isAuthorSelf && isLogin && (
                        <FollowBtn
                            initFollowed={author.hasFollowed || false}
                            uid={author.userId || ''}
                            type="fans-list"
                        />
                    )}
                </div>
            ) : (
                <div className="article-content-time">{dayjs(Number(time) * 1000).format(FORMAT.detail)}</div>
            )}
            <SylPage
                articleClassName="article-main"
                content={article || ''}
                videoSettings={{
                    ...videoSettings,
                    errorText: MessageText.clickToRetry,
                    getPlayerInstance: (videoId, playerInstance) => {
                        const player = playerInstance as any;

                        if (player && videoEvent) {
                            const { name, extra } = videoEvent;

                            player.on('pause', () => {
                                const { cumulateTime, currentTime, duration } = player;
                                const totalPlayTime = cumulateTime / 1000;
                                Tea(name, {
                                    play_total_time: totalPlayTime,
                                    play_current_time: currentTime,
                                    video_total_time: duration,
                                    video_id: videoId,
                                    article_id: id,
                                    play_completion_rate: totalPlayTime / duration,
                                    ...extra,
                                });
                            });
                        }
                    },
                }}
                needPreRender
            />
            {useDir && (
                <Directory
                    className="article-content-dir"
                    directory={dirList || []}
                    useFix
                    offsetLeft={winWidth => {
                        if (winWidth > 1300) {
                            return winWidth * 0.5 + 330;
                        }
                        return 980;
                    }}
                    offsetTop={368}
                />
            )}
        </div>
    ) : null;
};

export default ArticleContent;
