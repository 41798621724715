/**
 * copy from mobile
 * 多行文本省略，点击按钮展开
 */
import React, { HTMLAttributes, ReactNode, useRef, useState } from 'react';
import cs from 'classnames';
import Icon from '@components/icon';
import OverflowDetect from '@components/overflow-detect';
import './index.less';

export interface ExpandableTextProps extends Omit<HTMLAttributes<HTMLDivElement>, 'style'> {
    value: ReactNode;
    initHeight: string;
    onClickExpand?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    pressed?: boolean;
    highlight?: boolean;
}

export default function ExpandableText({
    value,
    initHeight,
    onClickExpand,
    pressed,
    highlight,
    ...props
}: ExpandableTextProps) {
    const [expand, setExpand] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [maxHeight, setMaxHeight] = useState(initHeight);
    return (
        <OverflowDetect
            {...props}
            hiddenStyle={{ width: '100%' }}
            ellipsis={
                <div className={cs('expandable-text-ellipsis', { pressed, highlight })} style={{ maxHeight }}>
                    {!expand && (
                        <div
                            onClick={e => {
                                setExpand(true);
                                const height = ref.current?.offsetHeight;
                                if (height) {
                                    setMaxHeight(`${height}px`);
                                }
                                onClickExpand?.(e);
                            }}
                            className="expand-btn"
                        >
                            <Icon type="caret-down" />
                        </div>
                    )}
                    <span ref={ref}>{value}</span>
                </div>
            }
            normal={<div>{value}</div>}
        />
    );
}
