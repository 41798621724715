import classNames from 'classnames';
import React from 'react';
import './index.less';

interface IProps {
    url: string;
    className?: string;
    isMobile?: boolean;
}

const BookCover: React.FC<IProps> = ({ url, className, isMobile = false }) => {
    return (
        <div className={classNames('home-id-book-cover', className, { mobile: isMobile })}>
            <span className="home-id-book-cover-line" />
            <img src={url} alt="" />
        </div>
    );
};
export default BookCover;
