import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import ArticleContent from '@components/article-content';
import Header from '@components/header';
import MobileHeader from '@components/mobile-header';
import { HeaderTheme } from '@constants/enum';
import Footer from '@components/footer';
import { Tea, useArticle, useHideNavValue, useLocationParams, useUserInfo } from '@utils/index';
import { Empty } from '@byted/serial-components-i18n';
import Loading from '@components/loading';
import cs from 'classnames';
import { useTranslation } from '@ies/intl-react-plugin';
import { useDispatch } from 'react-redux';
import { diggArticle } from '@service/api';
import { setArticleDiggNumber } from '@redux/actions/article';
import CommentList, { CommentListProps } from '@components/h5/comment-list';
import { DiggOperate } from '@typings/api/novel/toutiao_muye_overseas_homeapi';
import { EventType } from '@typings/api/novel/origin_overseas_base';
import InAppHeader from '@components/h5/header';
import { usePageDuration } from '@utils/duration';
import { EVENTS } from '@constants/event';
import { isAcademyType, useId } from './util';

export type ArticlePageQuery = {
    scroll_comment?: string;
    title?: string;
};

interface IProps {
    className?: string;
    bottom?: React.ReactNode;
    isMobile?: boolean;
}

const Article: React.FC<IProps> = ({ className, isMobile = false, bottom }) => {
    const { content, title, time, serverInited, commentOpen, diggCount, author, type } = useArticle();
    const user = useUserInfo();
    const hideNav = useHideNavValue();
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const id = useId();
    const pageRef = useRef<HTMLDivElement>(null);

    const { params: queryObj } = useLocationParams<ArticlePageQuery>();

    // 是否使用课堂埋点
    const useAcademyEvent = isAcademyType(type);

    usePageDuration(EVENTS.STAY_ACADEMY_DETAIL, { article_id: id }, useAcademyEvent);

    const getPageRef = useCallback(() => pageRef.current, []);

    const commentActions = useMemo<CommentListProps['actions']>(() => {
        return {
            like: async (nextOperate, userDigged) => {
                const res = await diggArticle({
                    item_id: id,
                    operate: nextOperate,
                });
                if (res?.code !== 0) {
                    throw res;
                }
                if (nextOperate === DiggOperate.DIGG) {
                    Tea('click_like_class');
                }
                const nextNum = Math.max(0, (diggCount || 0) + (userDigged ? -1 : 1));
                dispatch(setArticleDiggNumber(nextNum));
                return true;
            },
        };
    }, [diggCount, id, dispatch]);

    const addClickEvent = () => e => {
        const target: any = e.target;
        if (target.tagName.toLowerCase() === 'a') {
            Tea('click_article_link', { id, url: target.href });
        }
    };

    const scrollToBottom = () => {
        const pageH = document.body.clientHeight;
        const winH = window.innerHeight;
        const scrollTop = -document.body.getBoundingClientRect().y;
        const footerH = document.querySelector('.footer')?.clientHeight || 0;
        // 滚动到页面底部
        if (pageH - scrollTop - footerH <= winH) {
            Tea('click_article', { id, type: 1 });
            window.removeEventListener('scroll', scrollToBottom);
        }
    };

    useEffect(() => {
        Tea('click_article', { id, type: 0 });
        // 监听页面内部点击a标签
        window.addEventListener('click', addClickEvent);
        window.addEventListener('scroll', scrollToBottom);
        return () => {
            window.removeEventListener('click', addClickEvent);
            window.removeEventListener('scroll', scrollToBottom);
        };
    }, []);

    if (!serverInited) {
        return <Loading className="article-loading" />;
    }

    const showComment = isMobile && user.isLogin && commentOpen;

    return (
        <div ref={pageRef} id="article" className={cs('article', className, hideNav && 'hide-nav')}>
            {!hideNav && (isMobile ? <MobileHeader /> : <Header theme={HeaderTheme.Picture} />)}
            {hideNav && queryObj.title ? (
                <InAppHeader
                    style={{
                        backgroundColor: '#fff',
                    }}
                    title={decodeURIComponent(queryObj.title)}
                />
            ) : null}
            {content ? (
                <ArticleContent
                    isMobile={isMobile}
                    data={{ content, title, time, author }}
                    needDir={!isMobile}
                    videoEvent={useAcademyEvent ? { name: EVENTS.STAY_ACADEMY_VIDEO } : undefined}
                    showFollowBtn={isMobile}
                />
            ) : (
                <Empty description={i18n.t('page_not_accessible')} />
            )}
            {bottom}
            {content && showComment && (
                <>
                    <div className="article__separator" />
                    <CommentList
                        initScrollPosition={queryObj.scroll_comment === '1' && isMobile ? 0 : undefined}
                        eventType={EventType.DetailPageComment}
                        actions={commentActions}
                        getContainer={getPageRef}
                        itemId={id}
                        diggCount={diggCount}
                    />
                </>
            )}
            {!hideNav && <Footer />}
            {/* 撑起评论底部 fixed input */}
            {showComment ? (
                <div
                    className={cs('article__shim', {
                        'article__shim--black': showComment && !hideNav,
                    })}
                />
            ) : null}
        </div>
    );
};

export default Article;
