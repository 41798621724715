import React, { useCallback, useState } from 'react';

import { useTranslation } from '@ies/intl-react-plugin';
import { Entry, getAvatar, useGoAdmin, useUserInfo } from '@utils/index';

import { Tooltip } from '@byted/serial-components-i18n';

import Icon from '@components/icon';

import './index.less';

const { MenuItem } = Tooltip;

interface IProps {
    showAccount: () => void;
    isMobile?: boolean;
}

const UserInfo: React.FC<IProps> = ({ showAccount, isMobile = false }) => {
    const { isLogin, avatar, name, media_id } = useUserInfo();
    const [menuVisible, setMenuVisible] = useState(false);
    const { i18n } = useTranslation();
    const goAdmin = useGoAdmin();

    const logOut = useCallback(() => {
        import('@containers/account/sdk')
            .then(({ accountSDK }) => {
                accountSDK.login.logout({ next: '/' });
            })
            .catch(e => {
                console.log('account::logout error', e);
            });
    }, []);

    if (!isLogin) {
        return null;
    }

    const handleGoUserInfo = () => {
        setMenuVisible(false);
        if (isLogin && media_id) {
            goAdmin({
                [Entry.PC]: '/user-info',
                [Entry.H5]: '',
            });
        } else {
            showAccount();
        }
    };

    const menu = (
        <>
            <MenuItem onClick={() => handleGoUserInfo()}>
                <Icon type="profile" />
                <span>{i18n.t('sso_profile')}</span>
            </MenuItem>
            <MenuItem onClick={logOut}>
                <Icon type="logout" />
                <span>{i18n.t('common_nav_logout')}</span>
            </MenuItem>
        </>
    );

    const renderTooltip = () => {
        if (isMobile) {
            // h5显示异常，需要调整一下结构
            return (
                <div className="user-info-content" onClick={() => setMenuVisible(!menuVisible)}>
                    <img src={getAvatar(avatar)} />
                    <span>{name}</span>
                    <Tooltip
                        className="user-info-tooltip"
                        position="top"
                        type="menu"
                        popupVisible={menuVisible}
                        content={menu}
                        unmountOnExit={false}
                    >
                        <div>
                            <Icon type="trangle" />
                        </div>
                    </Tooltip>
                </div>
            );
        }
        return (
            <Tooltip
                className="user-info-tooltip"
                position="br"
                type="menu"
                popupVisible={menuVisible}
                content={menu}
                unmountOnExit={false}
            >
                <div className="user-info-content">
                    <img src={getAvatar(avatar)} />
                    <span>{name}</span>
                    <Icon type="trangle" />
                </div>
            </Tooltip>
        );
    };

    return renderTooltip();
};

export default UserInfo;
