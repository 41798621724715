import { useEffect, useRef } from 'react';
import { Tea, useReportClient } from '@utils/tea';
import bridge from '@bridge/worldance';
import { isInApp } from './env';
import { isBrowser } from '@constants/const';

export const useAppDuration = (event: string, params: Record<string, string | number>, enable = true) => {
    const startTimeRef = useRef<number>(Date.now());
    const reportClient = useReportClient();

    const sendTea = () => {
        const duration = Date.now() - startTimeRef.current;

        console.log('send duration ---------------', duration);
        reportClient(event, {
            ...params,
            stay_time: (duration / 1000) | 0,
        });
    };

    const initStartTime = () => {
        console.log('init start time ---------------');
        startTimeRef.current = Date.now();
    };

    useEffect(() => {
        if (!isBrowser || !isInApp() || !enable) {
            return;
        }

        initStartTime();

        const visibleSubscription = bridge.app.onPageVisible(() => {
            console.log('page show ---------------');
            initStartTime();
        });

        const inVisibleSubscription = bridge.app.onPageInvisible(() => {
            console.log('page hide ---------------');
            sendTea();
        });

        return () => {
            console.log('router/enable change ---------------');
            visibleSubscription.remove();
            inVisibleSubscription.remove();
            if (enable) {
                sendTea();
            }
        };
    }, [enable]);
};

enum DocVisibleState {
    Visible = 'visible',
    Hidden = 'hidden',
}

export const useBrowserDuration = (event: string, params: Record<string, string | number>, enable = true) => {
    const startTimeRef = useRef<number>(Date.now());

    const sendTea = () => {
        const duration = Date.now() - startTimeRef.current;

        console.log('send duration ---------------', duration);
        Tea(event, { ...params, stay_time: (duration / 1000) | 0 });
    };

    const initStartTime = () => {
        console.log('init start time ---------------');
        startTimeRef.current = Date.now();
    };

    useEffect(() => {
        if (!isBrowser || isInApp() || !enable) {
            return;
        }

        const onVisibleChange = () => {
            const visibleState = document.visibilityState;

            if (visibleState === DocVisibleState.Visible) {
                console.log('page show ---------------');
                initStartTime();
            }
            if (visibleState === DocVisibleState.Hidden) {
                console.log('page hide ---------------');
                sendTea();
            }
        };

        const onUnload = () => {
            console.log('page unload ---------------');
            sendTea();
        };

        initStartTime();
        document.addEventListener('visibilitychange', onVisibleChange);
        document.addEventListener('unload', onUnload);

        return () => {
            console.log('router/enable change ---------------');
            document.removeEventListener('visibilitychange', onVisibleChange);
            document.removeEventListener('unload', onUnload);
            if (enable) {
                sendTea();
            }
        };
    }, [enable]);
};

/**
 * @param {string} event  事件名称
 * @param {Record<string, string | number>} params 上报事件参数
 * @param {boolean} enable 是否开启
 */
export const usePageDuration = (event: string, params: Record<string, string | number>, enable = true) => {
    useAppDuration(event, params, enable);
    useBrowserDuration(event, params, enable);
};
