import React, { useMemo, useState, useRef } from 'react';
import Footer from '@components/footer';
import Header from '@components/header';
import RightBar from '@components/right-bar';
import Download from '@containers/home/pc/components/download';
import Academy from './components/academy';
import Author from './components/author';
import Banner from './components/banner';
import Rank from './components/rank';
import { Tea, useHeaderTheme, useHome, useRefStateSync, useScrollTrigger } from '@utils/index';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { loadData } from '../utils';
import { useLoadData } from '@utils/loadData';
import { IDConfigType } from '../type';
import { getIdConfigList } from '@service/api';
import { initHomeData } from '@redux/actions/home';
import { Affix } from '@byted/serial-components-i18n';
import { useTranslation } from '@ies/intl-react-plugin';
import CustomEase from 'gsap/CustomEase';
import { HeaderTheme } from '@constants/enum';
import './index.less';
import Copyright, { CopyrightRef } from './components/copyright';

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);

CustomEase.create('myEase', '.25,.1,.25,1');

const { FixedPosition } = Affix;

export let isJumping = false;

const PageModule = {
    banner: 'pc_homepage',
    academy: 'pc_academy',
    author: 'pc_star_writer',
    copyright: 'publishing_module_name',
    rank: 'pc_writer_list',
    app: 'pc_download',
};

const moduleEl = {
    [PageModule.banner]: '#home_banner',
    [PageModule.academy]: '#home_academy',
    [PageModule.author]: '#home_author',
    [PageModule.rank]: '#home_rank',
    [PageModule.app]: '#home_app',
    [PageModule.copyright]: '#home_copyright',
};

const HomeID = () => {
    const homeRef = useRef<HTMLDivElement>(null);
    const authorRef = useRef<any>();
    const indicatorRef = useRef<HTMLUListElement>(null);
    const [active, setActive] = useState<string>('');
    const [jumpModule, jumpModuleRef, setJumpModule] = useRefStateSync<string | null>(null);
    const { serverInited = false } = useHome();

    const copyrightRef = useRef<CopyrightRef>(null);

    const headerInfo = useHeaderTheme({
        ref: homeRef,
        getModules: () => Array.from(homeRef.current!.querySelectorAll('section')),
        pageThemes: [
            HeaderTheme.Black,
            HeaderTheme.Black,
            HeaderTheme.Black,
            HeaderTheme.White,
            HeaderTheme.White,
            HeaderTheme.Black,
            HeaderTheme.Black,
        ],
        pageBgs: ['transparent', '#fff', '#fff', '#000', '#000', '#fff', '#fff'],
        getHeaderHeight: () => 80,
    });

    const { t } = useTranslation();

    useLoadData<IDConfigType>({
        serverInited,
        request: getIdConfigList,
        action: initHomeData,
    });

    const onEnter = (moduleType: string) => {
        setActive(moduleType);
        if (moduleType !== jumpModuleRef.current) {
            return;
        }
        isJumping = false;
        if (jumpModuleRef.current === PageModule.author) {
            authorRef.current.play();
        }
        if (jumpModuleRef.current === PageModule.copyright) {
            copyrightRef.current?.play();
        }
        setJumpModule(null);
    };

    const moduleList = useMemo(
        () => [
            {
                el: '#home_banner',
                onEnter: () => onEnter(PageModule.banner),
            },
            {
                el: '#home_academy',
                onEnter: () => onEnter(PageModule.academy),
            },
            {
                el: '#home_author',
                onEnter: () => {
                    Tea('show_publishing_module');
                    onEnter(PageModule.author);
                },
            },
            {
                el: '#home_copyright',
                onEnter: () => {
                    Tea('show_top_author');
                    onEnter(PageModule.copyright);
                },
            },
            {
                el: '#home_rank',
                onEnter: () => {
                    Tea('show_author_list');
                    onEnter(PageModule.rank);
                },
            },
            {
                el: '#home_app',
                onEnter: () => onEnter(PageModule.app),
            },
        ],
        [jumpModule],
    );

    useScrollTrigger(moduleList, () => window.innerHeight / 2);

    const jumpToModule = (v: string) => {
        gsap.to(window, { duration: 0.5, scrollTo: moduleEl[v], offsetY: 0, ease: 'myEase' });
    };

    return (
        <div className="home-id" ref={homeRef}>
            {serverInited && <Header {...headerInfo} />}
            <Banner />
            <Academy />
            <Author ref={authorRef} />
            <Copyright ref={copyrightRef} />
            <Rank />
            <div className="home">
                <Download />
            </div>
            <Footer />
            {serverInited && (
                <FixedPosition
                    className="home-id-faq"
                    offsetBottom={80}
                    fixBottom={602}
                    getContainer={() => {
                        if (homeRef.current) {
                            return homeRef.current;
                        }
                        return null;
                    }}
                >
                    <RightBar onClickFeedback={() => Tea('click_help_desk')} />
                </FixedPosition>
            )}
            {serverInited && (
                <FixedPosition
                    className="home-id-dir"
                    offsetTop={winH => {
                        const indicator = indicatorRef.current;
                        const indicatorH = indicator!.getBoundingClientRect().height;
                        return (winH - indicatorH) / 2;
                    }}
                    fixBottom={930}
                    getContainer={() => {
                        if (homeRef.current) {
                            return homeRef.current;
                        }
                        return null;
                    }}
                >
                    <ul className="home-id-indicator" ref={indicatorRef}>
                        {Object.values(PageModule).map((v, index) => {
                            const isActive = v === active;
                            const activeText = isActive ? t(v) : '';
                            return (
                                <li
                                    className={isActive ? 'home-id-indicator-text' : 'home-id-indicator-item'}
                                    onClick={() => {
                                        jumpToModule(v);
                                        setJumpModule(v);
                                        Tea('click_guide_bar');
                                        isJumping = true;
                                    }}
                                    key={index}
                                >
                                    {activeText}
                                </li>
                            );
                        })}
                    </ul>
                </FixedPosition>
            )}
        </div>
    );
};

HomeID.loadData = loadData;

export default HomeID;
