/**
 * 点击加载
 */
import React, { HTMLAttributes, MouseEventHandler, useRef, useState } from 'react';
import { useTranslation } from '@ies/intl-react-plugin';
import cs from 'classnames';
import Icon from '@components/icon';
import './index.less';
import ListLoading from '@components/h5/list-loading';

export interface ClickLoadProps extends Omit<HTMLAttributes<HTMLDivElement>, 'style'> {
    showLoad?: boolean; // 是否展示加载按钮
    onClickLoad: (e: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>; // Pending时展示loading
    restCount?: number;
}

function ClickLoad({ showLoad = false, className, children, onClickLoad, restCount, ...props }: ClickLoadProps) {
    const { i18n } = useTranslation();
    const domRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(false);
    const [height, setHeight] = useState<number | null>(null);
    const onClick: MouseEventHandler<HTMLElement> = e => {
        setLoading(true);
        setHeight(innerRef.current?.offsetHeight || null);
        onClickLoad(e).finally(() => {
            setLoading(false);
            setHeight(innerRef.current?.offsetHeight || null);
            setTimeout(() => {
                setHeight(null);
            }, 500);
        });
    };

    return (
        <div
            {...props}
            style={{ height: height != null ? `${height}px` : undefined }}
            className={cs('click-load-list', className)}
            ref={domRef}
        >
            <div className="click-load-list-inner" ref={innerRef}>
                {children}
                {loading && <ListLoading className="click-load-list-loading" />}
                {!loading && showLoad && (
                    <div className="load-more-btn" onClick={onClick}>
                        <div className="load-more-btn-text">
                            {restCount
                                ? i18n.t(
                                      'expand_replies',
                                      {
                                          count: restCount,
                                      },
                                      `Expand ${restCount} replies`,
                                  )
                                : i18n.t('view_more_comments', undefined, 'View more comments')}
                        </div>
                        <Icon className="load-more-btn-icon" type="expand-more" />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ClickLoad;
