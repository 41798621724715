import { PressKeyCls } from '@components/h5/comment-list/comment-card';
import { IUserState } from '@redux/reducers/user';
import { CommentInfo, ReplyCommentInfo } from '@typings/api/novel/toutiao_muye_overseas_homeapi';
import { composedPath } from '@utils/compose-path';

/**
 * 生成单条评论唯一 ID
 * @param context commentId: 评论 or 回复 的 id
 * @returns id
 */
export function genCommentCardUId(context: { comment?: CommentInfo; reply?: ReplyCommentInfo }): string {
    const { comment, reply } = context || {}; // 优先取 reply
    return `c${comment?.comment_id || ''}_${reply?.reply_id || ''}`;
}

/** 获取点击的评论DOM */
export function getCommentCardDom(e: Event): HTMLElement | undefined {
    const path = composedPath(e);
    return path.find(el => el instanceof HTMLElement && el.classList.contains(PressKeyCls));
}

export const getContainerScrollTop = (getContainer: () => HTMLElement | null) => {
    return getContainer()?.scrollTop || 0;
};

export const scrollContainerTo = (getContainer: () => HTMLElement | null, top: number) => {
    getContainer()?.scrollTo({
        top,
        behavior: 'smooth',
    });
};

export const isContainerScrollToEnd = (getContainer: () => HTMLElement | null) => {
    const container = getContainer();
    const { height } = container?.getBoundingClientRect() || {};
    return (height || window.innerHeight) + getContainerScrollTop(getContainer) >= (container?.scrollHeight || 0) - 2;
};

// 格式化评论文本，去除多余换行
export function formatCommentText(text: string) {
    return text
        .split('\n')
        .filter(v => Boolean(v))
        .join('\n');
}

export const createComment = (ctx: { comment_id: string; value: string; user: IUserState }): CommentInfo => {
    const { comment_id, user, value } = ctx;
    return {
        comment_id,
        book_id: '',
        item_id: '',
        create_time: String(Math.floor(Date.now() / 1000)),
        text: formatCommentText(value),
        digg_count: 0,
        author_digg: false,
        reply_count: 0,
        comment_user: {
            user_id: user.id,
            user_name: user.name,
            avatar_url: user.avatar,
            is_author: false, // 要注意一下 这里是 true 会出现 author 标
        },
    };
};

export const createReplyComment = (ctx: {
    comment?: CommentInfo;
    reply?: ReplyCommentInfo;
    reply_id: string;
    value: string;
    user: IUserState;
}): ReplyCommentInfo => {
    const { reply, comment, reply_id, value, user } = ctx;
    return {
        reply_id,
        parent_comment_id: comment?.comment_id || '',
        to_reply_id: reply?.reply_id || '',
        book_id: comment?.book_id || '',
        item_id: comment?.item_id || '',
        create_time: String(Math.floor(Date.now() / 1000)),
        text: formatCommentText(value),
        digg_count: 0,
        author_digg: false,
        reply_count: 0,
        comment_user: {
            user_id: user.id,
            user_name: user.name,
            avatar_url: user.avatar,
            is_author: false,
        },
        reply_to_user: reply?.comment_user,
    };
};

export const updateItemInList = <T>(newElement: T, list: T[], key: keyof T) =>
    list.map(v => {
        if (v[key] === newElement[key]) {
            return newElement;
        }
        return v;
    });

/**
 * 防止异步闭包数据问题 最好重新拿一次对应的最新对象
 */
export const getCurrentCommentFromList = (list: CommentInfo[], commentId: string, replyId?: string) => {
    const comment = (list || []).find(c => c.comment_id === commentId);
    const reply = replyId && comment ? (comment.reply_list || []).find(r => r.reply_id === replyId) : undefined;
    return [comment, reply] as const;
};

export const differenceByProp = <T>(source: T[], exclude: T[], prop: keyof T) => {
    const excludeSet = new Set(exclude.map(v => v[prop]));
    return source.filter(v => !excludeSet.has(v[prop]));
};
