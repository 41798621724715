import { DataAction } from '@redux/typings';
import { ArticleState } from '../reducers/article';

export const INIT_ARTICLE_DATA = 'INIT_ARTICLE_DATA';

export const SET_DIGG_NUMBER = 'SET_DIGG_NUMBER';

export function initArticleData(data: ArticleState) {
    return {
        type: INIT_ARTICLE_DATA,
        data,
    };
}

export function setArticleDiggNumber(number: number) {
    return {
        type: SET_DIGG_NUMBER,
        data: number,
    };
}

export type ActionType =
    | DataAction<ArticleState, typeof INIT_ARTICLE_DATA>
    | DataAction<Parameters<typeof setArticleDiggNumber>[0], typeof SET_DIGG_NUMBER>;
