import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import cs from 'classnames';

import { HeaderTheme, isBrowser, MessageText, MobileHeaderHeight } from '@constants/index';

import Lang from '@components/lang';
import { Divider, Drawer, Message } from '@byted/serial-components-i18n';
import Icon from '@components/icon';
import UserInfo from '@components/header/user-info';

import { useAccount } from '@containers/account';
import { useScrollFix, px2rem, Tea, useUserInfo, useInitLogin } from '@utils/index';
import { useTranslation } from '@ies/intl-react-plugin';

import { LangCode } from '@common/lang';
import { getRewardList, getFAQConfig } from '@service/api';

import './index.less';
import DebuggerBlock from '@components/debugger';

interface IProps {
    theme?: HeaderTheme;
    style?: any;
    getAccountVisibleFn?: any;
    loginCallback?: () => void; // 登陆成功后回调
}

const Header: React.FC<IProps> = ({ style, theme = HeaderTheme.Black, getAccountVisibleFn, loginCallback }) => {
    const [menuShow, setMenuShow] = useState(false);
    const [showReward, setShowReward] = useState(false);
    const [showFAQ, setShowFAQ] = useState(false);

    const { id } = useUserInfo();
    const history = useHistory();
    const { handleWrite, getAccountPopup, setAccountVisible } = useAccount(true, loginCallback);

    useInitLogin(handleWrite);
    const { i18n } = useTranslation();

    useScrollFix(menuShow);

    useEffect(() => {
        getAccountVisibleFn && getAccountVisibleFn(setAccountVisible);
    }, [setAccountVisible]);

    const getRewardNum = async () => {
        try {
            const resp = await getRewardList({
                page_index: 0,
                page_count: 0,
                language: i18n.language,
            });
            if (resp.code) {
                throw Error(resp.message);
            }
            setShowReward(resp.data.total_count > 0);
        } catch (e) {
            console.log(e);
        }
    };

    const getFAQ = async () => {
        try {
            const resp = await getFAQConfig({
                language: i18n.language,
                entrance: 1,
            });

            if (resp.code) {
                throw Error(resp.message);
            }

            const { status } = resp.data;
            setShowFAQ(status);
        } catch {
            Message.error(MessageText.netError);
        }
    };

    const handleMenuShow = () => {
        setMenuShow(true);
        document.body.classList.add('show-menu');
    };
    const handleMenuHide = () => {
        setMenuShow(false);
        document.body.classList.remove('show-menu');
    };

    const getMenuItem = (text: string, link: any, className?: string, refresh?: boolean) => {
        const handleMenuClick = _link => {
            if (refresh) {
                location.href = _link;
            } else {
                history.push(_link);
                handleMenuHide();
            }
        };
        const isFunction = typeof link !== 'string';
        return (
            <span className={className} onClick={isFunction ? link : () => handleMenuClick(link)}>
                {text}
                <div className="link-tag" />
                {!isFunction && isBrowser && location.pathname === link && <Icon type="point" />}
            </span>
        );
    };

    const handleFAQ = () => {
        window.open('/faq');
        Tea('click_faq', {
            faq_entry: 0,
        });
    };

    const onWrite = () => {
        Tea('write_stories_click', { user_id: id });
        handleWrite();
    };

    useEffect(() => {
        getRewardNum();
        getFAQ();
    }, []);

    const lang = i18n.language as LangCode;

    return (
        <>
            <header
                className={cs({ white: theme === HeaderTheme.White })}
                style={{ ...style, height: `${px2rem(MobileHeaderHeight)}rem` }}
            >
                <Link className="header-logo" to="/" />
                <DebuggerBlock />
                <div className="header-menu">
                    <Lang theme={theme} className="header-lang" />
                    <Divider type="vertical" />
                    <span onClick={handleMenuShow} />
                </div>
            </header>
            <Drawer
                className="header-collapse"
                visible={menuShow}
                closable={false}
                maskClosable={false}
                placement="right"
                footer={null}
            >
                <div className="header-collapse-top">
                    <span className="collapes-close" onClick={handleMenuHide} />
                </div>
                <div className="header-collapse-body">
                    {getMenuItem(i18n.t('common_menu_home'), '/')}
                    {getMenuItem(
                        i18n.t('common_nav_banefit'),
                        () => {
                            Tea('click_benefits');
                            location.href = '/event/benefit';
                        },
                        undefined,
                    )}
                    {showReward && getMenuItem(i18n.t('reward_exit'), '/reward', 'header-collapse-reward')}
                    {getMenuItem(i18n.t('common_nav_write'), onWrite)}
                    {/* {showFAQ && getMenuItem(i18n.t('helpdesk_faq'), handleFAQ)} */}
                </div>
                <div className="header-collapse-bottom">
                    <UserInfo showAccount={handleWrite} isMobile />
                </div>
            </Drawer>
            {getAccountPopup()}
        </>
    );
};

export default Header;
