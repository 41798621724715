import React from 'react';
import Article from '../article';
import { loadSSRData, useArticleData } from '../util';

import './index.less';

const ArticlePC = () => {
    useArticleData();

    return <Article />;
};

ArticlePC.loadData = loadSSRData;

export default ArticlePC;
