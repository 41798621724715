import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Empty, Message, Pagination } from '@byted/serial-components-i18n';

import { searchFAQ } from '@service/api';
import { MessageText } from '@constants/value';
import { Tea } from '@utils/tea';
import { useTranslation } from '@ies/intl-react-plugin';
import Loading from '@components/loading';
import { debounce } from 'lodash';
import { useLocationParams } from '@utils/hooks';
import { setFromSearchPage } from '../common';

import './index.less';

const PAGE_SIZE = 10;
const INIT_PAGE_CURRENT = 0;
const NO_RESULT = 0;

const Search: React.FC = () => {
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(INIT_PAGE_CURRENT);
    const [list, setList] = useState(() => []);
    const [searchKey, setSearchKey] = useState<string>();
    const { location, params } = useLocationParams();

    const { i18n } = useTranslation();

    const searchByKey = async (index: number) => {
        try {
            window.scrollTo(0, 0);
            setLoading(true);
            const resp = await searchFAQ({
                page_index: index,
                page_count: PAGE_SIZE,
                query: searchKey,
                language: i18n.language,
            });
            if (resp.code) {
                Message.error(MessageText.netError);
                return;
            }
            setTotal(resp.data.total);
            setList(resp.data.search_list);
            Tea('enter_search_result', {
                search_text: searchKey,
                search_result: total,
                search_status: 0,
            });
        } catch (e) {
            Message.error(MessageText.netError);
            Tea('enter_search_result', {
                search_text: searchKey,
                search_result: total,
                search_status: 1,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleClickItem = (category: any) => {
        const articlePath: string = Object.values(category).join('_').replace(/_0/g, '');
        const newUrl = `/faq?article=${articlePath}`;

        // 从搜索结果点进文章时，把这个文章的id加入到localStorage的from_search_article数组中
        // 用发送show_faq_page这个埋点时，标记文章是从搜索结果点进去的文章
        setFromSearchPage(articlePath);
        history.push(newUrl);
    };

    const handlePageChange = async (val: number) => {
        searchByKey(val);
        setCurrent(val);
    };

    useEffect(() => {
        const searchVal = params.search?.trim();
        setSearchKey(searchVal);
        setCurrent(INIT_PAGE_CURRENT);
    }, [location]);

    useEffect(() => {
        if (searchKey) {
            searchByKey(INIT_PAGE_CURRENT);
            return;
        }
        if (searchKey === '') {
            setTotal(NO_RESULT);
            setLoading(false);
        }
    }, [searchKey]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="search-wrap">
            <div className="search-wrap-title">{i18n.t('helpdesk_searchresult').replace('{}', `${total}`)}</div>
            <div className="search-wrap-content">
                {total > 0 ? (
                    list.map((item: any) => {
                        return (
                            <div
                                key={item.item_id}
                                className="search-wrap-content-item"
                                onClick={() => handleClickItem(item.parent_category)}
                            >
                                <div
                                    className="search-wrap-content-item-title"
                                    dangerouslySetInnerHTML={{
                                        __html: item.title_highlight,
                                    }}
                                />
                                <div
                                    className="search-wrap-content-item-content"
                                    dangerouslySetInnerHTML={{
                                        __html: item.content_highlight,
                                    }}
                                />
                            </div>
                        );
                    })
                ) : (
                    <Empty description={i18n.t('helpdesk_searchresult_non')} />
                )}

                {total > PAGE_SIZE && (
                    <Pagination
                        className="search-wrap-pagination"
                        current={current}
                        pageSize={PAGE_SIZE}
                        total={total}
                        onChange={handlePageChange}
                    />
                )}
            </div>
        </div>
    );
};

export default Search;
