import { useEffect } from 'react';
import { getUserInfo } from '@service/api';
import { useDispatch } from 'react-redux';
import { initUserInfo, setFinish } from '@redux/actions/user';

export const updateUser = async (dispatch: any) => {
    try {
        const res = await getUserInfo();
        const { code, data } = res || {};
        if (!code && data.id && data.id !== 'undefined') {
            dispatch(
                initUserInfo({
                    ...res.data,
                    isLogin: true,
                }),
            );
        }
        return res;
    } catch (e) {
        console.error('account::getUserInfo error', e);
        return null;
    } finally {
        dispatch(setFinish());
    }
};

const User = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        updateUser(dispatch);
    }, []);

    return null;
};

export default User;
