import { useUserInfo } from './hooks';
import HappyDebugger, {
    CopyPlugin,
    Event,
    FakePlugin,
    NetworkPlugin,
    PanelKey,
    Switch,
    definePlugin,
} from '@novel/happy-debug';
import React, { useEffect, useState } from 'react';
import { isInApp, isiOS } from './env';
import Cookies from 'js-cookie';

const UserMsg = 'user_msg';
const allowlist = ['/api/node', '/api/author', '/reading/'];

export const DISABLED_SSR_KEY = 'disabled_ssr_data';

export const disableSSR = (cookie: string) => cookie.includes(DISABLED_SSR_KEY);

const SSRPluginTemplate = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (Cookies.get(DISABLED_SSR_KEY)) {
            setOpen(true);
        }
    }, []);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginRight: 5 }}>禁用SSR数据</p>
            <Switch
                value={open}
                onChange={v => {
                    if (!v) {
                        Cookies.remove(DISABLED_SSR_KEY);
                    } else {
                        Cookies.set(DISABLED_SSR_KEY, '1');
                    }
                    setOpen(v);
                }}
            />
        </div>
    );
};

export const SSRPlugin = definePlugin(() => {
    return {
        key: 'ssr_plugin',
        panel: PanelKey.Basic,
        apply: ({ plugin }) => {
            plugin.on(Event.Render, ({ render }) => {
                render(SSRPluginTemplate);
            });
        },
    };
});

export const myDebugger = new HappyDebugger({
    env: {
        allowlist,
    },
    JSBRequest: isInApp()
        ? {
              response: {
                  body: isiOS() ? '__params.data.data.body' : '__params.data.body',
                  headers: isiOS() ? '__params.data.data.header' : '__params.data.headers',
                  status: isiOS() ? '__params.data.data.status' : '__params.data.status',
              },
          }
        : false,
    plugins: [
        FakePlugin({ allowlist }),
        CopyPlugin({
            key: UserMsg,
            title: '作者信息',
            order: 2,
            data: [],
        }),
        // 线上版本移除部分 plugin，注：process.env.BUILD_TYPE !== 'online' 不可封装成变量通过 import 导入，会使 tree shaking 失效
        ...(process.env.BUILD_TYPE !== 'online'
            ? [
                  CopyPlugin({
                      key: 'url_msg',
                      title: 'url信息',
                      order: 4,
                      data: [{ label: '当前url', value: loc => loc.href }],
                  }),
                  SSRPlugin(),
              ]
            : []),
        // NetworkPlugin 必须放到最后
        NetworkPlugin({ allowlist }),
    ],
});

export const useDebugger = () => {
    const user = useUserInfo();

    const userMsg = [
        {
            label: '作者id',
            value: user.id,
        },
        {
            label: '作者名',
            value: user.name,
        },
        {
            label: '作者国家',
            value: user.region,
        },
    ];

    useEffect(() => {
        if (!user.isLoading) {
            myDebugger?.updatePlugin(UserMsg, { data: userMsg });
        }
    }, [user]);
};
