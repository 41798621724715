import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from './routes';
import { useTea } from '@utils/tea';
import User from '@containers/user';
import { useEnterPage } from '@utils/hooks';
import { initMonitor } from '@utils/monitor';
import { changeDayjsLocale } from '@utils/day';
import { LangCode } from '@common/lang';
import { useTranslation } from '@ies/intl-react-plugin';
import { ConfigProvider } from '@byted/serial-components-i18n';
import { checkWebId } from '@containers/account/ttwid';
import { useDebugger } from '@utils/debugger';
import { initCsrf } from '@utils/csrf';

import './index.less';

const Inspector = process.env.NODE_ENV !== 'production' ? require('react-dev-inspector').Inspector : null;

initCsrf();
initMonitor();

const App: React.FC = () => {
    const { i18n } = useTranslation();

    useEnterPage();
    useTea();
    useDebugger();

    useEffect(() => {
        checkWebId();
    }, []);

    useEffect(() => {
        changeDayjsLocale(i18n.language as LangCode);
    }, [i18n.language]);

    const app = (
        <ConfigProvider lang={i18n.language as any}>
            <User />
            <Switch>
                {routes.map((route, index) => (
                    <Route {...route} key={index} />
                ))}
                <Redirect from="/*" to="/" />
            </Switch>
        </ConfigProvider>
    );

    if (Inspector) {
        return (
            <>
                <Inspector keys={['Command', 'Shift', 'S']} />
                {app}
            </>
        );
    }

    return app;
};

export default App;
