/* eslint-disable no-inner-declarations */
import { BgStyleList, useCopyright } from './utils';
import React, { forwardRef, ForwardRefRenderFunction, MouseEvent, useEffect, useImperativeHandle, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isJumping } from '../..';
import { useTranslation } from '@ies/intl-react-plugin';
import Loading from '@components/loading';
import { Tea } from '@utils/index';

import './index.less';

export interface CopyrightRef {
    play: () => void;
}
const BezierFunc = 'cubic-bezier(0.25, 0.1, 0.25, 1)';

const Copyright: ForwardRefRenderFunction<CopyrightRef> = (_, ref) => {
    const { copyrightList, loading } = useCopyright();
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<ScrollTrigger>();
    const scrollAnimationRef = useRef<any>(null);
    const bookCoverAnimationRef = useRef<any>(null);
    const bookCoverOpacityRef = useRef<any>(null);
    const nextBookAnimationRef = useRef<any>(null);
    const goDetailRef = useRef<HTMLDivElement>(null);

    const isBookCanHover = useRef(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (!copyrightList.length) {
            return;
        }
        triggerRef.current = ScrollTrigger.create({
            trigger: containerRef.current,
            start: 'top+=150px bottom',
            onEnterBack: play,
            onEnter: play,
        });
        return () => triggerRef.current?.kill();
    }, [copyrightList]);

    const play = () => {
        if (isJumping) {
            return;
        }
        triggerRef.current?.kill();
        const module = containerRef.current;
        const tl = gsap.timeline();
        document.body.style.height = '100%';
        document.body.style.overflow = 'hidden';
        tl.to(window, {
            duration: 0.5,
            scrollTo: module!,
            offsetY: 0,
            ease: 'myEase',
        });
        tl.fromTo(
            containerRef.current,
            {
                y: 150,
                opacity: 1,
                ease: BezierFunc,
            },
            {
                y: 0,
                opacity: 1,
                ease: BezierFunc,
                duration: 0.75,
            },
            '<',
        );

        tl.fromTo(
            headerRef.current,
            {
                y: 150,
                opacity: 0,
                ease: BezierFunc,
            },
            {
                y: 0,
                opacity: 1,
                ease: BezierFunc,
                duration: 0.75,
            },
            0.75,
        );
        tl.fromTo(
            scrollContainerRef.current,
            {
                y: 150,
                opacity: 0,
                ease: BezierFunc,
            },
            {
                y: 0,
                opacity: 1,
                duration: 0.75,
                ease: BezierFunc,
                onComplete: () => {
                    document.body.style.height = '';
                    document.body.style.overflow = '';

                    // 书封可以hover
                    setTimeout(() => {
                        isBookCanHover.current = true;
                    }, 2);
                },
            },
            1.5,
        );
        tl.fromTo(
            goDetailRef.current,
            {
                y: 150,
                opacity: 0,
                ease: BezierFunc,
            },
            {
                y: 0,
                opacity: 1,
                duration: 0.75,
                ease: BezierFunc,
            },
            '<',
        );
        scrollAnimationRef.current = tl.to(
            scrollContainerRef.current,
            {
                // 一次循环的距离
                x: -((216 + 24) * 9),
                repeat: -1,
                duration: 40,
                ease: 'linear',
            },
            2.4,
        );
    };

    const handleMouseEnter = (e: MouseEvent, bookName: string) => {
        if (!isBookCanHover.current) {
            return;
        }
        e.stopPropagation();
        Tea('hover_publishing_book', {
            book_name: bookName,
        });
        const tl = gsap.timeline();

        scrollAnimationRef.current.pause();
        bookCoverOpacityRef.current = tl.to((e.currentTarget as any).childNodes[0], { opacity: 0 });
        bookCoverAnimationRef.current = tl.to(
            (e.currentTarget as any).childNodes[3],
            { display: 'block', opacity: 1, duration: 0.5 },
            0.2,
        );
        nextBookAnimationRef.current = tl
            .to(e.currentTarget.nextSibling, { x: -50, duration: 0, zIndex: -1 }, '<')
            .to(e.currentTarget.nextSibling, { x: 0, duration: 0.15, ease: BezierFunc }, '<');
    };
    const handleMouseLeave = (e: MouseEvent) => {
        if (!isBookCanHover.current) {
            return;
        }
        e.stopPropagation();
        scrollAnimationRef.current.play();
        bookCoverOpacityRef.current.reverse();
        nextBookAnimationRef.current.reverse();
        bookCoverAnimationRef.current.seek({ duration: 0, delay: 0 });
    };

    useImperativeHandle(ref, () => ({
        play,
    }));

    const handleGoCopyright = () => {
        Tea('click_publishing_detail');
        window.open(`${window.location.origin}/copyright`);
    };

    return (
        <section className="home-id-copyright" id="home_copyright" ref={containerRef}>
            {!loading ? (
                <>
                    <div className="home-id-copyright-header" ref={headerRef}>
                        <div className="home-id-copyright-title">{t('publishing_module_name')}</div>
                        <div className="home-id-copyright-subtitle">{t('publishing_module_name_second')}</div>
                    </div>

                    <div className="home-id-copyright-scroll-container" ref={scrollContainerRef}>
                        {[...copyrightList, ...copyrightList].map((item, index) => {
                            return (
                                <div
                                    className="home-id-copyright-item"
                                    onMouseEnter={e => handleMouseEnter(e, item.book_name)}
                                    onMouseLeave={handleMouseLeave}
                                    key={item.book_name}
                                >
                                    <div className="home-id-copyright-item-wrapper" />
                                    <span className="home-id-copyright-item-line" />
                                    <img className="home-id-copyright-img" src={item.book_cover} />
                                    <div className="home-id-copyright-extra" style={{ background: BgStyleList[index] }}>
                                        <div className="home-id-copyright-extra-book-name">{item.book_name}</div>
                                        <div className="home-id-copyright-extra-author">
                                            <img
                                                className="home-id-copyright-extra-author-avatar"
                                                src={item.author_avatar}
                                            />
                                            <div className="home-id-copyright-extra-author-name">
                                                {item.author_name}
                                            </div>
                                        </div>
                                        <div className="home-id-copyright-extra-introduction">
                                            {item.book_introduction}
                                        </div>
                                        <div className="home-id-copyright-extra-publish-container">
                                            <img
                                                className="home-id-copyright-extra-logo"
                                                src={item.publish_house_logo}
                                            />
                                            <div className="home-id-copyright-extra-publish-divider" />
                                            <div className="home-id-copyright-extra-publish">{item.has_published}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="home-id-copyright-go-detail-container">
                        <div className="home-id-copyright-go-detail" onClick={handleGoCopyright} ref={goDetailRef}>
                            <div className="home-id-copyright-go-detail-info">{t('published_book_detail')}</div>
                            <div className="home-id-copyright-go-detail-icon" />
                        </div>
                    </div>
                </>
            ) : (
                <Loading />
            )}
        </section>
    );
};

export default forwardRef(Copyright);
