import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { StoreType } from '@redux/store';
import { useHistory, useLocation } from 'react-router-dom';
import { HeaderTheme } from '@constants/enum';
import qs from 'qs';
import { isMobileDevice } from './util';
import { Tea } from './tea';

enum PageType {
    Official,
    Benefit,
    Contest,
}

const PageURl = {
    [PageType.Official]: '/',
    [PageType.Benefit]: '/benefit',
};

const PageEnterEvent = {
    [PageType.Official]: 'enter_PC',
    [PageType.Benefit]: 'enter_benefit',
};

export const useEnterPage = () => {
    const history = useHistory();

    const postPageTea = (path: string) => {
        let pageKey;
        for (const key in PageURl) {
            if (path === PageURl[key]) {
                pageKey = key;
            }
        }
        Tea(PageEnterEvent[pageKey]);
    };

    useEffect(() => {
        postPageTea(location.pathname);
        const unListen = history.listen(location => {
            console.log('location::', location);
            setTimeout(() => {
                window.scrollTo(0, 0);
                postPageTea(location.pathname);
            }, 1);
        });
        return unListen;
    }, []);
};

export const useUserInfo = () => {
    const userInfo = useSelector((state: StoreType) => state.user);
    return userInfo;
};

export const useHome = () => {
    const home = useSelector((state: StoreType) => state.home);
    return home;
};

export const useArticle = () => {
    const article = useSelector((state: StoreType) => state.article);
    return article;
};

export const usePost = () => {
    const post = useSelector((state: StoreType) => state.post);
    return post;
};

export function useRefState<T>(
    initialValue: T,
): [T, React.MutableRefObject<T>, React.Dispatch<React.SetStateAction<T>>] {
    const [state, setState] = useState<T>(initialValue);
    const stateRef = useRef<T>(state);
    useEffect(() => {
        stateRef.current = state;
    }, [state]);
    return [state, stateRef, setState];
}

export function useRefStateSync<T>(initialValue: T): [T, React.MutableRefObject<T>, (v: T) => void] {
    const [state, setState] = useState<T>(initialValue);
    const stateRef = useRef<T>(state);

    const setValue = (v: T) => {
        setState(v);
        stateRef.current = v;
    };

    return [state, stateRef, setValue];
}

export function useRefUpdate<T>(initialValue?: T): [T | undefined, (v: T) => void] {
    const [num, update] = useState(0);
    const ref = useRef<T | undefined>(initialValue);

    const setState = (v: T) => {
        ref.current = v;
        update(num + 1);
    };

    return [ref.current, setState];
}

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
};

interface UseHeaderThemeProps {
    ref: any;
    pageThemes: HeaderTheme[];
    pageBgs: string[];
    getModules: () => Element[];
    getHeaderHeight: () => number;
    onShow?: (index: number) => void;
}

export const useHeaderTheme = (props: UseHeaderThemeProps) => {
    const [headerInfo, setHeaderInfo] = useState<any>({});
    const lastModule = useRef(-1);

    const { ref, pageThemes, pageBgs, getModules, getHeaderHeight, onShow } = props;

    const initHeaderInfo = () => {
        setHeaderInfo({
            theme: pageThemes[0],
            style: { background: pageBgs[0] },
        });
    };

    const handleFn = () => {
        const body = ref.current;
        if (!body || body.style.position === 'fixed') {
            return;
        }
        if (body.getBoundingClientRect().y >= 0) {
            lastModule.current = -1;
            initHeaderInfo();
            return;
        }
        const modules = getModules();
        let index = 0;
        for (let i = 0; i < modules.length; i++) {
            const pagesY = modules[i].getBoundingClientRect().y;
            const pageH = modules[i].clientHeight;
            if (-pagesY < pageH - getHeaderHeight()) {
                index = i;
                break;
            }
        }

        if (lastModule.current !== index) {
            lastModule.current = index;
            onShow?.(index);
            const isLast = index + 1 > pageThemes.length;
            setHeaderInfo({
                theme: isLast ? pageThemes[index] : pageThemes[index + 1],
                style: { background: isLast ? pageBgs[index] : pageBgs[index + 1] },
            });
        }
    };

    useEffect(() => {
        onShow?.(0);
        initHeaderInfo();
    }, []);

    useEffect(() => {
        document.addEventListener('scroll', handleFn);
        return () => {
            document.removeEventListener('scroll', handleFn);
        };
    }, [headerInfo]);

    return headerInfo as any;
};

/**
 *
 * @param showDrawer 弹窗是否显示
 * @param scrollBody 底部滚动页面的ref
 */
export const useScrollFix = (showDrawer: boolean, scrollBody?: any) => {
    const [oldInfo, setOldInfo] = useState<any>({});
    useEffect(() => {
        const body = scrollBody ? scrollBody.current : document.querySelector('#root')?.firstElementChild;
        if (!body || !isMobileDevice()) {
            return;
        }
        if (showDrawer) {
            const scrollY = body.getBoundingClientRect().y;
            const offsetY = body.offsetTop;
            const pageY = scrollY - offsetY;
            setOldInfo({
                pos: body.style.position,
                top: body.style.top,
                pageY,
            });
            body.style.position = 'fixed';
            requestAnimationFrame(() => {
                body.style.top = `${pageY}px`;
            });
        } else {
            body.style.top = oldInfo.top;
            body.style.position = oldInfo.pos;
            window.scrollTo(0, -oldInfo.pageY);
        }
    }, [showDrawer]);
};

// 返回上一页
export function useGoBack() {
    const history = useHistory();

    return () => {
        if (history.length > 1) {
            return history.goBack();
        }

        history.replace('/');
        return null;
    };
}

export const useLocationParams = <T extends Record<string, string>>(): { location: any; params: T } => {
    const location = useLocation();
    return {
        location,
        params: (qs.parse(location.search.slice(1)) || {}) as T,
    };
};

// 从query参数获取是否隐藏内容
export function useHideNavValue(): boolean {
    const { params } = useLocationParams();
    return Boolean(params.hideNav);
}

type ScrollTriggerItem = {
    el: string | Element;
    onEnter?: () => void;
    onFirstEnter?: () => void;
    [key: string]: any;
};

export const useScrollTrigger = (items: ScrollTriggerItem[], getOffsetTop?: () => number) => {
    const elemsRef = useRef<(Element | null)[]>([]);
    const curIndex = useRef();
    const enterMarks = useRef({});

    useEffect(() => {
        const _elems: Element[] = [];
        items.forEach(item => {
            if (typeof item.el === 'string') {
                const el = document.querySelector(item.el);
                el && _elems.push(el);
                return;
            }
            _elems.push(item.el as Element);
        });
        elemsRef.current = _elems;

        const offsetTop = getOffsetTop?.() || 0;
        scrollFn(offsetTop)();
        document.addEventListener('scroll', scrollFn(offsetTop));
        return () => {
            document.removeEventListener('scroll', scrollFn(offsetTop));
        };
    }, [items]);

    const markEnter = (index: number) => {
        enterMarks.current[index] = {
            ...enterMarks.current,
            [index]: true,
        };
    };

    const scrollFn = (offsetTop: number) => () => {
        const elems = elemsRef.current;
        if (!elems.length) {
            return;
        }

        let index;
        for (let i = 0; i < elems.length; i++) {
            const rect = elems[i]?.getBoundingClientRect();
            const pageY = rect?.y || 0;
            const height = rect?.height || 0;
            if (pageY > offsetTop) {
                break;
            }
            if (pageY + height > 0) {
                index = i;
            } else {
                index = undefined;
            }
        }

        if (index === undefined) {
            curIndex.current = undefined;
        } else {
            const { onEnter, onFirstEnter } = items[index];
            if (curIndex.current !== index) {
                curIndex.current = index;
                onEnter?.();
            }
            if (!enterMarks.current[index]) {
                onFirstEnter?.();
            }
            markEnter(index);
        }
    };
};

export const useInitLogin = (cb: () => void) => {
    const { isLoading, isLogin } = useUserInfo();

    useEffect(() => {
        if (!isLoading && !isLogin && location.search.includes('register=1')) {
            cb();
        }
    }, [isLoading]);
};
