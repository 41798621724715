import BookCover from '@containers/home-id/component/book-cover';
import { RankItem } from '@containers/home-id/type';
import React, { useState } from 'react';
import cs from 'classnames';
import { getIndex } from '../../../utils';
import { Tea } from '@utils/index';

const getTextColor = (index: number, isActive: boolean) => {
    if (index === 0) {
        if (isActive) {
            return '#fff';
        }
        return '#FF5F00';
    }
    if (index === 1) {
        return '#D7A461';
    }
    if (index === 2) {
        return '#007549';
    }
    return 'rgba(0, 0, 0, 0.2)';
};

interface IProps {
    data: RankItem[];
    title: string;
    type: number;
    className?: string;
}

const Panel: React.FC<IProps> = ({ className, data, title, type }) => {
    const [active, setActive] = useState(0);

    const getOpacity = (show: boolean) => {
        return show ? { opacity: 1 } : { opacity: 0 };
    };

    return (
        <div className={`home-id-rank-panel ${className}`}>
            <h1>{title}</h1>
            <div className="home-id-rank-list">
                {data.map((item, index) => {
                    return (
                        <div className={cs('home-id-rank-list-item-wrapper', { active: index === active })} key={index}>
                            <div className="list-item-slider">
                                <div
                                    className="home-id-rank-list-item active"
                                    style={
                                        index === 0
                                            ? {
                                                  background: item.background_color,
                                                  color: '#fff',
                                                  ...getOpacity(active === index),
                                              }
                                            : { ...getOpacity(active === index) }
                                    }
                                >
                                    <div className="list-item-index" style={{ color: getTextColor(index, true) }}>
                                        {getIndex(index)}
                                    </div>
                                    <div className="list-item-msg">
                                        <div className="msg-title">{item.title}</div>
                                        <div className="msg-user">
                                            <img src={item.avatar} alt="" />
                                            <span>{item.author}</span>
                                        </div>
                                    </div>
                                    <BookCover url={item.image_url} className="list-item-book" />
                                </div>
                                <div
                                    className="home-id-rank-list-item"
                                    style={{ ...getOpacity(active !== index) }}
                                    onClick={() => setActive(index)}
                                    onMouseEnter={() => {
                                        Tea('click_author_list_book', { type, bid: item.bid });
                                        setActive(index);
                                    }}
                                >
                                    <div className="list-item-index" style={{ color: getTextColor(index, false) }}>
                                        {getIndex(index)}
                                    </div>
                                    <span className="list-item-title">{item.title}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default Panel;
