export type PostPageQuery = {
    title?: string;
    scroll_comment?: string;
};

export enum GoToUserPageTeaEnum {
    community = 1,
    comment = 2,
    postCard = 3,
    detailPage = 4,
    fansPage = 5,
}

export enum PostRole {
    Subject = 1,
    Object,
}

export enum PostCollectType {
    detailPage = 1,
    community,
    userPage,
}

export enum FollowAuthorType {
    follow = 1,
    unFollow,
}
