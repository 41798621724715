import { isBrowser, DEV } from '@constants/const';

export const initCsrf = () => {
    if (!isBrowser || DEV) {
        return;
    }
    const csrf = (window as any).secsdk.csrf;

    // csrf
    csrf.setProtectedHost({
        'www.fizzo.org': {
            TOKEN_PATH: '/api',
        },
        'fizzo.org': {
            TOKEN_PATH: '/api',
        },
    });
};
