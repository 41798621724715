import React, { useState, useEffect } from 'react';
import cs from 'classnames';
import { Dialog, Toast } from '@arco-design/mobile-react-serial-i18n';
import { MessageText } from '@constants/value';
import { useTranslation } from '@ies/intl-react-plugin';

import './index.less';
import { postFollowAuthor } from '@service/api';
import { FollowOperate } from '@typings/api/novel/toutiao_muye_overseas_homeapi';
import { Tea } from '@utils/tea';
import { EVENTS } from '@constants/event';
import { FollowAuthorType } from '@containers/post/typings';

interface IProps {
    type?: 'normal' | 'fans-list';
    className?: string;
    initFollowed: boolean;
    uid: string;
}

const MAX_FOLLOW_COUNT_ERROR_CODE = -6006;

export const FollowBtn: React.FC<IProps> = ({ type = 'normal', className, initFollowed, uid }) => {
    const [isFollowing, setIsFollowing] = useState(initFollowed);

    const { i18n } = useTranslation();

    useEffect(() => {
        setIsFollowing(initFollowed);
    }, [initFollowed]);

    const handleFollowClick = () => {
        if (isFollowing) {
            Dialog.confirm({
                title: i18n.t('cancel_follow'),
                okText: i18n.t('contrat_sign_confirm'),
                cancelText: i18n.t('cancel_button'),
                onOk: () => {
                    setIsFollowing(false);
                    editFollow(true);
                },
            });
            return;
        }
        setIsFollowing(true);
        editFollow(false);
    };

    const editFollow = async (flag: boolean) => {
        try {
            const { code } = await postFollowAuthor({
                media_id: uid,
                operate: flag ? FollowOperate.UNFOLLOW : FollowOperate.FOLLOW,
            });
            if (code === MAX_FOLLOW_COUNT_ERROR_CODE) {
                Toast.info(i18n.t('follow_upperlimit'));
                return;
            }

            if (code !== 0) {
                throw Error();
            }
            Tea(EVENTS.CLICK_POST_FOLLOW, {
                type: flag ? FollowAuthorType.unFollow : FollowOperate.FOLLOW,
            });
        } catch (error) {
            setIsFollowing(flag);
            Toast.error(MessageText.netError);
        }
    };

    return (
        <div
            className={`follow-btn ${isFollowing ? 'following' : 'unFollowing'} ${type} ${className}`}
            onClick={handleFollowClick}
        >
            {!isFollowing && <div className={cs('follow-btn-icon', { black: type === 'fans-list' })} />}
            <span className="follow-btn-info">{i18n.t('forum_follow')}</span>
        </div>
    );
};
