import React, { useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';
import cs from 'classnames';
import { useTranslation } from '@ies/intl-react-plugin';
import { gsap } from 'gsap';
import { useHome } from '@utils/hooks';
import { lazyLoadImg } from '@containers/home/setting';
import { LangCode } from '@common/lang';

interface IProps {}

const CARD_WIDTH = 372;

const getNoteWidth = (lang: LangCode) => {
    switch (lang) {
        case LangCode.ID:
            return 510;
        default:
            return 760;
    }
};

const MOVE_SPEED = 8000;

const Writer: React.FC<IProps> = () => {
    const { i18n } = useTranslation();
    const { writer_notes } = useHome();
    const timer = useRef<any>(null);
    const loaded = useRef(false);
    const ref = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);
    const flagRef = useRef<HTMLDivElement>(null);
    const tl = useRef<any>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [entered, setEntered] = useState(false);

    useEffect(() => {
        if (!writer_notes!.length || tl.current) {
            return;
        }
        tl.current = gsap
            .timeline({
                scrollTrigger: {
                    trigger: ref.current,
                    start: 'top 80%',
                    scrub: true,
                    onEnter: () => {
                        if (!loaded.current) {
                            lazyLoadImg(ref.current!);
                            loaded.current = true;
                        }
                    },
                    onUpdate: throttle(({ progress }) => {
                        if (progress >= 0.45 && !entered) {
                            setEntered(true);
                        }
                    }),
                },
            })
            .to(titleRef.current, { y: -100 }, 0)
            .to('.home-writer-item-2', { y: -240 }, 0)
            .to('.home-writer-item-1', { y: -160 }, '<12%')
            .to(flagRef.current, { y: -351 }, 0)
            .to('.home-writer-indicator', { y: -160 }, 0);

        return () => {
            tl.current!.scrollTrigger?.kill();
            tl.current = null;
        };
    }, [writer_notes]);

    useEffect(() => {
        if (!writer_notes!.length || !entered) {
            return;
        }
        if (timer.current) {
            clearInterval(timer.current);
            timer.current = null;
        }
        timer.current = setInterval(() => {
            setCurrentIndex(prev => {
                if (prev < writer_notes!.length - 1) {
                    return prev + 1;
                }
                return 0;
            });
        }, MOVE_SPEED);
        return () => {
            clearInterval(timer.current);
            timer.current = null;
        };
    }, [entered]);

    const handleChange = (index: number) => () => {
        setCurrentIndex(index);
    };

    const lang = i18n.language as LangCode;
    const noteWidth = getNoteWidth(lang);
    const totalWidth = CARD_WIDTH + noteWidth;
    const widen = lang !== LangCode.EN && lang !== LangCode.ID;
    return (
        <section className={cs('home-writer', { widen })} ref={ref}>
            <h1 ref={titleRef}>{i18n.t('web_fromwriter')}</h1>
            <div ref={flagRef} className="home-writer-flag" />
            <div className="home-writer-list-wrapper">
                <div
                    className="home-writer-list"
                    style={{ left: -(totalWidth + 42) * currentIndex, paddingLeft: `calc(50% - ${totalWidth / 2}px)` }}
                >
                    {writer_notes!.map((item, index) => (
                        <div
                            className={cs('home-writer-item', `home-writer-item-${(index + 1) % 2 === 0 ? 2 : 1}`)}
                            key={index}
                            onClick={handleChange(index)}
                        >
                            <div className="home-writer-item-left">
                                <img data-src={item.picture_url} alt="" />
                                <div className="home-writer-item-left-text">
                                    {item.intro}
                                    <p>{item.writer_name}</p>
                                </div>
                            </div>
                            <div className="home-writer-item-right" style={{ width: noteWidth }}>
                                <p dangerouslySetInnerHTML={{ __html: item.note }} />
                                <img data-src={item.signature_url} />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="home-writer-indicator">
                    {writer_notes!.map((_, index) => (
                        <div
                            className={cs('indicator-item', {
                                'indicator-item-current': index === currentIndex,
                            })}
                            key={index}
                            onClick={handleChange(index)}
                        >
                            <div className="indicator-item-block" />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Writer;
