export function numberFormatter(num: number, digits: number, unitLowerCase = false) {
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'K' },
        { value: 1e6, symbol: 'M' }, // 最大到 M 即可
        // { value: 1e9, symbol: 'G' },
        // { value: 1e12, symbol: 'T' },
        // { value: 1e15, symbol: 'P' },
        // { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find(look => {
            return num >= look.value;
        });
    return item
        ? `${(num / item.value).toFixed(digits).replace(rx, '$1')}${
              item.symbol && unitLowerCase ? item.symbol.toLowerCase() : item.symbol
          }` // 这里需不需要 toLocaleLowerCase ?
        : '0';
}
