import Mue from '@byted/mue-sdk';
import { isBoe, isBrowser } from '@constants/const';
import Slardar from '@slardar/web/sg';
import { getPid } from './util';

export const BID = 'novel_pc_i18n';
export const REPO_NAME = 'novel-fe/serial-pc-i18n';
export const SCM_VERSION = process.env.BUILD_VERSION;
export const COMMIT_HASH = process.env.BUILD_BASE_COMMIT_HASH;

let isH5 = false;

const configPage = () => {
    let pid = getPid();
    if (isH5) {
        pid = `h5_${pid}`;
    }

    const first_enter = Mue.setFirstEnterSync({
        pid,
        bid: BID,
        key: 'cdn',
        version: COMMIT_HASH,
    });
    Slardar('context.merge', {
        first_enter,
    });

    return pid;
};

const getEnv = () => {
    if (process.env.BUILD_TYPE !== 'online' || isBoe) {
        return 'test';
    }
    return 'production';
};

export function initMonitor(h5 = false) {
    if (!isBrowser) {
        return;
    }

    isH5 = h5;

    Slardar('context.merge', {
        resource_type: 'cdn',
        repo_name: REPO_NAME,
        scm_version: SCM_VERSION,
        commit_hash: COMMIT_HASH,
    });

    Slardar.init({
        bid: BID,
        pid: configPage(),
        env: getEnv(),
        release: SCM_VERSION,
        plugins: {
            pageview: {
                extractPid: configPage,
            },
            ajax: {
                ignoreUrls: [],
            },
        },
    });

    // 初始化mue
    Mue.init({
        isDebug: false,
        ratio: 1,
        syncSentry: false,
        slardarInstance: Slardar,
    });

    Slardar.start();
}

/** API请求失败 */
export const mueRequestFail = (url: string, resp: any = {}, extra: any = {}) => {
    Mue.requestFail(url, {}, resp, extra);
};

/** API请求返回结果有误 */
export const mueResponseFail = (url: string, resp: any = {}, extra: any = {}) => {
    Mue.responseError(url, {}, resp, extra);
};
