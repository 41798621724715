const escapeChar = (arr: string[]) => {
    const excapeArr = arr.map(char => {
        // 转义特殊字符
        const specialChars = '^$\\.*+?()[]{}|';
        return specialChars.includes(char) ? `\\${char}` : char;
    });

    // 生成：[!\"#$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}~]
    return `${excapeArr.join('')}`;
};

// ASCII 中的标点符号
// ASCII 码表: https://tool.oschina.net/commons?type=4
const ASCII_MARKS = escapeChar([
    '!',
    '"',
    '$',
    '#',
    '%',
    '&',
    "'",
    '(',
    ')',
    '*',
    '+',
    ',',
    '-',
    '.',
    '/',
    ':',
    ';',
    '<',
    '=',
    '>',
    '?',
    '@',
    '[',
    '\\',
    ']',
    '^',
    '_',
    '`',
    '{',
    '|',
    '}',
    '~',
]);

export const Start = new RegExp(`^[${ASCII_MARKS}\\s]`);
export const End = new RegExp(`[${ASCII_MARKS}\\s]$`);
export const Content = new RegExp(`^[a-zA-Z0-9${ASCII_MARKS}\\s]+$`);
