import { TtWid } from '@byted-ucenter/ttwid';

export const checkWebId = async () => {
    const webId = new TtWid({
        aid: 4793,
        service: 'fizzo.org',
        unionHost: 'https://ttwid-sg.byteoversea.com',
    });
    try {
        await webId.checkWebId();
    } catch (e) {
        console.log('checkWebId::error', e);
    }
};
