import { useEffect, useMemo } from 'react';
import { usePost } from '@utils/hooks';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadData } from './data';
import { Tea } from '@utils/tea';
import { CommentListProps } from '@components/h5/comment-list';
import { diggPost, postCollectPost } from '@service/api';
import { CollectOperate, DiggOperate } from '@typings/api/novel/toutiao_muye_overseas_homeapi';
import { setPostCollectNumber, setPostDiggNumber } from '@redux/actions/post';
import { ArticleContentProps } from '@components/article-content';
import { PostState } from '@redux/reducers/post';
import { EVENTS } from '@constants/event';
import { PostCollectType } from './typings';

/**
 * Post 页面所需 数据 + 逻辑部分
 * @returns
 */
export const usePostPage = (): {
    post: PostState;
    id: string;
    articleContent: ArticleContentProps;
    commentActions: CommentListProps['actions'];
} => {
    const post = usePost();
    const { id } = useParams<{ id: string }>();

    const dispatch = useDispatch();

    // init fetch data
    useEffect(() => {
        if (!post.serverInited) {
            loadData(dispatch, id);
        }
    }, []);

    useEffect(() => {
        Tea('show_post', { id, url: window.location.href, category_name: post.category_name });
    }, []);

    const commentActions = useMemo<CommentListProps['actions']>(() => {
        return {
            like: async (nextOperate, userDigged) => {
                const res = await diggPost({
                    post_id: id,
                    operate: nextOperate,
                });
                if (res?.code !== 0) {
                    throw res;
                }
                if (nextOperate === DiggOperate.DIGG) {
                    Tea('click_like');
                }
                const nextNum = Math.max(0, (post.diggCount || 0) + (userDigged ? -1 : 1));
                dispatch(setPostDiggNumber(nextNum));
                return true;
            },
            collect: async (nextOperate, userCollected) => {
                const res = await postCollectPost({
                    post_id: id,
                    operate: nextOperate,
                });
                if (res?.code !== 0) {
                    throw res;
                }
                if (nextOperate === CollectOperate.COLLECT) {
                    Tea(EVENTS.CLICK_COLLECT, {
                        channel: PostCollectType.detailPage,
                    });
                    Tea(EVENTS.CLICK_POST_COLLECT, {
                        postId: id,
                        category_name: post.category_name,
                    });
                }
                const nextNum = Math.max(0, (post.collectCount || 0) + (userCollected ? -1 : 1));
                dispatch(setPostCollectNumber(nextNum));
                return true;
            },
        };
    }, [post.diggCount, id, dispatch, post.collectCount]);

    const articleContent = useMemo(() => {
        return {
            content: post.content,
            time: post.time,
            title: post.title,
            author: post.author,
        };
    }, [post.content, post.time, post.title, post.author]);

    return {
        id,
        post,
        commentActions,
        articleContent,
    };
};
