import { ArticleContentProps } from '@components/article-content';
import { ActionType, INIT_POST_DATA, SET_COLLECT_NUMBER, SET_DIGG_NUMBER } from '@redux/actions/post';

export enum PostType {
    official,
    user,
}
export interface PostState extends ArticleContentProps {
    serverInited: boolean;
    commentOpen: boolean;
    diggCount: number;
    collectCount: number;
    category_name: string;
    postType?: PostType;
    extraImg?: any[];
}

const postState: PostState = {
    content: '',
    title: '',
    time: 0,
    category_name: '',
    commentOpen: false,
    diggCount: 0,
    collectCount: 0,
    serverInited: false,
    postType: PostType.official,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function (state = postState, action: ActionType): PostState {
    switch (action.type) {
        case INIT_POST_DATA:
            return {
                ...state,
                ...action.data,
            };
        case SET_DIGG_NUMBER:
            return {
                ...state,
                diggCount: action.data,
            };
        case SET_COLLECT_NUMBER:
            return {
                ...state,
                collectCount: action.data,
            };
        default:
            return state;
    }
}
