import { IDConfigType } from '@containers/home-id/type';
import { Advantage, BookTrend, WriterNote } from '@containers/home/setting';

export interface IHomeState {
    writer_notes?: WriterNote[];
    book_trends?: BookTrend[];
    advantages?: Advantage[];
    top_banner?: IDConfigType['top_banner'];
    top_author?: IDConfigType['top_author'];
    academy?: IDConfigType['academy'];
    rank_popular?: IDConfigType['rank_popular'];
    rank_recent?: IDConfigType['rank_recent'];
    rank_youth?: IDConfigType['rank_youth'];
    serverInited?: boolean;
}

const homeInit: IHomeState = {
    writer_notes: [],
    book_trends: [],
    advantages: [],
    top_banner: [],
    academy: [],
    serverInited: false,
};

export default function (state = homeInit, action): IHomeState {
    switch (action.type) {
        case 'INIT_HOME_DATA':
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
}
