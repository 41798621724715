import { ScreenWidth } from '@constants/value';
import { px2rem } from './util';

/**
 * 获取真实屏幕宽度下的像素大小
 * 使用场景 设计稿px -> getPX -> scroll | 和dom获取的PX计算 | ...
 * @param px 375屏宽下的px值
 * @returns 真实屏宽下的px值
 */
export const getPX = (px: number) => {
    try {
        const { ROOT_FONT_SIZE } = window as any;
        if (ROOT_FONT_SIZE) {
            const rem = px2rem(px);
            return ROOT_FONT_SIZE * rem;
        }
        return (px / ScreenWidth) * window.innerWidth;
    } catch (err) {
        return px;
    }
};
