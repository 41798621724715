export enum PopupType {
    Unknow,
    PrivacyPolicy,
    CookiesPolicy,
    ServiceAgreement,
    CommunityGuidelines,
}

export enum HeaderTheme {
    White,
    Black,
    Picture,
    LogoFullBlack,
}
