/* eslint-disable max-len */
import { Message } from '@byted/serial-components-i18n';
import { LangCode } from '@common/lang';
import { MessageText } from '@constants/value';
import { initHomeData } from '@redux/actions/home';
import { getClassList, getNoticeList, getRewardList } from '@service/api';
import { Announcement, Activity } from '@typings/api/novel/toutiao_muye_overseas_homeapi';
import { dayjs } from '@utils/day';
import { useHome } from '@utils/index';
import { useEffect, useState } from 'react';
import { AcademyItem } from './type';

export const getIndex = (num: number) => (num > 8 ? num + 1 : `0${num + 1}`);

type Target = Window | HTMLElement | undefined | null;
export interface Options {
    target?: Target | (() => Target);
    capture?: boolean;
    once?: boolean;
    passive?: boolean;
    open?: boolean;
}

export const loadData = async (ctx: any, store: any, isMobile?: boolean) => {
    const { config } = ctx.app.controller;
    try {
        const data = await config.getIdConfig(ctx);
        store.dispatch(
            initHomeData(
                {
                    ...data,
                    serverInited: true,
                },
                isMobile,
            ),
        );
    } catch (e) {
        console.log(e);
        console.error('loadData error');
    }
};

export const useAcademy = (num = 9) => {
    const [loading, setLoading] = useState(true);
    const { academy = [], serverInited } = useHome();
    const [rightCards, setRightCards] = useState<AcademyItem[]>([]);

    useEffect(() => {
        getAcademy();
    }, []);

    const getAcademy = async () => {
        try {
            const resp = await getClassList({
                page_count: num,
                page_index: 0,
                language: LangCode.ID,
            });
            if (resp && resp.code === 0) {
                const { creation_class_list = [] } = resp.data || {};
                creation_class_list.splice(0, 3);
                setRightCards(creation_class_list);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    return {
        leftCards: academy,
        rightCards,
        loading: !serverInited || loading,
    };
};

export const useActivity = () => {
    const [loading, setLoading] = useState(true);
    const [activity, setActivity] = useState<Activity[]>([]);
    const getActivity = async () => {
        try {
            const resp = await getRewardList({
                page_index: 0,
                page_count: 3,
                language: LangCode.ID,
            });
            setActivity(resp.data.activity_list);
        } catch (e) {
            Message.error(MessageText.netError);
            console.log(e);
        } finally {
            setTimeout(() => setLoading(false), 500);
        }
    };

    useEffect(() => {
        getActivity();
    }, []);

    return {
        loading,
        data: activity,
    };
};

export const useNotice = (num = 7) => {
    const [loading, setLoading] = useState(true);
    const [noticeList, setNoticeList] = useState<Announcement[]>([]);

    useEffect(() => {
        getActivity();
    }, []);

    const getActivity = async () => {
        try {
            const resp = await getNoticeList({
                page_index: 0,
                page_count: num,
                location: 2,
                language: LangCode.ID,
            });
            if (resp && resp.code === 0) {
                const { announcement_list: d = [] } = resp.data || {};
                setNoticeList(d);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setTimeout(() => setLoading(false), 500);
        }
    };

    return {
        data: noticeList,
        loading,
    };
};

export const useTopAuthor = () => {
    const { top_author = [], serverInited } = useHome();
    return { data: top_author, loading: !serverInited };
};

export const hex2Rgba = (bgColor, alpha = 1) => {
    const color = bgColor.slice(1); // 去掉'#'号
    const rgba = [
        parseInt(`0x${color.slice(0, 2)}`),
        parseInt(`0x${color.slice(2, 4)}`),
        parseInt(`0x${color.slice(4, 6)}`),
        alpha,
    ];
    return `rgba(${rgba.toString()})`;
};

export const getBackground = (hex: string) => {
    return `linear-gradient(180.07deg, ${hex2Rgba(hex, 0)} -26.05%, ${hex2Rgba(hex, 1)} 81.12%)`;
};

export const getTime = (stamp: number | string) => dayjs.utc(Number(stamp) * 1000).format('MMM DD, YYYY HH:MM A');
