import { IUserState } from '../reducers/user';

export function initUserInfo(userInfo: Partial<IUserState>) {
    return {
        type: 'FETCH_INFO',
        data: userInfo,
    };
}

export function setUserAbstract(data: Partial<IUserState>) {
    return {
        type: 'FETCH_ABSTRACT',
        data,
    };
}

export function setUserEmail(email: string) {
    return {
        type: 'FETCH_EMAIL',
        data: {
            email,
        },
    };
}

export function setFinish() {
    return {
        type: 'FETCH_FINISH',
        data: null,
    };
}
