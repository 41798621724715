import { ArticleContentProps } from '@components/article-content';
import { ActionType, INIT_ARTICLE_DATA, SET_DIGG_NUMBER } from '@redux/actions/article';
import { I18nDetailPageType } from '@typings/api/novel/origin_overseas_base';

export interface ArticleState extends ArticleContentProps {
    serverInited: boolean;
    commentOpen: boolean;
    diggCount: number;
    hasClassCore?: boolean;
    type?: I18nDetailPageType;
}

const articleInit: ArticleState = {
    content: '',
    title: '',
    type: undefined,
    time: 0,
    commentOpen: false,
    diggCount: 0,
    hasClassCore: true,
    serverInited: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function (state = articleInit, action: ActionType): ArticleState {
    switch (action.type) {
        case INIT_ARTICLE_DATA:
            return {
                ...state,
                ...action.data,
            };
        case SET_DIGG_NUMBER:
            return {
                ...state,
                diggCount: action.data,
            };
        default:
            return state;
    }
}
