import { useEffect, useMemo, useState } from 'react';
import bridge from '@bridge/worldance';
import { JSBridgeResponse } from '@bridge/worldance/lib/app/getAppInfo';

import { isInApp } from './env';
import { getPX } from './px';

type AppInfo = JSBridgeResponse;

const HeaderHeight = 50;

export const useInApp = () => {
    const [appInfo, setAppInfo] = useState<AppInfo>();
    const [barHeight, setBarHeight] = useState(0);
    useEffect(() => {
        if (isInApp()) {
            bridge.app.getAppInfo().then(info => {
                setAppInfo(info);
                setBarHeight(info.statusbar_height || 0);
            });
        }
    }, []);

    const value = useMemo(() => {
        const topHeight = barHeight + HeaderHeight;
        return {
            appInfo,
            viewInfo: {
                barHeight,
                headerHeight: HeaderHeight,
                topHeight,
                topHeightPX: getPX(topHeight),
            },
        };
    }, [appInfo, barHeight]);

    return value;
};
