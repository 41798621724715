import { Button, Message, Pagination, Tabs, Tooltip } from '@byted/serial-components-i18n';
import { ClassContentType, CreationZone } from '@typings/api/novel/origin_overseas_base';
import { CreationClass, GetCreationClassListRequest, SortType } from '@typings/api/novel/toutiao_muye_overseas_homeapi';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from '@ies/intl-react-plugin';
import cs from 'classnames';
import Header from '@components/header';
import Loading from '@components/loading';
import Footer from '@components/footer';
import { Tea } from '@utils/tea';
import { dayjs } from '@utils/day';
import { HeaderTheme } from '@constants/enum';
import { getAcademyList, getAcademyZoneList } from '@service/api';
import { MessageText } from '@constants/value';
import { EVENTS } from '@constants/event';
import { EnterAcademyDetailSource } from '@constants/academy';
import { getArticleId } from '@utils/util';
import Icon from '@components/icon';
import { useRefStateSync } from '@utils/hooks';
import { LangCode } from '@common/lang';

import './index.less';

const ZONE_TEXT = {
    [CreationZone.New]: 'academy_part1',
    [CreationZone.Skill]: 'academy_part2',
    [CreationZone.Advanced]: 'academy_part3',
    [CreationZone.Professional]: 'academy_part4',
    [CreationZone.BeginnerWriter]: 'beginner_writer',
    [CreationZone.BasicWritingClass]: 'basic_writing_class',
    [CreationZone.AdvancedWritingClass]: 'advanced_writing_class',
    [CreationZone.GenreSpecificWritingClasses]: 'genre_specific_writing_class',
    [CreationZone.AuthorSharingAndDiscussion]: 'author_sharing_and_discussion',
};
const TEA_ZONE_TEXT = {
    [CreationZone.New]: 'beginners_guide',
    [CreationZone.Skill]: 'writing_skills',
    [CreationZone.Advanced]: 'advanced_lessons',
    [CreationZone.Professional]: 'experts_experience',
};

const SORT_LIST = [
    {
        text: 'newest',
        value: SortType.NEWEST,
    },
    {
        text: 'hottest',
        value: SortType.HOTTEST,
    },
];

const PAGE_SIZE = 10;

const Academy: React.FC = () => {
    const [classList, setClassList] = useState<CreationClass[]>([]);

    const [loading, setLoading] = useState(true);

    const [total, setTotal] = useState(0);
    const [pageIndex, pageIndexRef, setPageIndex] = useRefStateSync(0);

    const [categoryMap, setCategoryMap] = useState<{ [k in CreationZone]: string[] }>();
    const [tabList, setTabList] = useState<CreationZone[]>([]);
    const [tab, setTab] = useState<CreationZone>();
    const [subTab, setSubTab] = useState<string>();
    const [sortType, setSortType] = useState(SortType.NEWEST);

    const { i18n } = useTranslation();

    useEffect(() => {
        getZoneList();

        Tea('show_academy');
    }, []);

    useEffect(() => {
        if (tab || subTab) {
            setPageIndex(0);
            getClassList();
        }
    }, [tab, subTab, sortType]);

    const getZoneList = async () => {
        try {
            const resp = await getAcademyZoneList({
                language: i18n.language,
            });

            if (resp.code) {
                throw Error();
            }

            const map = resp.data.category_map as { [k in CreationZone]: string[] };
            const keyList = Object.keys(map).map(i => Number(i)) as CreationZone[];
            if (!keyList.length) {
                return;
            }

            setCategoryMap(map);
            setTabList(keyList);
            setTab(keyList[0]);
        } catch {
            Message.error(MessageText.netError);
        }
    };

    const getClassList = async () => {
        try {
            setLoading(true);
            const resp = await getAcademyList({
                page_index: pageIndexRef.current,
                page_count: PAGE_SIZE,
                language: i18n.language,
                creation_zone: tab,
                academy_sub_tab: subTab,
                sort_type: sortType,
            });

            if (resp.code) {
                throw Error();
            }

            const { creation_class_list, total_count } = resp.data;
            setClassList(creation_class_list);
            setTotal(total_count);

            window.scrollTo(0, 0);
        } catch {
            Message.error(MessageText.netError);

            setClassList([]);
            setTotal(0);
        } finally {
            setLoading(false);
        }
    };

    const handlelink = (link: string) => {
        window.open(link);

        Tea(EVENTS.CLICK_ACADEMY_DETAIL, {
            channel: EnterAcademyDetailSource.PCAcademyList,
            article_id: getArticleId(link),
        });

        Tea('click_acadmey_article', {
            url: link,
        });
    };

    const renderSubCategory = (item: CreationZone, subItem?: string) => {
        const chosen = tab === item && subTab === subItem;
        return (
            <Button type={chosen ? 'warning' : 'default'} onClick={() => setSubTab(subItem)}>
                {subItem ? i18n.t(subItem) : i18n.t('common_all')}
            </Button>
        );
    };

    const renderList = () => (
        <>
            <div className="academy-list">
                {classList.map(item => (
                    <div className="academy-list-item" key={item.title} onClick={() => handlelink(item.link_url)}>
                        <div className="academy-list-item-img">
                            <img src={item.thumb_uri} />
                            {item.class_content_type === ClassContentType.Video && <div className="img-play" />}
                        </div>
                        <div className="academy-list-item-info">
                            <p>{item.title}</p>
                            <div className="info-desc">{item.desc}</div>
                            <span>{dayjs(item.publish_time! * 1000).format('YYYY-MM-DD')}</span>
                        </div>
                    </div>
                ))}
            </div>
            {total > PAGE_SIZE && (
                <Pagination
                    total={total}
                    pageSize={PAGE_SIZE}
                    current={pageIndex + 1}
                    onChange={page => {
                        setPageIndex(page - 1);
                        getClassList();
                    }}
                />
            )}
        </>
    );

    return (
        <div className="academy">
            <Header theme={HeaderTheme.Picture} />
            <div className="academy-content">
                <h1>{i18n.t('academy_header')}</h1>
                {Boolean(tabList.length) && (
                    <Tabs
                        activeTab={tab ? `${tab}` : undefined}
                        onChange={key => {
                            setTab(Number(key));
                            setSubTab(undefined);

                            Tea('click_academy', {
                                name: Number(key),
                            });
                        }}
                        extra={
                            <Tooltip
                                className="academy-sort-tooltip"
                                position="br"
                                trigger="hover"
                                arrowVisible
                                unmountOnExit={false}
                                content={
                                    <div className="academy-sort">
                                        <p>{i18n.t('filter_sort')}</p>
                                        {SORT_LIST.map(item => {
                                            const chosen = sortType === item.value;
                                            return (
                                                <div
                                                    className={cs('academy-sort-item', {
                                                        'academy-sort-item-chosen': chosen,
                                                    })}
                                                    onClick={() => {
                                                        if (!chosen) {
                                                            setSortType(item.value);
                                                        }
                                                    }}
                                                >
                                                    <span>{i18n.t(item.text)}</span>
                                                    {chosen && <Icon type="choosed" />}
                                                </div>
                                            );
                                        })}
                                    </div>
                                }
                            >
                                <span className="academy-sort-icon">
                                    {' '}
                                    <Icon type="filter" />
                                </span>
                            </Tooltip>
                        }
                    >
                        {tabList.map(item => (
                            <Tabs.TabPane key={item} title={i18n.t(ZONE_TEXT[item])}>
                                {i18n.language === LangCode.ID && (
                                    <>
                                        {renderSubCategory(item)}
                                        {categoryMap?.[item].map(subItem => renderSubCategory(item, subItem))}
                                    </>
                                )}
                            </Tabs.TabPane>
                        ))}
                    </Tabs>
                )}
                {loading ? <Loading /> : renderList()}
            </div>
            <Footer />
        </div>
    );
};

export default Academy;
