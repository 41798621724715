import { baseFontSize, DefaultAvatar, ScreenWidth } from '@constants/value';
import { isiOS } from './env';
import { isBrowser } from '@constants/const';

export const showLoginDrawer = () => location.search.includes('register=1');

export function setStorage<T = any>(item: string, value: T) {
    if (window.localStorage && item) {
        const storeValue = JSON.stringify(value);
        localStorage.setItem(item, storeValue);
    }
}

export function getStorage<T = any>(item: string, defaultValue?: T) {
    let result: any = '';
    if (window.localStorage && item) {
        const value = localStorage.getItem(item);
        try {
            result = JSON.parse(value || '');
        } catch (e) {
            result = defaultValue !== undefined ? defaultValue : value;
        }
    }
    return result;
}

export const getStyle = (obj, attr) => {
    if (obj.currentStyle) {
        return obj.currentStyle[attr];
    }
    return window.getComputedStyle(obj, null)[attr];
};

export function hasEmoji(name: string) {
    const EmojiAndSymbol =
        /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/;
    return EmojiAndSymbol.test(name);
}

export const isEmailVaild = (email: string) => {
    return Boolean(email) && /^[\w\+.]+@\w+(\.\w+)+$/.test(email);
};

export const isPasswordVaild = (password: string) => {
    const hasDigit = /[0-9]+/.test(password);
    const hasLetter = /[a-zA-Z]+/.test(password);
    const hasSpecial = /[_~!@#$%^&*()+,.:;=<>?/|\-[\]\\]+/.test(password);
    const hasExclusive = /[^\w~!@#$%^&*()+,.:;=<>?/|\-[\]\\]+/.test(password);
    const typeOk = !hasExclusive && hasDigit && hasLetter && hasSpecial;
    return typeOk;
};

const padTime = (num: number) => {
    return num.toString().padStart(2, '0');
};

export const getDateString = (year: number, month: number, day: number) => {
    return `${year}-${padTime(month)}-${padTime(day)}`;
};

export interface GetImgUrlParams {
    uri?: string;
    width?: number;
    height?: number;
    type?: string;
}

export function getImgUrl(params: GetImgUrlParams = {}) {
    const { uri = '', width = 0, height = 0, type = 'jpg' } = params;
    if (!uri) {
        return '';
    }
    const isBoe = window.location.origin.includes('boei18n');
    let newUri = '';
    const dpr = 2;

    if (width || height) {
        newUri = `img/${uri}~tplv-resize:${width * dpr}:${height * dpr}.${type}`;
    } else {
        newUri = `origin/${uri}`;
    }

    const src = isBoe ? `https://p-boei18n.*************/${newUri}` : `https://p16-novel-sg.ibyteimg.com/${newUri}`;

    return src;
}

export const getAvatar = src =>
    (src.includes('http') || src.includes('base64') ? src : getImgUrl({ uri: src })) ||
    getImgUrl({ uri: DefaultAvatar });

export const getWinScale = () => {
    const clientWidth =
        window.innerWidth && document.documentElement.clientWidth
            ? Math.min(window.innerWidth, document.documentElement.clientWidth)
            : window.innerWidth ||
              document.documentElement.clientWidth ||
              (document.body && document.body.clientWidth) ||
              ScreenWidth;
    return clientWidth / ScreenWidth;
};

export const getWinHeight = () =>
    document.body.clientHeight || window.innerHeight || document.documentElement.clientHeight;

export const px2rem = (size: number) => {
    return Math.floor((size / baseFontSize) * 1000000) / 1000000;
};

export const isMobileDevice = () => {
    if (window.location.href.indexOf('force_mobile=1') > 0) {
        return true;
    }
    const reg =
        /(phone|pad|pod|iPhone|iPod|iPad|Android|Linux|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;
    return reg.test(navigator.userAgent);
};

export const getPid = () => {
    const path = window.location.pathname;
    const pathArr = path.split('/');
    const reg = /[0-9]|\%/;
    const data = pathArr.filter(item => {
        if (reg.test(item) || !item) {
            return false;
        }
        return true;
    });
    return data.join('_') || 'home';
};

export const hasSetUserName = (name: string) => name && !/User|用户\d+/.test(name);

export const str2Timestamp = (v: string | undefined) => Number(v) * 1000;

export const jumpToPage = link => () => {
    location.href = link;
};

export const openLinkInNewTab = link => {
    if (isiOS()) {
        window.location.href = link;
    } else {
        window.open(link);
    }
};

export const hasKey = (target, key) => key in target;

export const jumpUrl = url => window.open(url);

// 从详情页链接中获取 articleId
export const getArticleId = (str: string) => str.match(/article\/([0-9]*)/)?.[1];

export const isNotUndefined = <T>(val: T | undefined): val is T => val !== undefined;

export function formatNumber(num: number, digits = 1, unitLowerCase = false) {
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'K' },
        { value: 1e6, symbol: 'M' }, // 最大到 M 即可
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find(look => num >= look.value);
    return item
        ? `${(num / item.value).toFixed(digits).replace(rx, '$1')}${
              item.symbol && unitLowerCase ? item.symbol.toLowerCase() : item.symbol
          }` // 这里需不需要 toLocaleLowerCase ?
        : '0';
}
