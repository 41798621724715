import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-web/build/player/lottie_light';
import loadingImg from './loading.json';

import './index.less';

interface LoadingProps {
    height?: number;
    width?: number;
    className?: string;
    style?: any;
}

export default function Loading(props: LoadingProps) {
    const ref = useRef<HTMLDivElement>(null);
    const { height = 80, width = 80, className = '' } = props;

    useEffect(() => {
        Lottie.loadAnimation({
            container: ref.current!,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: loadingImg,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        });
    }, []);

    return (
        <div {...props} className={`common-loading ${className}`}>
            <div className="common-loading-inner">
                <div ref={ref} style={{ width, height }} />
            </div>
        </div>
    );
}
