import cs from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

interface IProps {
    color: string;
    className: string;
    top?: number;
    rotate?: number;
}

const Background: React.FC<IProps> = ({ color, className, top = 0, rotate }) => {
    const [textLen, setTextLen] = useState(0);
    const bgRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTextLen(Math.ceil(window.innerWidth / 518));
    }, []);

    const getItem = () => {
        if (textLen === 0) {
            return null;
        }
        return (
            <div className="home-id-rank-bg-item" style={{ background: color, width: textLen * 518 }}>
                {new Array(textLen).fill(
                    <div className="text-item">
                        {/* starling-disable-next-line */}
                        <span className="text-item-1">FIZZO</span>
                        {/* starling-disable-next-line */}
                        <span className="text-item-2">Ignite the Storyteller in You </span>
                    </div>,
                )}
            </div>
        );
    };
    return (
        <div
            className={cs('home-id-rank-bg-wrapper', className)}
            ref={bgRef}
            style={{ transform: `translateY(${top}px) rotate(${rotate}deg)` }}
        >
            <div className="home-id-rank-bg-slider" style={{ width: textLen * 518 * 4 }}>
                {getItem()}
                {getItem()}
                {getItem()}
                {getItem()}
            </div>
        </div>
    );
};
export default Background;
