import React, { useMemo } from 'react';
import Carousel from '../carousel';
import { getIndex } from '../../utils';
import classNames from 'classnames';
import { getPX } from '@utils/px';

import './index.less';

interface IProps {
    total: number;
    current: number;
    isMobile?: boolean;
}

const Indicator: React.FC<IProps> = ({ total, current, isMobile = false }) => {
    const data = useMemo(() => new Array(total).fill(1), [total]);
    const scrollBarWidth = !isMobile ? (186 / total) * (current + 1) : getPX((197 / total) * (current + 1));
    const size = isMobile ? getPX(38) : 60;

    return (
        <div className={classNames('home-id-author-index', { mobile: isMobile })}>
            <div className="home-id-author-index-left">
                <Carousel
                    style={{ height: total * size }}
                    name="bottom"
                    data={data}
                    active={current}
                    renderItem={(item, index) => <div className="index-item">{getIndex(index)}</div>}
                    maxWidth={total * size}
                    width={size}
                    height={size}
                    padding={0}
                    maxShowLen={total}
                    scale={1}
                    startPos={0}
                    direction="vertical"
                />
            </div>
            <div className="home-id-author-index-progress">
                <span style={{ width: scrollBarWidth }} />
            </div>
            <div className="home-id-author-index-right">{getIndex(total - 1)}</div>
        </div>
    );
};
export default Indicator;
