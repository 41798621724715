import React from 'react';
import { doubleClick } from '@byted/serial-utils/dist/tools/SerialDebugger/helper';
import { myDebugger } from '@utils/debugger';

const DebuggerBlock: React.FC = () => {
    const handleClick = doubleClick(() => {
        myDebugger.show();
    });
    return <div style={{ flex: 1, height: 'inherit', margin: '-20px 0' }} onClick={handleClick} />;
};
export default DebuggerBlock;
