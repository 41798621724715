import React, { CSSProperties, ReactNode } from 'react';
import cs from 'classnames';
import Icon from '@components/icon';
import { px2rem } from '@utils/util';

import './index.less';
import { useInApp } from '@utils/in-app';
import { isInApp } from '@utils/env';
import bridge from '@bridge/worldance';
import { useGoBack } from '@utils/hooks';

export interface HeaderProps {
    /** 标题 */
    title?: ReactNode;
    /** 右侧内容 */
    right?: ReactNode;
    /** class */
    className?: string;
    /** style */
    style?: CSSProperties;
    /** 字体颜色主题，默认黑色 */
    theme?: 'black' | 'white';
    /** 显示logo，默认false */
    logo?: boolean;
    /** 是否需要header（fixed）在文档流中的占位，默认false */
    noBlock?: boolean;
    /** 返回回调 */
    onBack?: () => void;
}

const InAppHeader: React.FC<HeaderProps> = ({
    title,
    right,
    className,
    theme = 'black',
    onBack,
    logo = false,
    noBlock,
    style,
}) => {
    const { viewInfo } = useInApp();
    const { topHeight, barHeight, headerHeight } = viewInfo;

    const goBack = useGoBack();
    const handleClickBack = () => {
        if (onBack) {
            onBack();
        }
        if (isInApp()) {
            bridge.view.close();
        } else {
            goBack();
        }
    };

    const isWhiteTheme = theme === 'white';

    return (
        <>
            <div
                className={cs('inapp-header', className, { white: isWhiteTheme })}
                style={{
                    paddingTop: `${px2rem(barHeight)}rem`,
                    height: `${px2rem(headerHeight)}rem`,
                    ...style,
                }}
            >
                {logo ? (
                    <div className="inapp-header-left logo">
                        <div id="logo" className="inapp-header-left-logo" />
                        <div className="inapp-header-left-divider" />
                        <div className="inapp-header-left-title">{title}</div>
                    </div>
                ) : (
                    <div className="inapp-header-left" onClick={handleClickBack}>
                        <Icon className="inapp-header-left-icon" type="back" />
                        <div className="inapp-header-left-title">{title}</div>
                    </div>
                )}
                {right}
            </div>
            {!noBlock && <div className="inapp-header-block" style={{ height: `${px2rem(topHeight)}rem` }} />}
        </>
    );
};

export default InAppHeader;
