import Header from '@components/header';
import { HeaderTheme } from '@constants/enum';
import Loading from '@components/loading';
import React, { useEffect, useState } from 'react';
import Footer, { FooterTheme } from '@components/footer';
import { useTranslation } from '@ies/intl-react-plugin';
import { PAGE_COUNT, useCopyrightReq } from './hook';
import { Carousel, Pagination } from '@byted/serial-components-i18n';
import { jumpUrl } from '@utils/util';
import PublishHouseLogo1 from '../../../static/images/publish-house-logo1.png';
import PublishHouseLogo2 from '../../../static/images/publish-house-logo2.png';
import PublishHouseLogo3 from '../../../static/images/publish-house-logo3.png';
import PublishHouseLogo4 from '../../../static/images/publish-house-logo4.png';
import PublishHouseLogo5 from '../../../static/images/publish-house-logo5.png';
import PublishHouseLogo6 from '../../../static/images/publish-house-logo6.png';
import { Tea } from '@utils/tea';

import './index.less';

export default function Copyright() {
    const { t } = useTranslation();
    const [hasChangeHeaderTheme, setHasChangeHeaderTheme] = useState(false);

    const {
        list: copyrightData,
        listLoading,
        pageIndex,
        initLoading,
        getList,
        total,
        banner: bannerData,
    } = useCopyrightReq();

    const handleScroll = () => {
        if (window.scrollY >= 40) {
            setHasChangeHeaderTheme(true);
        } else {
            setHasChangeHeaderTheme(false);
        }
    };

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handlePageChange = (index: number) => {
        if (index - 1 === pageIndex) {
            return;
        }
        getList(index - 1);
    };

    const handlePurchase = (bookName: string, link: string) => {
        Tea('click_publishing_detail_purchase', {
            book_name: bookName,
        });
        window.open(link);
    };

    const renderContent = () => {
        return (
            <div className="copyright-container">
                <div className="copyright-header">
                    <div className="copyright-header-title">{t('publishing_module_name')}</div>
                    <div className="copyright-header-subtitle">{t('publishing_module_name_second')}</div>
                </div>
                {bannerData.length > 0 && (
                    <Carousel className="copyright-banner" autoPlay animation="fade" showArrow="never">
                        {bannerData?.map((item, index) => (
                            <div className="carousel-wrapper" key={index} onClick={() => jumpUrl(item.link)}>
                                <div className="carousel-wrapper-center">
                                    <img src={item.uri} />
                                </div>
                            </div>
                        ))}
                    </Carousel>
                )}
                <div className="copyright-content">
                    {copyrightData.map((item, index) => {
                        return (
                            <div className="copyright-content-item" key={item.book_name}>
                                <div className="copyright-content-item-cover-container">
                                    <span className="copyright-content-item-cover-line" />
                                    <img className="copyright-content-item-cover" src={item.book_cover} />
                                </div>
                                <div className="copyright-content-item-detail">
                                    <div className="copyright-content-item-detail-header">
                                        <div className="copyright-content-item-detail-header-bookname">
                                            {item.book_name}
                                        </div>
                                        {!!Number(item.is_hot) && (
                                            <div className="copyright-content-item-detail-header-hot" />
                                        )}
                                    </div>
                                    <div className="copyright-content-item-detail-author">
                                        <div className="copyright-content-item-detail-author-avatar-container">
                                            <img
                                                className="copyright-content-item-detail-author-avatar"
                                                src={item.author_avatar}
                                            />
                                        </div>

                                        <div className="copyright-content-item-detail-author-name">
                                            {item.author_name}
                                        </div>
                                    </div>
                                    <div className="copyright-content-item-detail-introduction">
                                        {item.book_introduction}
                                    </div>
                                    <div className="copyright-content-item-detail-footer">
                                        <div className="copyright-content-item-detail-footer-publish">
                                            <img
                                                className="copyright-content-item-detail-footer-publish-logo"
                                                src={item.publish_house_logo}
                                            />
                                            <div className="copyright-content-item-detail-footer-publish-divider" />
                                            <div className="copyright-content-item-detail-footer-publish-status">
                                                {item.has_published}
                                            </div>
                                        </div>
                                        {item.purchase_link.length > 3 && (
                                            <div
                                                className="copyright-content-item-detail-footer-purchase"
                                                onClick={() => handlePurchase(item.book_name, item.purchase_link)}
                                            >
                                                <div>{t('purchase_published_book')}</div>
                                                <div className="copyright-content-item-detail-footer-purchase-icon" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {total > PAGE_COUNT && (
                    <Pagination
                        className="copyright-pagination"
                        total={total}
                        pageSize={PAGE_COUNT}
                        current={pageIndex + 1}
                        onChange={handlePageChange}
                    />
                )}
                <div className="copyright-publish-logo">
                    <img className="copyright-publish-logo-item" src={PublishHouseLogo1} />
                    <img className="copyright-publish-logo-item" src={PublishHouseLogo2} />
                    <img className="copyright-publish-logo-item" src={PublishHouseLogo3} />
                    <img className="copyright-publish-logo-item" src={PublishHouseLogo4} />
                    <img className="copyright-publish-logo-item" src={PublishHouseLogo5} />
                    <img className="copyright-publish-logo-item" src={PublishHouseLogo6} />
                </div>
            </div>
        );
    };

    return (
        <div className="copyright">
            <Header style={{ background: hasChangeHeaderTheme ? '#f7f7f7' : 'transparent' }} />
            {initLoading ? <Loading /> : renderContent()}
            <Footer theme={FooterTheme.White} />
        </div>
    );
}
