import { I18n } from '@ies/starling_intl';

type KeyType = string | number;
export function dynamicText<T extends KeyType>(obj: { [key in T]: string }): { [key in T]: string } {
    const dynamicObj: any = {};

    for (const key in obj) {
        Object.defineProperty(dynamicObj, key, {
            get() {
                return I18n.t(obj[key]);
            },
            enumerable: true,
        });
    }

    return dynamicObj;
}
