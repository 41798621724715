import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import ArrowBtn from '@components/arrow-btn';
import { useTranslation } from '@ies/intl-react-plugin';
import { renderI18nText } from '@utils/text';

interface IProps {
    onApply: () => void;
}

const Story: React.FC<IProps> = ({ onApply }) => {
    const { i18n } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);
    const flagRef = useRef<HTMLDivElement>(null);
    const subTitleRef = useRef<HTMLDivElement>(null);
    const card1Ref = useRef<HTMLDivElement>(null);
    const card2Ref = useRef<HTMLDivElement>(null);
    const btnRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const tl = gsap
            .timeline({
                scrollTrigger: {
                    trigger: ref.current,
                    start: 'top bottom',
                    end: 'bottom center',
                    scrub: true,
                },
            })
            .to(titleRef.current, { y: -140 }, 0)
            .to(card1Ref.current, { y: -200 }, '<14%')
            .to(card2Ref.current, { y: -200 }, '<2%')
            .to(flagRef.current, { y: -234 }, '<50%')
            .to(subTitleRef.current, { y: -150 }, 0)
            .to(btnRef.current, { y: -150 }, '<1%');

        return () => {
            tl.scrollTrigger?.kill();
        };
    }, []);

    return (
        <section className="home-story" ref={ref}>
            <h1 ref={titleRef}>{i18n.t('web_longstory')}</h1>
            <h6 ref={subTitleRef}>
                {i18n.t('web_longstory_note1')}
                <br />
                {i18n.t('web_longstory_note2')}&nbsp;
                <b>{i18n.t('web_longstory_note3')}</b>&nbsp;
                {renderI18nText(i18n.t('web_longstory_note4'))}
            </h6>
            <div ref={flagRef} className="home-story-flag" />
            <div className="home-story-list">
                <div ref={card1Ref} className="home-story-item">
                    <p>
                        <span>{i18n.t('web_longstory_exclusive')}</span>
                    </p>
                    <div>
                        <span>
                            {i18n.t('web_longstory_exclusive_note1')}&nbsp;
                            <b>{i18n.t('web_longstory_exclusive_note2')}</b>
                        </span>
                    </div>
                </div>
                <div ref={card2Ref} className="home-story-item">
                    <p>
                        <span>{i18n.t('web_longstory_nonexclusive')}</span>
                    </p>
                    <div>
                        <span>
                            {i18n.t('web_longstory_nonexclusive1')}&nbsp;
                            <b>{i18n.t('web_longstory_nonexclusive2')}</b>
                        </span>
                    </div>
                </div>
            </div>
            <div ref={btnRef}>
                <ArrowBtn text={i18n.t('web_longstory_button')} onClick={onApply} />
            </div>
        </section>
    );
};

export default Story;
