import { registerReactInstance } from '@ies/intl-react-plugin';
import { I18n } from '@ies/starling_intl';
import Cookies from 'js-cookie';
import { LangCode, I18nNameSpaces, LANG_COOKIE_KEY } from '@common/lang';

// 服务端请求语言设置
export const setRequestLang = (config: I18nConfig) => {
    return new Promise(resolve => {
        const { lang, translations } = config;
        I18n.addResourceBundle(lang, I18nNameSpaces.PC, translations);
        I18n.setLang(lang, resolve);
    });
};

// 客户端本地语言设置
export const setClientLang = (lang: string) => {
    if (lang === I18n.language) {
        return;
    }

    Cookies.set(LANG_COOKIE_KEY, lang);
    // location.reload会保持原来页面的高度，故修改为location.replace
    location.replace(location.href);
};

export const getInitI18nConfig = (): Partial<I18nConfig> => (window as any).__I18N || {};

export const initI18n = (config?: any) =>
    new Promise(resolve => {
        const { lang, translations } = config || getInitI18nConfig();
        I18n.use(registerReactInstance).init(
            {
                lng: lang,
                fallbackLng: LangCode.EN,
                ns: Object.values(I18nNameSpaces),
                defaultNS: I18nNameSpaces.PC,
                resources: {
                    [lang]: {
                        [I18nNameSpaces.PC]: translations,
                    },
                },
                parseMissingKeyHandler: missing => {
                    console.warn(missing);
                },
                missingKeyHandler: (lng, ns, key, fallbackValue) => {
                    console.warn('MISSING TRANSLATION:', lng, ns, key, fallbackValue);
                },
            },
            resolve,
        );
    });
