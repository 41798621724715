import React, { useState, useEffect, useRef } from 'react';
import cs from 'classnames';
import { useTranslation } from '@ies/intl-react-plugin';

import { JoinType, loadData, loadImgAsync, postTea } from '../setting';
import { useAccount } from '@containers/account';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { getConfigList } from '@service/api';
import { useDispatch } from 'react-redux';
import { initHomeData } from '@redux/actions/home';
import { Message } from '@byted/serial-components-i18n';
import { useHeaderTheme, useHome } from '@utils/hooks';
import { Tea } from '@utils/tea';

import { MessageText } from '@constants/value';
import { LangCode } from '@common/lang';

import ArrowBtn from '@components/arrow-btn';
import Trend from './components/trend';
import Advantage from './components/advantage';
import Story from './components/story';
import Writer from './components/writer';
import Download from './components/download';
import Footer from '@components/footer';
import Header from '@components/header';

import ribbonImg from '../images/ribbon-sprite.png';
import bgImg from '../images/start-bg.png';
import bookImg from '../images/start-book.jpeg';

import './index.less';
import { HeaderTheme } from '@constants/enum';

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
    const ref = useRef<HTMLDivElement>(null);

    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const { serverInited } = useHome();
    const ribbonRef = useRef<HTMLDivElement>(null);

    const { theme, style } = useHeaderTheme({
        ref,
        getModules: () => Array.from(ref.current!.querySelectorAll('section, footer')),
        pageThemes: [
            HeaderTheme.LogoFullBlack,
            HeaderTheme.LogoFullBlack,
            HeaderTheme.Black,
            HeaderTheme.White,
            HeaderTheme.Black,
            HeaderTheme.White,
            HeaderTheme.Black,
            HeaderTheme.White,
        ],
        pageBgs: ['transparent', 'transparent', '#eee', '#000', '#eee', 'transparent', '#eee', '#000'],
        getHeaderHeight: () => 100,
        onShow: postTea,
    });
    const { handleWrite, getAccountPopup } = useAccount();

    const [bgLoaded, setBgLoaded] = useState(false);
    const [bookLoaded, setBookLoaded] = useState(false);
    const [ribbonLoaded, setRibbonLoaded] = useState(false);

    // 飘带定时动画
    useEffect(() => {
        const el = ribbonRef.current;
        if (!ribbonLoaded || !el) {
            return;
        }

        const handleAnimationEnd = () => {
            el.classList.remove('ribbon-anim');
            setTimeout(() => {
                el.classList.add('ribbon-anim');
            }, 10000);
        };

        setTimeout(() => {
            el.classList.add('ribbon-anim');
            el.addEventListener('animationend', handleAnimationEnd);
        }, 2000);

        return () => {
            el.removeEventListener('animationend', handleAnimationEnd);
        };
    }, [ribbonLoaded]);

    // 大图再加载
    useEffect(() => {
        loadImgAsync(bgImg).then(() => setBgLoaded(true));
        loadImgAsync(bookImg).then(() => setBookLoaded(true));
        loadImgAsync(ribbonImg).then(() => setRibbonLoaded(true));
    }, []);

    // 配置数据获取
    useEffect(() => {
        if (!serverInited) {
            getConfig();
        }

        ScrollTrigger.refresh(true);
    }, []);

    const getConfig = async () => {
        try {
            const resp = await getConfigList({ language: i18n.language });
            if (!resp.code) {
                dispatch(initHomeData(resp.data));
            }
        } catch {
            Message.error(MessageText.netError);
        }
    };

    const onJoin = (type: number) => {
        handleWrite();
        Tea('website_join', { join_page: type });
    };

    return (
        <div className={cs('home', { 'home-id': i18n.language === LangCode.ID })} ref={ref}>
            <Header theme={theme} style={style} />
            <section className="home-start">
                <div className="home-start-bg">
                    <div className="home-start-bg-left" />
                    <div className="home-start-bg-mid" />
                    <div
                        className="home-start-bg-right"
                        style={bgLoaded ? { backgroundImage: `url(${bgImg})` } : undefined}
                    >
                        <div
                            className="home-start-bg-ribbon"
                            style={ribbonLoaded ? { backgroundImage: `url(${ribbonImg})` } : undefined}
                            ref={ribbonRef}
                        />
                    </div>
                </div>
                <div
                    className="home-start-book"
                    style={bookLoaded ? { backgroundImage: `url(${bookImg})` } : undefined}
                />
                <div className="home-start-slogan">
                    <h2>
                        {i18n.t('web_slogan')}
                        <br />
                        {i18n.t('web_slogan1')}
                        <br />
                        {i18n.t('web_slogan2')}
                    </h2>
                    <p>{i18n.t('web_slogan_note')}</p>
                    <ArrowBtn text={i18n.t('web_firstpage_button')} onClick={() => onJoin(JoinType.Banner)} />
                </div>
                <div className="home-big-img" />
            </section>
            <Advantage />
            <Story onApply={() => onJoin(JoinType.LongStory)} />
            <Writer />
            <Trend />
            <Download />
            <Footer />
            {getAccountPopup()}
        </div>
    );
};

Home.loadData = loadData;

export default Home;
