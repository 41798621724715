import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { gsap } from 'gsap';

import './index.less';

// const getPX = (size, params) => {
//     const { basicWidth, maxWidth, fitWidth } = params;
//     if (!fitWidth) {
//         return size;
//     }
//     return (size / basicWidth) * maxWidth;
// };

const shiftArr = (params, offset) => {
    const { fakeList: arr } = params;
    const len = arr.length;
    const _arr = arr.slice();
    const afterScrollMap = {};
    const beforeScrollMap = {};
    let newArr;
    let offsetArr;

    if (offset > 0) {
        offsetArr = _arr.splice(_arr.length - offset);
        newArr = offsetArr.concat(_arr);
        offsetArr.forEach((item, index) => {
            afterScrollMap[`f${index}`] = index;
            beforeScrollMap[`f${index}`] = index - offset;
        });
    } else {
        offsetArr = _arr.splice(0, 0 - offset);
        newArr = _arr.concat(offsetArr);
        offsetArr.forEach((item, index) => {
            afterScrollMap[`f${index}`] = len + offset + index;
            beforeScrollMap[`f${index}`] = len + index;
        });
    }
    arr.forEach((item, index) => {
        afterScrollMap[item.index] = index + offset;
    });

    const targetMap = {};
    newArr.forEach((item, index) => {
        targetMap[item.index] = index;
    });

    return {
        // 滚动后的数组
        fakeArr: newArr,
        // 滚动前的位置
        beforeScrollMap,
        // 滚动后的位置
        afterScrollMap,
        // 最终的位置
        targetMap,
        // 虚拟卡片
        fakeData: offsetArr,
    };
};

const getPos = (pos, params) => {
    const { maxShowLen, defaultWidth, curWidth, gapWidth, startPos, direction } = params;
    const oneSideNum = (maxShowLen - 1) / 2;
    // 计算当前卡片的位置
    let offset = 0;
    if (pos < oneSideNum) {
        // 如果是在左侧的卡片
        offset = startPos + pos * (defaultWidth + gapWidth);
    } else if (pos > oneSideNum) {
        // 如果是在右侧的卡片
        offset = startPos + pos * (defaultWidth + gapWidth) + (curWidth - defaultWidth);
    } else {
        // 如果是当前选中的卡片
        offset = startPos + oneSideNum * (defaultWidth + gapWidth);
    }

    return direction === 'horizontal'
        ? {
              translateX: offset,
          }
        : {
              translateY: offset,
          };
};

const getSize = params => {
    const { curWidth, defaultHeight } = params;
    return {
        width: curWidth,
        height: defaultHeight,
    };
};

const getScale = (isCurrent, params) => {
    const { scale } = params;
    if (!isCurrent) {
        return { scale };
    }
    return { scale: 1 };
};

interface IProps<T> {
    data: T[];
    name: string;
    autoPlay?: boolean;
    className?: string;
    style?: any;
    scale?: number;
    startPos?: number;
    height?: number;
    active?: number;
    padding?: number;
    width?: number;
    maxShowLen?: number;
    maxWidth?: number;
    duration?: number;
    direction?: 'horizontal' | 'vertical';
    onChange?: (index: number) => void;
    onAfterChange?: (index: number) => void;
    onInited?: () => void;
    renderItem?: (item: T, index: number) => JSX.Element;
}

interface CarouselRef {
    prev: () => void;
    next: () => void;
    getPrevIndex: () => number;
    getNextIndex: () => number;
}

type Item<T> = { value: T; index: number };

function Carousel<T = any>(props: IProps<any>, ref: ForwardedRef<CarouselRef>) {
    const {
        data: _data,
        name,
        style,
        autoPlay = false,
        className = '',
        padding = 50,
        width = 248,
        maxShowLen = 5,
        height = 485,
        maxWidth = 1440,
        startPos = -47,
        active,
        scale,
        duration = 0.5,
        direction = 'horizontal',
        renderItem,
        onChange,
        onAfterChange,
        onInited,
    } = props;
    const [fakeData, setFakeData] = useState<Item<T>[]>([]);
    const [running, setRunning] = useState(false);
    const [data, setData] = useState<Item<T>[]>([]);
    const [inited, setInited] = useState(false);
    const paramsRef = useRef<any>();
    const curIndex = useRef<number>(0);

    const [play, setPlay] = useState(true);
    const timerRef = useRef<any>();
    const isPaused = useRef<boolean>(false);

    const createParams = () => {
        const _maxWidth = maxWidth ?? window.innerWidth;
        const curWidth = _maxWidth - (maxShowLen - 1) * (padding + width) - startPos * 2;

        const params = {
            defaultWidth: width,
            gapWidth: padding,
            maxShowLen,
            startPos: startPos ?? -width * 0.19,
            curWidth,
            scale: scale || width / curWidth,
            maxWidth: _maxWidth,
            defaultHeight: height,
            fakeList: data,
            direction,
            offsetMap: {},
            beforeMap: {},
            afterMap: {},
        };
        paramsRef.current = params;
    };

    const setParams = (params: any) => {
        paramsRef.current = {
            ...paramsRef.current,
            ...params,
        };
    };

    const getParams = () => {
        return paramsRef.current;
    };

    const getPrevIndex = (index?: number) => {
        let prev = (index ?? curIndex.current) - 1;
        if (prev < 0) {
            prev = _data.length - 1;
        }

        return prev;
    };

    const getNextIndex = (index?: number) => {
        let next = (index ?? curIndex.current) + 1;
        if (next > _data.length - 1) {
            next = 0;
        }
        return next;
    };

    const getGap = index => {
        const { offsetMap } = getParams();
        const curPos = offsetMap[curIndex.current];
        const targetPos = offsetMap[index];
        const gap = curPos - targetPos;

        return gap;
    };

    const handleClickCard = index => {
        if (running || curIndex.current === index) {
            return;
        }

        const gap = getGap(index);
        const params = getParams();

        const { targetMap, beforeScrollMap, afterScrollMap, fakeArr, fakeData: newFakeData } = shiftArr(params, gap);

        setParams({
            fakeList: fakeArr,
            offsetMap: targetMap,
            beforeMap: beforeScrollMap,
            afterMap: afterScrollMap,
        });

        setRunning(true);
        setFakeData(newFakeData);
        curIndex.current = index;
        onChange?.(index);
    };

    const getElement = (isReal = true) => {
        const elems = document.querySelectorAll(`#${name} .${isReal ? 'real-item' : 'fake-item'}`);
        return Array.from(elems);
    };

    const initAnimation = () => {
        // 初始化参数
        createParams();
        const params = getParams();

        const { fakeArr, targetMap } = shiftArr(params, 2);
        setParams({
            fakeList: fakeArr,
            offsetMap: targetMap,
        });

        const cards: Element[] = getElement();

        cards.forEach((el: Element, index) => {
            gsap.to(el, {
                duration: 0,
                ease: 'myEase',
                ...getPos(targetMap[index], params),
                ...getSize(params),
                ...getScale(curIndex.current === index, params),
            });
        });

        setInited(true);
    };

    const handleSlideComplete = tl => {
        setRunning(false);
        onAfterChange?.(curIndex.current);
        setFakeData([]);
        tl.kill();
        setPlay(true);
    };

    const slide = () => {
        const params = getParams();
        const { offsetMap, beforeMap, afterMap } = params;
        const tl = gsap.timeline();
        // 1. 添加fake元素
        const fakeCards: Element[] = getElement(false);
        fakeCards.forEach((item, index) => {
            tl.to(item, {
                ...getPos(beforeMap[`f${index}`], params),
                ...getSize(params),
                ...getScale(false, params),
                duration: 0,
            });
        });

        // 2. 播放位移动画
        const cards: Element[] = getElement();
        fakeCards.forEach((item, index) => {
            tl.to(
                item,
                {
                    ...getPos(afterMap[`f${index}`], params),
                    ...getSize(params),
                    ...getScale(false, params),
                    ease: 'myEase',
                    duration,
                },
                '-100%',
            );
        });
        cards.forEach((item, index) => {
            tl.to(
                item,
                {
                    ...getPos(afterMap[index], params),
                    ...getSize(params),
                    ...getScale(curIndex.current === index, params),
                    ease: 'myEase',
                    duration,
                },
                '-100%',
            );
        });

        // 3. 使用真实元素替换掉fake元素
        fakeData.forEach(item => {
            tl.to(cards[item.index], {
                ...getPos(offsetMap[item.index], params),
                ...getSize(params),
                ...getScale(false, params),
                ease: 'myEase',
                duration: 0,
                onComplate: () => handleSlideComplete(tl),
            });
        });
    };

    const slidePrev = () => {
        if (running) {
            return;
        }
        pausedAuoPlay();
        const _active = getPrevIndex();
        if (active !== undefined) {
            onChange?.(_active!);
            return;
        }
        handleClickCard(_active);
    };

    const slideNext = () => {
        if (running) {
            return;
        }
        pausedAuoPlay();
        const _active = getNextIndex();
        if (active !== undefined) {
            onChange?.(_active);
            return;
        }
        handleClickCard(_active);
    };

    const startAutoPlay = () => {
        if (isPaused.current) {
            return;
        }
        timerRef.current = setTimeout(() => {
            if (isPaused.current) {
                return;
            }
            setPlay(false);
            slideNext();
        }, 3000);
    };

    const pausedAuoPlay = () => {
        clearTimeout(timerRef.current);
        setPlay(false);
    };

    const handleMouseEnter = () => {
        isPaused.current = true;
        pausedAuoPlay();
    };

    const handleMouseLeave = () => {
        isPaused.current = false;
        if (!running && autoPlay) {
            startAutoPlay();
        }
    };

    useEffect(() => {
        if (autoPlay && play) {
            startAutoPlay();
        }
    }, [autoPlay, play]);

    useEffect(() => {
        if (inited) {
            onInited?.();
        }
    }, [inited]);

    useEffect(() => {
        if (_data.length) {
            const maxLen = maxShowLen > _data.length ? maxShowLen : _data.length;
            const items = new Array(maxLen).fill(1).map((item, index) => {
                const originItem = _data[index];
                return _data[index] ? { value: originItem, index } : { value: null, index };
            });
            setData(items);
        }
    }, [_data]);

    useEffect(() => {
        if (data.length) {
            initAnimation();
        }
    }, [data]);

    useEffect(() => {
        if (active !== undefined && inited) {
            handleClickCard(active);
        }
    }, [active]);

    useEffect(() => {
        if (running) {
            slide();
        }
    }, [running]);

    useImperativeHandle(ref, () => ({
        prev: slidePrev,
        next: slideNext,
        getPrevIndex,
        getNextIndex,
        getElement,
        getSize: () => {
            if (!inited) {
                return;
            }
            return getSize(getParams());
        },
    }));

    if (!data.length) {
        return null;
    }

    const getFakeItem = () => <div style={{ width, height }}></div>;

    return (
        <div className={`fz-carousel-wrapper ${className}`} style={style} id={name}>
            {fakeData.map((item, index) => (
                <div
                    className="fz-carousel-item fake-item"
                    key={index}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {item.value ? renderItem?.(item.value, item.index) : getFakeItem()}
                </div>
            ))}
            {data.map((item, index) => (
                <div
                    className="fz-carousel-item real-item"
                    onClick={() => (item.value ? handleClickCard(index) : undefined)}
                    key={index}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {item.value ? renderItem?.(item.value, item.index) : getFakeItem()}
                </div>
            ))}
        </div>
    );
}

export default forwardRef(Carousel);
