export enum EnterAcademyListSource {
    /** pc官网首页推荐 */
    HomeRecommend = 'pc_official_home_recommend',
}

export enum EnterAcademyDetailSource {
    /** pc官网课堂列表 */
    PCAcademyList = 'pc_official_academy_list',
    /** pc官网首页推荐 */
    PCHomeRecommend = 'pc_official_home_recommend',
    /** pc官网首页推荐 */
    H5HomeRecommend = 'h5_official_home_recommend',
    /** h5官网课堂列表 */
    H5AcademyList = 'h5_official_academy_list',
}
