export function getScrollY() {
    return window.scrollY || window.pageYOffset;
}

export function isScrollToEnd() {
    return window.innerHeight + getScrollY() >= document.body.offsetHeight - 2;
}

/**
 * 检测滚动是否已经停止 目前 scrollEnd 事件还没法用 只能用 onscroll 延迟判断
 */
export function detectScrollFinished(
    element: HTMLElement | Window | Document | null,
    callback: () => void,
    timeout = 100,
) {
    if (!element) {
        return () => {};
    }
    let timer: ReturnType<typeof setTimeout>;
    const handleScroll = () => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(callback, timeout);
    };
    element.addEventListener('scroll', handleScroll);
    return () => {
        element.removeEventListener('scroll', handleScroll);
    };
}
