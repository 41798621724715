// @ts-nocheck
/* eslint-disable */
/* tslint:disable */

export type OpStatusRegion = string;

/** 书本来源 */
export enum BookFrom {
    ORIGINAL_STORY = 0,
    TRANSLATION = 1,
}

export enum Gender {
    NOT_SET = -1,
    FEMALE = 0,
    MALE = 1,
}

/** 合同类型 */
export enum ContractType {
    /** Exclusive */
    Exclusive = 0,
    /** Non-Exclusive with 2 chapters earlier */
    NonExclusiveA = 1,
    /** Non-Exclusive without 2 chapters earlier */
    NonExclusiveB = 2,
}

/** 签约状态 */
export enum AuthorizeType {
    /** 未签约 */
    UNAUTHORIZED = 0,
    /** 已签约 */
    AUTHORIZED = 1,
}

/** 合同状态 */
export enum ContractStatus {
    /** 未签约 */
    NOT_SIGN = 0,
    /** 签约中 */
    SIGNING = 1,
    /** 签约失败 */
    SIGN_FAILED = 2,
    /** 签约成功合同待填写 */
    SIGN_SUCCESS = 3,
    /** 合同已填写 */
    CONTRACT_MODIFIED = 4,
    /** 合同已归档 */
    CONTRACT_ARCHIVED = 5,
    /** 签约完成，合同已终止 */
    SIGN_CONTRACT_TERMINATE = 6,
    /** 签约未完成，合同已终止 */
    NOT_SIGN_CONTRACT_TERMINATE = 7,
}

export enum ContractPaymentMethod {
    PAYPAL = 0,
    BANK_TRANSFER = 1,
}

/** 立即/定时发文 */
export enum TimerStatus {
    IMMEDIATELY = 0,
    DELAY = 1,
}

/** 作品分类 */
export enum Dimensions {
    /** 体裁 */
    Genre = 1,
    /** 角色 */
    Character = 2,
    /** 场景 */
    Trope = 3,
    /** 主题 */
    Theme = 4,
    /** 不分类 */
    Others = 10,
}

/** Tos上传分类 */
export enum UploadFileType {
    /** 不分类 */
    OTHERS = 0,
    /** 图片 */
    IMAGE = 1,
    CONTRACT = 2,
}

/** 印尼激励活动状态 */
export enum BenefitStatus {
    /** 未知 */
    Unknown = 0,
    /** 已申请 */
    Applied = 1,
    /** 满足条件+未申请活动 */
    CanApply = 2,
    /** 没有书籍 */
    NoBook = 3,
    /** 有书籍，但是无满足条件的印尼独家签约 */
    NoMeetBook = 4,
    /** 满足其他条件，但fake uv <1000 */
    InsufficientUV = 5,
}

/** 发文格式 */
export enum TextFormat {
    /** 网文 */
    NetTextFormat = 1,
    /** 富文本 */
    RichTextFormat = 2,
}

export enum OverseaReadCompletionLevel {
    /** 无 */
    NO_VALUE = -1,
    /** 5万字读完率 */
    READ_COMPLETION_LEVEL_5 = 1,
    /** 10万字读完率 */
    READ_COMPLETION_LEVEL_10 = 2,
    /** 15万字读完率 */
    READ_COMPLETION_LEVEL_15 = 3,
    /** 20万字读完率 */
    READ_COMPLETION_LEVEL_20 = 4,
    /** 30万字读完率 */
    READ_COMPLETION_LEVEL_30 = 5,
    /** 40万字读完率 */
    READ_COMPLETION_LEVEL_40 = 6,
    /** 50万字读完率 */
    READ_COMPLETION_LEVEL_50 = 7,
    /** 60万字读完率 */
    READ_COMPLETION_LEVEL_60 = 8,
    /** 70万字读完率 */
    READ_COMPLETION_LEVEL_70 = 9,
    /** 80万字读完率 */
    READ_COMPLETION_LEVEL_80 = 10,
    /** 25万字读完率 */
    READ_COMPLETION_LEVEL_25 = 11,
}

export enum ActivityTimeStatus {
    /** 所有 */
    All = 0,
    /** 进行中 */
    InProgress = 1,
    /** 新书自投 */
    ToBeOpen = 2,
    /** 提签作品 */
    Close = 3,
}

/** home页入口 */
export enum HomeEntrance {
    /** 无 */
    NoValue = 0,
    /** FAQ */
    FAQ = 1,
    /** Feedback */
    Feedback = 2,
}

/** 分区 */
export enum CreationZone {
    /** all */
    All = 0,
    /** 新手专区 */
    New = 1,
    /** 写作技巧 */
    Skill = 2,
    /** 分类进阶 */
    Advanced = 3,
    /** 大神专访 */
    Professional = 4,
    /** 初级作家 */
    BeginnerWriter = 10,
    /** 基础写作课堂 */
    BasicWritingClass = 11,
    /** 高级写作课堂 */
    AdvancedWritingClass = 12,
    /** 针对特定类型的写作课程 */
    GenreSpecificWritingClasses = 13,
    /** 作者分享与讨论 */
    AuthorSharingAndDiscussion = 14,
}

/** 作家课堂类型 */
export enum ClassContentType {
    Text = 0,
    Video = 1,
}

/** 完结申请状态  0:未申请 1:申请中 2:已拒绝 */
export enum ApplyCreationStatus {
    NotApply = 0,
    InApply = 1,
    RefuseApply = 2,
}

/** 小红点通知事件 */
export enum EventType {
    Unknow = -1,
    All = 0,
    Announcement = 1,
    Academy = 2,
    Activities = 3,
    BookComment = 4,
    ChapterComment = 5,
    SpeakComment = 6,
    /** 勋章 */
    Medal = 7,
    /** 详情页评论 */
    DetailPageComment = 8,
    /** 论坛帖子 */
    Post = 9,
    /** 论坛消息 */
    ForumMsg = 10,
    /** 论坛关注帖子 */
    Following = 11,
}

/** 已读未读 */
export enum EventReadStatus {
    Read = 0,
    Unread = 1,
}

/** 去掉小红点 */
export enum ClearEvent {
    Retain = 0,
    Clear = 1,
}

/** 作者引导项 */
export enum AuthorGuideFields {
    LearnBenefits = 1,
    BeProfessional = 2,
    CreateBook = 3,
}

/** AppID */
export enum AppID {
    Fizzo = 4171,
    Mytopia = 8481,
}

/** 全球生效 */
/** 书籍op_status */
export enum OpStatus {
    /** 待上架 */
    ToShelf = 0,
    /** 上架 */
    OnShelf = 1,
    /** 全面下架 */
    AllDownShelf = 3,
    /** 版权下架 */
    CopyrightDownShelf = 4,
}

/** 作品引导状态 */
export enum BookGuideStep {
    /** 创建作品审核中 */
    BookAuditing = 1,
    /** 创建作品审核未通过 */
    BookAuditReject = 2,
    /** 创建作品审核通过,未创建章节 */
    BookAuditPass = 3,
    /** 创建作品审核通过,<5k,可签约 */
    BookCanSignLessFive = 4,
    /** 创建作品审核通过,>=5k,可签约 */
    BookCanSignMoreFive = 5,
    /** 申请签约审核中 */
    ContractAuditing = 6,
    /** 合同可以签约 */
    ContractCanSign = 7,
    /** 签约任务审核拒绝 */
    ContractReject = 8,
    /** 手动终止未签约合同 */
    ContractTerminated = 9,
    /** 书籍已签约 */
    ContractSigned = 10,
    /** 书籍验书前 */
    BookVerifyBefore = 11,
    /** 书籍验书中 */
    BookVerifying = 12,
    /** 书籍验书完成 */
    BookVerifyCompleted = 13,
}

/** 书本来源 */
export enum SpeakDeleteStatus {
    NoDelete = 0,
    Delete = 1,
}

export enum AuthorSpeakStatus {
    /** 审核中 */
    Reviewing = 0,
    /** 审核通过 */
    Pass = 1,
    /** 审核不通过 */
    Reject = 2,
    /** 用户删除 */
    Deleted = 3,
    /** 草稿 */
    Draft = 6,
}

export enum WithdrawStatus {
    /** 提现模块隐藏（余额 & 提现按钮） */
    Hide = -2,
    /** 提现按钮不可用（按钮置灰） */
    Disable = -1,
    /** 未签约 */
    NotSign = 0,
    /** 低于可提现门槛 */
    BelowThreshold = 1,
    /** 可提现 */
    Withdrawable = 2,
}

export enum MedalType {
    /** 发文、签约 */
    /** 第一次成功发布章节后获得 */
    FirstPublishABook = 1,
    /** 第一部作品签约成功后获得 */
    FirstSignTheContractWithThePlatform = 2,
    /** 日更新 */
    /** 不间断更新21天，中间没有断更 */
    Update21DaysWithoutABreak = 3,
    /** 不间断更新60天，中间没有断更 */
    Update60DaysWithoutABreak = 4,
    /** 不间断更新100天，中间没有断更 */
    Update100DaysWithoutABreak = 5,
    /** 不间断更新200天，中间没有断更 */
    Update200DaysWithoutABreak = 6,
    /** 写长 */
    /** 累计写作字数达到50k */
    WriteTo50KWordsInTotal = 7,
    /** 累计写作字数达到100k */
    WriteTo100KWordsInTotal = 8,
    /** 累计写作字数达到150k */
    WriteTo150KWordsInTotal = 9,
    /** 累计写作字数达到200K */
    WriteTo200KWordsInTotal = 10,
    /** 累计写作字数达到500K */
    WriteTo500KWordsInTotal = 11,
    /** 累计写作字数达到1000K */
    WriteTo1000KWordsInTotal = 12,
    /** 斋月活动 */
    /** 斋月活动阶段一，3.23-4.6连续日更 */
    RamadanActivityStageOne = 13,
    /** 斋月活动阶段二，3.23-4.20连续日更，且有两次定时发文 */
    RamadanActivityStageTwo = 14,
    /** 斋月活动阶段三，3.23-4.26连续日更 */
    RamadanActivityStageThree = 15,
    /** 节日手动勋章 */
    FestivalManualMedal = 100,
}

export enum AnnouncementType {
    All = 0,
    News = 1,
    WritingContests = 2,
    ProductUpdates = 3,
    InterviewsWithAuthors = 4,
}

export enum AnnouncementTop {
    NoSet = 0,
    Top = 1,
}

export enum SignTerminateStatus {
    /** 待确认 */
    NotConfirm = 0,
    /** 已确认 */
    Confirm = 1,
    /** 已终止 */
    Terminated = 2,
}

/** 内容云审核状态 */
export enum CloudStatus {
    /** 审核中 */
    Reviewing = 0,
    /** 审核通过 */
    Pass = 1,
    /** 审核不通过 */
    Reject = 2,
    /** 用户删除 */
    Deleted = 3,
    /** 草稿 */
    Draft = 6,
}

/** 敏感词检测场景 */
export enum SensitiveTextCheckScene {
    /** 章节名称 */
    ChapterName = 1,
}

/** 征文活动竞赛状态: */
export enum ContestParticipateStatus {
    /** 竞赛未开启 */
    ParticipateOff = 0,
    /** 竞赛已开启 */
    ParticipateOn = 1,
}

/** 论坛帖子类型: */
export enum ForumPostType {
    /** 帖子 */
    Post = 1,
    /** 课堂 */
    Academy = 2,
    /** 公告 */
    Announcement = 3,
    /** 关注 */
    Follow = 4,
}

/** 论坛作者类型 */
export enum ForumAuthorType {
    Normal = 0,
    Whitelist = 1,
    Blacklist = 2,
}

/** 小说结构 */
export enum NovelStructure {
    /** 小说设定 */
    Setting = 1,
    /** 小说梗概 */
    Synopsis = 2,
    /** 小说角色 */
    Characters = 3,
    /** 小说大纲 */
    SectionOutline = 4,
    /** 小说细纲 */
    ChapterOutline = 5,
    /** 小说正文 */
    Text = 6,
    /** 小说分类 */
    Category = 7,
    /** 小说次细纲，介于细纲和正文中间的生成产物，后加的 */
    ChapterDetailOutline = 8,
    /** 总结梗概 */
    SummarySynopsis = 9,
    /** 总结角色 */
    SummaryCharacters = 10,
    /** 总结梗概 */
    SummaryPreStructure = 11,
    /** 小说封面 */
    Cover = 12,
    /** 小说查询 */
    Query = 13,
    /** 小说润色 */
    Polish = 14,
    /** 小说描写 */
    Describe = 15,
}

/** 国际化详情页类别: */
export enum I18nDetailPageType {
    /** 其它 */
    OTHER = 0,
    /** 公告详情 */
    NOTICE = 1,
    /** 活动详情 */
    ACTIVITY = 2,
    /** 作家课堂 */
    CLASS = 3,
}

/** ai写作类型 */
export enum AIBookType {
    AINewBook = 1,
    AIOldBook = 2,
}

/** 用户事件类型: */
export enum UserEventType {
    /** 字数更新 */
    WORDS_UPDATE = 1,
    /** 连更天数 */
    SERIAL_DAYS = 2,
}

/** 作者风险等级 */
export enum AuthorRiskLevel {
    /** 正常用户 */
    Normal = 0,
    /** 风险作者（手动标记） */
    Risk = 1,
    /** 风险作者（系统标记） */
    SystemRisk = 2,
    /** 封禁作者（审核侧标记） */
    Ban = 3,
}

/** ai协议类型 */
export enum AIAgreementType {
    Text = 0,
    Cover = 1,
    /** 【废弃】协议只有前两种 */
    Query = 2,
}

export enum AICoverStyle {
    /** 动漫 */
    Anime = 1,
    /** 人像 */
    Common = 2,
}

/** ai白名单能力场景id */
export enum AIAbilityBizID {
    /** 作者ai书封白名单场景 */
    UserAICover = 102,
    /** 作者ai查询白名单场景 */
    UserAIQuery = 103,
    /** 作者ai润色白名单场景 */
    UserAIPolish = 104,
    /** 作者ai描写白名单场景 */
    UserAIDescribe = 105,
}

/** ai能力枚举 */
export enum AIAbilityType {
    /** ai写作 */
    AIWriter = 1,
    /** ai封面 */
    AICover = 2,
    /** ai查询 */
    AIQuery = 3,
    /** ai润色 */
    AIPolish = 4,
    /** ai描写 */
    AIDescribe = 5,
}

/** ai查询阶段 */
export enum AIQueryStage {
    /** 查询中 */
    Querying = 0,
    /** 回答结束 */
    ReplyFinished = 1,
    /** 全部结束 */
    AllFinished = 2,
}

/** ai润色风格 */
export enum AIPolishStyle {
    /** 重新措辞 */
    ShowNotTell = 1,
    /** 更多对话 */
    Rephrase = 2,
    /** 展示，而不是讲述 */
    MoreDialogue = 3,
    /** 更多内心冲突、心理描写 */
    MoreInnerConflict = 4,
    /** 青年氛围 */
    YouthTone = 5,
    /** 更加戏剧性 */
    MoreDramatic = 6,
}

/** ai描写类型 */
export enum AIDescribeType {
    /** 人物描写 */
    Character = 1,
    /** 场景描写 */
    Scene = 2,
    /** 环境描写 */
    Environment = 3,
}

/** 签约步骤 */
export enum SigningStep {
    /** 作者签约步骤 */
    WriterSigning = 1,
    /** 平台签约步骤 */
    PlatformSigning = 2,
}

/** 签约作者类型 */
export enum ContractWriterType {
    /** 新签约作者：从未发起过签约的作者 */
    NewWriter = 1,
    /** 老作者 */
    OldWriter = 2,
}

/** 排序类型 */
export enum OrderType {
    /** 不排序 */
    None = 0,
    /** 正序 */
    ASC = 1,
    /** 倒序 */
    DESC = 2,
}

/** 实验状态 */
export enum ExperimentStatus {
    /** 暂未满足条件 */
    NotReady = 1,
    /** 待实验 */
    Ready = 2,
    /** 审核中 */
    Verifying = 3,
    /** 审核不通过 */
    Reject = 4,
    /** 进行中——对应实验处于开启 */
    Pass = 5,
    /** 待确认结果——对应实验处于开启 */
    ToConfirm = 6,
    /** 已确认——对应实验处于关闭 */
    Confirmed = 7,
    /** 已暂停失效——对应实验处于关闭 */
    Stop = 8,
    /** 待编辑确认结果——对应实验处于开启 */
    ToEditorConfirm = 9,
}

export enum ExperimentCondition {
    /** - 开始推荐作品 */
    Recommending,
    /** - 签约&在架作品 */
    SignedOnShelf,
    /** - 昨天阅读UV大于40 */
    YesterdayUV40,
    /** - 字数大于10万字 */
    WordNumber100K,
    /** - 对未完结书来说，最新7日有更新 */
    FinishOrUpdate7Day,
    /** - 作品没有在其他实验中（一般是运营手动开启的实验） */
    NotInExperiment,
}

/** 低画风字段 */
export enum SensitiveField {
    /** 书名 */
    Title = 0,
    /** 封面 */
    Cover = 1,
    /** 简介 */
    Description = 2,
    /** 简介关键词 */
    AbstractKeywords = 3,
}

export const DEFAULT = 'default';

/** 书籍上下架结构体 */
export interface OpStatusInfo {
    AppID: AppID;
    Region: OpStatusRegion;
    Status: OpStatus;
}

export interface OrderBy {
    Field: string;
    Asc: boolean;
}

/** 帖子Tab (AdminConfig 动态新增) */
export interface PostTab {
    Type: number;
    Name: string;
    /** 是否允许用户发帖 */
    AllowPost: boolean;
    /** 是否展示发帖入口 */
    ShowPost?: boolean;
}
