import React from 'react';
import Background from './background';
import Panel from './panel';
import { useHome } from '@utils/index';
import { useTranslation } from '@ies/intl-react-plugin';
import Loading from '@components/loading';

import './index.less';

export enum RankType {
    TopAuthor = 1,
    TopNewAuthor = 2,
    TopMaleBookAuthor = 3,
}

const Rank: React.FC = () => {
    const { rank_popular = [], rank_recent = [], rank_youth = [], serverInited } = useHome();
    const { t } = useTranslation();
    return (
        <section className="home-id-rank" id="home_rank">
            {serverInited ? (
                <>
                    <div className="home-id-rank-bg">
                        <Background className="home-id-rank-bg-1" color="#007549" rotate={-7.06} top={280} />
                        <Background className="home-id-rank-bg-2" color="#D7A461" rotate={1.54} top={282} />
                        <Background className="home-id-rank-bg-3" color="#FF5F00" rotate={4.87} top={1181} />
                    </div>
                    <div className="home-id-rank-panel-wrapper">
                        <Panel
                            title={t('pc_top_author_list')}
                            data={rank_popular}
                            className="home-id-rank-panel-1"
                            type={RankType.TopAuthor}
                        />
                        <Panel
                            title={t('pc_top_new_author_list')}
                            data={rank_recent}
                            className="home-id-rank-panel-2"
                            type={RankType.TopNewAuthor}
                        />
                        <Panel
                            title={t('pc_top_male_author_list')}
                            data={rank_youth}
                            className="home-id-rank-panel-3"
                            type={RankType.TopMaleBookAuthor}
                        />
                    </div>
                </>
            ) : (
                <Loading />
            )}
        </section>
    );
};
export default Rank;
