import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { Carousel } from '@byted/serial-components-i18n';
import Icon from '@components/icon';
import { AdminPathPC, jumpUrl, Tea, useGoAdmin, useHome, useNav, useUserInfo } from '@utils/index';
import ArrowBtn from '@components/arrow-btn';
import { useTranslation } from '@ies/intl-react-plugin';
import { useAccount } from '@containers/account';
import Loading from '@components/loading';
import { getTime, useActivity, useNotice } from '@containers/home-id/utils';
import BannerImage from '../../../images/banner.png';

import './index.less';

const useAnimation = (loading: boolean) => {
    const [hasPlay, setHasPlay] = useState(false);
    useEffect(() => {
        if (!loading && !hasPlay) {
            initAnimation();
        }
    }, [loading]);

    const initAnimation = () => {
        const tl = gsap.timeline();
        tl.fromTo('.carousel-slider', { xPercent: 0 }, { xPercent: 100, duration: 0.6, ease: 'myEase' })
            .fromTo('.pc-header-container', { yPercent: 0 }, { yPercent: 100, duration: 0.5, ease: 'myEase' }, 0.6)
            .fromTo('.home-id-banner-activity', { scaleY: 0 }, { scaleY: 1, duration: 0.6, ease: 'myEase' }, 0.8)
            .fromTo('.notice-slider', { xPercent: 0 }, { xPercent: 100, duration: 0.6, ease: 'myEase' }, 0.8)
            .fromTo(
                ['.home-id-banner-content', '.home-id-academy-inner'],
                { translateY: 20, opacity: 0, ease: 'myEase' },
                { translateY: 0, opacity: 1, duration: 0.5, ease: 'myEase', onComplete: () => setHasPlay(true) },
                1.6,
            );
    };

    return hasPlay;
};

const Banner = () => {
    const { top_banner, serverInited } = useHome();
    const { t } = useTranslation();
    const { handleWrite, getAccountPopup } = useAccount();
    const activity = useActivity();
    const notice = useNotice();
    const { id } = useUserInfo();

    const loading = activity.loading || notice.loading || !serverInited;
    const hasPlay = useAnimation(loading);

    const goAdmin = useGoAdmin();
    const navTo = useNav();

    const onJoin = () => {
        Tea('click_start_writing');
        handleWrite();
    };

    const handleClickNotice = () => {
        Tea('enter_annoucement');
        if (!id) {
            handleWrite(() => goAdmin(AdminPathPC.Announcement));
            return;
        }
        goAdmin(AdminPathPC.Announcement);
    };

    return (
        <section className="home-id-banner" id="home_banner">
            {!loading ? (
                <>
                    <div className="home-id-banner-top">
                        <div className="carousel-slider" />
                        <Carousel
                            autoPlay={hasPlay}
                            animation="fade"
                            showArrow="never"
                            onChange={index => Tea('slide_banner', { pos: index })}
                        >
                            <div className="carousel-wrapper">
                                <div className="carousel-wrapper-center">
                                    <div className="carousel-content">
                                        <h1>{t('banner_context_first')}</h1>
                                        <span>{t('banner_context_second')}</span>
                                        <ArrowBtn text={t('web_firstpage_button')} onClick={onJoin} />
                                    </div>
                                    <img src={BannerImage} alt="" />
                                </div>
                            </div>
                            {top_banner?.map((item, index) => (
                                <div
                                    className="carousel-wrapper"
                                    key={index}
                                    style={{ width: '100%', background: item.background_color }}
                                    onClick={() => jumpUrl(item.link)}
                                >
                                    <div className="carousel-wrapper-center">
                                        <img src={item.image_url} />
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <div className="home-id-banner-bottom">
                        <div className="home-id-banner-activity">
                            <div className="home-id-banner-content">
                                <div
                                    className="home-id-banner-activity-header"
                                    onClick={() => {
                                        Tea('enter_activity');
                                        navTo('/reward');
                                    }}
                                >
                                    <h1>{t('massage_activity')}</h1>
                                    <Icon type="point" />
                                </div>
                                <div className="home-id-banner-activity-content">
                                    {activity.data.map((item, index) => (
                                        <div
                                            className="activity-item"
                                            key={index}
                                            onClick={() => {
                                                Tea('enter_activity', { url: item.activity_link });
                                                jumpUrl(item.activity_link);
                                            }}
                                        >
                                            <div className="img-wrapper">
                                                <img src={item.picture_link} alt="" />
                                            </div>
                                            <div className="activity-item-right">
                                                <h5>{item.name}</h5>
                                                <span>
                                                    {getTime(item.start_time)} - {getTime(item.end_time)}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="home-id-banner-notice">
                            <div className="home-id-banner-notice-bg">
                                <div className="notice-slider" />
                            </div>

                            <div className="home-id-banner-content">
                                <div className="home-id-banner-notice-header" onClick={handleClickNotice}>
                                    <h1>{t('home_announcement')}</h1>
                                    <Icon type="point" />
                                </div>
                                <div className="home-id-banner-notice-content">
                                    {notice.data.map((item, index) => {
                                        return (
                                            <div
                                                className="notice-item"
                                                key={index}
                                                onClick={() => {
                                                    Tea('enter_annoucement', {
                                                        url: item.link_url,
                                                    });
                                                    jumpUrl(item.link_url);
                                                }}
                                            >
                                                <span>{t('detail_label')}</span>
                                                <div className="notice-item-title">{item.title}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    {getAccountPopup()}
                </>
            ) : (
                <Loading style={{ height: '100vh' }} />
            )}
        </section>
    );
};

export default Banner;
