/**
 * copy from mobile
 * 举报功能封装，依赖ReportPopup
 */
import { EventType } from '@typings/api/novel/origin_overseas_base';
import React, { useCallback, useState } from 'react';
import { useTranslation } from '@ies/intl-react-plugin';
import { Toast } from '@arco-design/mobile-react-serial-i18n';
import { reportComment } from '@service/api';
import ReportPopup, { ReportFormType } from './index';
import { Tea } from '@utils/tea';
import { MessageText } from '@constants/value';
import { EventMap } from './event';

export interface UseReportCommentProps {
    commentType: EventType;
}

export interface ReportItem {
    commentId: string;
}

export function useReportComment({ commentType }: UseReportCommentProps) {
    const { i18n } = useTranslation();
    const [reportItem, setReportItem] = useState({ commentId: '' });
    const [compMounted, setCompMounted] = useState(false);
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState<ReportFormType>({ reason: '', advice: '' });

    const teaReport = useCallback(
        (is_successful = true) => {
            const t = (e: string, params = {}) => Tea(e, { ...params, is_successful });
            if (commentType === EventType.BookComment) {
                t('click_submit_report');
            } else if (commentType === EventType.ChapterComment) {
                t('click_submit_chapter');
            } else if (commentType === EventType.SpeakComment) {
                t('click_report_author_note_comment');
            } else if (commentType === EventType.DetailPageComment || commentType === EventType.Post) {
                t('click_report_class', {
                    event_type: EventMap[commentType],
                });
            }
        },
        [commentType],
    );

    const submitReport = useCallback(
        async ({ reason, advice }) => {
            try {
                const res = await reportComment({
                    comment_id: reportItem.commentId,
                    report_reason: reason,
                    comment_type: commentType,
                    advice,
                });
                if (res?.code === 0) {
                    Toast.success(i18n.t('comment_report_success_toast', undefined, 'Report Successfully'));
                    teaReport();
                } else {
                    throw res;
                }
            } catch (error) {
                teaReport(false);
                Toast.toast(MessageText.netError);
            }
        },
        [commentType, reportItem, i18n, teaReport],
    );

    const execute = useCallback((item: ReportItem) => {
        setReportItem(item);
        setCompMounted(true);
        setTimeout(() => {
            setVisible(true);
        }, 0);
        setValue({ reason: '', advice: '' });
    }, []);

    const handleClosePopup = useCallback(() => {
        setVisible(false);
        setTimeout(() => {
            setCompMounted(false);
        }, 300);
    }, []);

    const ReportPopupElement = compMounted ? (
        <ReportPopup
            value={value}
            onChange={setValue}
            visible={visible}
            close={handleClosePopup}
            onSubmit={formItem => {
                submitReport(formItem);
                handleClosePopup();
            }}
        />
    ) : null;

    return {
        execute,
        ReportPopupElement,
    };
}
