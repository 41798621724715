import React, { HTMLAttributes, useEffect, useRef } from 'react';
import Lottie from 'lottie-web/build/player/lottie_light';
import cs from 'classnames';
import loadingData from '@static/lottie/loading.json';
import './index.less';
import { px2rem } from '@utils/util';

interface LoadingProps extends HTMLAttributes<HTMLDivElement> {
    height?: number; // default to 80
    width?: number; // default to 80
    fixed?: boolean; // default to false
    absolute?: boolean; // default to false
}

export default function Loading({ height = 64, width = 64, fixed, absolute, className, ...otherProps }: LoadingProps) {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        Lottie.loadAnimation({
            container: ref.current!,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: loadingData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        });
    }, []);

    return (
        <div className={cs('app-loading', { fixed, absolute }, className)} {...otherProps}>
            <div ref={ref} style={{ width: `${px2rem(width)}rem`, height: `${px2rem(height)}rem` }} />
        </div>
    );
}
