import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '@ies/intl-react-plugin';
import { Button, Message } from '@byted/serial-components-i18n';
import { Tea, useGoAdmin, useUserInfo } from '@utils/index';
import { MessageText } from '@constants/value';
import { updateUser } from '../user';
import { accountSDK } from './sdk';
import Input from './input';
import Oauth from './oauth';
import { AccountPage, Events, JoinType, ReportEvent } from './constants';
import { AccountLogger } from '@utils/logger';

interface IProps {
    onChangePage: (page: AccountPage, replace?: boolean, extra?: any) => void;
    loginCallback?: () => void;
}

const Login: React.FC<IProps> = ({ onChangePage, loginCallback }) => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const goAdmin = useGoAdmin();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const { id: uid } = useUserInfo();
    const handleEmailChange = (value: string) => {
        setEmail(value);
        setErrMsg('');
    };

    const handldPwdChange = (value: string) => {
        setPassword(value);
        setErrMsg('');
    };

    const handleLogin = async () => {
        try {
            await accountSDK.login.pwdLogin({
                account: email,
                password,
            });
            Tea('writer_login', {
                button_click: 'email',
                user_id: uid,
                is_success: 1,
            });
            const resp = await updateUser(dispatch);
            if (!resp) {
                return;
            }

            if (resp.code) {
                Message.error(MessageText.operateError);
                return;
            }

            if (resp.data.media_id) {
                // 作者账号登陆
                Tea(Events.WriterJoinThirdPart, { join_type: JoinType.AuthorAccount });
                loginCallback ? loginCallback() : goAdmin();
                return;
            }
            // 用户账号登陆
            Tea(Events.WriterJoinThirdPart, { join_type: JoinType.UserAcccountFromApp });
            goAddName();
        } catch (e) {
            AccountLogger.info('login_error', {
                type: ReportEvent.Email,
                error: JSON.stringify(e),
            });

            setErrMsg(i18n.t('sso_username_error'));
            Tea('writer_login', {
                button_click: 'email',
                user_id: uid,
                is_success: 0,
            });
        }
    };

    const goRegisterOauth = (params: any) => {
        onChangePage(AccountPage.RegisterOauth, false, params);
    };

    const goAddName = () => {
        onChangePage(AccountPage.AddName, true);
    };

    return (
        <div className="login">
            <h1>{i18n.t('sso_login_title')}</h1>
            <h6>
                <div className="login-tip">{i18n.t('sso_login_text')}</div>
                <span onClick={() => onChangePage(AccountPage.Register, true)}>{i18n.t('sso_login_text1')}</span>
            </h6>
            <Oauth fromLogin onUserNotExist={goRegisterOauth} onUserNameEmpty={goAddName} />
            <div className="login-br">{i18n.t('sso_login_or')}</div>
            <Input
                value={email}
                onChange={handleEmailChange}
                placeholder={i18n.t('sso_email_default')}
                validation={errMsg}
                hideValidation
            />
            <Input
                password
                value={password}
                onChange={handldPwdChange}
                placeholder={i18n.t('sso_password_default')}
                validation={errMsg}
            />
            <Button className="account-button" disabled={!email || !password} onClick={handleLogin} autoLoading>
                {i18n.t('sso_next_btn')}
            </Button>
            <div className="login-forget" onClick={() => onChangePage(AccountPage.Forget)}>
                {i18n.t('sso_forget_password')}
            </div>
        </div>
    );
};

export default Login;
