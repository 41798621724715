import { createPortal } from 'react-dom';
import React, { Dispatch, MouseEventHandler, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Icon from '@components/icon';

export interface Position {
    right: number;
    top: number;
}

export interface ActionItemProps {
    text: string;
    iconType: string;
    onClick?: MouseEventHandler;
}

export interface CommentCardActionProps {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    actions: ActionItemProps[];
    getPosition: () => Position;
}

export default function CommentCardAction({ visible, setVisible, actions, getPosition }: CommentCardActionProps) {
    const [position, setPosition] = useState<Position>({ right: 0, top: 0 });
    const overlayRef = useRef<HTMLDivElement>(null);
    const eventClearRef = useRef<(() => void) | undefined>();
    useEffect(() => {
        const onTouchStart = () => {
            setVisible(false);
            // prevent start事件会导致此次触摸无法滚动
        };
        const onTouchEnd = (e: Event) => {
            if (e.cancelable) {
                e.preventDefault();
            }
            e.stopPropagation();
            // Toast.toast(`touched${e.cancelable}`);
            eventClearRef.current?.();
        };
        const onTouchCancel = (e: Event) => {
            if (e.cancelable) {
                e.preventDefault();
            }
            e.stopPropagation();
            // Toast.toast('touch cancel');
            eventClearRef.current?.();
        };
        if (visible) {
            // Toast.toast('bind');
            eventClearRef.current?.();
            setPosition(getPosition());
            const overlay = overlayRef.current;
            overlay?.addEventListener('touchstart', onTouchStart, { passive: false });
            overlay?.addEventListener('touchend', onTouchEnd, { passive: false });
            overlay?.addEventListener('touchcancel', onTouchCancel, { passive: false });
            eventClearRef.current = () => {
                overlay?.removeEventListener('touchstart', onTouchStart);
                overlay?.removeEventListener('touchend', onTouchEnd);
                overlay?.removeEventListener('touchcancel', onTouchCancel);
                // Toast.toast('removed');
                eventClearRef.current = undefined;
            };
        }
        // return () => {
        //     eventClearRef.current?.();
        // };
    }, [visible]);

    const genActionItem = (text: string, iconType: string, onClick?: MouseEventHandler): ReactNode => (
        <div
            key={`${text}${iconType}`}
            onClick={e => {
                e.stopPropagation();
                setVisible(false);
                onClick?.(e);
            }}
            className="list-item"
        >
            <Icon className="list-item-icon" type={iconType} />
            <div className="list-item-text">{text}</div>
        </div>
    );
    const genOverlay = () => <div ref={overlayRef} className="comment-card-action-overlay" />;

    const genContainer = () => (
        <CSSTransition
            mountOnEnter
            unmountOnExit
            timeout={{ enter: 150, exit: 75 }}
            in={visible}
            classNames="comment-card-action"
        >
            <div
                style={{ top: `${position.top}px`, right: `${position.right}px` }}
                className="comment-card-action-container"
            >
                <div className="comment-card-action-content-wrap">
                    {actions.map(a => genActionItem(a.text, a.iconType, a.onClick))}
                    {/* {showReport && genActionItem(t('report'), 'tip-off', onClickReport)} */}
                    {/* {showDelete && genActionItem(t('delete'), 'delete-normal', onClickDelete)} */}
                </div>
            </div>
        </CSSTransition>
    );

    if (typeof document === 'undefined') {
        // SSR 环境
        return null;
    }

    return createPortal(
        <>
            {visible && genOverlay()}
            {genContainer()}
        </>,
        document.body,
    );
}
