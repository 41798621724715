import React, { useState, useEffect } from 'react';
import { Message, Table } from '@byted/serial-components-i18n';

import { useHistory } from 'react-router-dom';
import { useTranslation } from '@ies/intl-react-plugin';
import { useUserInfo } from '@utils/hooks';

import { getRewardStatus } from '@service/api';
import { columnData, columns } from './setting';
import Loading from '@components/loading';
import { MessageText } from '@constants/value';
import { getTimeText } from '@utils/i18n-locale';
import { Tea } from '@utils/tea';
import BackPage from '@components/back-page';
import { LangCode } from '@common/lang';

import './index.less';

const REWARD_TERM = 'https://docs.google.com/document/d/1qVEohU3Cm0Txmj0yvIkfaUOypJ4NfNDv39OBUQftZU8/edit';

enum AwardType {
    BestPerformanceReward,
}

enum BenefitStatus {
    /** 未知 */
    Unknown = 0,
    /** 已申请 */
    Applied = 1,
    /** 满足条件+未申请活动 */
    CanApply = 2,
    /** 没有书籍 */
    NoBook = 3,
    /** 有书籍，但是无满足条件的印尼独家签约 */
    NoMeetBook = 4,
    /** 满足其他条件，但fake uv <1000 */
    InsufficientUV = 5,
}

const Reward: React.FC = () => {
    const { i18n } = useTranslation();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState<BenefitStatus>(BenefitStatus.Unknown);
    const [applyTime, setApplyTime] = useState(0);
    const { media_id, isLoading } = useUserInfo();

    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (media_id) {
            getStatus();
            posTea();
        } else {
            window.location.href = '/reward';
        }
    }, [isLoading]);

    useEffect(() => {
        if (i18n.language !== LangCode.ID) {
            window.location.href = '/reward';
        }
    }, [i18n.language]);

    const posTea = () => {
        Tea('enter_award', {
            award_type: AwardType.BestPerformanceReward,
        });
    };

    const getStatus = async () => {
        try {
            const resp = await getRewardStatus();
            if (resp.code) {
                throw Error();
            }
            const { benefit_status, apply_time } = resp.data;
            setStatus(benefit_status);
            setApplyTime(apply_time);
        } catch (e: any) {
            Message.error(MessageText.netError);
            console.log('#getRewardStatus:' + e.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading />;
    }

    const getDate = getTimeText(false, 'MMM.DD YYYY,UTC+0');

    return (
        <div className="id-activity">
            <BackPage text="Reward" onBack={() => history.replace('/reward')} />
            <div className="id-activity-banner" />
            <h6>
                Best Performance Reward&nbsp;
                {status === BenefitStatus.Applied && <div className="tag-flag">&nbsp;Sudah Terdaftar</div>}
            </h6>
            {status === BenefitStatus.Applied && (
                <div className="id-activity-tip">
                    Saya telah membaca dan&nbsp;
                    <a target="_blank" className="a-link" href={REWARD_TERM} rel="noreferrer">
                        menyetujui
                    </a>
                    .&nbsp;({getDate(`${applyTime}`)})
                </div>
            )}
            <p className="id-activity-regulation">Regulation</p>
            <ul>
                <li>Periode: 28 Januari - 30 April 2022* (Zona Waktu Publikasi UTC+0)</li>
                <li>Kontes berlaku untuk novel karya asli/karya orisinal.</li>
                <li>Kontes hanya berlaku untuk naskah eksklusif (ongoing). </li>
                <li>Jumlah reward yang didapat:</li>
            </ul>
            <Table columns={columns} data={columnData} />
            <p>Ketentuan Reward</p>
            <ul className="ul-reward">
                <li className="li-sub">
                    <span>
                        Di bulan pertama saat campaign dimulai, insentif akan diberlakukan untuk semua novel yang telah
                        memenuhi persyaratan. Untuk novel yang sebelumnya sudah menjalani proses perpanjangan kontrak,
                        di mana kontrak sebelumnya tidak memenuhi ketentuan tersebut, maka hanya persentase (%) total
                        jumlah kata ter-update selama periode campaign berlangsung yang akan diperhitungkan.
                    </span>
                    <div>
                        Jika novel mencapai 500ribu kata di bulan Januari, maka penulis akan mendapatkan insentif&nbsp;
                        reward&nbsp; untuk bulan Januari sebanyak 6 tingkatan berdasarkan masing-masing &quot;range
                        kata&quot;: terhitung mulai dari &quot;Jumlah Kata Dasar 50.000&quot; hingga &quot;Jumlah Kata
                        Dasar 400.000&quot;. Selanjutnya, insentif reward bulan Januari beserta pendapatan lainnya (jika
                        ada) akan dibayarkan secara bersamaan pada pertengahan bulan Februari.
                    </div>
                    <div>
                        Namun, apabila semisal novel diubah kontraknya menjadi novel ekslusif di 300ribu kata, maka
                        HANYA tingkatan &quot;range kata 200.001-300.000&quot; yang menjadi perhitungan insentif{' '}
                        reward&nbsp; pada bulan Januari (hanya insentif reward &quot;Jumlah Kata Dasar 150.000&quot;
                        yang akan dihitung). Sementara itu, insentif reward untuk &quot;range kata&quot; dimulai dari
                        kata ke 300.001 hingga seterusnya yang ada di bulan Januari akan dibayarkan secara bersamaan
                        dengan pendapatan lainnya (jika ada) pada pertengahan bulan Februari.
                    </div>
                </li>
                <li>
                    Sebagai salah satu persyaratan, Best Performance Reward akan diberikan kepada pemenang jika novelnya
                    memenuhi persyaratan{' '}
                    <a href="/event/benefit" target="_blank" className="a-link">
                        bonus update harian
                    </a>{' '}
                    pada bulan yang sama dan seterusnya. Jika penulis gagal memenuhi persyaratan tersebut, maka
                    penghargaan Best Performance Reward tidak akan berlaku lagi.
                </li>
                <li>
                    Masing-masing penulis diberikan satu kali kesempatan untuk ikut serta dalam&nbsp; Best Performance
                    Reward&nbsp; dan satu penulis hanya boleh mengajukan satu novel saja. Apabila penulis mengajukan
                    lebih dari satu novel, maka Fizzo akan memilih novel dengan jumlah reward&nbsp; tertinggi.
                </li>
                <li className="li-sub">
                    <span>
                        Pihak Fizzo berhak mendiskualifikasi peserta apabila ditemukan kecurangan selama kontes
                        berlangsung.
                    </span>
                    <div>
                        Kecurangan seperti plagiarisme, repetisi bab, pengulangan konten, penipuan bab, chapter skipping
                        (longkap bab) akan didiskualifikasi dari Best Performance Reward Campaign.
                    </div>
                </li>
                <li>
                    Peserta boleh berpartisipasi dalam Best Performance Reward Campaign jika novelnya sudah dibaca oleh
                    minimal 1.000 pembaca.
                </li>
                <li>
                    Best Performance Reward akan dievaluasi berdasarkan laporan rata-rata total nilai mingguan dari
                    &quot;Persentase (%) Selesai Baca&quot; per &quot;jumlah kata dasar&quot;.
                </li>
                <li>Peserta akan menerima total reward pada bulan berikutnya setelah nama pemenang diumumkan. </li>
                <li>
                    Hanya novel karya asli dengan hak cipta sendiri yang boleh diikutsertakan. Tidak ada toleransi untuk
                    tindakan plagiarisme.
                </li>
                <li>Fizzo berhak menentukan pemenang dari tahap awal hingga tahap akhir.</li>
            </ul>
        </div>
    );
};

export default Reward;
