import dayjs from 'dayjs';

import 'dayjs/locale/en';
import 'dayjs/locale/id';
import 'dayjs/locale/es';
import 'dayjs/locale/pt';

import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { LangCode } from '@common/lang';

dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(utc);

dayjs.locale('en');

export const changeDayjsLocale = (lang: LangCode = LangCode.EN) => dayjs.locale(lang);

export const utcTime = (time: string | number, format: string) => dayjs.utc(Number(time) * 1000).format(format);

export { dayjs };
