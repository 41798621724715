import { dayjs } from './day';

export const formatPrice = (num: number) => num;
// 先不删，以后可能需要适配本地化
// `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, getDecimalPoint(dayjs.locale() as LangCode));

export const getTimeText =
    (isLocal = true, format?: string) =>
    (d: string | Date | undefined) => {
        const _format = format || 'HH:mm A, MMM.DD';
        let day;
        if (typeof d === 'string') {
            day = dayjs(Number(d) * 1000);
        } else {
            day = d ? dayjs(d) : dayjs();
        }
        return isLocal ? day.format(_format) : day.utc().format(_format);
    };

export function formatTime(time: string | number, format = 'lll') {
    // Dec 31, 2019 06:45PM
    return dayjs(Number(time) * 1000).format(format);
}
