import { Message } from '@byted/serial-components-i18n';
import { MessageText } from '@constants/value';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

interface UseLoadData<T> {
    serverInited: boolean;
    request: () => Promise<{ code: number; data: T; message: string }>;
    action: (data: any) => any;
}

export function useLoadData<T>({ serverInited, request, action }: UseLoadData<T>) {
    const dispatch = useDispatch();
    useEffect(() => {
        if (!serverInited) {
            getData();
        }
    }, [serverInited]);

    const getData = async () => {
        try {
            const resp = await request();
            if (!resp.code) {
                dispatch(action({ ...resp.data, serverInited: true }));
            }
        } catch {
            Message.error(MessageText.netError);
        }
    };
}
