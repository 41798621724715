import axios from 'axios';

export interface ImgInfo {
    width: number;
    height: number;
    type: string;
}
export const getImgInfo = (file: any): Promise<ImgInfo> =>
    new Promise(resolve => {
        let imgType = '';
        try {
            const getImg = (url: any) => {
                const image = new Image();
                image.onload = () => {
                    const { width } = image;
                    const { height } = image;
                    resolve({ width, height, type: imgType });
                };
                image.src = url;
            };
            if (typeof file === 'string') {
                axios.get(file, { responseType: 'blob' }).then(res => {
                    imgType = res.data.type;
                    getImg(URL.createObjectURL(res.data));
                });
            } else {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const data = e.target?.result;
                    // 加载图片获取图片真实宽度和高度
                    getImg(data);
                };
                const finalFile = file.originFileObj || file;
                reader.readAsDataURL(finalFile);
                imgType = finalFile.type;
            }
        } catch (e) {
            resolve({} as any);
        }
    });
