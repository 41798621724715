import { dynamicText } from '@utils/locale';

type MessageTextType =
    | 'netError'
    | 'operateError'
    | 'createSuccess'
    | 'modifySuccess'
    | 'publishSuccess'
    | 'uploadSuccess'
    | 'submitSuccess'
    | 'clickToRetry';
export const MessageText = dynamicText<MessageTextType>({
    netError: 'common_error_toast1',
    operateError: 'common_error_toast',
    createSuccess: 'common_toast_success_create',
    modifySuccess: 'common_toast_success_modify',
    publishSuccess: 'common_toast_success_publish',
    uploadSuccess: 'common_toast_success_upload',
    submitSuccess: 'common_toast_success_submit',
    clickToRetry: 'page_retry',
});

export const ScreenWidth = 375;

export const DefaultAvatar = '/tos-alisg-i-836v1mcgsk-sg/a928ed1f18bb4a64acca9b622ba9bf91';

export const MobileHeaderHeight = 44;
export const PCHeaderHeight = 144;

export const baseFontSize = 50; // 50px对应1rem
