import { initPostData } from '@redux/actions/post';
import { PostState, PostType } from '@redux/reducers/post';
import { getPostDetail, getPostDetailInApp } from '@service/api';
import { GetPostDetailResponse } from '@typings/api/novel/toutiao_muye_overseas_homeapi';
import { isInApp } from '@utils/env';
import { serverPostToClientPost } from './utils';

type ContentInfo = {
    materials: any[];
    skeleton: {
        data: string;
        type: string;
    };
};

export const loadData = async (dispatch: any, postId: string) => {
    try {
        const resp = await getPostDetailInApp({ post_id: postId });

        if (resp.code === -4025 || resp.code === 0) {
            const {
                title = '',
                content = '',
                comment_open,
                digg_count,
                collect_count,
                post_user,
                create_time,
                category_name,
                is_old_schema = true,
            } = resp.data;
            // 历史帖子
            if (is_old_schema) {
                dispatch(
                    initPostData({
                        title,
                        time: create_time || 0,
                        content,
                        category_name: category_name || '',
                        commentOpen: comment_open === 1,
                        diggCount: digg_count || 0,
                        collectCount: collect_count || 0,
                        serverInited: true,
                        author: post_user
                            ? {
                                  userId: post_user.user_id,
                                  avatarURL: post_user.avatar_url,
                                  username: post_user.user_name,
                                  hasFollowed: post_user.has_followed,
                                  isAuthorSelf: post_user.is_author_self,
                              }
                            : undefined,
                    }),
                );
                return;
            }
            // 新帖子
            const { materials } = JSON.parse(content) as ContentInfo;
            let postType: PostType = PostType.official;
            // 判断是不是作者发的帖子
            if (materials.length !== 0) {
                postType = materials[0]?.data?.post_type;
            }

            const params: PostState = {
                title,
                time: create_time || 0,
                content: serverPostToClientPost(content, postType),
                commentOpen: comment_open === 1,
                diggCount: digg_count || 0,
                collectCount: collect_count || 0,
                serverInited: true,
                category_name: category_name || '',
                author: post_user
                    ? {
                          userId: post_user.user_id,
                          avatarURL: post_user.avatar_url,
                          username: post_user.user_name,
                          hasFollowed: post_user.has_followed,
                          isAuthorSelf: post_user.is_author_self,
                      }
                    : undefined,
                postType,
                extraImg: materials.filter(item => item.type === 'img'),
            };

            if (postType === PostType.official) {
                delete params.extraImg;
            }

            dispatch(initPostData(params));
        } else if (resp.code) {
            throw Error(resp?.toString?.());
        }
    } catch (e) {
        console.log('loadData getPostDetail err>>>>>', e);
    }
};

export const loadSSRData = async (ctx: any, store: any) => {
    const temp = ctx.url.match(/^\/post\/(.*)/)[1];
    const id = temp.split('?')[0];
    await loadData(store.dispatch, id);
};
