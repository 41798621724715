import React, { PropsWithChildren, useMemo } from 'react';
import './index.less';

import { Popover } from '@arco-design/mobile-react-serial-i18n';
import { SerialI18nPopoverProps } from '@arco-design/mobile-react-serial-i18n/esm/popover';
import { SerialI18nPopoverMenuProps } from '@arco-design/mobile-react-serial-i18n/esm/popover/menu';
import Icon from '@components/icon';

export interface Selection<T> {
    text: string;
    value: T;
}

export interface SelectPopoverProps<T> extends SerialI18nPopoverProps {
    onSelect?: (value: T) => void;
    selections: Selection<T>[];
    selected: Selection<T>['value'] | undefined;
    disabled?: boolean;
}

const convert: <T>(v: Selection<T>) => string = v => `${v.value}`;

export default function SelectPopover<T extends string | number>(props: PropsWithChildren<SelectPopoverProps<T>>) {
    const { children, selected, onSelect, selections, disabled, ...rest } = props;

    const valueConvertor = useMemo(() => new Map<string, T>(selections.map(s => [convert(s), s.value])), [selections]);

    const handleSelect = (value: string) => {
        // 需要转成对应的 type 给 handler
        const typedValue = valueConvertor.get(value);
        if (typedValue !== undefined) {
            onSelect?.(typedValue);
        }
    };

    const handleClickMenuItem = () => {};

    const selectMenu = useMemo<SerialI18nPopoverMenuProps['menu']>(() => {
        return selections.map(m => {
            const isSelected = selected === m.value;
            return {
                text: (
                    <div
                        className={`select-popover__selection-text ${
                            isSelected ? 'select-popover__selection-text--selected' : ''
                        }`}
                    >
                        {m.text}
                    </div>
                ),
                value: convert(m), // 需要转成 string...
                icon: isSelected ? <Icon type="choosed" /> : <div className="select-popover__not-choose" />,
            };
        });
    }, [selections, selected]);

    if (disabled) {
        return <div>{children}</div>;
    }

    return (
        <Popover.Menu
            clickSelfToClose
            onSelect={handleSelect}
            menu={selectMenu}
            onClickMenuItem={handleClickMenuItem}
            direction="bottomLeft"
            theme="white"
            innerPopoverClassName="select-popover"
            useClickStatus
            renderArrow={() => null}
            bordered={false}
            {...rest}
        >
            {children}
        </Popover.Menu>
    );
}
