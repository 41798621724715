import React from 'react';
import Footer from '@components/footer';
import Header from '@components/header';
import Content from './component';
import { HeaderTheme } from '@constants/enum';

const IDActivity = () => {
    return (
        <div className="id-activity-container" style={{ paddingTop: 141 }}>
            <Header theme={HeaderTheme.Picture} />
            <Content />
            <Footer />
        </div>
    );
};

export default IDActivity;
