import { composedPath } from '@utils/compose-path';
import { useEffect } from 'react';

function hasYScrollbar(el?: Element) {
    if (!el || el.nodeType !== Node.ELEMENT_NODE) {
        return false;
    }

    const style = window.getComputedStyle(el);
    return (
        (['auto', 'scroll'].includes(style.overflowY) || el.tagName === 'SELECT') && el.scrollHeight > el.clientHeight
    );
}

function shouldScroll(el: Element, e: WheelEvent): boolean {
    const delta = e.deltaY;

    const alreadyAtStart = Math.abs(el.scrollTop) < 1;
    const alreadyAtEnd = Math.abs(el.scrollTop + el.clientHeight - el.scrollHeight) < 1;

    const scrollingUp = delta < 0;
    const scrollingDown = delta > 0;
    // console.log('c', el.scrollTop, el.clientHeight, el.scrollHeight);
    // console.log('a', alreadyAtStart, scrollingUp, alreadyAtEnd, scrollingDown);
    if (!alreadyAtStart && scrollingUp) {
        return true;
    }
    if (!alreadyAtEnd && scrollingDown) {
        return true;
    }

    return false;
}

export function useScrollIsolator(container: HTMLElement | null) {
    useEffect(() => {
        const onWheel = (e: WheelEvent) => {
            const path = composedPath(e);
            let prevent = true;
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < path.length; ++i) {
                const el = path[i] as HTMLElement;
                if (hasYScrollbar(el) && shouldScroll(el, e)) {
                    prevent = false;
                }
                if (el === container) {
                    break;
                }
            }
            if (prevent) {
                e.preventDefault();
            }
        };
        container?.addEventListener('wheel', onWheel, { passive: false });
        return () => {
            container?.removeEventListener('wheel', onWheel);
        };
    }, [container]);
}
