import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import cs from 'classnames';
import { Message, Tooltip } from '@byted/serial-components-i18n';
import { useTranslation } from '@ies/intl-react-plugin';
import { setClientLang } from '@utils/i18n';
import { useRefState, Tea, useUserInfo } from '@utils/index';
import { modifyAuthor } from '@service/index';
import { HeaderTheme, MessageText } from '@constants/index';
import { SUPPORT_LANGS } from '@common/lang';

import LangLogo from './images/lang-logo.png';
import LangLogoWhite from './images/lang-logo-white.png';

import './index.less';
import './index.h5.less';

const { MenuItem } = Tooltip;
interface IProps {
    className?: string;
    theme?: HeaderTheme;
}

const Lang: React.FC<IProps> = (props: IProps) => {
    const { className, theme = HeaderTheme.Black } = props;
    const { i18n } = useTranslation();
    const langCode = i18n.language;
    const [visible, setVisible] = useState(false);
    const location = useLocation();

    const [_, changingRef, setChanging] = useRefState(false);

    const chooseLang = (language: string) => {
        setClientLang(language);
        setVisible(false);
        Tea('language_select_click', {
            url_path: location.pathname,
        });
    };
    const info = useUserInfo();

    const changeLang = (language: string) => async () => {
        if (changingRef.current) {
            return;
        }

        if (language === langCode) {
            setVisible(false);
            return;
        }

        if (!info?.isLogin || !info?.media_id) {
            chooseLang(language);
            return;
        }

        try {
            setChanging(true);
            const resp: any = await modifyAuthor({
                language,
            });

            if (resp.code) {
                Message.error(resp.message || MessageText.netError);
                return;
            }

            chooseLang(language);
        } catch {
            Message.error(MessageText.netError);
        } finally {
            setChanging(false);
        }
    };

    return (
        <Tooltip
            type="menu"
            className={cs(className, 'lang-tooltip')}
            unmountOnExit={false}
            position="bottom"
            popupVisible={visible}
            onVisibleChange={v => {
                v ? setVisible(true) : setVisible(false);
            }}
            content={
                <>
                    {SUPPORT_LANGS.map(({ name, code }) => (
                        <MenuItem
                            key={code}
                            className={cs('lang-item', {
                                'lang-item-active': code === langCode,
                            })}
                            onClick={changeLang(code)}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </>
            }
        >
            <img className="lang-content" src={theme === HeaderTheme.White ? LangLogoWhite : LangLogo} alt="" />
        </Tooltip>
    );
};

export default Lang;
