import { ColumnProps } from '@byted/serial-components-i18n/lib/Table';
import { formatPrice } from '@utils/i18n-locale';

export const columns: ColumnProps[] = [
    {
        title: 'Range Kata',
        dataIndex: 'word_range',
        className: 'id-activity-first',
        headerCellStyle: { borderTopLeftRadius: 6, borderBottomLeftRadius: 6 },
        render: (list: number[]) => `${formatPrice(list[0])}-${formatPrice(list[1])}`,
    },
    {
        title: 'Persentase (%) Selesai Baca',
        headerCellStyle: { borderTopRightRadius: 6 },
        children: [
            {
                title: 'jumlah kata dasar',
                dataIndex: 'reading_count',
                className: 'id-activity-second',
                render: (val: number) => formatPrice(val),
            },
            {
                title: '≥8%',
                dataIndex: 'more_8',
                className: 'id-activity-third',
                render: (val: number) => (val ? `$${formatPrice(val)}` : '-'),
            },
            {
                title: '≥15%',
                dataIndex: 'more_15',
                className: 'id-activity-third',

                render: (val: number) => (val ? `$${formatPrice(val)}` : '-'),
            },
            {
                title: '≥20%',
                dataIndex: 'more_20',
                className: 'id-activity-third',
                render: (val: number) => (val ? `$${formatPrice(val)}` : '-'),
            },
            {
                title: '≥30%',
                dataIndex: 'more_30',
                className: 'id-activity-third',
                render: (val: number) => (val ? `$${formatPrice(val)}` : '-'),
            },
            {
                title: '≥40%',
                dataIndex: 'more_40',
                className: 'id-activity-third',
                render: (val: number) => (val ? `$${formatPrice(val)}` : '-'),
            },
            {
                title: '≥50%',
                dataIndex: 'more_50',
                className: 'id-activity-third',
                render: (val: number) => (val ? `$${formatPrice(val)}` : '-'),
            },
            {
                title: '≥60%',
                dataIndex: 'more_60',
                className: 'id-activity-third',
                render: (val: number) => (val ? `$${formatPrice(val)}` : '-'),
                headerCellStyle: { borderBottomRightRadius: 6 },
            },
        ],
    },
];

export const columnData = [
    {
        word_range: [100000, 150000],
        reading_count: 50000,
        more_8: 0,
        more_15: 0,
        more_20: 50,
        more_30: 150,
        more_40: 400,
        more_50: 900,
        more_60: 1900,
    },
    {
        word_range: [150001, 200000],
        reading_count: 100000,
        more_8: 0,
        more_15: 0,
        more_20: 250,
        more_30: 350,
        more_40: 600,
        more_50: 1100,
        more_60: 2100,
    },
    {
        word_range: [200001, 300000],
        reading_count: 150000,
        more_8: 0,
        more_15: 0,
        more_20: 450,
        more_30: 550,
        more_40: 800,
        more_50: 1300,
        more_60: 2300,
    },
    {
        word_range: [300001, 400000],
        reading_count: 200000,
        more_8: 0,
        more_15: 500,
        more_20: 700,
        more_30: 1200,
        more_40: 2200,
        more_50: 4200,
        more_60: 0,
    },
    {
        word_range: [400001, 500000],
        reading_count: 300000,
        more_8: 0,
        more_15: 700,
        more_20: 900,
        more_30: 1400,
        more_40: 2400,
        more_50: 4400,
        more_60: 0,
    },
    {
        word_range: [500001, 600000],
        reading_count: 400000,
        more_8: 0,
        more_15: 900,
        more_20: 1100,
        more_30: 1600,
        more_40: 2600,
        more_50: 4600,
        more_60: 0,
    },
    {
        word_range: [600001, 700000],
        reading_count: 500000,
        more_8: 1000,
        more_15: 1400,
        more_20: 2400,
        more_30: 4400,
        more_40: 8400,
        more_50: 0,
        more_60: 0,
    },
    {
        word_range: [700001, 800000],
        reading_count: 600000,
        more_8: 1200,
        more_15: 1600,
        more_20: 2600,
        more_30: 4600,
        more_40: 8600,
        more_50: 0,
        more_60: 0,
    },
    {
        word_range: [800001, 900000],
        reading_count: 700000,
        more_8: 1500,
        more_15: 1900,
        more_20: 2900,
        more_30: 4900,
        more_40: 8900,
        more_50: 0,
        more_60: 0,
    },
    {
        word_range: [900001, 1000000],
        reading_count: 800000,
        more_8: 2000,
        more_15: 2400,
        more_20: 3400,
        more_30: 5400,
        more_40: 9400,
        more_50: 0,
        more_60: 0,
    },
];
