export const EVENTS = {
    /* ***************** 作家课堂 ***************** */
    // 点击课堂详情
    CLICK_ACADEMY_DETAIL: 'click_academy_detail',
    // 课堂详情页停留
    STAY_ACADEMY_DETAIL: 'stay_academy_detail',
    // 课堂视频播放时长
    STAY_ACADEMY_VIDEO: 'stay_academy_video',
    // 点击课堂列表
    CLICK_ACADEMY_LIST: 'click_academy_list',
    // 进入课堂（历史埋点）
    ENTER_ACADEMY: 'enter_academy',
    // 点击课堂推荐
    CLICK_ACADEMY_RECOMMENDATION: 'click_academy_recommendation',

    ENTER_FORUM_HOMEPAGE: 'enter_forum_homepage',
    CLICK_COLLECT: 'click_collect',
    CLICK_POST_COLLECT: 'click_post_collect',
    CLICK_POST_FOLLOW: 'click_post_follow',
    ENTER_FIZZO_WRITER_FORUM_ALL: 'enter_fizzo_writer_forum_all',
    // 课堂打分
    ACADEMY_RATING: 'academy_rating',
};

export enum GoToUserPageTeaEnum {
    community = 1,
    comment = 2,
    postCard = 3,
    detailPage = 4,
    fansPage = 5,
}

export enum PostRole {
    Subject = 1,
    Object,
}

export enum PostCollectType {
    detailPage = 1,
    community,
    userPage,
}

export enum FollowAuthorType {
    follow = 1,
    unFollow,
}
