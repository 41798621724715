import React, { useRef } from 'react';
import './index.less';

import cs from 'classnames';
import { useTranslation } from '@ies/intl-react-plugin';
import { Empty } from '@byted/serial-components-i18n';

import Header from '@components/header';
import Loading from '@components/loading';
import Footer from '@components/footer';
import { useHideNavValue } from '@utils/hooks';

import { loadSSRData } from '../data';
import { usePostPage } from '../use-post-page';

import ArticleContent from '@components/article-content';
import { HeaderTheme } from '@constants/enum';
import { Tea } from '@utils/tea';

const PostPC = () => {
    const { post, articleContent, id } = usePostPage();
    const { serverInited } = post;
    const hideNav = useHideNavValue();
    const { i18n } = useTranslation();

    const hasScrollEndOnce = useRef(false);

    if (!serverInited) {
        return <Loading className="article-loading" />;
    }

    return (
        <div id="post" className={cs('post', hideNav && 'hide-nav')}>
            {!hideNav && <Header theme={HeaderTheme.Picture} />}
            {articleContent.content ? (
                <ArticleContent
                    data={articleContent}
                    needDir
                    onScrollEnd={() => {
                        if (!hasScrollEndOnce.current) {
                            hasScrollEndOnce.current = true;
                            Tea('read_post', { category_name: post.category_name, postId: id });
                        }
                    }}
                />
            ) : (
                <Empty description={i18n.t('page_not_accessible')} />
            )}
            {!hideNav && <Footer />}
        </div>
    );
};

PostPC.loadData = loadSSRData;

export default PostPC;
