import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '@ies/intl-react-plugin';
import { Button, Message, Radio, Select } from '@byted/serial-components-i18n';
import { getConfigs, postFollowAuthor, setUserInfo } from '@service/api';
import { hasEmoji, useUserInfo, Tea, hasSetUserName, useGoAdmin } from '@utils/index';
import { updateUser } from '../user';
import Input from './input';
import { ErrorCode, Events, RegsiterStatus } from './constants';
import I18n from '@ies/starling_intl';
import keyBy from 'lodash/keyBy';
import { MessageText } from '@constants/value';
import { LangCode } from '@common/lang';
import { FollowOperate } from '@typings/api/novel/toutiao_muye_overseas_homeapi';
import { Content } from '../../constants/reg';

export enum AuditType {
    name = 1,
    avatar = 2,
}

type AddNameProps = {
    extra: {
        type: string;
    };
};

interface ChannelItem {
    label: string;
    value: string;
    has_detail: '0' | '1';
    placeholder: string;
    region: LangCode | 'all';
}

export const FORUM_OFFICIAL_ID = '7145320853956789249';

const CHANNEL_KEY = 'fizzo_account_channel_all';

const experienceList = [
    { label: 'sso_information_experience_yes', value: 'yes' },
    { label: 'sso_information_experience_no', value: 'no' },
];

enum ExperienceEnum {
    no = 'no',
    yes = 'yes',
}

enum ExperienceCodeEnum {
    no = 0,
    yes = 1,
}

const ExperienceMap = {
    [ExperienceEnum.no]: ExperienceCodeEnum.no,
    [ExperienceEnum.yes]: ExperienceCodeEnum.yes,
};

const AddName: React.FC<AddNameProps> = ({ extra }) => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { id: uid, name: userName } = useUserInfo();

    const [name, setName] = useState(hasSetUserName(userName) ? userName : '');

    const [source, setSource] = useState('');
    const [sourceError, setSourceError] = useState(false);
    const [sourceDetail, setSourceDetail] = useState<Record<string, string>>({});
    const [showLimit, setShowLimit] = useState<Record<string, boolean>>({});
    const [sourceVaild, setSourceVaild] = useState<Record<string, boolean>>({});

    const [experience, SetExperience] = useState('');
    const [experienceError, setExperienceError] = useState(false);

    const [channels, setChannels] = useState<ChannelItem[]>([]);
    const [channelMap, setChannelMap] = useState<Record<string, ChannelItem>>({});

    const [errMsg, setErrMsg] = useState('');
    const [code, setCode] = useState('');
    const [codeInvalid, setCodeInvalid] = useState(false);

    const goAdmin = useGoAdmin();

    const hasSourceDetail = Number(channelMap[source]?.has_detail);

    const { type } = extra || { type: '' };

    const changeName = (value: string) => {
        setName(value);
        isNameVaild(value);
    };

    const isNameVaild = (value?: string) => {
        const len = (value || name).trim().length;
        if (len < 1 || len > 32) {
            setErrMsg(i18n.t('sso_username_default'));
            return false;
        }

        if (hasEmoji(name)) {
            setErrMsg(i18n.t('sso_username_emoji'));
            return false;
        }

        if (!Content.test(name)) {
            setErrMsg(i18n.t('toast_create_writer_name'));
            return false;
        }

        setErrMsg('');
        return true;
    };
    const changeCode = (value: string) => {
        setCode(value);
        isCodeValid(value);
    };

    const isCodeValid = (value: string = code) => {
        if (value.length && !/[a-zA-Z0-9]{6}/.test(value)) {
            setCodeInvalid(true);
        } else if (codeInvalid) {
            setCodeInvalid(false);
        }
    };

    const isSourceVaild = (value?: string) => {
        const detail = value || sourceDetail[source];
        if (hasSourceDetail && (detail?.length < 1 || detail?.length > 70 || !detail)) {
            setSourceVaild({ ...sourceVaild, [source]: false });
            return;
        }
        setSourceVaild({ ...sourceVaild, [source]: true });
    };

    const handleSetName = async () => {
        if (!isNameVaild()) {
            return;
        }
        Tea('writer_signup', {
            button_click: type,
            user_id: uid,
            is_success: 1,
        });
        const params: any = {
            name: name.trim(),
            source: Number(source),
            lang: I18n.language,
        };
        if (code.length) {
            params.referral_code = code;
        }
        params.source = source;
        params.source_detail = sourceDetail[source]?.trim();
        params.has_experience = ExperienceMap[experience];
        try {
            const resp = await setUserInfo(params);
            switch (resp.code) {
                case 0: {
                    const { audit_fails: fails } = resp.data;
                    if (fails?.includes(AuditType.name)) {
                        setErrMsg(i18n.t('profile_name_failed'));
                        break;
                    }

                    await updateUser(dispatch);
                    await postFollowAuthor({
                        media_id: FORUM_OFFICIAL_ID,
                        operate: FollowOperate.FOLLOW,
                    });
                    goAdmin();

                    // 业务埋点
                    Tea(Events.WriterSignupInformation, {
                        is_success: RegsiterStatus.Success,
                        is_experienced: ExperienceMap[experience],
                    });
                    break;
                }
                case ErrorCode.UserNameExist:
                    setErrMsg(i18n.t('sso_username_exist'));
                    Tea(Events.WriterSignupInformation, {
                        is_success: RegsiterStatus.Failed,
                        is_experienced: ExperienceMap[experience],
                    });
                    break;
                default:
                    setErrMsg(resp.message);
                    Tea(Events.WriterSignupInformation, {
                        is_success: RegsiterStatus.Failed,
                        is_experienced: ExperienceMap[experience],
                    });
                    break;
            }
        } catch (e) {
            console.log('account::setUserInfo', e);
        }
    };

    const setSourceDetailValue = v => {
        isSourceVaild(v);
        setSourceDetail({ ...sourceDetail, [source]: v });
        if (v.length) {
            setShowLimit({ ...showLimit, [source]: true });
        } else {
            setShowLimit({ ...showLimit, [source]: false });
        }
    };

    const getChannels = async () => {
        try {
            const resp: any = await getConfigs({ keys: CHANNEL_KEY });
            if (resp.code) {
                throw Error();
            }
            const data: ChannelItem[] = resp.data[CHANNEL_KEY].data;
            if (data) {
                data.push({
                    label: 'sso_information_other',
                    value: '7',
                    has_detail: '1',
                    placeholder: 'sso_information_othernote',
                    region: 'all',
                });
            }
            let dataInRegion = data.filter(item => item.region === i18n.language || item.region === 'all');
            if (!dataInRegion.length) {
                dataInRegion = data;
            }
            setChannelMap(keyBy(dataInRegion, 'value'));
            setChannels(dataInRegion);
        } catch (e) {
            Message.error(MessageText.netError);
        }
    };

    const renderSourceDetail = () => {
        const channel = channelMap[source];
        const { placeholder } = channel || {};
        if (hasSourceDetail) {
            const error = !sourceVaild[source] && sourceVaild[source] !== undefined;
            return (
                <Input
                    value={sourceDetail[source]}
                    hideValidation={!error}
                    placeholder={i18n.t(placeholder, undefined, placeholder)}
                    validation={i18n.t('sso_information_numbernote')}
                    onChange={v => setSourceDetailValue(v)}
                    onBlur={() => isSourceVaild()}
                    showWordLimit={showLimit[source]}
                    maxLength={70}
                />
            );
        }
        return null;
    };

    useEffect(() => {
        getChannels();
    }, []);

    return (
        <div className="add-name">
            <h1>{i18n.t('sso_information_welcome')}</h1>
            <h6>1. {i18n.t('sso_information_name')}</h6>
            <Input
                value={name}
                onChange={changeName}
                onBlur={() => isNameVaild()}
                validation={errMsg}
                placeholder={i18n.t('sso_username_default')}
            />
            <h6>2. {i18n.t('sso_information_channel')}</h6>
            <div className="account-select-wrap">
                <Select
                    error={sourceError}
                    className="account-select"
                    value={source || undefined}
                    placeholder="Please select a method"
                    options={channels.map(item => ({
                        label: i18n.t(item.label, undefined, item.label),
                        value: item.value,
                    }))}
                    onBlur={() => {
                        if (!source) {
                            setSourceError(true);
                        }
                    }}
                    onChange={(v: string) => {
                        setSourceError(false);
                        setSource(v);
                    }}
                />
                {sourceError && <div className="account-input-valid">{i18n.t('sso_information_smnote')}</div>}
            </div>
            {renderSourceDetail()}
            <h6>3. {i18n.t('sso_information_experience')}</h6>
            <div className="account-select-wrap">
                <Select
                    error={experienceError}
                    className="account-select"
                    value={experience || undefined}
                    placeholder={i18n.t('sso_information_experience_select')}
                    options={experienceList.map(item => ({
                        label: i18n.t(item.label, undefined, item.label),
                        value: item.value,
                    }))}
                    onBlur={() => {
                        if (!experience) {
                            setExperienceError(true);
                        }
                    }}
                    onChange={(v: string) => {
                        setExperienceError(false);
                        SetExperience(v);
                    }}
                />
                {experienceError && (
                    <div className="account-input-valid">{i18n.t('sso_information_experience_tip')}</div>
                )}
            </div>
            <h6 className="add-name-optional">4. {i18n.t('referral_referralcode')}</h6>
            <Input
                value={code}
                maxLength={6}
                placeholder={i18n.t('referral_enterreferralcode')}
                onChange={changeCode}
                onBlur={() => isCodeValid()}
                validation={codeInvalid ? i18n.t('referral_note') : undefined}
            />
            <Button
                className="account-button"
                onClick={handleSetName}
                disabled={
                    !name.trim().length ||
                    Boolean(errMsg) ||
                    !source ||
                    (hasSourceDetail && !sourceVaild[source]) ||
                    codeInvalid ||
                    !experience
                }
                autoLoading
            >
                {i18n.t('sso_start_btn')}
            </Button>
        </div>
    );
};

export default AddName;
