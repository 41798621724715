import React from 'react';
import { Button } from '@byted/serial-components-i18n';
import { useGoBack } from '@utils/hooks';
import Icon from '@components/icon';

import './index.less';
import './index.h5.less';

export interface BackPageProps {
    text: React.ReactNode;
    extra?: React.ReactNode;
    rightExtra?: React.ReactNode;
    className?: string;
    onBack?: () => void;
}

export default function BackPage(props: BackPageProps) {
    const { onBack, text, extra, rightExtra, className = '' } = props;
    const goBack = useGoBack();

    const handleBack = () => {
        if (onBack) {
            onBack();
            return;
        }
        goBack();
    };
    return (
        <div className={`common-back-page ${className}`}>
            <Button className="common-back-page-btn" type="text" onClick={handleBack}>
                <Icon type="left" style={{ fontSize: 22 }} />
                <span className="common-back-page-text">{text}</span>
            </Button>
            {extra && <span className="common-back-page-extra">{extra}</span>}
            {rightExtra && <span className="common-back-page-right-extra">{rightExtra}</span>}
        </div>
    );
}
