// @ts-nocheck
/* eslint-disable */
/* tslint:disable */

import * as base from '../base';
import * as toutiao_novel_apigateway_common from '../novel/toutiao_novel_apigateway_common';
import * as origin_overseas_base from './origin_overseas_base';

export enum HfcCategoryRank {
    /** 一级分类 */
    FIRST_CATEGORY = 1,
    /** 二级分类 */
    SECONDARY_CATEGORY = 2,
    /** 三级分类 */
    THIRD_CATEGORY = 3,
}

export enum BannerType {
    HOME_PAGE_BANNER = 0,
    FORUM_BANNER = 1,
}

export enum ChapterListOrder {
    /** 正向 */
    POSTIVE = 0,
    /** 逆向 */
    NEGATIVE = 1,
}

/** 评论标签类型 */
export enum CommentLabelType {
    /** 置顶标签 */
    PINNED = 0,
    /** 官方标签 */
    OFFICIAL = 1,
}

/** 评论排序类型 */
export enum CommentSortType {
    /** 最热 */
    HOTTEST = 0,
    /** 最新 */
    NEWEST = 1,
}

/** 评论筛选时间范围 */
export enum CommentTimeRange {
    /** 全部时间 */
    ALLTIME = 0,
    /** 最近24小时 */
    LAST24HOURS = 1,
    /** 最近7天 */
    LAST7DAYS = 2,
    /** 最近30天 */
    LAST30DAYS = 3,
}

export enum DiggOperate {
    /** 点赞 */
    DIGG = 0,
    /** 取消点赞 */
    UNDIGG = 1,
}

export enum FollowOperate {
    /** 关注 */
    FOLLOW = 0,
    /** 取消关注 */
    UNFOLLOW = 1,
}

export enum InteractiveType {
    /** 发布 */
    POST = 0,
    /** 收藏 */
    Collect = 1,
    /** 点赞 */
    Like = 2,
}

export enum FollowRelationType {
    /** 正在关注 */
    Following = 0,
    /** 被关注 */
    Followers = 1,
    /** 互关 */
    Friends = 2,
}

/** 通用排序类型 */
export enum SortType {
    /** 最热 */
    HOTTEST = 0,
    /** 最新 */
    NEWEST = 1,
}

export enum PostFilterType {
    /** 全部 */
    All = 0,
    /** 个人 */
    OnlyMe = 1,
}

/** 帖子标签类型 */
export enum PostLabelType {
    /** 置顶标签 */
    PINNED = 0,
    /** hot标签 */
    HOT = 1,
}

/** 论坛帖子类型: */
export enum ForumMsgType {
    /** 帖子审核通知 */
    PostAudit = 1,
    /** 帖子处理通知 */
    PostProcress = 2,
    /** 帖子评论通知 */
    PostComment = 3,
}

export enum CollectOperate {
    /** 收藏 */
    COLLECT = 0,
    /** 取消收藏 */
    UNCOLLECT = 1,
}

export interface BookList {
    /** 书籍ID */
    book_id: string;
    /** 书籍名称 */
    book_name: string;
    /** 书籍封面URI（不带域名） */
    thumb_uri: string;
    /** 书籍上下架状态 1:上架 2:待上架 3:下架 */
    status: number;
    /** 书籍总字数 */
    word_number: number;
    /** 书籍章节总数 */
    chapter_number: number;
    /** 完结状态 0:已完结 1:连载中 */
    creation_status: number;
    /** 签约状态 0:未签约 1:已签约 */
    authorize_type: number;
    /** 最近更新时间（时间戳） */
    last_chapter_time: string;
    /** 最新章节目录标题 */
    last_chapter_title: string;
    /** 签约流程： 0:未签约；1:签约中 2:签约失败 3:签约成功 */
    sign_progress: number;
    /** 是否可以申请签约 0:否；1:是 */
    can_apply_signing: number;
    /** 书籍审核状态 1:待审核 2:审核未通过 3:修改审核未通过 4:审核通过 5:修改审核中 */
    verify_status: number;
    /** 预期写作字数  0: 未设置; 1: Under 100k; 101: 101k-200k; 201: 201k-300k; 301: 301k-400k; 401: 401k-500k; 501: 501k-600k; 601: 601k-700k; 701: Over 700k */
    expected_words?: number;
    contest?: string;
    platform?: number;
    /** 透传合同表状态 -1: 合同不存在 0: 合同初始化 1:合同签约完成 2: 合同已终止 3: 合同草稿态 */
    origin_contract_status?: number;
    delivery_appid?: Array<string>;
}

export interface BookAbstract {
    /** 书籍ID */
    book_id: string;
    /** 书籍名称 */
    book_name: string;
}

export interface GetAnnouncementListRequest {
    /** 页号 */
    page_index: number;
    /** 分页大小 */
    page_count: number;
    /** 公告类型 0：ALL， 1：News 2：Writing Contests 3：Product Updates 4：Interviews with Authors */
    announcement_type: origin_overseas_base.AnnouncementType;
    language?: string;
    clear_event?: origin_overseas_base.ClearEvent;
    base?: base.Base;
}

export interface Announcement {
    /** 标题 */
    title: string;
    /** 链接 */
    link_url: string;
    /** 发布时间 */
    announcement_time: string;
    /** 是否是热点 */
    is_hot: number;
    /** 公告类型 0：ALL， 1：News 2：Writing Contests 3：Product Updates 4：Interviews with Authors */
    announcement_type: origin_overseas_base.AnnouncementType;
    /** 简介 */
    abstract: string;
    event_read_status?: origin_overseas_base.EventReadStatus;
    top?: origin_overseas_base.AnnouncementTop;
}

export interface GetAnnouncementListRespData {
    /** 总数 */
    total_count: number;
    /** 公告列表 */
    announcement_list: Array<Announcement>;
}

export interface GetAnnouncementListResponse {
    data: GetAnnouncementListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetHomeBookListRequest {
    /** 页号 */
    page_index: number;
    /** 分页大小 */
    page_count: number;
    base?: base.Base;
}

export interface GetHomeBookListRespData {
    /** 总数 */
    total_count: number;
    /** 书籍列表 */
    book_list: Array<BookList>;
}

export interface GetHomeBookListResponse {
    data: GetHomeBookListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetAuthorAbstractRequest {
    base?: base.Base;
}

export interface GetAuthorAbstractRespData {
    /** 入住天数 */
    checkin_days: number;
    /** 邮箱 */
    email: string;
    /** 是否有绑定的编辑 */
    have_bound_editor?: boolean;
    /** 作品语言 */
    language?: string;
    /** 笔名审核状态 0: 默认状态 1:审核已通过 2:审核总 3:审核失败 4: Robot Up */
    pen_name_verify_status?: number;
    /** 作者入驻引导 */
    author_settled_guide?: { [key: number]: number };
    /** 注册天数 */
    create_days?: number;
    /** 终止状态 */
    sign_terminate_status?: origin_overseas_base.SignTerminateStatus;
    /** 归档的西葡书 */
    achieved_es_books?: Array<BookAbstract>;
    /** 是否有ai生成能力 */
    ai_generate_ability?: boolean;
    /** 监护人邮箱 */
    guardian_email?: string;
    /** 作者风险等级 */
    risk_level?: origin_overseas_base.AuthorRiskLevel;
    /** ai能力 */
    ai_ability_map?: { [key: number]: boolean };
    /** 签约状态。1-已签约 */
    authorize_type?: number;
    /** 实验待确认书本 */
    experiment_result_produce_book?: Array<BookAbstract>;
}

export interface GetAuthorAbstractResponse {
    data: GetAuthorAbstractRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetCreationClassListRequest {
    /** 页号 */
    page_index: number;
    /** 分页大小 */
    page_count: number;
    /** 语言 */
    language?: string;
    /** 专区 */
    creation_zone?: origin_overseas_base.CreationZone;
    clear_event?: origin_overseas_base.ClearEvent;
    /** 课堂二级分类，选课堂时，不传该字段为一级分类下所有 */
    academy_sub_tab?: string;
    /** 排序类型 */
    sort_type?: SortType;
    base?: base.Base;
}

export interface CreationClass {
    title: string;
    desc: string;
    link_url: string;
    thumb_uri: string;
    /** 是否视频 */
    class_content_type?: origin_overseas_base.ClassContentType;
    /** 发布时间 */
    publish_time?: number;
    event_read_status?: origin_overseas_base.EventReadStatus;
    /** 专区 */
    creation_zone?: origin_overseas_base.CreationZone;
    /** 点赞数量 */
    digg_count?: number;
    /** 评论数 */
    comment_count?: number;
}

export interface GetCreationClassListRespData {
    /** 总数 */
    total_count: number;
    /** 书籍列表 */
    creation_class_list: Array<CreationClass>;
}

export interface GetCreationClassListResponse {
    data: GetCreationClassListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetCreationZoneListRequest {
    language: string;
    base?: base.Base;
}

export interface GetCreationZoneListRespData {
    creation_zone_list: Array<origin_overseas_base.CreationZone>;
    /** 课堂分类，一级分类以及下面的二级分类的key */
    category_map?: { [key: number]: Array<string> };
}

export interface GetCreationZoneListResponse {
    data: GetCreationZoneListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetClassRecommendRequest {
    /** 语言 */
    language: string;
    /** 当前课堂详情页ID */
    item_id: string;
    base?: base.Base;
}

export interface GetClassRecommendRespData {
    /** 课堂列表 */
    creation_class_list: Array<CreationClass>;
    /** 当前课堂专区 */
    creation_zone: origin_overseas_base.CreationZone;
    /** 当前课堂二级分类 */
    academy_sub_tab?: string;
}

export interface GetClassRecommendResponse {
    data: GetClassRecommendRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface ClassReadEventRequest {
    /** 语言 */
    language: string;
    /** 当前课堂详情页ID */
    item_id: string;
    base?: base.Base;
}

export interface ClassReadEventResponse {
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface ModifyAuthorAttrRequest {
    /** 作家语言 */
    language?: string;
    base?: base.Base;
}

export interface ModifyAuthorAttrResponse {
    lang?: string;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetUnixTimeStampRequest {
    base?: base.Base;
}

export interface GetUnixTimeStampRespData {
    /** unix 时间戳 */
    time_stamp: string;
}

export interface GetUnixTimeStampResponse {
    data: GetUnixTimeStampRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface KeepEmailRequest {
    /** email */
    email: string;
    /** language */
    language: string;
    base?: base.Base;
}

export interface KeepEmailResponse {
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface Activity {
    id: string;
    name: string;
    description: string;
    bonus: string;
    activity_link: string;
    picture_link: string;
    start_time: number;
    end_time: number;
    time_status: origin_overseas_base.ActivityTimeStatus;
    event_read_status?: origin_overseas_base.EventReadStatus;
}

export interface GetActivityListRequest {
    page_index: number;
    page_count: number;
    language: string;
    time_status?: origin_overseas_base.ActivityTimeStatus;
    clear_event?: origin_overseas_base.ClearEvent;
    base?: base.Base;
}

export interface GetActivityListRespData {
    total_count: number;
    activity_list: Array<Activity>;
}

export interface GetActivityListResponse {
    data: GetActivityListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetDetailPageRequest {
    item_id: string;
    Base?: base.Base;
}

export interface GetDetailPageRespData {
    item_id: string;
    title?: string;
    content?: string;
    create_time?: number;
    modify_time?: number;
    /** 是否开启评论，0：不开启，1：开启 */
    comment_open?: number;
    /** 点赞数量 */
    digg_count?: number;
    /** 发帖者信息 */
    post_user?: PostUserInfo;
    /** 详情页类型 */
    type?: origin_overseas_base.I18nDetailPageType;
    /** 是否已经评分过 */
    has_score?: boolean;
}

export interface GetDetailPageResponse {
    data: GetDetailPageRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface DetailPageDiggRequest {
    item_id: string;
    operate: DiggOperate;
    base?: base.Base;
}

export interface DetailPageDiggResponse {
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface DetailPageScoreRequest {
    item_id: string;
    score: number;
    reason?: string;
    suggestion?: string;
    base?: base.Base;
}

export interface DetailPageScoreResponse {
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetHomeEntranceConfigRequest {
    language: string;
    entrance: origin_overseas_base.HomeEntrance;
    base?: base.Base;
}

export interface HomeEntranceConfig {
    /** 0-inactive，1-active */
    status: number;
    link: string;
}

export interface GetHomeEntranceConfigResponse {
    data: HomeEntranceConfig;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetHfcCategoryRequest {
    language: string;
    base?: base.Base;
}

export interface HfcCategoryNode {
    category_id: string;
    parent_id: string;
    index: number;
    rank: HfcCategoryRank;
    title: string;
    language: string;
    /** 8: optional string ArticleTitle (agw.key = "article_title")  国内当时启用了这个字段，具体原因记不得了 */
    child_category?: Array<HfcCategoryNode>;
}

export interface GetHfcCategoryRespData {
    category_list?: Array<HfcCategoryNode>;
}

export interface GetHfcCategoryResponse {
    data: GetHfcCategoryRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetHfcArticleRequest {
    item_id?: string;
    category_id?: string;
    base?: base.Base;
}

export interface HfcArticleInfo {
    item_id: string;
    category_id: string;
    title: string;
    content: string;
    modify_time: string;
    language: string;
}

export interface GetHfcArticleResponse {
    data: HfcArticleInfo;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface SearchHfcArticleRequest {
    page_index: number;
    page_count: number;
    query: string;
    language: string;
    base?: base.Base;
}

export interface SearchHfcArticleInfo {
    status: number;
    item_id: string;
    category_id: string;
    title_highlight: string;
    content_highlight: string;
    language: string;
    parent_category: { [key: string]: string };
}

export interface SearchHfcArticleRespData {
    total: number;
    search_list?: Array<SearchHfcArticleInfo>;
}

export interface SearchHfcArticleResponse {
    data: SearchHfcArticleRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetCommonConfigRequest {
    /** 后台 admin_config 的key */
    config_key: string;
    base?: base.Base;
}

export interface CommonConfig {
    content: string;
}

export interface GetCommonConfigResponse {
    data: CommonConfig;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetEventCountRequest {
    event_type: origin_overseas_base.EventType;
    /** 语言 */
    language: string;
    /** xxx,xxx,xxx */
    event_type_list?: string;
    base?: base.Base;
}

export interface EventData {
    event_type: origin_overseas_base.EventType;
    /** 未读消息数量 */
    count: number;
    /** 副标题 */
    cover_message: string;
    /** 最新更新时间 */
    update_time: number;
    /** 未读消息数涉及的书本数量(评论相关) */
    comment_book_count?: number;
}

export interface GetEventCountRespData {
    total: number;
    event_data: Array<EventData>;
}

export interface GetEventCountResponse {
    data: GetEventCountRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetBannerListRequest {
    /** 页号 */
    page_index: number;
    /** 分页大小 */
    page_count: number;
    /** 语言 */
    language: string;
    /** banner类型 */
    banner_type?: BannerType;
    base?: base.Base;
}

export interface Banner {
    link: string;
    uri: string;
}

export interface GetBannerListRespData {
    total: number;
    banner_list: Array<Banner>;
}

export interface GetBannerListResponse {
    data: GetBannerListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetSuspensionBarListRequest {
    /** 语言 id,es,pt,en */
    language: string;
    base?: base.Base;
}

export interface SuspensionBar {
    title: string;
    content: string;
    /** 前端的展示条件，透传编辑后台配置，eg：["book_guide_step","12"] */
    condition?: Array<string>;
}

export interface GetSuspensionBarListRespData {
    total: number;
    suspension_bar_list?: Array<SuspensionBar>;
}

export interface GetSuspensionBarListResponse {
    data: GetSuspensionBarListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetCommentBookListRequest {
    /** 页号 */
    page_index: number;
    /** 分页大小 */
    page_count: number;
    comment_type: origin_overseas_base.EventType;
    base?: base.Base;
}

export interface CommentBook {
    book_id: string;
    book_name: string;
    thumb_url: string;
    unread_count: number;
    total_chapter?: number;
    thumb_uri?: string;
    speak_total_chapter?: number;
}

export interface GetCommentBookListRespData {
    total: number;
    book_list: Array<CommentBook>;
}

export interface GetCommentBookListResponse {
    data?: GetCommentBookListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetCommentChapterListRequest {
    /** 书本ID */
    book_id: string;
    /** 页号 */
    page_index: number;
    /** 分页大小 */
    page_count: number;
    /** 顺序: 0 正向 1 逆向 */
    order: ChapterListOrder;
    /** 如果是有话说章节列表传入6 */
    comment_event_type?: origin_overseas_base.EventType;
    base?: base.Base;
}

export interface CommentChapter {
    item_id: string;
    title: string;
    /** 有话说评论管理需要 */
    unread_count?: number;
    /** 有话说评论管理需要 */
    speak_id?: string;
    /** 章节序号 */
    chapter_no?: number;
}

export interface GetCommentChapterListRespData {
    total: number;
    chapter_list: Array<CommentChapter>;
}

export interface GetCommentChapterListResponse {
    data?: GetCommentChapterListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface CommentUserInfo {
    /** 评论者UID */
    user_id: string;
    /** 评论者Name */
    user_name: string;
    /** 评论者头像url */
    avatar_url: string;
    /** 评论者是否是作者 */
    is_author: boolean;
}

export interface CommentInfo {
    /** 评论ID */
    comment_id: string;
    /** 书本ID */
    book_id: string;
    /** 章节ID */
    item_id: string;
    /** 评论者信息 */
    comment_user: CommentUserInfo;
    /** 评论时间 */
    create_time: string;
    /** 评论内容 */
    text: string;
    /** 点赞数量 */
    digg_count: number;
    /** 作者是否点赞 */
    author_digg: boolean;
    /** 二级评论回复数 */
    reply_count: number;
    /** 回复列表(默认只携最新的一条) */
    reply_list?: Array<ReplyCommentInfo>;
    /** 书评-评分 */
    score?: number;
    /** 章节序号 */
    chapter_no?: number;
    /** 章节标题 */
    chapter_title?: string;
    /** 标签列表 */
    label_list?: Array<CommentLabelType>;
}

export interface GetBookCommentListRequest {
    book_id: string;
    /** 页号 */
    page_index: number;
    /** 分页大小 */
    page_count: number;
    /** 评论排序类型 */
    sort_type: CommentSortType;
    /** 评论筛选时间范围 */
    time_range: CommentTimeRange;
    clear_event?: origin_overseas_base.ClearEvent;
    base?: base.Base;
}

export interface GetBookCommentListRespData {
    total: number;
    comment_list: Array<CommentInfo>;
}

export interface GetBookCommentListResponse {
    data?: GetBookCommentListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetAuthorBookCommentRequest {
    book_id: string;
    base?: base.Base;
}

export interface GetAuthorBookCommentRespData {
    comment_info: CommentInfo;
}

export interface GetAuthorBookCommentResponse {
    data?: GetAuthorBookCommentRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetChapterCommentListRequest {
    book_id: string;
    /** 选择All Chapter时，传递0或不传递即可 */
    item_id?: string;
    /** 页号 */
    page_index: number;
    /** 分页大小 */
    page_count: number;
    /** 评论排序类型 */
    sort_type: CommentSortType;
    /** 评论筛选时间范围 */
    time_range: CommentTimeRange;
    clear_event?: origin_overseas_base.ClearEvent;
    base?: base.Base;
}

export interface GetChapterCommentListRespData {
    total: number;
    comment_list: Array<CommentInfo>;
    pre_item?: CommentChapter;
    next_item?: CommentChapter;
}

export interface GetChapterCommentListResponse {
    data?: GetChapterCommentListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetSpeakCommentListRequest {
    book_id: string;
    /** 有话说id */
    speak_id?: string;
    /** 页号 */
    page_index: number;
    /** 分页大小 */
    page_count: number;
    /** 评论排序类型 */
    sort_type: CommentSortType;
    /** 评论筛选时间范围 */
    time_range: CommentTimeRange;
    clear_event?: origin_overseas_base.ClearEvent;
    base?: base.Base;
}

export interface GetSpeakCommentListRespData {
    total: number;
    /** 有话说内容 */
    speak_content: string;
    /** 有话说ID */
    speak_id: string;
    comment_list: Array<CommentInfo>;
    item_id: string;
    chapter_title: string;
    speak_status: origin_overseas_base.AuthorSpeakStatus;
}

export interface GetSpeakCommentListResponse {
    data?: GetSpeakCommentListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetReplyCommentListRequest {
    comment_id: string;
    offset: number;
    /** 一次最多50条 */
    count: number;
    base?: base.Base;
}

export interface ReplyCommentInfo {
    /** 回复ID */
    reply_id: string;
    /** 二级评论所属的一级评论ID */
    parent_comment_id: string;
    /** 回复某条评论/回复的ID */
    to_reply_id?: string;
    book_id: string;
    item_id: string;
    create_time: string;
    text: string;
    digg_count: number;
    author_digg: boolean;
    /** 回复的回复总数 */
    reply_count: number;
    /** 回复者信息 */
    comment_user: CommentUserInfo;
    /** 被回复者信息 */
    reply_to_user?: CommentUserInfo;
    /** 标签列表 */
    label_list?: Array<CommentLabelType>;
}

export interface GetReplyCommentListRespData {
    total: number;
    reply_list: Array<ReplyCommentInfo>;
    has_more: boolean;
    next_offset?: number;
}

export interface GetReplyCommentListResponse {
    data: GetReplyCommentListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface CommentPublishRequest {
    comment_type: origin_overseas_base.EventType;
    book_id?: string;
    item_id?: string;
    /** 书评-评分 */
    score?: number;
    /** 评论内容 */
    text?: string;
    /** 增加支持有话说评论发布 */
    speak_id?: string;
    /** 增加支持帖子评论发布 */
    post_id?: string;
    base?: base.Base;
}

export interface CommentPublishRespData {
    comment_id: string;
}

export interface CommentPublishResponse {
    data?: CommentPublishRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface CommentReplyRequest {
    reply_text: string;
    /** 回复的一级ID */
    comment_id: string;
    /** 回复别人的回复需传 */
    to_reply_id?: string;
    comment_type?: origin_overseas_base.EventType;
    /** comment_type=post时，传帖子id */
    item_id?: string;
    base?: base.Base;
}

export interface CommentReplyRespData {
    reply_id: string;
}

export interface CommentReplyResponse {
    data: CommentReplyRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface CommentDeleteRequest {
    comment_id: string;
    comment_type?: origin_overseas_base.EventType;
    item_id?: string;
    base?: base.Base;
}

export interface CommentDeleteResponse {
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface CommentDiggRequest {
    comment_id: string;
    operate: DiggOperate;
    base?: base.Base;
}

export interface CommentDiggResponse {
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface CommentReportTypeListRequest {
    /** en, id, es, pt */
    language: string;
    base?: base.Base;
}

export interface CommentReportInfo {
    report_reason: string;
}

export interface CommentReportTypeListRespData {
    report_list: Array<CommentReportInfo>;
}

export interface CommentReportTypeListResponse {
    data: CommentReportTypeListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface CommentReportRequest {
    comment_id: string;
    report_reason: string;
    advice?: string;
    comment_type: origin_overseas_base.EventType;
    base?: base.Base;
}

export interface CommentReportResponse {
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface CommentUpdateRequest {
    /** 评论ID */
    comment_id: string;
    /** 评分 */
    score?: number;
    /** 评论 */
    text?: string;
    base?: base.Base;
}

export interface CommentUpdateRespData {
    comment_id: string;
}

export interface CommentUpdateResponse {
    data: CommentUpdateRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetItemCommentListRequest {
    /** 详情页ID */
    item_id: string;
    /** 页号 */
    page_index: number;
    /** 分页大小 */
    page_count: number;
    /** 评论排序类型 */
    sort_type: CommentSortType;
    base?: base.Base;
}

export interface GetItemCommentListRespData {
    total: number;
    comment_list: Array<CommentInfo>;
    /** 自己是否给详情页点赞，前端建议加在这里 */
    my_digg?: boolean;
    has_more?: boolean;
}

export interface GetItemCommentListResponse {
    /** data */
    data?: GetItemCommentListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetPostCommentListRequest {
    /** 帖子ID */
    post_id: string;
    /** 页号 */
    page_index: number;
    /** 分页大小 */
    page_count: number;
    /** 评论排序类型 */
    sort_type: CommentSortType;
    base?: base.Base;
}

export interface GetPostCommentListRespData {
    total: number;
    comment_list: Array<CommentInfo>;
    /** 自己是否给帖子点赞，前端建议加在这里 */
    my_digg: boolean;
    has_more: boolean;
    /** 自己是否收藏帖子，前端建议加在这里 */
    my_collect?: boolean;
}

export interface GetPostCommentListResponse {
    /** data */
    data?: GetPostCommentListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface MarkAuthorGuideRequest {
    guide_field: origin_overseas_base.AuthorGuideFields;
    base?: base.Base;
}

export interface MarkAuthorGuideResponse {
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetAuthorMedalsRequest {
    clear_event: origin_overseas_base.ClearEvent;
    base?: base.Base;
}

export interface AuthorMedal {
    medal_type: origin_overseas_base.MedalType;
    /** 获取时间 0-未获取 */
    award_time: number;
    event_read_status: origin_overseas_base.EventReadStatus;
}

/** 手动发放勋章 */
export interface ManualMedal {
    medal_key: string;
    title: string;
    description: string;
    cover_url: string;
    /** 获取时间 0-未获取 */
    award_time: number;
    event_read_status: origin_overseas_base.EventReadStatus;
}

export interface GetAuthorMedalsResponse {
    /** 勋章列表 */
    data: Array<AuthorMedal>;
    code: number;
    log_id: string;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetAuthorMedalsResponseV1Data {
    /** 勋章列表 */
    author_medal: Array<AuthorMedal>;
    /** 手动勋章 */
    manual_medal: Array<ManualMedal>;
}

export interface GetAuthorMedalsV1Response {
    data: GetAuthorMedalsResponseV1Data;
    code: number;
    log_id: string;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetMedalPosterRequest {
    medal_type: origin_overseas_base.MedalType;
    title: string;
    description: string;
    cover_url: string;
    award_time: string;
    /** Name */
    username?: string;
    /** 头像url */
    avatar_url?: string;
    base?: base.Base;
}

export interface PosterInfo {
    poster_url: string;
}

export interface GetMedalPosterResponse {
    /** 海报信息 */
    data: PosterInfo;
    code: number;
    log_id: string;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetMedalGuideRequest {
    base?: base.Base;
}

export interface MedalGuideMeta {
    medal_type?: origin_overseas_base.MedalType;
    target_value?: number;
    current_value?: number;
    book_name?: string;
    current_medal_type?: origin_overseas_base.MedalType;
}

export interface GetMedalGuideRespData {
    medal_guide_list?: Array<MedalGuideMeta>;
}

export interface GetMedalGuideResponse {
    data: GetMedalGuideRespData;
    code: number;
    log_id: string;
    message: string;
    base_resp?: base.BaseResp;
}

export interface SignTerminateRequest {
    base?: base.Base;
}

export interface SignTerminateResponse {
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface ForumPersonalCenterRequest {
    media_id: string;
    base?: base.Base;
}

export interface ForumPrivacyInfo {
    allow_visit_post?: boolean;
    allow_visit_collect?: boolean;
    allow_visit_like?: boolean;
    allow_visit_followers?: boolean;
    allow_visit_following?: boolean;
}

export interface ForumPersonalInfo {
    media_id: string;
    author_name: string;
    author_avatar: string;
    author_intro: string;
    following_num: number;
    followers_num: number;
    friends_num: number;
    /** 作者帖子收到的点赞数量 */
    digg_num: number;
    /** 作者帖子收到的收藏数量 */
    collect_num: number;
    privacy_info: ForumPrivacyInfo;
    /** 是否已关注该作者(主态没有该字段) */
    has_followed?: boolean;
    /** 作者勋章列表 */
    author_medals?: Array<origin_overseas_base.MedalType>;
    /** 是否允许用户发帖(客态没有该字段) */
    allow_post?: boolean;
    /** 手动勋章 */
    manual_medal?: Array<ManualMedal>;
}

export interface ForumPersonalCenterResponse {
    data: ForumPersonalInfo;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface ForumPrivacySettingRequest {
    allow_visit_post?: boolean;
    allow_visit_collect?: boolean;
    allow_visit_like?: boolean;
    allow_visit_followers?: boolean;
    allow_visit_following?: boolean;
    base?: base.Base;
}

export interface ForumPrivacySettingResponse {
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface ForumAuthorFollowRequest {
    media_id: string;
    operate: FollowOperate;
    base?: base.Base;
}

export interface ForumAuthorFollowResponse {
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface ForumInteractiveRecordRequest {
    media_id: string;
    type: InteractiveType;
    /** 第一次请求为0 */
    offset: number;
    /** 一次最多50条 */
    count: number;
    base?: base.Base;
}

export interface ForumInteractiveRecordRespData {
    total_count: number;
    post_list: Array<Post>;
    next_offset: number;
    has_more: boolean;
}

export interface ForumInteractiveRecordResponse {
    data: ForumInteractiveRecordRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface ForumFollowRelationRequest {
    media_id: string;
    type: FollowRelationType;
    /** 第一次请求为0 */
    offset: number;
    count: number;
    base?: base.Base;
}

export interface FollowAuthorInfo {
    author_media_id: string;
    author_name: string;
    author_avatar: string;
    author_abstract: string;
    /** 是否关注 */
    has_followed: boolean;
    /** 客态专属（是否是访问者自身） */
    is_self: boolean;
}

export interface ForumFollowRelationRespData {
    total_count: number;
    follow_author_list: Array<FollowAuthorInfo>;
    next_offset: number;
    has_more: boolean;
}

export interface ForumFollowRelationResponse {
    data: ForumFollowRelationRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetPostListRequest {
    /** 第一次请求为0 */
    offset: number;
    /** 一次最多50条 */
    count: number;
    /** 排序类型 */
    sort_type: SortType;
    /** 获取帖子的类型 (帖子、课堂、公告、关注) */
    type?: origin_overseas_base.ForumPostType;
    /** 课堂子tab */
    academy_tab_type?: origin_overseas_base.CreationZone;
    clear_event?: origin_overseas_base.ClearEvent;
    /** 帖子子tab */
    post_tab_type?: number;
    /** 帖子筛选类型 */
    post_filter_type?: PostFilterType;
    /** 课堂二级分类，选课堂时，不传该字段为一级分类下所有 */
    academy_sub_tab?: string;
    base?: base.Base;
}

export interface PostUserInfo {
    /** 发帖者UID */
    user_id: string;
    /** 发帖者Name */
    user_name: string;
    /** 发帖者头像url */
    avatar_url: string;
    /** 是否关注作者 */
    has_followed?: boolean;
    /** 是否是作者本人(本人不展示关注按钮) */
    is_author_self?: boolean;
}

export interface Post {
    /** 帖子ID/详情页ID */
    post_id: string;
    /** 帖子标题 */
    title: string;
    /** 发帖者信息 */
    post_user: PostUserInfo;
    /** 发帖时间戳 */
    create_time: number;
    /** 点赞数量 */
    digg_count: number;
    /** 自己是否点赞 */
    my_digg: boolean;
    /** 评论数 */
    comment_count: number;
    /** 帖子封面 */
    cover_url?: string;
    /** 标签列表 */
    label_list?: Array<PostLabelType>;
    /** 类型 */
    type?: origin_overseas_base.ForumPostType;
    /** 链接 */
    link_url?: string;
    /** 0：关闭，1：开启 */
    comment_open?: number;
    /** 收藏数量 */
    collect_count?: number;
    /** 自己是否收藏 */
    my_collect?: boolean;
    /** 分类名称 */
    category_name?: string;
}

export interface GetPostListRespData {
    total_count: number;
    post_list: Array<Post>;
    next_offset: number;
    has_more: boolean;
}

export interface GetPostListResponse {
    data: GetPostListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetForumSelectedListRequest {
    base?: base.Base;
}

export interface GetForumSelectedListRespData {
    total_count: number;
    post_list: Array<Post>;
}

export interface GetForumSelectedListResponse {
    data: GetForumSelectedListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetForumMsgListRequest {
    /** 页号 */
    page_index: number;
    /** 分页大小 */
    page_count: number;
    clear_event?: origin_overseas_base.ClearEvent;
    base?: base.Base;
}

export interface ForumMsgInfo {
    msg_id: string;
    type: ForumMsgType;
    avatar_url?: string;
    reply_title?: string;
    title?: string;
    content?: string;
    post_info?: Post;
    comment_id?: string;
    duration?: string;
    /** 二级评论所属的一级评论ID, 不存在为"0" */
    parent_comment_id?: string;
    /** 上级评论内容 */
    parent_content?: string;
}

export interface GetForumMsgListRespData {
    total_count: number;
    forum_msg_list: Array<ForumMsgInfo>;
}

export interface GetForumMsgListResponse {
    data: GetForumMsgListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetPostTabListRequest {
    base?: base.Base;
}

export interface GetPostTabListRespData {
    post_tab_list: Array<origin_overseas_base.PostTab>;
    /** 可发布数 */
    available_num?: number;
}

export interface GetPostTabListResponse {
    data: GetPostTabListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface PostDiggRequest {
    post_id: string;
    operate: DiggOperate;
    base?: base.Base;
}

export interface PostDiggResponse {
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetPostDetailRequest {
    post_id: string;
    Base?: base.Base;
}

export interface GetPostDetailRespData {
    post_id: string;
    title: string;
    content: string;
    /** 发帖者信息 */
    post_user: PostUserInfo;
    create_time: number;
    /** 是否开启评论，0：不开启，1：开启 */
    comment_open: number;
    /** 点赞数量 */
    digg_count: number;
    /** 自己是否点赞 */
    my_digg: boolean;
    /** 最近修改时间 */
    modify_time: number;
    /** 是否为旧Schema格式 */
    is_old_schema?: boolean;
    /** 收藏数量 */
    collect_count?: number;
    /** 自己是否收藏 */
    my_collect?: boolean;
    /** 分类名称 */
    category_name?: string;
}

export interface GetPostDetailResponse {
    data: GetPostDetailRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface PublishPostRequest {
    title: string;
    content: string;
    /** 帖子所属tab_type */
    post_tab_type: number;
    cover_uri?: string;
    base?: base.Base;
}

export interface PublishPostRespData {
    post_id: string;
}

export interface PublishPostResponse {
    data: PublishPostRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface PostCollectRequest {
    post_id: string;
    operate: CollectOperate;
    base?: base.Base;
}

export interface PostCollectResponse {
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetInspirationCategoryRequest {
    base?: base.Base;
}

export interface GetInspirationCategoryResponse {
    data?: { [key: string]: Array<string> };
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export interface GetInspirationBookListRequest {
    /** 页号 */
    page_index: number;
    /** 分页大小 */
    page_count: number;
    /** 品类 */
    type: string;
    /** 分类 */
    category: string;
    base?: base.Base;
}

export interface InspirationBookDetail {
    /** 书号 */
    book_id: string;
    /** 跳转书号 */
    navigate_book_id: string;
    /** 书名 */
    book_name: string;
    /** 封面地址 */
    thumb_uri: string;
    /** 简介 */
    abstract: string;
    /** 完结状态 */
    creation_status: number;
    /** 历史 UV */
    history_uv: number;
    /** 展示 UV */
    display_history_uv: string;
}

export interface GetInspirationBookListRespData {
    total_count: number;
    post_list: Array<InspirationBookDetail>;
}

export interface GetInspirationBookListResponse {
    data?: GetInspirationBookListRespData;
    log_id: string;
    code: number;
    message: string;
    base_resp?: base.BaseResp;
}

export declare class OverseasHomeApiService {
    /** 主页接口 */
    public GetAnnouncementList(req: GetAnnouncementListRequest): Promise<GetAnnouncementListResponse>;

    public GetHomeBookList(req: GetHomeBookListRequest): Promise<GetHomeBookListResponse>;

    public GetAuthorAbstract(req: GetAuthorAbstractRequest): Promise<GetAuthorAbstractResponse>;

    /** 作家课堂 */
    public GetCreationClassList(req: GetCreationClassListRequest): Promise<GetCreationClassListResponse>;

    public GetCreationZoneList(req: GetCreationZoneListRequest): Promise<GetCreationZoneListResponse>;

    public GetClassRecommend(req: GetClassRecommendRequest): Promise<GetClassRecommendResponse>;

    public ClassReadEvent(req: ClassReadEventRequest): Promise<ClassReadEventResponse>;

    /** 获取unix时间戳 */
    public GetUnixTimeStamp(req: GetUnixTimeStampRequest): Promise<GetUnixTimeStampResponse>;

    /** 提供给海外作家后台的更改作者信息接口 */
    public ModifyAuthorAttr(req: ModifyAuthorAttrRequest): Promise<ModifyAuthorAttrResponse>;

    /** 首页留资入口 */
    public KeepEmail(req: KeepEmailRequest): Promise<KeepEmailResponse>;

    /** 活动列表 */
    public GetActivityList(req: GetActivityListRequest): Promise<GetActivityListResponse>;

    /** 详情页: */
    public GetDetailPage(req: GetDetailPageRequest): Promise<GetDetailPageResponse>;

    /** 详情页点赞 */
    public DetailPageDigg(req: DetailPageDiggRequest): Promise<DetailPageDiggResponse>;

    /** 详情页打分 */
    public DetailPageScore(req: DetailPageScoreRequest): Promise<DetailPageScoreResponse>;

    /** 入口配置 */
    public GetHomeEntranceConfig(req: GetHomeEntranceConfigRequest): Promise<GetHomeEntranceConfigResponse>;

    /** 帮助反馈中心 HFC */
    public GetHfcCategory(req: GetHfcCategoryRequest): Promise<GetHfcCategoryResponse>;

    public GetHfcArticle(req: GetHfcArticleRequest): Promise<GetHfcArticleResponse>;

    public SearchHfcArticle(req: SearchHfcArticleRequest): Promise<SearchHfcArticleResponse>;

    /** 通用配置接口 */
    public GetCommonConfig(req: GetCommonConfigRequest): Promise<GetCommonConfigResponse>;

    /** 获取小红点个数. EventType=0表示所有事件 */
    public GetEventCount(req: GetEventCountRequest): Promise<GetEventCountResponse>;

    /** h5banner页 */
    public GetBannerList(req: GetBannerListRequest): Promise<GetBannerListResponse>;

    /** 数据页悬浮条列表 */
    public GetSuspensionBarList(req: GetSuspensionBarListRequest): Promise<GetSuspensionBarListResponse>;

    /** 评论互动管理 */
    /** 评论-获取书本列表 */
    public GetCommentBookList(req: GetCommentBookListRequest): Promise<GetCommentBookListResponse>;

    /** 评论-获取章节列表 */
    public GetCommentChapterList(req: GetCommentChapterListRequest): Promise<GetCommentChapterListResponse>;

    /** 评论-获取书评 */
    public GetBookCommentList(req: GetBookCommentListRequest): Promise<GetBookCommentListResponse>;

    /** 评论—获取作者书评 */
    public GetAuthorBookComment(req: GetAuthorBookCommentRequest): Promise<GetAuthorBookCommentResponse>;

    /** 评论-获取章评 */
    public GetChapterCommentList(req: GetChapterCommentListRequest): Promise<GetChapterCommentListResponse>;

    /** 评论-获取有话说评论 */
    public GetSpeakCommentList(req: GetSpeakCommentListRequest): Promise<GetSpeakCommentListResponse>;

    /** 评论-获取回复评论 */
    public GetReplyCommentList(req: GetReplyCommentListRequest): Promise<GetReplyCommentListResponse>;

    /** 评论-发布 */
    public CommentPublish(req: CommentPublishRequest): Promise<CommentPublishResponse>;

    /** 评论-回复 */
    public CommentReply(req: CommentReplyRequest): Promise<CommentReplyResponse>;

    /** 评论-删除 */
    public CommentDelete(req: CommentDeleteRequest): Promise<CommentDeleteResponse>;

    /** 评论-点赞 */
    public CommentDigg(req: CommentDiggRequest): Promise<CommentDiggResponse>;

    /** 评论-获取举报类型 */
    public CommentReportTypeList(req: CommentReportTypeListRequest): Promise<CommentReportTypeListResponse>;

    /** 评论-举报 */
    public CommentReport(req: CommentReportRequest): Promise<CommentReportResponse>;

    /** 评论-修改 */
    public CommentUpdate(req: CommentUpdateRequest): Promise<CommentUpdateResponse>;

    /** 获取详情页评论列表 */
    public GetItemCommentList(req: GetItemCommentListRequest): Promise<GetItemCommentListResponse>;

    /** 获取帖子评论列表 */
    public GetPostCommentList(req: GetPostCommentListRequest): Promise<GetPostCommentListResponse>;

    /** 作者入驻引导: */
    public MarkAuthorGuide(req: MarkAuthorGuideRequest): Promise<MarkAuthorGuideResponse>;

    /** 勋章 */
    public GetAuthorMedals(req: GetAuthorMedalsRequest): Promise<GetAuthorMedalsResponse>;

    public GetAuthorMedalsV1(req: GetAuthorMedalsRequest): Promise<GetAuthorMedalsV1Response>;

    /** 勋章海报 */
    public GetMedalPoster(req: GetMedalPosterRequest): Promise<GetMedalPosterResponse>;

    /** 勋章引导 */
    public GetMedalGuide(req: GetMedalGuideRequest): Promise<GetMedalGuideResponse>;

    /** 西葡签约终止 */
    public SignTerminate(req: SignTerminateRequest): Promise<SignTerminateResponse>;

    /** 论坛个人中心: */
    public ForumPersonalCenter(req: ForumPersonalCenterRequest): Promise<ForumPersonalCenterResponse>;

    /** 论坛个人隐私设置: */
    public ForumPrivacySetting(req: ForumPrivacySettingRequest): Promise<ForumPrivacySettingResponse>;

    /** 论坛作者帖子互动记录: */
    public ForumInteractiveRecord(req: ForumInteractiveRecordRequest): Promise<ForumInteractiveRecordResponse>;

    /** 论坛作者关注功能: */
    public ForumAuthorFollow(req: ForumAuthorFollowRequest): Promise<ForumAuthorFollowResponse>;

    /** 论坛关注关系: */
    public ForumFollowRelation(req: ForumFollowRelationRequest): Promise<ForumFollowRelationResponse>;

    /** 论坛精选列表: */
    public GetForumSelectedList(req: GetForumSelectedListRequest): Promise<GetForumSelectedListResponse>;

    /** 论坛消息通知： */
    public GetForumMsgList(req: GetForumMsgListRequest): Promise<GetForumMsgListResponse>;

    /** 帖子Tab列表 */
    public GetPostTabList(req: GetPostTabListRequest): Promise<GetPostTabListResponse>;

    /** 论坛帖子列表: (帖子、课堂、公告、关注) */
    public GetPostList(req: GetPostListRequest): Promise<GetPostListResponse>;

    /** 帖子点赞: */
    public PostDigg(req: PostDiggRequest): Promise<PostDiggResponse>;

    /** 帖子详情: */
    public GetPostInfo(req: GetPostDetailRequest): Promise<GetPostDetailResponse>;

    /** 帖子发布: */
    public PublishPost(req: PublishPostRequest): Promise<PublishPostResponse>;

    /** 帖子收藏: */
    public PostCollect(req: PostCollectRequest): Promise<PostCollectResponse>;

    /** 灵感榜单 */
    public GetInspirationCategory(req: GetInspirationCategoryRequest): Promise<GetInspirationCategoryResponse>;

    public GetInspirationBookList(req: GetInspirationBookListRequest): Promise<GetInspirationBookListResponse>;
}
