// 需要登陆验证的url
export const ValidateUrl = ['id-activity'];

export const PAGE_SIZE = 9;

export enum TagStatus {
    Active = 1,
    ToBeActive = 2,
    Expired = 3, // 已过期
}

export const TagFlag = {
    [TagStatus.Active]: 'active',
    [TagStatus.ToBeActive]: 'wait',
    [TagStatus.Expired]: 'disabled',
};

export const TagText = {
    [TagStatus.Active]: 'reward_status_inprogress',
    [TagStatus.ToBeActive]: 'reward_status_tobeopened',
    [TagStatus.Expired]: 'reward_status_closed',
};

export const getTitleText = (str: string) => (str.length >= 53 ? str.substring(0, 53).trim() + '...' : str);

export const FORMAT = 'YYYY-MM-DD';
