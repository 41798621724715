import { initArticleData } from '@redux/actions/article';
import { getArticleDetail } from '@service/api';
import { I18nDetailPageType } from '@typings/api/novel/origin_overseas_base';
import { useArticle } from '@utils/hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const loadData = async (dispatch: any, articleId) => {
    try {
        const resp = await getArticleDetail({ item_id: articleId });

        if (resp.code === -4025 || resp.code === 0) {
            const {
                title = '',
                content = '',
                modify_time,
                comment_open,
                digg_count,
                post_user,
                type,
                has_score,
            } = resp.data;
            dispatch(
                initArticleData({
                    title,
                    content,
                    type,
                    time: modify_time || 0,
                    commentOpen: comment_open === 1,
                    diggCount: digg_count || 0,
                    serverInited: true,
                    hasClassCore: has_score,
                    author: post_user
                        ? {
                              userId: post_user.user_id,
                              avatarURL: post_user.avatar_url,
                              username: post_user.user_name,
                              hasFollowed: post_user.has_followed,
                          }
                        : undefined,
                }),
            );
        } else if (resp.code) {
            throw Error(resp?.toString?.());
        }
    } catch (e) {
        console.log('loadData>>>>>', JSON.stringify(e));
    }
};

export const loadSSRData = async (ctx: any, store: any) => {
    const temp = ctx.url.match(/^\/article\/(.*)/)[1];
    const id = temp.split('?')[0];
    await loadData(store.dispatch, id);
};

export const useId = () => {
    const { id } = useParams<{ id: string }>();
    return id;
};

export const useArticleData = () => {
    const data = useArticle();
    const dispatch = useDispatch();
    const id = useId();

    useEffect(() => {
        if (!data.serverInited) {
            loadData(dispatch, id);
        }
    }, []);

    return data;
};

export const isAcademyType = (type: I18nDetailPageType | undefined) => type === I18nDetailPageType.CLASS;
