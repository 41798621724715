import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cs from 'classnames';

import { useTranslation } from '@ies/intl-react-plugin';
import { useAccount } from '@containers/account';

import Icon from '@components/icon';
import UserInfo from './user-info';
import { Divider, Message } from '@byted/serial-components-i18n';
import { HeaderTheme } from '@constants/enum';
import Lang from '@components/lang';

import { Tea } from '@utils/tea';
import { useInitLogin, useUserInfo } from '@utils/hooks';

import { LangCode } from '@common/lang';
import { getFAQConfig, getRewardList } from '@service/api';
import { MessageText } from '@constants/value';

import './index.less';
import DebuggerBlock from '@components/debugger';

interface IProps {
    style?: any;
    theme?: HeaderTheme;
    getAccountVisibleFn?: any;
    loginCallback?: () => void; // 登陆成功后回调
}

const Header: React.FC<IProps> = ({ style, theme = HeaderTheme.Black, getAccountVisibleFn, loginCallback }) => {
    const [showReward, setShowReward] = useState(false);
    const [showFAQ, setShowFAQ] = useState(false);
    const [loading, setLoading] = useState(true);

    const history = useHistory();
    const { i18n } = useTranslation();
    const { handleWrite, getAccountPopup, setAccountVisible } = useAccount(false, loginCallback);
    const { id } = useUserInfo();

    useInitLogin(handleWrite);
    useEffect(() => {
        getAccountVisibleFn && getAccountVisibleFn(setAccountVisible);
    }, [setAccountVisible]);

    const getRewardNum = async () => {
        try {
            const resp = await getRewardList({
                page_index: 0,
                page_count: 0,
                language: i18n.language,
            });
            if (resp.code) {
                throw Error(resp.message);
            }
            setShowReward(resp.data.total_count > 0);
        } catch (e) {
            console.log(e);
        }
    };

    const getFAQ = async () => {
        try {
            const resp = await getFAQConfig({
                language: i18n.language,
                entrance: 1,
            });

            if (resp.code) {
                throw Error(resp.message);
            }

            const { status } = resp.data;
            setShowFAQ(status);
        } catch {
            Message.error(MessageText.netError);
        }
    };

    const renderHeaderItem = (config: {
        child: string | React.ReactNode;
        className?: string;
        onClick?: () => void;
        isArrow?: boolean;
    }) => {
        const { child, className, onClick, isArrow = true } = config;

        return (
            <>
                <div onClick={onClick} className={cs('pc-header-item', className, { 'pc-header-arrowlink': isArrow })}>
                    {child}
                    {isArrow && <Icon type="point" />}
                </div>
                <Divider type="vertical" />
            </>
        );
    };

    const goToPage = (path: string) => () => history.push(path);

    const handleFAQ = () => {
        Tea('click_faq', {
            faq_entry: 0,
        });
        window.open('/faq');
    };

    const onWrite = () => {
        Tea('write_stories_click', { user_id: id });
        handleWrite();
    };

    useEffect(() => {
        Promise.all([getRewardNum(), getFAQ()]).then(() => {
            setLoading(false);
        });
    }, []);

    const renderNav = () => (
        <div className="pc-header-nav">
            {showReward &&
                renderHeaderItem({
                    child: i18n.t('reward_exit'),
                    onClick: goToPage('/reward'),
                    className: 'pc-header-reward',
                })}
            {renderHeaderItem({
                child: i18n.t('common_nav_banefit'),
                onClick: () => {
                    Tea('click_benefits');
                    goToPage('/event/benefit')();
                },
            })}
            {showFAQ &&
                renderHeaderItem({
                    child: i18n.t('helpdesk_faq'),
                    onClick: handleFAQ,
                })}
            {renderHeaderItem({
                child: i18n.t('common_nav_write'),
                onClick: onWrite,
            })}
            {renderHeaderItem({
                child: <Lang className="pc-header-lang" theme={theme} />,
                isArrow: false,
            })}
            {id &&
                renderHeaderItem({
                    child: <UserInfo showAccount={handleWrite} />,
                    isArrow: false,
                })}
        </div>
    );

    return (
        <header
            className={cs('pc-header-container', {
                white: theme === HeaderTheme.White,
                picture: theme === HeaderTheme.Picture,
                'logo-full-black': theme === HeaderTheme.LogoFullBlack,
            })}
            style={style}
        >
            <div className="pc-header">
                <div className="pc-header-logo" onClick={goToPage('/')} />
                <DebuggerBlock />
                {!loading && renderNav()}
            </div>
            {getAccountPopup()}
        </header>
    );
};

export default Header;
