import React, { useCallback } from 'react';
import { useTranslation } from '@ies/intl-react-plugin';
import FeedbackBtn from './feedback';
import { Link } from 'react-router-dom';
import { Tea } from '@utils/index';

import './index.less';

/** home页入口 */
export enum HomeEntrance {
    /** 无 */
    NoValue = 0,
    /** FAQ */
    FAQ = 1,
    /** Feedback */
    Feedback = 2,
}

interface IProps {
    showFAQ?: boolean;
    onClickFeedback?: () => void;
}

const RightBar: React.FC<IProps> = ({ showFAQ = true, onClickFeedback }) => {
    const { i18n, t } = useTranslation();

    // 切换语言后重载组件
    const FeedbackBtnI18n = useCallback(() => <FeedbackBtn onClick={onClickFeedback} />, [i18n.language]);

    return (
        <div className="right-bar">
            {showFAQ && (
                <Link to="/faq" className="right-bar-item" onClick={() => Tea('click_hover_faq')}>
                    <div className="right-bar-item-icon faq-icon" />
                    <div className="right-bar-item-text">{t('helpdesk_faq')}</div>
                </Link>
            )}
            <FeedbackBtnI18n />
        </div>
    );
};

export default RightBar;
