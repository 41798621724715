import React, { useState, ReactNode, useMemo, useCallback, HTMLAttributes, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import cs from 'classnames';
import { useTranslation } from '@ies/intl-react-plugin';
import { getStorage, setStorage } from '@utils/util';
import { useRefState, useUserInfo } from '@utils/hooks';
import { Icon } from '@byted/serial-components-i18n';
import { Tea } from '@utils/tea';
import { ChatContent } from './chat-content';
import { useAccount } from '@containers/account';

import './index.less';

export interface PopoverProps {
    children: ReactNode;
    visible: boolean;
    setVisible?: (v: boolean) => void;
}

export function Popover({ children, visible }: PopoverProps) {
    const contentRef = useRef<HTMLDivElement>(null);

    return (
        <div className="popover-panel">
            <CSSTransition in={visible} timeout={200} classNames="scale-transition">
                <div ref={contentRef} className="popover-panel-content">
                    <div className="popover-panel-content-flex-container">{children}</div>
                </div>
            </CSSTransition>
            <div className="spacer-bottom" />
        </div>
    );
}

interface BtnProps extends HTMLAttributes<HTMLDivElement> {
    text: ReactNode;
    icon: ReactNode;
}

function Btn({ text, icon, className, ...props }: BtnProps) {
    return (
        <div {...props} className={cs('right-bar-item', className)}>
            <div className="right-bar-item-icon">{icon}</div>
            <div className="right-bar-item-text">{text}</div>
        </div>
    );
}

export function useLocalState<T>(key: string, defaultValue: T): [T, (value: T) => void] {
    const localValue = useMemo<T>(() => getStorage<T>(key), []);
    const [state, setState] = useState<T>(localValue || defaultValue);
    const wrappedSetState = useCallback(
        (value: T) => {
            setState(value);
            setStorage(key, value);
        },
        [setState],
    );
    return [state, wrappedSetState];
}

const HasUnreadReplyKey = 'fizzo-feedback-has-unread';

export default function FeedbackBtn({ onClick }: { onClick?: () => void }) {
    const [visible, visibleRef, setVisible] = useRefState(false);
    const [hasUnreadReply, setHasUnreadReply] = useLocalState(HasUnreadReplyKey, false);
    const location = useLocation();
    const { id } = useUserInfo();
    const {
        i18n: { t },
    } = useTranslation();
    const toggleVisible = (v: boolean) => {
        setVisible(v);
        if (v) {
            setHasUnreadReply(false);
        }
    };
    const { setAccountVisible, getAccountPopup } = useAccount(false, () => {
        setAccountVisible(false);
        setVisible(true);
    });
    useEffect(() => {
        if (visibleRef.current) {
            toggleVisible(false);
        }
    }, [location]);

    const handleClickFeedback = () => {
        onClick?.();
        // 点击反馈按钮
        Tea('click_feedback');
        if (!id) {
            setAccountVisible(true);
            return;
        }
        toggleVisible(!visible);
    };
    return (
        <>
            <Btn
                icon={<Icon style={{ fontSize: '20px' }} type="feedback" />}
                text={t('helpdesk_enhtry')}
                className={cs(hasUnreadReply && 'has-new-dot')}
                onClick={handleClickFeedback}
            />
            <Popover visible={visible}>
                <ChatContent
                    visible={visible}
                    onTopButtonClick={() => toggleVisible(false)}
                    onGetNewReply={() => {
                        if (!visibleRef.current) {
                            setHasUnreadReply(true);
                        }
                    }}
                />
            </Popover>
            {getAccountPopup()}
        </>
    );
}
