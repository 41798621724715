import { getStorage, setStorage } from '@utils/util';

/**
 * 获取menu里第一个可阅读文章的菜单项
 * @param menu 左侧菜单树
 * @returns
 */
export const getDefaultActiveMenu = (menu: any) => {
    // 当前循环的层级的第一个分类
    let curRank = menu[0];
    const path: string[] = [];
    // 如果还存在子分类（child_category）并且子分类（child_category）的长度大于0，继续获取下一个层级
    while (curRank) {
        path.push(curRank.category_id);
        curRank = curRank?.child_category?.[0];
    }
    return {
        key: path[path.length - 1],
        keyPath: path,
    };
};

/**
 * 判断菜单的路径key在menu中是否有对应的category_id
 * @param articleKey 001_0011_11 代表文章层级的key，001代表第一层的category_id, 0011代表第二层，11代表第三层
 * @param menu 菜单树
 * @param index 表示当前递归到的菜单的层级
 * @returns
 */
export const checkArticleIsInMenu = (keyPath: string[], menu: any[], index = 0) => {
    const curMenu = menu.find(item => item.category_id === keyPath[index]);
    // 当前层级没有对应category_id，则表示菜单中不存在这一层级的菜单，返回false
    if (!curMenu) {
        return false;
    }
    // 当前层级有对应category_id，遍历下一个层级是否有对应的category_id
    const nextChildMenu = curMenu?.child_category;
    if (nextChildMenu?.length && keyPath[index + 1]) {
        // 同时存在下一个层级的menu和key，继续遍历
        return checkArticleIsInMenu(keyPath, nextChildMenu, index + 1);
    }
    if (!nextChildMenu?.length && !keyPath[index + 1]) {
        // 同时不存在下一个层级的menu和key，说明menu每个层级都有对应的category_id
        return true;
    }
    // menu和keyPath的层级对不上，返回false
    return false;
};

export const FromSearchKey = 'from_search_article';

export const getFromSearchPage = (article: string) => {
    const fromSearchArr = getStorage(FromSearchKey, []);
    const index = fromSearchArr.indexOf(article);
    if (index > -1) {
        fromSearchArr.splice(index, 1);
        setStorage(FromSearchKey, fromSearchArr);
        return true;
    }
    return false;
};

export const setFromSearchPage = (article: string) => {
    const fromSearchArr = getStorage(FromSearchKey, []);
    fromSearchArr.push(article);
    setStorage(FromSearchKey, fromSearchArr);
};

export const removeFromSearchPage = (article: string) => {
    const fromSearchArr: string[] = getStorage(FromSearchKey, []);
    const index = fromSearchArr.indexOf(article);
    if (index > 0) {
        setStorage(FromSearchKey, fromSearchArr.splice(index, 1));
    }
};
