import React, { useEffect, useRef, useState } from 'react';
import Header from '@components/header';
import Footer from '@components/footer';
import Loading from '@components/loading';

import { Divider, Empty, Message, Pagination } from '@byted/serial-components-i18n';
import { HeaderTheme } from '@constants/enum';
import { useTranslation } from '@ies/intl-react-plugin';
import { useUserInfo } from '@utils/hooks';

import { getRewardList } from '@service/api';
import { MessageText } from '@constants/value';
import { FORMAT, getTitleText, PAGE_SIZE, TagFlag, TagText, ValidateUrl } from '../setting';

import dayjs from 'dayjs';

import './index.less';

const ClickCardClass = 'reward-list-item--click';

const Reward: React.FC = () => {
    // todo 加上类型声明
    const [activities, setActivities] = useState<any[]>();
    const [total, setTotal] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);
    const [loading, setLoading] = useState(true);
    const [detailUrl, setDetailUrl] = useState('');

    const { media_id } = useUserInfo();

    const { i18n } = useTranslation();

    const accountFn = useRef<any>();

    const jumpToActivity = (activity: any) => {
        const link = activity.activity_link;
        const isValidate = ValidateUrl.some(url => {
            if (link.includes(url)) {
                setDetailUrl(url);
                return true;
            }
            return false;
        });
        if (isValidate && !media_id) {
            const setAccountVisible = accountFn.current;
            setAccountVisible(true);
            return;
        }
        setDetailUrl('');
        window.open(link);
        document.querySelector(`#activity${activity.id}`)?.classList.add(ClickCardClass);
    };

    // 获取活动列表
    const getActivities = async () => {
        try {
            const resp = await getRewardList({
                page_index: pageIndex - 1,
                page_count: PAGE_SIZE,
                language: i18n.language,
            });
            if (resp.code) {
                Message.error(MessageText.netError);
                console.log(resp.message);
            }
            setActivities(resp.data.activity_list);
            setTotal(resp.data.total_count);
            window.scrollTo(0, 0);
        } catch (e) {
            Message.error(MessageText.netError);
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const handleLoginIn = () => {
        const setAccountVisible = accountFn.current;
        setAccountVisible(false);
        window.open(`${location.origin}/reward/${detailUrl}`);
    };

    useEffect(() => {
        getActivities();
    }, [pageIndex]);

    const showList = activities && activities.length > 0;

    if (loading) {
        return <Loading className="reward-loading" />;
    }

    return (
        <div className="reward">
            <Header
                theme={HeaderTheme.Picture}
                getAccountVisibleFn={fn => (accountFn.current = fn)}
                loginCallback={detailUrl ? handleLoginIn : undefined}
            />
            <div className="reward-body">
                <h3>{i18n.t('reward_exit')}</h3>
                {showList ? (
                    <>
                        <div className="reward-list">
                            {activities.map(activity => (
                                <div
                                    id={`activity${activity.id}`}
                                    className="reward-list-item"
                                    key={activity.id}
                                    onClick={() => jumpToActivity(activity)}
                                    onMouseEnter={() => {
                                        document
                                            .querySelector(`#activity${activity.id}`)
                                            ?.classList.remove(ClickCardClass);
                                    }}
                                >
                                    <img src={activity.picture_link} alt="" />
                                    <div className="reward-list-item-content">
                                        <h4>
                                            {getTitleText(activity.name)}&nbsp;
                                            <i className={`tag-flag tag-flag--${TagFlag[activity.time_status]}`}>
                                                {i18n.t(TagText[activity.time_status])}
                                            </i>
                                        </h4>
                                        <p>{activity.description}</p>
                                        <span>
                                            {i18n.t('reward_periode')}:{' '}
                                            {dayjs.utc(activity.start_time * 1000).format(FORMAT)}-
                                            {dayjs.utc(activity.end_time * 1000).format(FORMAT)}(
                                            {i18n.t('chapter_published_utc0')})
                                            <br />
                                            {activity.bonus}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {total > PAGE_SIZE ? (
                            <Pagination
                                className="reward-pagination"
                                total={total}
                                pageSize={PAGE_SIZE}
                                current={pageIndex}
                                onChange={setPageIndex}
                            />
                        ) : (
                            <Divider>{i18n.t('reward_staytuned')}</Divider>
                        )}
                    </>
                ) : (
                    <Empty description={i18n.t('reward_staytuned')} />
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Reward;
