import { RouteProps } from 'react-router';
import Home from '@containers/home/pc';
import Reward from '@containers/reward/pc';
import IdActivity from '@containers/reward/id-activity/pc';
import MagicEvent from '@containers/event/pc';
import Article from '@containers/article/pc';
import Post from '@containers/post/pc';
import FAQ from '@containers/faq';
import Academy from '@containers/academy';
import Entry from '@containers/entry/pc';
import Copyright from '@containers/copyright/pc';
// export const Home = loadable(/* #__LOADABLE__ */ () => import(/* webpackChunkName: "Home" */ '../containers/home'));

export const routes: RouteProps[] = [
    {
        path: ['/', '/home', '/native'],
        component: Entry,
        exact: true,
    },
    {
        path: '/reward',
        component: Reward,
        exact: true,
    },
    {
        path: '/reward/id-activity',
        component: IdActivity,
        exact: true,
    },
    {
        path: '/article/:id',
        component: Article,
        exact: true,
    },
    {
        path: '/post/:id',
        component: Post,
        exact: true,
    },
    {
        path: '/faq',
        component: FAQ,
        exact: true,
    },
    // 魔方活动路由
    {
        path: '/event/:name',
        component: MagicEvent,
        exact: true,
    },
    {
        path: '/academy',
        component: Academy,
        exact: true,
    },
    {
        path: '/copyright',
        component: Copyright,
        exact: true,
    }
];
