import bridge from '@bridge/worldance';
import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

import { FIZZO_DOMAIN, isInApp } from '@utils/env';
import { mueRequestFail, mueResponseFail } from '@utils/monitor';
import { getStorage } from '@utils/util';

export interface RequestConfig extends AxiosRequestConfig {
    form?: boolean;
}

const axiosClient = axios.create({
    timeout: 60 * 1000,
    transformRequest: axios.defaults.transformRequest,
});

export const DEV = process.env.NODE_ENV === 'dev';

export const getApiBase = () => {
    if (DEV) {
        if (Number(getStorage('use_boe'))) {
            return FIZZO_DOMAIN.boe;
        }
        return FIZZO_DOMAIN.online;
    }
    if (typeof location !== 'undefined') {
        return location.origin;
    }
    return FIZZO_DOMAIN.online;
};

/**
 * copy from mobile
 * 判断了 InApp
 * P.S 鹊桥包能够兼容 SSR 环境
 * @param url
 * @param method
 * @param useJSON
 * @param config
 * @returns
 */
export function requestInApp<T extends object, S>(
    url: string,
    method: 'get' | 'post' = 'get',
    useJSON = false,
    config: RequestConfig = {},
): (params?: T) => Promise<S> {
    return async params => {
        const headers = useJSON ? { 'Content-Type': 'application/json', ...(config.headers || {}) } : config.headers;

        if (isInApp()) {
            const resp = await bridge.net.request({
                url: `${getApiBase()}${url}`,
                method: method.toUpperCase() as Uppercase<'get' | 'post'>,
                header: headers,
                use_web_raw_data: Number(useJSON),
                ...(method === 'get' ? { params } : { data: params }),
            });
            const { status, body } = resp || {};

            if (status === 200 && body) {
                if (body.code && body.code !== 0) {
                    mueResponseFail(url, body);
                }
                return body;
            }

            mueRequestFail(url, body, {
                resp_status: status,
            });
            throw body;
        }

        const requestConfig: AxiosRequestConfig = {
            url,
            method,
            headers,
        };
        if (method === 'get') {
            requestConfig.params = params;
        } else if (useJSON) {
            requestConfig.data = JSON.stringify(params);
        } else if (config.form) {
            requestConfig.data = params;
        } else {
            requestConfig.data = qs.stringify(params);
        }

        const resp = await axiosClient(requestConfig);
        const { status, statusText, data } = resp || {};

        if (status === 200 && data) {
            if (data.code && data.code !== 0) {
                mueResponseFail(url, data);
            }
            return data;
        }

        mueRequestFail(url, data, {
            resp_status: status,
            resp_text: statusText,
        });
        throw data;
    };
}
