import React, { useRef } from 'react';
import Icon from '@components/icon';
import AcademyCarousel from '@containers/home-id/component/academy-carousel';
import { useAcademy } from '@containers/home-id/utils';
import { useTranslation } from '@ies/intl-react-plugin';
import { useNav } from '@utils/nav';
import Loading from '@components/loading';
import { Tea, getArticleId } from '@utils/index';
import { EVENTS } from '@constants/event';

import './index.less';
import { EnterAcademyDetailSource, EnterAcademyListSource } from '@constants/academy';

const Academy: React.FC = () => {
    const moduleRef = useRef<HTMLDivElement>(null);
    const { leftCards, rightCards, loading } = useAcademy();
    const { t } = useTranslation();

    const navTo = useNav();

    return (
        <section className="home-id-academy" id="home_academy" ref={moduleRef}>
            {!loading ? (
                <div className="home-id-academy-inner">
                    <div
                        className="home-id-academy-guide"
                        onClick={() => {
                            navTo('/academy');
                            Tea(EVENTS.ENTER_ACADEMY);
                            Tea(EVENTS.CLICK_ACADEMY_LIST, {
                                channel: EnterAcademyListSource.HomeRecommend,
                            });
                        }}
                    >
                        <h1>{t('academy_header')}</h1>
                        <Icon type="point" />
                    </div>
                    <div className="home-id-academy-bottom">
                        <AcademyCarousel
                            trigger={moduleRef.current}
                            data={leftCards}
                            isInited={!loading}
                            onClick={item => {
                                Tea(EVENTS.CLICK_ACADEMY_DETAIL, {
                                    channel: EnterAcademyDetailSource.PCHomeRecommend,
                                    article_id: getArticleId(item.link),
                                });
                            }}
                        />
                        <div className="home-id-academy-cards">
                            {rightCards.map((item, index) => (
                                <a
                                    className="home-id-academy-card"
                                    href={item.link_url}
                                    target="_blank"
                                    key={index}
                                    rel="noreferrer"
                                    onClick={() => {
                                        Tea(EVENTS.ENTER_ACADEMY, { enter_academy_detail: item.link_url });
                                        Tea(EVENTS.CLICK_ACADEMY_DETAIL, {
                                            channel: EnterAcademyDetailSource.PCHomeRecommend,
                                            article_id: getArticleId(item.link_url),
                                        });
                                    }}
                                >
                                    <div className="img-wrapper">
                                        <img src={item.thumb_uri} alt="" />
                                    </div>
                                    <div className="card-text">{item.title}</div>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </section>
    );
};
export default Academy;
