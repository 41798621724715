import React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18n } from '@ies/starling_intl';
import { initI18n } from '@utils/i18n';
import { createClientStore } from '@redux/store';
import App from './App';
import { AppInfoContext, Entry } from '@utils/index';
import { changeDayjsLocale } from '@utils/day';
import { LangCode } from '@common/lang';

const store = createClientStore();
const pcInfo = {
    entry: Entry.PC,
};
const render = () => {
    ReactDom.hydrate(
        <Provider store={store}>
            <AppInfoContext.Provider value={pcInfo}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </AppInfoContext.Provider>
        </Provider>,
        document.querySelector('#root'),
    );
};

initI18n().then(() => {
    // initial config dayjs for client rendering locale time format
    changeDayjsLocale(I18n.language as LangCode);
    render();
});
