import { createStore, combineReducers } from 'redux';
import article from './reducers/article';
import post from './reducers/post';
import home from './reducers/home';
import user from './reducers/user';

const reducers = combineReducers({ home, user, article, post });

export function createServerStore() {
    return createStore(reducers);
}

export function createClientStore() {
    const initStates = (window as any).__SSRDATA || {};
    return createStore(reducers, initStates);
}

export type StoreType = ReturnType<typeof reducers>;
