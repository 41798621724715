import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from '@ies/intl-react-plugin';
import { gsap } from 'gsap';
import ArrowBtn from '@components/arrow-btn';
import { lazyLoadImg, getDownloadUrl } from '@containers/home/setting';
import { LangCode } from '@common/lang';
import cs from 'classnames';

import Phone1 from '../../images/phone-1.png';
import Phone3 from '../../images/phone-3.png';
import Phone4 from '../../images/phone-4.png';
import Phone5 from '../../images/phone-5.png';
import Phone6 from '../../images/phone-6.png';
import Phone7 from '../../images/phone-7.png';
import Phone8 from '../../images/phone-8.png';

const PHONES = {
    [LangCode.EN]: [Phone1],
    [LangCode.ID]: [Phone3, Phone4],
    [LangCode.PT]: [Phone5, Phone6],
    [LangCode.ES]: [Phone7, Phone8],
};

const Download: React.FC = () => {
    const { i18n } = useTranslation();
    const loaded = useRef(false);
    const ref = useRef<HTMLDivElement>(null);
    const app1Ref = useRef<HTMLImageElement>(null);
    const app2Ref = useRef<HTMLImageElement>(null);
    const flagRef = useRef<HTMLDivElement>(null);
    const [updated, setUpdated] = useState(false);

    const lang = i18n.language as LangCode;

    useEffect(() => {
        // fix: trend高度计算副作用
        setUpdated(true);
    }, []);

    useEffect(() => {
        if (!updated) {
            return;
        }
        const tl = gsap
            .timeline({
                scrollTrigger: {
                    trigger: ref.current,
                    start: 'top bottom',
                    scrub: true,
                    onEnter: () => {
                        if (!loaded.current) {
                            lazyLoadImg(ref.current!);
                            loaded.current = true;
                        }
                    },
                },
            })
            .fromTo(app1Ref.current, { y: lang === LangCode.EN ? 370 : 250 }, { y: lang === LangCode.EN ? 145 : 25 }, 0)
            .fromTo(app2Ref.current, { y: 75 }, { y: 282 }, 0)
            .fromTo(flagRef.current, { y: 58 }, { y: 177 }, 0);

        return () => {
            tl.scrollTrigger?.kill();
        };
    }, [updated]);

    const phones = PHONES[lang];

    const downloadUrl = getDownloadUrl(lang);

    return (
        <section className={cs('home-app', { [lang]: lang !== undefined })} ref={ref} id="home_app">
            <div className="home-app-bg">
                <div className="home-app-bg-left">
                    <div ref={flagRef} className="home-app-bg-flag" />
                </div>
                <div className="home-app-bg-mid" />
                <div className="home-app-bg-right" />
            </div>
            <div className="home-app-main">
                <div className="home-app-main-left">
                    <p>{i18n.t('web_download_note')}</p>
                    <div className="home-app-main-btns">
                        <ArrowBtn
                            icon="apple"
                            text={i18n.t('pc_download_appstore')}
                            onClick={() => {
                                window.location.href = downloadUrl.Ios;
                            }}
                        />
                        <ArrowBtn
                            icon="google"
                            text={i18n.t('pc_download_googleplay')}
                            onClick={() => {
                                window.location.href = downloadUrl.Android;
                            }}
                        />
                    </div>
                </div>
                <div className="home-app-main-right">
                    <img ref={app1Ref} data-src={phones[0]} className="home-app-main-one" />
                    {phones[1] && <img ref={app2Ref} data-src={phones[1]} className="home-app-main-two" />}
                </div>
            </div>
        </section>
    );
};

export default Download;
