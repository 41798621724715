/**
 * copy from mobile
 * 举报评论弹窗
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './index.less';

import { useTranslation } from '@ies/intl-react-plugin';
import { PopupSwiper, Radio, Textarea, Toast } from '@arco-design/mobile-react-serial-i18n';
import { SerialI18nTextareaRef } from '@arco-design/mobile-react-serial-i18n/esm/textarea';
import { PopupSwiperProps } from '@arco-design/mobile-react-serial-i18n/esm/popup-swiper';
import cs from 'classnames';

import { CommentReportInfo } from '@typings/api/novel/toutiao_muye_overseas_homeapi';
import Icon from '@components/icon';
import Loading from '@components/h5/loading';
import { MessageText } from '@constants/value';
import { getCommentReportTypeList } from '@service/api';

const getReportPopupHeight = () => {
    if (typeof window === 'undefined') {
        return 0;
    }
    return 0.9 * window.innerHeight;
};

const MaxAdviceLength = 300;

export interface ReportFormType {
    reason: string;
    advice: string;
}

export interface ReportPopupProps {
    visible: boolean;
    close: () => void;
    value: ReportFormType;
    onChange: (v: ReportFormType) => void;
    onSubmit: (v: ReportFormType) => void;
}

const resp = {
    code: 0,
    data: {
        report_list: [
            {
                report_reason: 'Extremismo violento',
            },
            {
                report_reason: 'Comportamiento que incita al odio',
            },
            {
                report_reason: 'Actividades ilegales y bienes regulados',
            },
            {
                report_reason: 'Contenido violento y gráfico',
            },
            {
                report_reason: 'Suicidio, autolesiones y actos peligrosos',
            },
            {
                report_reason: 'Acoso e intimidación',
            },
            {
                report_reason: 'Contenido sexual',
            },
            {
                report_reason: 'Seguridad de menores',
            },
            {
                report_reason: 'Contenido irrelevante',
            },
        ],
    },
    log_id: '20230118070740DAD12CB8B962D2AF0858',
    message: 'success',
};

export default function ReportPopup({ visible, close, value, onChange, onSubmit }: ReportPopupProps) {
    const textareaRef = useRef<SerialI18nTextareaRef>(null);
    const { i18n } = useTranslation();
    const hiddenInputRef = useRef<HTMLTextAreaElement>(null);
    const adviceCacheRef = useRef<Map<string, string>>(new Map());
    const [loading, setLoading] = useState(false);
    const [reportList, setReportList] = useState<CommentReportInfo[]>([]);

    const [nextPage, setNextPage] = useState(false);
    useEffect(() => {
        if (!visible) {
            setNextPage(false);
            adviceCacheRef.current = new Map();
            if (document.activeElement === textareaRef.current?.textarea) {
                textareaRef.current?.textarea?.blur();
            }
        }
    }, [visible]);

    const refresh = useCallback(async () => {
        try {
            setLoading(true);
            // const res = resp;
            const res = await getCommentReportTypeList({
                language: i18n.language,
            });
            if (res?.code === 0) {
                setReportList(res.data?.report_list || []);
            } else {
                throw res;
            }
        } catch (e) {
            Toast.toast(MessageText.netError);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (visible && !reportList.length) {
            refresh();
        }
    }, [visible]);

    const disabled = useMemo(() => {
        return !value.reason || value.advice.length > MaxAdviceLength;
    }, [value]);

    const goNextPage = (reason: string) => {
        setNextPage(true);
        onChange({ reason, advice: adviceCacheRef.current.get(reason) || '' });
        // 不能聚焦视口外的元素，否者布局错乱
        hiddenInputRef.current?.focus();
        setTimeout(() => {
            textareaRef.current?.textarea?.focus();
        }, 500);
    };

    const goPreviousPage = () => {
        setNextPage(false);
        adviceCacheRef.current.set(value.reason, value.advice);
        onChange({ reason: '', advice: value.advice });
    };

    const FirstPage = (
        <div className="report-popup-content">
            <div className="form-item-label required">
                <div className="label-text">{i18n.t('comment_report_reason_title', undefined, 'Report')}</div>
            </div>
            <div className="form-item-control">
                <Radio.Group value={value.reason}>
                    {loading && !reportList.length && <Loading absolute />}
                    {reportList.map((t, i) => (
                        <div
                            key={i}
                            onClick={() => {
                                goNextPage(t.report_reason);
                            }}
                        >
                            <Radio className="reason-radio" value={t.report_reason}>
                                {t.report_reason}
                            </Radio>
                        </div>
                    ))}
                </Radio.Group>
            </div>
        </div>
    );
    const SecondPage = (
        <div className="report-popup-content">
            <div className="form-item-label required">
                <div className="label-text">{i18n.t('comment_report_reason_title', undefined, 'Report')}</div>
            </div>
            <div className="form-item-control">
                <div onClick={() => goPreviousPage()}>
                    <Radio className="reason-radio" checked value={value.reason}>
                        {value.reason}
                    </Radio>
                </div>
                <div className="advice-title">
                    <div className="text">
                        <span>{i18n.t('comment_report_advice_title', undefined, 'More advice')}</span>
                        <span className="optional">{`${' ('}${i18n.t(
                            'input_optional',
                            undefined,
                            'Optional',
                        )}${')'}`}</span>
                    </div>
                    <div className="advice-title-counter">{`${value.advice.length}${'/'}${MaxAdviceLength}`}</div>
                </div>
                <Textarea
                    ref={textareaRef}
                    textareaClass="advice-textarea"
                    placeholder={i18n.t('comment_report_advice_placeholder', undefined, 'We hope for your advice')}
                    showStatistics={false}
                    maxLength={MaxAdviceLength}
                    value={value.advice}
                    onChange={(e, v) => onChange({ ...value, advice: v })}
                    onBlur={() => {
                        if (value.advice.length > MaxAdviceLength) {
                            onChange({ ...value, advice: value.advice.slice(0, MaxAdviceLength) });
                        }
                    }}
                />
            </div>
        </div>
    );

    const getScrollContainer = useCallback((content: HTMLDivElement | null) => {
        return [content, textareaRef.current?.textarea].filter(v => Boolean(v)) as HTMLElement[];
    }, []);

    const directions = useMemo<PopupSwiperProps['allowSwipeDirections']>(() => {
        return nextPage ? [] : ['bottom'];
    }, [nextPage]);

    return (
        <PopupSwiper
            className="report-popup-container"
            allowSwipeDirections={directions}
            mountOnEnter
            unmountOnExit={false}
            visible={visible}
            close={close}
            getScrollContainer={getScrollContainer}
            renderTitle={() => (
                <div className="report-popup-title">
                    <Icon
                        onClick={() => {
                            if (nextPage) {
                                goPreviousPage();
                            } else {
                                close();
                            }
                        }}
                        className={cs('title-btn', { next: nextPage })}
                        type="left-arrow"
                    />
                    <div className="title-text">{i18n.t('report', undefined, 'Report')}</div>
                    <div
                        className={cs('title-action', { disabled })}
                        onClick={() => {
                            if (disabled) {
                                return;
                            }
                            if (document.activeElement === textareaRef.current?.textarea) {
                                textareaRef.current?.textarea?.blur();
                            }
                            onSubmit(value);
                            close();
                        }}
                    >
                        {i18n.t('comment_report_submit', undefined, 'Submit')}
                    </div>
                    {/* 用于拉起软键盘 */}
                    <textarea ref={hiddenInputRef} className="report-popup-hidden-input" />
                </div>
            )}
            fixedHeight={getReportPopupHeight()}
        >
            <div className="report-popup">
                <div className="report-popup-container-outer">
                    <div className={cs('report-popup-container-inner', { next: nextPage })}>
                        {FirstPage}
                        {SecondPage}
                    </div>
                </div>
            </div>
        </PopupSwiper>
    );
}
