import React, { ForwardedRef, HTMLAttributes, useImperativeHandle, useRef } from 'react';
import cs from 'classnames';

import '@static/icon/index.css';

interface IconProps extends HTMLAttributes<HTMLSpanElement> {
    /**
     * icon name
     * */
    type: string;
    /**
     * icon color
     * */
    color?: string;
    className?: string;
    style?: React.CSSProperties;
}

const Icon = (props: IconProps, ref: ForwardedRef<HTMLSpanElement>) => {
    const { type, className, style, color, ...rest } = props;
    const domRef = useRef<HTMLSpanElement>(null);

    useImperativeHandle<HTMLSpanElement, HTMLSpanElement>(ref, () => domRef.current!);
    return (
        <i {...rest} ref={domRef} className={cs(className, { [`tomato-${type}`]: true })} style={{ ...style, color }} />
    );
};

export default React.forwardRef(Icon);
