import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

import srcEN from '../../images/font.svg';
import srcID from '../../images/font-id.svg';

export const Font = () => {
    const fontRef = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const tl = gsap
            .timeline({
                scrollTrigger: {
                    trigger: fontRef.current,
                    end: '+=1500px',
                    scrub: true,
                    pin: true,
                    onEnterBack: prog => {
                        setVisible(true);
                        console.log('Font onEnterBack::', prog);
                    },
                    onLeave: prog => {
                        setVisible(false);
                        console.log('Font onLeave::', prog);
                    },
                },
            })
            .to(fontRef.current, { scale: 70, x: -4500, y: 700 }, 0);

        return () => {
            tl.scrollTrigger?.kill();
        };
    }, []);
    return (
        <div className="home-trend-font" style={{ opacity: visible ? 1 : 0 }} ref={fontRef}>
            <img src={srcEN} />
        </div>
    );
};

export const FontId = () => {
    const fontRef = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const tl = gsap
            .timeline({
                scrollTrigger: {
                    trigger: fontRef.current,
                    end: '+=1500px',
                    scrub: true,
                    pin: true,
                    onEnterBack: prog => {
                        setVisible(true);
                        console.log('onEnterBack', prog);
                    },
                    onLeave: prog => {
                        setVisible(false);
                        console.log('onLeave', prog);
                    },
                },
            })
            .to(fontRef.current, { scale: 70, x: -3300 }, 0);

        return () => {
            tl.scrollTrigger?.kill();
        };
    }, []);
    return (
        <div className="home-trend-font" style={{ opacity: visible ? 1 : 0 }} ref={fontRef}>
            <img src={srcID} />
        </div>
    );
};
