import React from 'react';

export const renderI18nText = (text: string) => (
    <span
        className="render-i18n-text"
        dangerouslySetInnerHTML={{
            __html: text,
        }}
        style={{ whiteSpace: 'pre-wrap' }}
    />
);
