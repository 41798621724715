import { Message } from '@byted/serial-components-i18n';
import { MessageText } from '@constants/value';
import { getConfigs } from '@service/api';
import { useEffect, useState } from 'react';

const CopyrightKey = 'fizzo_home_copyright_id';

export interface CopyrightItem {
    book_name: string;
    book_cover: string;
    author_name: string;
    author_avatar: string;
    publish_house_name: string;
    publish_house_logo: string;
    book_introduction: string;
    has_published: boolean;
}

export const BgStyleList = [
    '#2E3829',
    '#3F2F2F',
    '#3B3659',
    '#674A26',
    '#3B3659',
    '#3B3659',
    '#3F2F2F',
    '#3F2F2F',
    '#2E3829',
    '#3F2F2F',
    '#674A26',
    '#2E3829',
    '#3F2F2F',
    '#3B3659',
    '#674A26',
    '#3B3659',
    '#3B3659',
    '#3F2F2F',
    '#3F2F2F',
    '#2E3829',
    '#3F2F2F',
    '#674A26',
];

export const useCopyright = () => {
    const [loading, setLoading] = useState(true);
    const [copyrightList, setCopyrightList] = useState<CopyrightItem[]>([]);

    useEffect(() => {
        getList();
    }, []);

    const getList = async () => {
        try {
            const resp: any = await getConfigs({ keys: CopyrightKey });
            if (resp.code) {
                throw Error();
            }
            const list = resp.data[CopyrightKey].data as CopyrightItem[];
            setCopyrightList(list);
        } catch (e) {
            console.log(e);
            Message.error(MessageText.netError);
        } finally {
            setLoading(false);
        }
    };

    return {
        copyrightList,
        loading,
    };
};
