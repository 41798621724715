import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { useHome } from '@utils/hooks';
import I18n from '@ies/starling_intl';

interface IProps {}

const Advantage: React.FC<IProps> = () => {
    const { advantages } = useHome();
    const ref = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);
    const flagRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const tl = gsap
            .timeline({
                scrollTrigger: {
                    trigger: ref.current,
                    start: 'top 70%',
                    scrub: true,
                },
            })
            .to(titleRef.current, { y: 60 })
            .to(listRef.current, { y: -150 }, '<20%')
            .to(flagRef.current, { y: -300 }, '<10%');

        return () => {
            tl.scrollTrigger?.kill();
        };
    }, []);
    return (
        <section className="home-advantage" ref={ref}>
            <h1 ref={titleRef}>{I18n.t('web_workwithus')}</h1>
            <div ref={flagRef} className="home-advantage-flag" />
            <div ref={listRef} className="home-advantage-list">
                {advantages?.map((item, index) => (
                    <div className="home-advantage-item" key={index}>
                        <p>{item.title}</p>
                        <div dangerouslySetInnerHTML={{ __html: item.content }} />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Advantage;
