import React, { useState } from 'react';
import { useTranslation } from '@ies/intl-react-plugin';
import Icon from '@components/icon';
import Oauth from '../oauth';
import { AccountPage, AppJoinWay, Events, JoinType, JoinWay } from '../constants';
import { useUserInfo, Tea, ProtocolPath, openProtocol } from '@utils/index';

import './index.less';
import EmailLogo from '@static/images/email.png';

interface IProps {
    isMobile: boolean;
    onChangePage: (page: AccountPage, replace?: boolean, extra?: any) => void;
}

const Register: React.FC<IProps> = ({ onChangePage, isMobile }) => {
    const { i18n } = useTranslation();

    const { id: uid } = useUserInfo();

    const goRegisterOauth = (params: any) => {
        onChangePage(AccountPage.RegisterOauth, false, params);
    };

    const goAddName = (type: string, appId = '') => {
        onChangePage(AccountPage.AddName, true, { type });
        Tea(Events.WriterJoinThirdPart, {
            join_type: JoinType.Register,
            join_way: AppJoinWay[appId],
        });
    };
    const handleRegisterEmail = () => {
        onChangePage(AccountPage.RegisterEmail);
        Tea('writer_signup', {
            button_click: 'email',
            user_id: uid,
            is_success: 1,
        });
        Tea(Events.WiterSignupEmail);
    };

    const openLangProtocol = openProtocol(i18n.language);

    return (
        <div className="register">
            <h1 style={{ width: '330px' }}>{i18n.t('sso_signup_title')}</h1>
            <h6>
                {i18n.t('sso_signup_text')}
                <br />
                {i18n.t('sso_login_guide')}
                &nbsp;
                <span onClick={() => onChangePage(AccountPage.Login, true)}>{i18n.t('sso_login_guide1')}</span>
            </h6>
            <div className="oauth-item email" onClick={handleRegisterEmail}>
                <img src={EmailLogo} />
                <span>{i18n.t('sso_signup_email')}</span>
                <Icon type="left" />
            </div>
            <div className="register-br">{i18n.t('sso_login_or')}</div>
            <Oauth onUserNotExist={goRegisterOauth} onUserNameEmpty={goAddName} />
            <div className="register-protocol">
                <div className="register-protocol-p">
                    {i18n.t('sso_signup_legal1')}&nbsp;&nbsp;
                    <span onClick={openLangProtocol(ProtocolPath.Service)}>{i18n.t('sso_signup_legal2')}</span>
                </div>
                <div className="register-protocol-p">
                    {i18n.t('sso_signup_legal3')}&nbsp;&nbsp;
                    <span onClick={openLangProtocol(ProtocolPath.Privacy)}>{i18n.t('sso_signup_legal4')}</span>
                </div>
            </div>
        </div>
    );
};

export default Register;
