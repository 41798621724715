import request from './request';
import { requestInApp } from './request-inapp';
import {
    GetUserInfoRequest,
    GetUserInfoResponse,
    UpdateUserInfoRequest,
    UpdateUserInfoResponse,
} from '@typings/api/novel/toutiao_novel_serial_node_fe_i18n';
import {
    ClassReadEventRequest,
    ClassReadEventResponse,
    CommentDiggRequest,
    CommentDiggResponse,
    CommentPublishRequest,
    CommentPublishResponse,
    CommentReplyRequest,
    CommentReplyResponse,
    CommentReportRequest,
    CommentReportResponse,
    CommentReportTypeListRequest,
    CommentReportTypeListResponse,
    DetailPageDiggRequest,
    DetailPageDiggResponse,
    ForumAuthorFollowRequest,
    ForumAuthorFollowResponse,
    DetailPageScoreRequest,
    DetailPageScoreResponse,
    GetClassRecommendRequest,
    GetClassRecommendResponse,
    GetCreationClassListRequest,
    GetCreationClassListResponse,
    GetCreationZoneListRequest,
    GetCreationZoneListResponse,
    GetDetailPageRequest,
    GetDetailPageResponse,
    GetItemCommentListRequest,
    GetItemCommentListResponse,
    GetPostCommentListRequest,
    GetPostCommentListResponse,
    GetPostDetailRequest,
    GetPostDetailResponse,
    GetReplyCommentListRequest,
    GetReplyCommentListResponse,
    PostCollectRequest,
    PostCollectResponse,
    PostDiggRequest,
    PostDiggResponse,
} from '@typings/api/novel/toutiao_muye_overseas_homeapi';
import { ExtendPropType } from '@utils/typings';
import { GetCopyrightListRequest, GetCopyrightListResponse } from '@typings/api/novel/toutiao_novel_serial_pc_i18n';

export const getConfigList = request('/api/pc/config');
export const getUserInfo = requestInApp<GetUserInfoRequest, any>('/api/node/i18n/user/info'); // media_id 确认有没有?
export const getIdConfigList = request('/api/pc/config/id');
export const getConfigs = requestInApp('/api/pc/config/common/v1');
export const setUserInfo = request<UpdateUserInfoRequest, UpdateUserInfoResponse>(
    '/api/node/i18n/user/update',
    'post',
    { json: true },
);
export const modifyAuthor = request('/api/author/home/modify_author/v0/', 'post');

// 首页
export const keepEmail = request('/api/author/home/keep_email/v0', 'post');

export const getRewardStatus = request('/api/author/statistic/benefit_status/v0');

export const getRewardList = request('/api/author/home/get_activities/v0');

// 详情页
export const getArticleDetail = requestInApp<GetDetailPageRequest, GetDetailPageResponse>(
    '/api/author/home/get_detail_page/v0/',
);

export const diggArticle = requestInApp<DetailPageDiggRequest, DetailPageDiggResponse>(
    '/api/author/home/detail_page/digg/v0/',
    'post',
);

// 详情页评论
export const getItemCommentList = requestInApp<GetItemCommentListRequest, GetItemCommentListResponse>(
    '/api/author/home/comment/item_comment_list/v0/',
);

// 获取课堂详情页 - 课堂推荐列表
export const getClassRecommendList = requestInApp<GetClassRecommendRequest, GetClassRecommendResponse>(
    '/api/author/home/class_recommend_list/v0',
);
// 上报课堂详情页 pv
export const reportClassRead = requestInApp<ClassReadEventRequest, ClassReadEventResponse>(
    '/api/author/home/class_read_event/v0',
    'post',
);

export const getCommentReplyList = requestInApp<
    ExtendPropType<GetReplyCommentListRequest, 'comment_id', string>,
    GetReplyCommentListResponse
>('/api/author/home/comment/reply_comment_list/v0');

export const publishComment = requestInApp<
    ExtendPropType<CommentPublishRequest, 'item_id' | 'post_id', string>,
    CommentPublishResponse
>('/api/author/home/comment/publish/v0/', 'post');

// 回复评论
export const replyComment = requestInApp<
    ExtendPropType<CommentReplyRequest, 'comment_id' | 'to_reply_id', string>,
    CommentReplyResponse
>('/api/author/home/comment/reply/v0/', 'post');

// 举报评论
export const reportComment = requestInApp<
    ExtendPropType<CommentReportRequest, 'comment_id', string>,
    CommentReportResponse
>('/api/author/home/comment/report/v0/', 'post');

// 获取举报类型
export const getCommentReportTypeList = requestInApp<CommentReportTypeListRequest, CommentReportTypeListResponse>(
    '/api/author/home/comment/report_type_list/v0/',
);

// 点赞/取消点赞评论
export const diggComment = requestInApp<ExtendPropType<CommentDiggRequest, 'comment_id', string>, CommentDiggResponse>(
    '/api/author/home/comment/digg/v0/',
    'post',
);

export const uploadCover = request('/api/author/book/upload_pic/v0', 'post', { form: true });

// 获取FAQ分类
export const getFAQCategory = request('/api/author/home/hfc/all_category/v0');
// 搜索FAQ
export const searchFAQ = request('/api/author/home/hfc/search_article/v0');
// 获取FAQ的文章
export const getFAQArticle = request('/api/author/home/hfc/article_info/v0');
// 获取当前语区是否开启FAQ
export const getFAQConfig = request('/api/author/home/get_entrance/v0/');

export const getAcademyList = request<GetCreationClassListRequest, GetCreationClassListResponse>(
    '/api/author/home/creation_class_list/v0',
);

export const getAcademyZoneList = request<GetCreationZoneListRequest, GetCreationZoneListResponse>(
    '/api/author/home/creation_zone_list/v0',
);

export const getClassList = request('/api/author/home/creation_class_list/v0/');
export const getNoticeList = request('/api/author/home/announcement_list/v0/');

// 帖子详情页
export const getPostDetail = request<GetPostDetailRequest, GetPostDetailResponse>('/api/author/home/post/detail/v0');

export const getPostDetailInApp = requestInApp<GetPostDetailRequest, GetPostDetailResponse>(
    '/api/author/home/post/detail/v0',
);

export const diggPost = requestInApp<PostDiggRequest, PostDiggResponse>('/api/author/home/post/digg/v0/', 'post');

// 帖子详情页评论
export const getPostCommentList = requestInApp<GetPostCommentListRequest, GetPostCommentListResponse>(
    '/api/author/home/comment/post_comment_list/v0',
);

// 收藏帖子
export const postCollectPost = requestInApp<PostCollectRequest, PostCollectResponse>(
    '/api/author/home/post/collect/v0',
    'post',
);

// 关注作者
export const postFollowAuthor = requestInApp<ForumAuthorFollowRequest, ForumAuthorFollowResponse>(
    '/api/author/home/forum/author_follow/v0',
    'post',
);
// 上报课堂打分
export const reportClassScore = requestInApp<DetailPageScoreRequest, DetailPageScoreResponse>(
    '/api/author/home/detail_page/score/v0',
    'post',
);



// 版权页---列表
export const getCopyrightList = requestInApp<GetCopyrightListRequest, GetCopyrightListResponse>(
    '/api/pc/config/copyright_list'
)
