import React, { HTMLAttributes } from 'react';
import cs from 'classnames';
import './index.less';

export interface EmptyProps extends HTMLAttributes<HTMLDivElement> {
    text?: string;
    fixed?: boolean; // default to false
    absolute?: boolean; // default to false
    type?: 'empty' | 'no-network' | 'fail'; // default to empty
}

export default function Empty({ className = '', text, fixed, absolute, type = 'empty', ...props }: EmptyProps) {
    return (
        <div {...props} className={cs('app-empty', { fixed, absolute }, className)}>
            <div className="app-empty-wrapper">
                <div className={cs('app-empty-image', `${type}-image`)} />
                {text && <div className="app-empty-text">{text}</div>}
            </div>
        </div>
    );
}
