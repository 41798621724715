export const LANG_COOKIE_KEY = 'local_lang';

export enum LangCode {
    /** 英语 */
    EN = 'en',
    /** 印尼语 */
    ID = 'id',
    /** 西语 */
    ES = 'es',
    /** 葡语 */
    PT = 'pt',
}

/** 页面支持语言列表 */
export const SUPPORT_LANGS = [
    {
        name: 'English',
        code: LangCode.EN,
    },
    {
        name: 'Bahasa Indonesia',
        code: LangCode.ID,
    },
];

export enum I18nNameSpaces {
    PC = 'pc',
}

/** 根据地区获取官方语言 */
export const getLangByRegion = (code?: string) => {
    // 印尼
    if (code === 'ID') {
        return LangCode.ID;
    }

    return LangCode.EN;
};

/** 根据Cookie获取语言设置 */
export const getLangByCookie = (cookie: string) => {
    const cookieLang = cookie && cookie.split(';').find(item => item?.includes(LANG_COOKIE_KEY));
    if (cookieLang) {
        const list = cookieLang.split('=');
        if (list.length > 1) {
            return list[1];
        }
    }
    return '';
};

/** 是否为支持语言 */
export const isSupportLang = (lang?: string) => {
    if (!lang) {
        return false;
    }

    return SUPPORT_LANGS.some(i => i.code === lang);
};
