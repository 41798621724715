import { Message } from '@byted/serial-components-i18n';
import { MessageText } from '@constants/value';
import { getConfigs, getCopyrightList } from '@service/api';
import { Copyright } from '@typings/api/novel/toutiao_novel_serial_pc_i18n';
import { useEffect, useState } from 'react';

const CopyrightBannerKey = 'fizzo_home_copyright_banner_id';

export const PAGE_COUNT = 10;

export const useCopyrightReq = () => {
    const [pageIndex, setPageIndex] = useState(0);
    const [total, setTotal] = useState(0);
    const [list, setList] = useState<Copyright[]>([]);
    const [banner, setBanner] = useState<{ uri: string; link: string }[]>([]);
    const [listLoading, setListLoading] = useState(true);
    const [bannerLoading, setBannerLoading] = useState(true);

    const getList = async (currentPage?: number) => {
        try {
            if (currentPage === undefined) {
                setListLoading(true);
            }
            const { data, code } = await getCopyrightList({
                page_count: PAGE_COUNT,
                page_index: currentPage ?? pageIndex,
            });

            if (code !== 0) {
                throw Error();
            }
            setList(data?.copyright_list || []);
            setTotal(data.total_count);
            if (currentPage !== undefined) {
                setPageIndex(currentPage);
            }
        } catch (error) {
            Message.error(MessageText.netError);
        } finally {
            setListLoading(false);
        }
    };

    const getBanner = async () => {
        try {
            setBannerLoading(true);
            const resp: any = await getConfigs({ keys: CopyrightBannerKey });
            if (resp.code) {
                throw Error();
            }
            setBanner(resp?.data?.[CopyrightBannerKey]?.data || []);
        } catch (error) {
            Message.error(MessageText.netError);
        } finally {
            setBannerLoading(false);
        }
    };

    useEffect(() => {
        getBanner();
        getList();
    }, []);

    return {
        initLoading: listLoading || bannerLoading,
        banner,
        list,
        total,
        pageIndex,
        listLoading,
        getList,
    };
};
