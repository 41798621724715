import React, { useEffect, useRef, useState } from 'react';
import './index.less';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import classNames from 'classnames';
import { AcademyCarouselItem } from '@containers/home-id/type';
import { Tea, jumpUrl } from '@utils/index';

gsap.registerPlugin(ScrollTrigger);

export interface DataType {
    img_url: string;
    title: string;
    desc: string;
    author: string;
}

const AnimationStatus = [{ rotateZ: 0 }, { rotateZ: -4.56 }, { rotateZ: 6.06 }];

export const useAnimation = (trigger: Element | string | null, dataIsInited: boolean) => {
    const [current, setCurrent] = useState(0);
    const [progress, setProgress] = useState<number>(0);
    const startTime = useRef(0);

    useEffect(() => {
        if (!dataIsInited || !trigger) {
            return;
        }
        let timer;
        function createAnimation() {
            let curIndex;
            let isInited = false;
            let hasEnter = false;
            const cards: Element[] = gsap.utils.toArray('.home-id-academy-carousel-card');
            const cardLen = cards.length;
            const maxZIndex = cardLen;
            let start = false;

            const onProgress = () => {
                timer = requestAnimationFrame(() => {
                    const offset = Number(new Date()) - startTime.current;
                    const totalTime = start ? 2800 : 2000;
                    cancelAnimationFrame(timer);

                    let progress = offset / totalTime;
                    if (progress > 1) {
                        progress = 0;
                    }
                    setProgress(progress);
                    onProgress();
                });
            };

            const getNextIndex = index => {
                let next = index + 1;
                if (next >= cards.length) {
                    next = 0;
                }
                return next;
            };

            const changeIndex = (index: number) => {
                setCurrent(index);
                curIndex = index;
            };

            const play = () => {
                const tl = gsap.timeline();
                const el = cards[curIndex];

                tl.to(
                    el,
                    {
                        zIndex: 1,
                        opacity: 0,
                        translateY: 80,
                        duration: 0.3,
                        ease: 'myEase',
                        onStart: () => {
                            start = true;
                            startTime.current = Number(new Date());
                        },
                    },
                    2,
                )
                    .to(el, {
                        translateY: 0,
                        duration: 0,
                        ease: 'myEase',
                    })
                    .to(el, {
                        opacity: 1,
                        duration: 0.5,
                        rotateZ: AnimationStatus[cardLen - 1]?.rotateZ,
                        ease: 'myEase',
                        onStart: () => {
                            // 更新当前卡片的index, 0-2循环
                            curIndex = getNextIndex(curIndex);
                            changeIndex(curIndex);
                            const el1 = cards[curIndex];
                            gsap.to(el1, {
                                rotateZ: AnimationStatus[0].rotateZ,
                                zIndex: maxZIndex,
                                ease: 'myEase',
                                onComplete: play,
                            });

                            // 更新其他卡片的位置
                            let pointIndex = curIndex;
                            for (let i = 0; i < cardLen - 2; i++) {
                                pointIndex = getNextIndex(pointIndex);
                                const el2 = cards[pointIndex];
                                gsap.to(el2, {
                                    rotateZ: AnimationStatus[i + 1].rotateZ,
                                    zIndex: maxZIndex - i - 1,
                                    ease: 'myEase',
                                });
                            }
                        },
                    });
            };

            const init = () => {
                cards.forEach((el, index) => {
                    const params: gsap.TweenVars = {
                        rotateZ: AnimationStatus[index].rotateZ,
                        zIndex: maxZIndex - index,
                        duration: 0,
                        ease: 'myEase',
                    };
                    gsap.to(el, params);
                });
                startTime.current = Number(new Date());
                isInited = true;
                changeIndex(0);
                if (hasEnter) {
                    startPlay();
                }
            };

            const startPlay = () => {
                startTime.current = Number(new Date());
                play();
                onProgress();
                scrollTrigger?.kill();
            };

            const scrollTrigger = ScrollTrigger.create({
                trigger,
                start: 'top center',
                onEnter: () => {
                    hasEnter = true;
                    if (isInited) {
                        startPlay();
                    }
                },
            });

            init();
        }

        if (dataIsInited) {
            createAnimation();
        }

        return () => cancelAnimationFrame(timer);
    }, [dataIsInited, trigger]);

    return { current, progress };
};

interface IProps {
    trigger: Element | string | null;
    data: AcademyCarouselItem[];
    isInited: boolean;
    isMobile?: boolean;
    onClick?: (item: AcademyCarouselItem) => void;
}

const AcademyCarousel: React.FC<IProps> = ({ data, trigger, isInited, isMobile, onClick }) => {
    const { progress } = useAnimation(trigger, isInited);

    const cards = data.slice().splice(0, 3);

    return (
        <div className={classNames('home-id-academy-carousel', { mobile: isMobile })}>
            <div className="home-id-academy-carousel-cards">
                {cards.map((item, index) => (
                    <div
                        className="home-id-academy-carousel-card"
                        key={index}
                        onClick={() => {
                            Tea('enter_academy', { url: item.link });
                            onClick?.(item);
                            jumpUrl(item.link);
                        }}
                    >
                        <div className="img-wrapper">
                            <img src={item.image_url} alt="" />
                        </div>
                        <div className="card-content">
                            <h6>{item.title}</h6>
                            <div className="card-desc">{item.intro}</div>
                            {!isMobile && <span>{item.desc}</span>}
                        </div>
                    </div>
                ))}
            </div>
            <div className="home-id-academy-carousel-time">
                <span style={{ width: `${progress * 100}%` }} />
            </div>
        </div>
    );
};
export default AcademyCarousel;
