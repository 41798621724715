/**
 * 通用评论卡片实现 copy from mobile
 */
import React, {
    ForwardedRef,
    MouseEventHandler,
    useCallback,
    useImperativeHandle,
    useRef,
    useState,
    forwardRef,
    HTMLAttributes,
    useMemo,
} from 'react';
import { useTranslation } from '@ies/intl-react-plugin';
import { I18n } from '@ies/starling_intl';
import { CommentInfo, CommentLabelType, ReplyCommentInfo } from '@typings/api/novel/toutiao_muye_overseas_homeapi';
import cs from 'classnames';
import Icon from '@components/icon';
import { dayjs } from '@utils/day';
import CommentCardAction, { ActionItemProps } from '@components/h5/comment-list/comment-card/action';
import { getPX } from '@utils/px';
import { goH5Admin, UserPathH5 } from '@utils/nav';
import { useUserInfo } from '@utils/hooks';
import { Tea } from '@utils/tea';
import { EVENTS } from '@constants/event';
import { GoToUserPageTeaEnum, PostRole } from '@containers/post/typings';
import ExpandableText from '@components/h5/expandable-text';

import './index.less';

const OneMinuteMs = 1 * 60 * 1000;
const OneHourMs = OneMinuteMs * 60;
const OneDayMs = OneHourMs * 24;
const TwoDayMs = OneDayMs * 2;
const OneWeekMs = OneDayMs * 7;

export const TitleActionCls = 'title-action';
export const DiggCls = 'digg';
export const PressKeyCls = '_pk_';

function getTimeText(time: string) {
    const { t } = I18n;
    const duration = Date.now() - Number(time) * 1000;
    if (isNaN(duration)) {
        return '';
    }
    if (duration < OneMinuteMs) {
        return t('just_now', undefined, 'Just now');
    }
    if (duration < OneHourMs) {
        const value = Math.floor(duration / OneMinuteMs);
        return t('min', { value });
    }
    if (duration < OneDayMs) {
        const value = Math.floor(duration / OneHourMs);
        return t('hour', { value });
    }
    if (duration < TwoDayMs) {
        return t('yesterday');
    }
    if (duration < OneWeekMs) {
        const value = Math.floor(duration / OneDayMs);
        return t('day', { value });
    }
    return dayjs(new Date(Number(time) * 1000)).format('ll');
}

function getDiggText(comment: GeneralComment) {
    const { digg_count, author_digg } = comment;
    let count = digg_count;
    if (author_digg && !count) {
        count += 1;
    }
    if (!count || count < 0) {
        return '';
    }
    if (count > 1000000) {
        // 固定保留一位小数
        return `${(count / 1000000).toFixed(1)}M`;
    }
    if (count > 1000) {
        // 保留一位小数，省略多余0
        return `${String(Number((count / 1000).toFixed(1)))}K`;
    }
    return String(count);
}

// 评论 / 回复评论
export type GeneralComment = (CommentInfo | ReplyCommentInfo) & Partial<CommentInfo & ReplyCommentInfo>;

export interface CommentCardProps extends HTMLAttributes<HTMLDivElement> {
    inset?: boolean; // 二级评论
    pressed?: boolean; // 选中态
    comment: GeneralComment; // 评论对象，CommentInfo / ReplyCommentInfo
    showChapter?: boolean; // 展示章节跳转
    showDelete?: boolean; // 展示删除操作
    showReport?: boolean; // 展示举报操作
    noReply?: boolean; // 不展示回复按钮
    topDivider?: boolean; // 展示顶部分隔线
    bottomDivider?: boolean; // 展示底部分隔线
    onClickReply?: MouseEventHandler<HTMLElement>; // 点击回复（整个卡片都是热区）
    onClickReport?: MouseEventHandler<HTMLElement>; // 点击举报
    onClickDelete?: MouseEventHandler<HTMLElement>; // 点击删除
    onClickDigg?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<void>; // 点赞
    onClickChapter?: MouseEventHandler<HTMLElement>; // 点击章节
}

export interface CommentCardRef {
    dom: HTMLDivElement | null;
    highlight: () => void; // 触发高亮
}

// 5行每行18px/0.36rem
const getInitMaxHeightPx = () => `${Math.floor(getPX(18) * 5)}px`;

function CommentCardRender(
    {
        inset,
        pressed,
        noReply,
        comment,
        showChapter,
        showDelete,
        showReport,
        topDivider,
        bottomDivider,
        onClickReply,
        onClickReport,
        onClickDelete,
        onClickDigg,
        onClickChapter,
        onClick,
        ...props
    }: CommentCardProps,
    ref: ForwardedRef<CommentCardRef>,
) {
    const { i18n } = useTranslation();
    const [actionVisible, setActionVisible] = useState(false);
    const [diggLoading, setDiggLoading] = useState(false);
    const [highlight, setHighlight] = useState(false);
    const domRef = useRef<HTMLDivElement>(null);
    const actionBtnRef = useRef<HTMLSpanElement>(null);

    const { id: uid } = useUserInfo();
    const highlightFn = useCallback(() => {
        setHighlight(true);
        setTimeout(() => {
            setHighlight(false);
        }, 1500);
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            dom: domRef.current,
            highlight: highlightFn,
        }),
        [],
    );
    const timeStr = useMemo(() => getTimeText(comment.create_time), [comment]);

    const onClickTitleActionBtn: MouseEventHandler = e => {
        e.stopPropagation();
        setActionVisible(!actionVisible);
    };
    const onClickDiggBtn: MouseEventHandler<HTMLElement> = e => {
        if (diggLoading) {
            return;
        }
        setDiggLoading(true);
        e.stopPropagation();
        onClickDigg?.(e).finally(() => {
            setDiggLoading(false);
        });
    };

    const onClickChapterName: CommentCardProps['onClickChapter'] = e => {
        e.stopPropagation();
        onClickChapter?.(e);
    };

    const commentText = useMemo(() => {
        // 当回复一级评论时to_reply_id和reply_to_user为空（可能出现id == '0'）
        if (comment.reply_to_user && Boolean(Number(comment.to_reply_id))) {
            const name = comment.reply_to_user.user_name;
            return (
                <span>
                    <span className="reply-text-name">{`${i18n.t(
                        'reply',
                        undefined,
                        'Reply',
                    )}${' @'}${name}${': '}`}</span>
                    <span>{comment.text}</span>
                </span>
            );
        }
        return comment.text;
    }, [comment.reply_to_user, comment.to_reply_id, comment.text]);

    const chapterText = useMemo(() => {
        if (!showChapter) {
            return '';
        }
        return `${i18n.t('comment_chapter_prefix')} ${comment.chapter_title}`;
    }, [comment.chapter_title, showChapter]);

    const actions = useMemo(() => {
        const list: ActionItemProps[] = [];
        if (showReport) {
            list.push({ text: i18n.t('report', undefined, 'Report'), iconType: 'tip-off', onClick: onClickReport });
        }
        if (showDelete) {
            list.push({
                text: i18n.t('delete', undefined, 'Delete'),
                iconType: 'delete-normal',
                onClick: onClickDelete,
            });
        }
        return list;
    }, [showReport, showDelete, onClickReport, onClickDelete]);

    const hasAction = showDelete || showReport;
    const getActionPosition = () => {
        const rect = actionBtnRef.current?.getBoundingClientRect();
        if (!rect) {
            return { right: 0, top: 0 };
        }
        return { right: window.innerWidth - rect.right, top: rect.bottom + getPX(8) };
    };

    const handleAvatar = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        Tea(EVENTS.ENTER_FORUM_HOMEPAGE, {
            channel: GoToUserPageTeaEnum.comment,
            role: uid === comment.comment_user.user_id ? PostRole.Subject : PostRole.Object,
            uid: comment.comment_user.user_id,
        });
        e.stopPropagation();
        goH5Admin(`${UserPathH5}/${comment.comment_user.user_id}`, {
            isSubject: uid === comment.comment_user.user_id ? 1 : 0,
        });
    };

    const Avatar = (
        <div className="comment-card-avatar">
            <div
                style={{ backgroundImage: `url(${comment.comment_user.avatar_url})` }}
                className="avatar-image"
                onClick={handleAvatar}
            />
        </div>
    );
    const Title = (
        <div className="comment-card-title">
            <div className="title-name">
                <div className="title-name-text">{comment.comment_user.user_name}</div>
                {comment.comment_user.is_author && (
                    <div className="title-name-author">
                        <div className="title-name-author-text">{i18n.t('author', undefined, 'Author')}</div>
                    </div>
                )}
                {comment.label_list?.map?.(label => {
                    const labelMap = {
                        [CommentLabelType.OFFICIAL]: {
                            styleType: 'gray',
                            text: 'Fizzo',
                        },
                        [CommentLabelType.PINNED]: {
                            styleType: '',
                            text: i18n.t('pinned_comment', undefined, 'Pinned'),
                        },
                    };
                    const config = labelMap[label] || {};
                    return (
                        <div key={label} className={`title-name-author ${config.styleType || ''}`}>
                            <div className="title-name-author-text">{config.text}</div>
                        </div>
                    );
                })}
            </div>
            {hasAction && (
                <Icon
                    ref={actionBtnRef}
                    onClick={onClickTitleActionBtn}
                    className={TitleActionCls}
                    type="menu-horizontal"
                />
            )}
            <CommentCardAction
                visible={actionVisible}
                setVisible={setActionVisible}
                actions={actions}
                getPosition={getActionPosition}
            />
        </div>
    );
    const Text = (
        <ExpandableText
            onClickExpand={e => e.stopPropagation()}
            className="comment-card-text"
            pressed={pressed}
            highlight={highlight}
            value={commentText}
            initHeight={getInitMaxHeightPx()}
        />
    );
    const Chapter = showChapter && comment.chapter_title && (
        <div className="comment-card-chapter">
            <div className="chapter-border" />
            <div onClick={onClickChapterName} className="chapter-name">
                <div className="text">{chapterText}</div>
                <Icon className="icon" type="arrow-right-normal" />
            </div>
        </div>
    );
    const Bottom = (
        <div className="comment-card-bottom">
            <div className="bottom-time">{timeStr}</div>
            {!noReply && (
                <div className="reply-btn">
                    <span className="text">{i18n.t('reply', undefined, 'Reply')}</span>
                </div>
            )}
            <div className={cs(DiggCls, { 'is-digg': comment.author_digg })}>
                <div className="digg-count">{getDiggText(comment)}</div>
                <Icon onClick={onClickDiggBtn} className="digg-icon" type="digg" />
            </div>
        </div>
    );
    return (
        <div
            {...props}
            ref={domRef}
            className={cs('comment-card', PressKeyCls, { inset, highlight, pressed })}
            onClick={e => {
                onClick?.(e);
                if (!noReply) {
                    onClickReply?.(e);
                }
            }}
        >
            <div className="comment-card-background" />
            {topDivider && <div className="comment-card-divider" />}
            <div className="comment-card-wrap">
                {Avatar}
                <div className="comment-card-content">
                    {Title}
                    {Text}
                    {Chapter}
                    {Bottom}
                </div>
            </div>
            {bottomDivider && <div className="comment-card-divider" />}
        </div>
    );
}

export default forwardRef(CommentCardRender);
