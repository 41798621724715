import React, { useEffect, useRef, useState } from 'react';

import { createAccountInstance } from './sdk';
import cs from 'classnames';

import { useTranslation } from '@ies/intl-react-plugin';
import { useGoAdmin, useRefUpdate, useScrollFix, useUserInfo } from '@utils/index';

import { AccountPage } from './constants';

import Login from './login';
import Register from './register';
import AddName from './add-name';
import Form from './register/form';
import Icon from '@components/icon';
import { Drawer } from '@byted/serial-components-i18n';

import './index.less';
import './index.h5.less';

export const useAccount = (isMobile = false, loginCallback?: () => void) => {
    const [accountVisible, setAccountVisible] = useState(false);
    const { isLogin, media_id } = useUserInfo();
    const [callback, setCallback] = useRefUpdate<(() => void) | undefined>();
    const goAdmin = useGoAdmin();

    useScrollFix(accountVisible);

    const handleWrite = (cb?: () => void) => {
        setCallback(cb);
        // 判断有没有media_id
        if (isLogin && media_id) {
            goAdmin();
        } else {
            setAccountVisible(true);
        }
    };

    const handleClose = () => {
        setAccountVisible(false);
    };

    const getAccountPopup = () => (
        <Account
            isMobile={isMobile}
            visible={accountVisible}
            onClose={handleClose}
            loginCallback={callback || loginCallback}
        />
    );
    return {
        accountVisible,
        setAccountVisible,
        handleWrite,
        getAccountPopup,
    };
};

interface IProps {
    isMobile?: boolean;
    visible: boolean;
    onClose: () => void;
    loginCallback?: () => void;
}

const Account: React.FC<IProps> = ({ isMobile, visible, onClose, loginCallback }) => {
    const { i18n } = useTranslation();
    const { isLogin } = useUserInfo();
    const drawerRef = useRef<HTMLDivElement>(null);

    const [pageList, setPageList] = useState<AccountPage[]>([AccountPage.Register]);
    const [pageExtraParams, setPageExtraParams] = useState<any>();

    useEffect(() => {
        if (visible && isLogin) {
            changePage(AccountPage.AddName, true);
        }
    }, [visible]);

    const backPrePage = () => {
        const list = pageList.slice();
        list.pop();
        setPageList(list);
    };

    const changePage = (page: AccountPage, replace?: boolean, extra?: any) => {
        const list = replace ? [] : pageList.slice();
        list.push(page);
        setPageList(list);
        if (extra) {
            setPageExtraParams(extra);
        }
    };
    const pageCount = pageList.length;
    const renderPage = () => {
        const currentPage = pageList[pageCount - 1];
        switch (currentPage) {
            case AccountPage.Login:
                return <Login onChangePage={changePage} loginCallback={loginCallback} />;
            case AccountPage.Register:
                return <Register onChangePage={changePage} isMobile={isMobile || false} />;
            case AccountPage.AddName:
                return <AddName extra={pageExtraParams} />;
            default:
                return <Form type={currentPage} onChangePage={changePage} extra={pageExtraParams} />;
        }
    };
    useEffect(() => {
        createAccountInstance();
    }, []);
    return (
        <Drawer
            className={cs('account-drawer', { h5: isMobile })}
            visible={visible}
            closable
            maskClosable={false}
            placement={isMobile ? 'right' : 'bottom'}
            onCancel={onClose}
            footer={null}
            unmountOnExit
        >
            <div className="account" ref={drawerRef}>
                <div className="account-left" />
                <div className="account-right">
                    {pageCount > 1 && (
                        <div onClick={backPrePage} className="account-right-back">
                            <Icon type="point" />
                            {i18n.t('sso_back')}
                        </div>
                    )}
                    <div className="account-right-page">{renderPage()}</div>
                </div>
            </div>
        </Drawer>
    );
};

export default Account;
