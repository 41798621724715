import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import { useTranslation } from '@ies/intl-react-plugin';
import { Button, Message, Select } from '@byted/serial-components-i18n';
import { getDateString, isEmailVaild, isPasswordVaild, Tea } from '@utils/index';
import Icon from '@components/icon';
import { updateUser } from '@containers/user';
import Input from '../input';
import {
    YearOptions,
    getDaysOptions,
    AccountPage,
    ErrorCode,
    Events,
    JoinType,
    JoinWay,
    InformationType,
    AgeNotice,
    ReportEvent,
} from '../constants';
import { accountSDK } from '../sdk';
import { AuthRegisterParams } from '../oauth';
import { AccountLogger } from '@utils/logger';

import './index.less';

interface IProps {
    type: AccountPage;
    extra: AuthRegisterParams;
    onChangePage: (page: AccountPage, replace?: boolean) => void;
}

export interface FormField {
    value: string;
    error?: string | boolean[];
}

const AgeNoticeCodeMap = {
    [ErrorCode.AgeGate]: AgeNotice.AgeGate,
    [ErrorCode.DeviceLocked]: AgeNotice.DeviceLock,
};

const Form: React.FC<IProps> = ({ type, extra, onChangePage }) => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();

    const [email, setEmail] = useState<FormField>({ value: '' });
    const [code, setCode] = useState<FormField>({ value: '' });
    const [password, setPassword] = useState<FormField>({ value: '' });

    const [daysOptions, setDaysOptions] = useState(getDaysOptions());
    const [birthError, setBirthError] = useState(false);
    const [month, setMonth] = useState<number>();
    const [day, setDay] = useState<number>();
    const [year, setYear] = useState<number>();

    const [ticket, setTicket] = useState('');

    const MonthOptions = i18n
        .t('sso_agegate_monthselect')
        .split(',')
        .map((m, i) => ({
            label: m,
            value: i + 1,
        }));

    useEffect(() => {
        if (extra?.profileKey) {
            setTicket(extra.profileKey);
        }
    }, [extra]);

    useEffect(() => {
        if (!month || !year) {
            return;
        }
        const newDate = new Date(year, month, 0);
        const newdays = newDate.getDate();
        if (newdays !== daysOptions.length) {
            setDaysOptions(getDaysOptions(newdays));
            if (day && day > newdays) {
                setDay(1);
            }
        }
    }, [month, year]);

    const checkEmail = () => {
        if (!isEmailVaild(email.value)) {
            setEmail(old => ({
                ...old,
                error: i18n.t('sign_email_error'),
            }));
        }
    };

    const checkCode = () => {
        if (!code.value.length) {
            setCode(old => ({
                ...old,
                error: i18n.t('sso_code_error'),
            }));
        }
    };

    const checkPwd = () => {
        const { value } = password;
        const lenValid = value.length >= 8 && value.length <= 20;
        const isFormat = isPasswordVaild(value);

        setPassword(old => ({
            ...old,
            error: [!lenValid, !isFormat],
        }));
    };

    const handleSetPwd = (value: string) => {
        setPassword({
            value,
            error: [],
        });
    };

    const registerSendCode = async () => {
        try {
            await accountSDK.login.sendEmailCode({
                email: email.value,
                password: '',
                extra_params: {
                    email_logic_type: 2,
                },
            });
        } catch (e: any) {
            const { error_code, description } = e || {};
            let tip = description;
            switch (error_code) {
                case ErrorCode.EmailExist:
                    tip = i18n.t('sso_email_exist');
                    break;
                default:
                    break;
            }
            setEmail(old => ({
                ...old,
                error: tip,
            }));
        }
    };

    const forgetSendCode = async () => {
        try {
            await accountSDK.forgetPwd.sendEmailCode({
                email: email.value,
                extra_params: {
                    email_logic_type: 2,
                },
            });
        } catch (e: any) {
            const { error_code, description } = e || {};
            let tip = description;
            switch (error_code) {
                case ErrorCode.NotRegistered:
                    tip = i18n.t('sso_username_error');
                    break;
                default:
                    break;
            }
            setEmail(old => ({
                ...old,
                error: tip,
            }));
        }
    };

    const sendCode = async () => {
        if (type === AccountPage.Forget) {
            await forgetSendCode();
            return;
        }
        await registerSendCode();
    };

    const resetPassword = async () => {
        try {
            await accountSDK.forgetPwd.resetPwdByEmail({
                email: email.value,
                code: code.value,
                password: password.value,
            });
            onChangePage(AccountPage.Login, true);
        } catch (e: any) {
            const { error_code, description } = e || {};
            switch (error_code) {
                case ErrorCode.CodeError:
                case ErrorCode.CodeFail:
                    setCode(old => ({
                        ...old,
                        error: i18n.t('sso_code_error'),
                    }));
                    break;
                default:
                    Message.error(description);
                    break;
            }
        }
    };

    const registerEmail = async () => {
        try {
            await accountSDK.login.emailRegister({
                email: email.value,
                code: code.value,
                password: password.value,
                extra_params: {
                    email_ticket: ticket,
                    birthday: getDateString(year!, month!, day!),
                    email_logic_type: 2,
                },
                ifLogin: true,
            });
            await updateUser(dispatch);
            Tea(Events.WriterJoinThirdPart, {
                join_type: JoinType.Register,
                join_way: JoinWay.Email,
            });
            Tea(Events.WriterJoinInformation, {
                information_type: InformationType.EmailRegsiter,
                age_notice: AgeNotice.Success,
            });
            onChangePage(AccountPage.AddName, true);
        } catch (e: any) {
            const { error_code, description, email_ticket } = e || {};

            // slardar 事件上报
            AccountLogger.info('register_error', {
                error: JSON.stringify(e),
                type: ReportEvent.Email,
            });

            if ([ErrorCode.CodeError, ErrorCode.CodeFail].includes(error_code)) {
                setCode(old => ({
                    ...old,
                    error: i18n.t('sso_code_error'),
                }));
                return;
            }
            if ([ErrorCode.DeviceLocked, ErrorCode.AgeGate].includes(error_code)) {
                setBirthError(true);
                if (email_ticket) {
                    setTicket(email_ticket);
                }
                Tea(Events.WriterJoinInformation, {
                    information_type: InformationType.EmailRegsiter,
                    age_notice: AgeNoticeCodeMap[error_code],
                });
                return;
            }
            Message.error(description);
        }
    };

    const registerAuth = async () => {
        const { platformAppId } = extra;
        try {
            await accountSDK.login.authLogin({
                platform_app_id: platformAppId,
                profile_key: ticket,
                extra_params: {
                    birthday: getDateString(year!, month!, day!),
                },
            });
            await updateUser(dispatch);
            Tea(Events.WriterJoinInformation, {
                information_type: InformationType.EnterBirth,
                age_notice: AgeNotice.Success,
            });
            onChangePage(AccountPage.AddName, true);
        } catch (e: any) {
            const { error_code, description, profile_key } = e || {};

            // slardar 事件上报
            AccountLogger.info('register_error', {
                error: JSON.stringify(e),
                type: ReportEvent.Oauth,
            });

            if ([ErrorCode.DeviceLocked, ErrorCode.AgeGate].includes(error_code)) {
                setBirthError(true);
                if (profile_key) {
                    setTicket(profile_key);
                }
                Tea(Events.WriterJoinInformation, {
                    information_type: InformationType.EnterBirth,
                    age_notice: AgeNoticeCodeMap[error_code],
                });
                return;
            }

            Message.error(description);
        }
    };

    const diasbleButton = () => {
        const emailInvaild = !email.value || email.error;
        const codeInvaild = !code.value || code.error;
        const pwdInvaild = !password.value || (password.error?.length && (password.error as boolean[]).some(i => i));

        switch (type) {
            case AccountPage.Forget:
                return Boolean(emailInvaild || codeInvaild || pwdInvaild);
            case AccountPage.RegisterOauth:
                return Boolean(!year || !month || !day);
            default:
                return Boolean(emailInvaild || codeInvaild || pwdInvaild || !year || !month || !day);
        }
    };

    const renderSetPassword = () => (
        <>
            <Input
                verify
                disableVerify={!isEmailVaild(email.value)}
                value={email.value}
                onChange={value => setEmail({ value, error: '' })}
                onBlur={checkEmail}
                onVerify={sendCode}
                validation={email.error as string}
                placeholder={i18n.t('sso_email_default')}
            />
            <Input
                value={code.value}
                onChange={value => setCode({ value, error: '' })}
                onBlur={checkCode}
                validation={code.error as string}
                placeholder={i18n.t('sso_code_default')}
                autoComplete="off"
            />
            <Input
                password
                value={password.value}
                onChange={handleSetPwd}
                onBlur={checkPwd}
                validation={password.error?.[0] || password.error?.[1] ? 'error' : ''}
                hideValidation
                placeholder={i18n.t('sso_password_default')}
                autoComplete="off"
            />
            <div
                className={cs('pwd-validate', {
                    'pwd-error': password.error?.[0],
                    'pwd-right': password.value.length >= 8 && password.value.length <= 20,
                })}
            >
                <div className="pwd-validate-icon" />
                <span>{i18n.t('sso_password_tip1')}</span>
            </div>
            <div
                className={cs('pwd-validate', {
                    'pwd-error': password.error?.[1],
                    'pwd-right': isPasswordVaild(password.value),
                })}
                style={{ marginTop: 8 }}
            >
                <div className="pwd-validate-icon" />
                <span>{i18n.t('sso_password_tip2')}</span>
            </div>
        </>
    );

    const renderBirthday = () => (
        <div className="birth-select">
            <Select
                placeholder={i18n.t('sso_agegate_month')}
                options={MonthOptions}
                value={month}
                onChange={value => {
                    setMonth(value);
                    setBirthError(false);
                }}
                arrowIcon={<Icon type="trangle" />}
            />
            <Select
                placeholder={i18n.t('sso_agegate_day')}
                options={daysOptions}
                value={day}
                onChange={value => {
                    setDay(value);
                    setBirthError(false);
                }}
                arrowIcon={<Icon type="trangle" />}
            />
            <Select
                placeholder={i18n.t('sso_agegate_year')}
                options={YearOptions}
                value={year}
                onChange={value => {
                    setYear(value);
                    setBirthError(false);
                }}
                arrowIcon={<Icon type="trangle" />}
            />
            <div className={cs('birth-select-msg', { valid: birthError })}>
                {birthError ? `${i18n.t('sso_agegate_error')}` : `${i18n.t('sso_agegate_text')}`}
            </div>
        </div>
    );

    if (type === AccountPage.Forget) {
        return (
            <div className="form-reset">
                <h1>{i18n.t('sso_reset_password')}</h1>
                <h6>{i18n.t('sso_reset_text')}</h6>
                {renderSetPassword()}
                <Button className="account-button" disabled={diasbleButton()} autoLoading onClick={resetPassword}>
                    {i18n.t('sso_reset_password')}
                </Button>
            </div>
        );
    }

    if (type === AccountPage.RegisterOauth) {
        return (
            <div className="form-auth">
                <h1>{i18n.t('sso_birthday_title')}</h1>
                <h6>{i18n.t('sso_birthday_text')}</h6>
                {renderBirthday()}
                <Button className="account-button" disabled={diasbleButton()} autoLoading onClick={registerAuth}>
                    {i18n.t('sso_next_btn')}
                </Button>
            </div>
        );
    }

    return (
        <div className="form-signup">
            <h1>{i18n.t('sso_signup_title1')}</h1>
            <h6>
                {i18n.t('sso_signup_text')}
                &nbsp;
                {i18n.t('sso_login_guide')}
                &nbsp;
                <span onClick={() => onChangePage(AccountPage.Login, true)}>{i18n.t('sso_login_guide1')}</span>
            </h6>
            {renderSetPassword()}
            <p>{i18n.t('sso_birthday_title')}</p>
            {renderBirthday()}
            <Button className="account-button" disabled={diasbleButton()} autoLoading onClick={registerEmail}>
                {i18n.t('sso_next_btn')}
            </Button>
        </div>
    );
};

export default Form;
