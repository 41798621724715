import { HOST } from '@common/index';
import { isBrowser, isTestEnv } from '@constants/const';
import { mueRequestFail, mueResponseFail } from '@utils/monitor';
import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

interface CommonParams {
    aid: number;
}

export const COMMON_PARAMS: CommonParams = {
    aid: 0,
};

export const client = axios.create({
    timeout: 1 * 60 * 1000,
    transformRequest: axios.defaults.transformRequest,
} as AxiosRequestConfig);

const DEV = process.env.NODE_ENV !== 'production';

if (isTestEnv && isBrowser) {
    const urlParams: any = qs.parse(window.location.search.replace('?', ''));
    if (urlParams.env) {
        client.defaults.headers['x-tt-env'] = urlParams.env;
    }
}

export interface RequestConfig extends AxiosRequestConfig {
    json?: boolean;
    form?: boolean;
}

export default function request<T = any, S = any>(
    url: string,
    method: 'get' | 'post' = 'get',
    config: RequestConfig = {},
): (params?: any, ctx?: any) => Promise<S> {
    return (params?: any, ctx?: any) => {
        const requestConfig: RequestConfig = {
            method,
            url,
            ...config,
        };

        const headers = {};

        if (method === 'get') {
            requestConfig.params = params;
        } else if (requestConfig.json) {
            requestConfig.data = JSON.stringify(params);
            headers['Content-Type'] = 'application/json';
        } else if (requestConfig.form) {
            requestConfig.data = params;
        } else {
            requestConfig.data = qs.stringify(params);
        }

        if (ctx) {
            const { header: ctxHeader = {} } = ctx.request;
            const cookieEnv = ctxHeader['cookie'].match(/x_tt_env=(.*)/)?.[1];
            const cookieEnvIsPPE = !!/^ppe.*/.test(cookieEnv);
            const host = DEV ? HOST : ctx.host;
            const env = ctxHeader['x-tt-env'] || ctxHeader['X-TT-ENV'] || cookieEnv;
            const ppe = ctxHeader['x-use-ppe'] || ctxHeader['X-USE-PPE'] || cookieEnvIsPPE;
            const cookie = ctxHeader.cookie;
            if (env) {
                headers['x-tt-env'] = env || cookieEnv;
            }
            if (ppe) {
                headers['x-use-ppe'] = ppe;
            }
            if (cookie) {
                (headers as any).cookie = cookie;
            }

            requestConfig.url = `${ctx.protocol}://${host}${url}`;
        }

        requestConfig.headers = {
            ...requestConfig.headers,
            ...headers,
        };

        return new Promise((resolve, reject) => {
            client(requestConfig)
                .then(resp => {
                    const { status, data, statusText } = resp;
                    if (ctx) {
                        // log后端logid
                        ctx.logger.info(`#get SSR data log_id: ${(resp as any)?.log_id}`);
                        ctx.logger.info(`#get SSR data resp: ${resp}`);
                    }
                    if (status === 200 && data) {
                        resolve(data);
                        return;
                    }
                    mueRequestFail(url, data, {
                        resp_status: status,
                        resp_text: statusText,
                    });
                    reject(data);
                })
                .catch(err => {
                    if (ctx) {
                        ctx.logger.error(
                            `#get SSR data error: url:${requestConfig.url} header: ${JSON.stringify(
                                requestConfig.headers,
                            )} error: ${JSON.stringify(err)}`,
                        );
                    }
                    if (err?.response) {
                        mueRequestFail(url, null, {
                            resp_status: err.response.status,
                            resp_text: err.response.statusText,
                        });
                    } else {
                        mueRequestFail(url);
                    }
                    reject(err);
                });
        });
    };
}
