import { LangCode } from '@common/lang';
import I18n from '@ies/starling_intl';
import { initHomeData } from '@redux/actions/home';
import { Tea } from '@utils/tea';

export interface WriterNote {
    writer_name: string;
    intro: string;
    picture_url: string;
    note: string;
    status: number;
    signature_url: string;
}

export interface BookTrend {
    book_name: string;
    cover_url: string;
}

export interface Advantage {
    title: string;
    content: string;
}

export enum JoinType {
    Banner,
    LongStory,
}

export const loadData = async (ctx: any, store: any) => {
    const { config } = ctx.app.controller;
    try {
        const data = await config.getConfig(ctx, { language: I18n.language });
        store.dispatch(
            initHomeData({
                ...data,
                serverInited: true,
            }),
        );
    } catch {
        console.error('loadData error');
    }
};

export const lazyLoadImg = (el: HTMLElement) => {
    console.log('start load img----------');
    const imgList = Array.from(el.querySelectorAll('img'));
    imgList.forEach(img => {
        if (img.dataset.src && !img.src) {
            img.src = img.dataset.src;
        }
    });
};

export const loadImgAsync = (src: string, retryCount = 0) => {
    return new Promise(resolve => {
        if (retryCount > 3) {
            resolve(true);
        }
        const img = new Image();
        img.onload = () => {
            resolve(true);
        };
        img.onerror = () => {
            loadImgAsync(src, retryCount + 1);
        };
        img.src = src;
    });
};

export const getDownloadUrl = (lang: LangCode) => {
    if (lang === LangCode.EN) {
        return {
            Android: 'https://play.google.com/store/apps/details?id=ebooks.reader.mytopia',
            Ios: 'https://apps.apple.com/us/app/mytopia-my-utopia-of-novels/id1587676837',
        };
    }
    return {
        Android: 'https://play.google.com/store/apps/details?id=e.books.reading.apps',
        Ios: 'itms-apps://itunes.apple.com/app/id1541508020',
    };
};

export const postTea = (type: number) => {
    Tea('webstie_module_show', { module_type: type });
};
