import { CommentInfo } from '@typings/api/novel/toutiao_muye_overseas_homeapi';

export interface CommentInfoWithReplyCursor extends CommentInfo {
    replyPageInfo?: {
        offset: number;
        hasMore: boolean;
    };
}

export enum CommentErrorCode {
    BlackList = -6005,
}
