import { LangCode } from '@common/lang';

const baseURl = '/magic/eco/runtime/release/';

const getMagicUrl = uri => {
    if (typeof window === 'undefined') {
        return '';
    }

    return location.origin + baseURl + uri;
};

interface PageMapType {
    [key: string]: PageEvent;
}

export interface PageEvent {
    // 当当前语言没有活动时，重定向到某个链接
    redirect?: string;
    pages: {
        [key: string]: LangDetail;
    };
    // 进入页面时需要发送的埋点
    tea?: {
        event: string;
        params?: {
            [key: string]: string;
        };
    };
}

export interface LangDetail {
    mobile?: string;
    pc?: string;
    redirect?: string; // 当前活动需要重定向到某个链接
    fixed?: boolean;
}

export const PageMap: PageMapType = {
    benefit: {
        pages: {
            [LangCode.EN]: {
                mobile: getMagicUrl('628b3fa86ed0e8033812fd16?appType=serial'),
                pc: getMagicUrl('628b707241976b034c99f358?appType=serial'),
            },
            [LangCode.ID]: {
                mobile: getMagicUrl('646757fa2f71cc034edc2d7a?appType=serial&magic_page_no=1'),
                pc: getMagicUrl('64671aeaad3d03049bc53f4d?appType=serial&magic_page_no=1'),
                fixed: true,
            },
            [LangCode.PT]: {
                mobile: getMagicUrl('624e9f83eb4ec473e1e73032?appType=serial'),
                pc: getMagicUrl('624e9f6dd98c34b04e53d3cd?appType=serial'),
            },
            [LangCode.ES]: {
                mobile: getMagicUrl('62579b9a90e63e033a3bd61b?appType=serial'),
                pc: getMagicUrl('6257b70a1a08373e682b1e19?appType=serial'),
            },
        },
        tea: {
            event: 'enter_benefit',
        },
    },
    genz: {
        redirect: '/reward',
        pages: {
            [LangCode.ID]: {
                mobile: getMagicUrl('623dd48fd606b00332d2fa89?appType=serial&magic_source=benefitpage'),
                pc: getMagicUrl('62387e95395cc3033cd6d376?appType=serial&magic_source=benefitpage'),
            },
        },
    },
};
